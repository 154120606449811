import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ifProp, ifNotProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import loadable from '@loadable/component';
import { Spinner } from 'reactstrap';

import SiteSettingsContext from '../../context/SiteSettingsContext';
import { DATA_SOURCE_INDEX } from '../../utils/constants';
import theme from '../../styles/theme';
import Feeds from './Feeds';
import FeedHeader from './FeedHeader';
import SpinnerContainer from '../SpinnerContainer';

const ProductPriceFeed = loadable(() => import('./ProductPriceFeed'), {
  fallback: (
    <SpinnerContainer height='100'>
      <Spinner size='lg' />
    </SpinnerContainer>
  )
});
const MonthProvider = loadable(() => import('../../context/MonthContext'), {
  resolveComponent: (monthContext) => monthContext.MonthProvider
});

const PriceFeedColumn = styled.div`
  background: ${prop('theme.colors.white')};
  box-shadow: -1px 1px 0.375rem rgba(0, 0, 0, 0.16);
  border-radius: 1rem;
  margin-bottom: 2rem;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin: 0;
    width: 16.25rem;
    right: 0;
    top: 0;
    z-index: 2;

    ${ifProp(
      { $page: 'news-article-type' },
      css`
        @media (min-width: ${prop('theme.breakpoints.lg')}) {
          margin-top: ${(props) => (props.$scrolling ? '0' : '7.8rem')};
        }
      `
    )}

    ${ifNotProp(
      { $page: 'news-item' },
      css`
        flex: 1 1 20%;
        position: absolute;
        top: 24px;
        ${ifProp(
          '$scrolling',
          css`
            margin-top: 0;
            position: fixed;
            right: ${prop('$rightOffset')}px;
            top: 128px;

            ${ifProp(
              '$fixBottom',
              css`
                position: absolute;
                right: 0;
                bottom: 40px;
                top: auto;
              `
            )}
          `
        )}
      `
    )}
  }
`;

const propTypes = {
  title: PropTypes.string,
  feedData: PropTypes.object
};

const defaultProps = {
  title: '',
  feedData: {}
};

function PriceFeeds({ feedData, title }) {
  const [isMobile, setIsMobile] = useState(false);
  const [rightOffset, setRightOffset] = useState(0);
  const [scrolling, setScrolling] = useState(false);
  const [fixBottom, setFixBottom] = useState(false);
  const offsetTop = title === 'Homepage' ? 450 : 144;
  const siteSettings = useContext(SiteSettingsContext);
  const { isProductPage, symbol } = feedData;
  const columnRef = useRef();

  useEffect(() => {
    const onScroll = (event) => {
      const distFromBottom =
        document.body.scrollHeight - window.innerHeight - window.scrollY;
      const scrollOffset = 266;
      const columnHeightMinusOffset = columnRef.current
        ? columnRef.current.offsetHeight - scrollOffset
        : 776; // default to height for normal price feed

      setScrolling(window.pageYOffset > offsetTop ? true : false);
      setFixBottom(distFromBottom <= columnHeightMinusOffset ? true : false);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrolling, columnRef]);

  useEffect(() => {
    let currentMobile = isMobile;

    const onResize = () => {
      const smallBreakpoint = parseInt(theme.breakpoints.lg, 10);
      const vw = document.body.clientWidth;
      const newValue = vw <= smallBreakpoint;

      if (newValue !== currentMobile) {
        setIsMobile(newValue);
        currentMobile = newValue;
      }

      if (vw >= 1504) {
        const calcWidth = (vw - 1504) / 2;
        setRightOffset(calcWidth);
      } else {
        setRightOffset(0);
      }
    };

    if (window) {
      onResize();
      window.addEventListener('resize', onResize);
    }

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [isMobile]);

  return (
    <PriceFeedColumn
      $page={title}
      $fixBottom={fixBottom}
      $rightOffset={rightOffset}
      $scrolling={scrolling}
      ref={columnRef}
    >
      <div>
        <StaticQuery
          query={`${priceFeedsQuery}`}
          render={(data) => {
            const symbolData = data && data.allPrismicProduct.edges;
            const indexTooltip = siteSettings.global.price_cards_tooltip;

            const symbolObject =
              symbolData &&
              symbolData.reduce((obj, { node }) => {
                const body = node.data.quote_body;
                const dataColumns = body.filter(
                  (elem) => Object.keys(elem).length > 0
                )[0].items;
                return {
                  ...obj,
                  [node.data.price_cards_data_source === DATA_SOURCE_INDEX
                    ? node.data.dxfeed_symbol
                    : node.data.futures_symbol]: {
                    symbol:
                      node.data.price_cards_data_source === DATA_SOURCE_INDEX
                        ? node.data.index_symbol
                        : node.data.futures_symbol,
                    title: node.data.title.text,
                    sort_order: node.data.sort_order,
                    dataColumns: dataColumns,
                    meta: {
                      id: node.prismicId,
                      type: node.type,
                      uid: node.uid
                    },
                    data_source: node.data.price_cards_data_source
                  }
                };
              }, {});

            return (
              <div>
                <FeedHeader symbol={symbol} indexTooltip={indexTooltip} />
                {isProductPage && !isMobile ? (
                  <MonthProvider symbol={symbol}>
                    <ProductPriceFeed
                      symbol={symbol}
                      symbolObject={symbolObject}
                    />
                  </MonthProvider>
                ) : (
                  <Feeds
                    indexTooltip={indexTooltip}
                    isMobile={isMobile}
                    symbolObject={symbolObject}
                  />
                )}
              </div>
            );
          }}
        />
      </div>
    </PriceFeedColumn>
  );
}

export const priceFeedsQuery = graphql`
  query PriceFeedsQuery {
    allPrismicProduct {
      edges {
        node {
          prismicId
          type
          uid
          data {
            title {
              text
            }
            dxfeed_symbol
            futures_symbol
            index_symbol
            price_cards_data_source
            sort_order
            quote_body {
              ... on PrismicProductDataQuoteBodyPriceMovement {
                id
                items {
                  dxfeed_value
                  dxfeed_previous_value
                  label
                  data_type
                }
              }
            }
          }
        }
      }
    }
  }
`;

PriceFeeds.propTypes = propTypes;
PriceFeeds.defaultProps = defaultProps;

export default PriceFeeds;
