import styled from 'styled-components';
import { prop } from 'styled-tools';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  color: ${prop('theme.colors.textGrey')};

  h3 {
    font-size: 1rem;
    font-weight: ${prop('theme.fontWeights.semiBold')};
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0;
  }
`;

export default StyledForm;
