import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import Collapse from 'reactstrap/lib/Collapse';

import BodySectionTitle from './BodySectionTitle';
import BodySection from './BodySection';
import { setBool, getHeadingType } from '../../utils/functions';
import downArrow from '../../images/arrows/down-arrow.svg';
import upArrow from '../../images/arrows/red-up-arrow.svg';
import BlockStyles from '../BlockStyles';
import { BLOCK_STYLES_SIDE_PADDING_ONLY } from '../../utils/constants';

const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-top: 0;
    padding-top: 1rem;
  }
`;

const BodySectionsWrapper = styled.div`
  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    padding-left: 0;
    padding-right: 0;
  }

  > * {
    &:first-child {
      :not(div) {
        padding-top: 1.5rem;
      }
    }
  }
`;

const Toggle = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  margin-top: 1.5rem;

  h2 {
    font-weight: ${prop('theme.fontWeights.medium')};
  }

  h3,
  h4 {
    margin-bottom: 0;
  }
`;

const StyledCollapse = styled(Collapse)`
  &.show,
  &.collapsing {
    margin-top: 1rem;
  }

  ${ifProp(
    { $orientation: 'row' },
    css`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    `
  )}
`;

const StyledHr = styled.hr`
  margin-top: 1.5rem;
  border: 1px solid ${prop('theme.colors.borderGrey')};
`;

function BodySections({
  node,
  index,
  pageUid,
  productSymbol,
  productName,
  template,
  blockHeader,
  blockId,
  tooltip
}) {
  const collapse = setBool(
    !isEmpty(node.section.primary) && node.section.primary.content_collapse
      ? node.section.primary.content_collapse
      : false
  );
  const [isExpanded, setIsExpanded] = useState(
    typeof window !== 'undefined' && window.location.hash.includes(blockId)
  );
  const iconRef = useRef();

  const toggleCollapse = () => {
    if (iconRef.current) {
      iconRef.current.className = isExpanded
        ? 'expanded-icon'
        : 'collapsed-icon';
    }

    setIsExpanded((prev) => !prev);
  };
  const orientation = get(node, 'section.primary.orientation', 'column');

  return (
    <div>
      {collapse ? (
        <BlockStyles template={template} data={BLOCK_STYLES_SIDE_PADDING_ONLY}>
          <Toggle className='block' onClick={toggleCollapse}>
            <BodySectionTitle
              blockHeader={blockHeader}
              tooltip={tooltip}
              collapse
            />
            <img ref={iconRef} src={isExpanded ? upArrow : downArrow} />
          </Toggle>
        </BlockStyles>
      ) : (
        <>
          <BodySectionTitle
            template={template}
            blockHeader={blockHeader}
            primary={node.section.primary}
            tooltip={tooltip}
            first={index === 0}
          />
          <BodySection
            pageUid={pageUid}
            bodySection={node.section}
            productSymbol={productSymbol}
            productName={productName}
            template={template}
          />
        </>
      )}
      <>
        {collapse ? (
          <StyledCollapse isOpen={isExpanded} $orientation={orientation}>
            <BodySection
              bodySection={node.section}
              productSymbol={productSymbol}
              productName={productName}
              template={template}
              titleHidden={true}
            />
            {node.children.map((child, index) => {
              return (
                <BodySections
                  node={child}
                  key={index}
                  productSymbol={productSymbol}
                  productName={productName}
                  template={template}
                />
              );
            })}
          </StyledCollapse>
        ) : (
          node.children.map((child, index) => {
            return (
              <BodySections
                node={child}
                key={index}
                productSymbol={productSymbol}
                productName={productName}
                template={template}
              />
            );
          })
        )}
      </>
      {collapse && (
        <BlockStyles template={template} data={BLOCK_STYLES_SIDE_PADDING_ONLY}>
          <div className='block'>
            <StyledHr />
          </div>
        </BlockStyles>
      )}
    </div>
  );
}

const propTypes = {
  body: PropTypes.array,
  pageUid: PropTypes.string,
  productName: PropTypes.string,
  productSymbol: PropTypes.string,
  template: PropTypes.string
};

const defaultProps = {
  body: [],
  pageUid: '',
  productName: '',
  productSymbol: '',
  template: ''
};

function PageBody({ body, pageUid, productSymbol, productName, template }) {
  const rootNode = {
    children: [],
    section: null,
    level: 0,
    parent: null
  };

  let currentParent = rootNode;

  body.forEach((bodySection) => {
    if (!bodySection) {
      return;
    }
    const headingType = getHeadingType(bodySection);
    let level = null;
    // Get the level only if heading is level 2 through 4.
    const levelMatch = headingType && headingType.match(/^heading([2-4])$/i);
    if (levelMatch && levelMatch.length > 0) {
      level = levelMatch[1];
    }

    if (
      null === level ||
      !['calendar_slice', 'text_block'].includes(bodySection.slice_type)
    ) {
      currentParent.children.push({
        children: [],
        section: bodySection,
        level,
        parent: currentParent
      });
      return;
    }

    if (level > currentParent.level) {
      const newNode = {
        children: [],
        section: bodySection,
        level,
        parent: currentParent
      };

      currentParent.children.push(newNode);
      currentParent = newNode;
      return;
    }

    if (level === currentParent.level) {
      const newNode = {
        children: [],
        section: bodySection,
        level,
        parent: currentParent.parent
      };

      currentParent.parent.children.push(newNode);
      currentParent = newNode;
      return;
    }

    if (level < currentParent.level) {
      // Find the new parent for this section.
      while (level <= currentParent.level) {
        currentParent = currentParent.parent;
      }

      const newNode = {
        children: [],
        section: bodySection,
        level,
        parent: currentParent
      };

      currentParent.children.push(newNode);
      currentParent = newNode;
      return;
    }
  });

  return rootNode.children.map((node, index) => {
    if (template && template === 'page') {
      const primary = node.section.primary;
      const { tooltip } = primary;
      let blockHeader = '';

      if (!isEmpty(primary.text_block_header)) {
        blockHeader = primary.text_block_header.richText[0];
      }

      if (!isEmpty(primary.calendar_title)) {
        blockHeader = primary.calendar_title.richText[0];
      }

      const blockId =
        blockHeader && blockHeader.text
          ? blockHeader.text.replace(/\s/g, '')
          : '';

      return (
        <BlockContainer
          id={blockId}
          key={`block_container_${index}`}
          className='anchorOffset'
        >
          <BodySectionsWrapper>
            <BodySections
              node={node}
              index={index}
              productSymbol={productSymbol}
              productName={productName}
              template={template}
              blockHeader={blockHeader}
              blockId={blockId}
              tooltip={tooltip}
            />
          </BodySectionsWrapper>
        </BlockContainer>
      );
    } else {
      return (
        <BlockContainer key={`block_container_${index}`}>
          <BodySectionsWrapper key={`body_section_wrapper_${index}`}>
            <BodySections
              node={node}
              key={index}
              index={index}
              pageUid={pageUid}
              productSymbol={productSymbol}
              productName={productName}
              template={template}
            />
          </BodySectionsWrapper>
        </BlockContainer>
      );
    }
  });
}

PageBody.propTypes = propTypes;
PageBody.defaultProps = defaultProps;

export default PageBody;
