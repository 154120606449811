import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';

import SEO from '../components/seo';
import Layout from '../components/layout';
import PageContainer from '../components/layout-components/PageContainer';
import MainCol from '../components/PageBody/MainCol';
import ColumnWrapper from '../components/PageBody/styled/ColumnWrapper';
import AuthenticatedWrapper from '../components/Community/AuthenticatedWrapper';
import { StepProvider } from '../context/StepContext';
import RegistrationFlowBody from '../components/Community/Registration/RegistrationFlowBody';
import actions from '../state/actions';
import errorTypes, { fetchErrors } from '../state/actions/errorTypes';
import ErrorPage from '../components/Community/Registration/ErrorPage';
import {
  INVOICE_STATUS_PAID,
  LINK_STATE_CONFIRMED,
  registrationStates,
  UNCONFIRMED_STATES
} from '../utils/constants';
import { isNullValue } from '../utils/functions';
import UnconfirmedIdentityPage from '../components/Community/Registration/UnconfirmedIdentityPage';
import { fetchDataAfterAuthentication } from '../state/actions/identityActions';
import FullPageSpinnerContainer from '../components/SessionCheck/FullPageSpinnerContainer';
import Spinner from '../components/SessionCheck/Spinner';

const propTypes = { data: PropTypes.object, location: PropTypes.object };

const defaultProps = { data: {}, location: {} };

function RegistrationFlow({ data, location }) {
  const {
    page_title,
    header_banner,
    linked_alert_text,
    linked_alert_image,
    allowed_users,
    body
  } = data.prismicRegistrationFlow.data;

  const pageName = page_title.text ? page_title.text : '';
  const totalSteps = body.length;

  const [isLinked, setIsLinked] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const {
    identity,
    subscriber,
    invoices,
    sponsorship,
    sponsorshipCode
  } = useSelector((state) => state.memberInfo);
  const apiErrors = useSelector((state) => state.apiErrors);
  const { loading: isAuthLoading } = useSelector((state) => state.auth);
  const { authResult } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isPermitted, setIsPermitted] = useState(false);

  const params = new URLSearchParams(location.search);
  const linked = params.get('linked');

  const anyInvoicesPaid =
    !isNullValue(invoices) &&
    invoices.some((invoice) => invoice.status === INVOICE_STATUS_PAID);

  const sponsorshipCodeParam = params.get('sponsorshipCode');
  // This covers the scenario where a user started regular registration and then wants to apply a sponsorship
  // This is only allowed if the user has not paid an invoice.
  const sponsoredFlowButNotSponsoredUser = sponsorshipCodeParam && !sponsorship;

  let startingStep = 0;
  if (
    !subscriber ||
    identity.link_state !== LINK_STATE_CONFIRMED ||
    !!linked ||
    sponsoredFlowButNotSponsoredUser
  ) {
    startingStep = 0;
  } else if (
    subscriber.registration_status ===
    registrationStates.PROFILE_UPDATE_REQUIRED
  ) {
    startingStep = 1;
  } else if (
    identity.registrationState === registrationStates.AGREEMENTS_REQUIRED
  ) {
    startingStep = 2;
  } else if (
    identity.registrationState === registrationStates.COMPLETE &&
    !anyInvoicesPaid
  ) {
    startingStep = 3;
  } else if (anyInvoicesPaid) {
    startingStep = totalSteps - 1;
  }

  useEffect(() => {
    if (linked === 'true') {
      setIsLinked(true);

      // When auto-navigating the user to this page after linking their account, data needs to be re-fetched in order to display the correct page
      if (authResult) {
        setIsFetching(true);
        dispatch(
          fetchDataAfterAuthentication(authResult, () => {
            setIsFetching(false);
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    if (!isAuthLoading && Object.keys(identity).length > 0) {
      const allowedUsersArray = allowed_users
        ? allowed_users.split(',').map((userId) => parseInt(userId))
        : [];
      const userIsNotPermitted =
        allowedUsersArray && !allowedUsersArray.includes(identity.id);

      if (userIsNotPermitted) {
        navigate('/registration');
      } else {
        setIsPermitted(true);
      }
    }
  }, [identity, isAuthLoading]);

  const isUnconfirmedIdentity = UNCONFIRMED_STATES.includes(
    identity.link_state
  );

  const areFetchErrorsPresent = apiErrors
    ? Object.keys(apiErrors).some((error) => fetchErrors.includes(error))
    : false;

  if (typeof window !== 'undefined') {
    if (sponsoredFlowButNotSponsoredUser && anyInvoicesPaid) {
      const sponsorErrorIndex = Object.keys(apiErrors).findIndex(
        (error) => error === errorTypes.SPONSORSHIP_AFTER_PAYMENT
      );
      if (sponsorErrorIndex < 0) {
        dispatch({
          type: actions.ADD_API_ERROR,
          payload: {
            type: errorTypes.SPONSORSHIP_AFTER_PAYMENT,
            message:
              'Sorry, you cannot add a sponsorship if you have already paid for a membership.'
          }
        });
      }
      navigate(`/registration`);
    }

    if (location.pathname === '/registration' && sponsorship) {
      navigate(
        `/sponsorship-registration/?sponsorshipCode=${sponsorship.sponsorship_code}`
      );
    } else if (
      !sponsorship &&
      location.pathname === '/sponsorship-registration' &&
      !sponsorshipCodeParam
    ) {
      navigate(`/registration`);
    }
  }

  if (!sponsorshipCode && sponsorshipCodeParam) {
    dispatch({
      type: actions.SET_SPONSORSHIP_CODE,
      payload: sponsorshipCodeParam
    });
  }

  return isFetching ? (
    <FullPageSpinnerContainer>
      <Spinner />
    </FullPageSpinnerContainer>
  ) : (
    <AuthenticatedWrapper location={location}>
      {!isPermitted && !areFetchErrorsPresent ? (
        <FullPageSpinnerContainer>
          <Spinner />
        </FullPageSpinnerContainer>
      ) : (
        <Layout
          title={!areFetchErrorsPresent && pageName}
          headerBanner={header_banner && header_banner.url}
          page='Registration'
        >
          <PageContainer $page='registration'>
            <MainCol>
              <ColumnWrapper subNav={false}>
                {areFetchErrorsPresent ? (
                  <ErrorPage />
                ) : (
                  <StepProvider
                    stepNumber={startingStep}
                    numberOfSteps={totalSteps}
                  >
                    {isUnconfirmedIdentity ? (
                      <UnconfirmedIdentityPage body={body} />
                    ) : (
                      <RegistrationFlowBody
                        body={body}
                        isLinked={isLinked}
                        linkedAlert={{
                          primary: {
                            image: linked_alert_image,
                            text_content: linked_alert_text
                          }
                        }}
                      />
                    )}
                  </StepProvider>
                )}
              </ColumnWrapper>
            </MainCol>
          </PageContainer>
        </Layout>
      )}
    </AuthenticatedWrapper>
  );
}

export const Head = ({ data: queryResult }) => {
  const pageData = queryResult.prismicRegistrationFlow.data;

  if (!pageData) {
    return null;
  }

  const { seo_title, seo_meta_description, seo_image, page_title } = pageData;

  return (
    <>
      <meta name='robots' content='noindex' />
      <SEO
        title={seo_title || page_title.text}
        description={seo_meta_description}
        image={seo_image}
      />
    </>
  );
};

export const query = graphql`
  query RegistrationFlowQuery($id: String!) {
    prismicRegistrationFlow(id: { eq: $id }) {
      data {
        seo_image {
          url
        }
        seo_meta_description
        seo_title
        page_title {
          richText
          text
        }
        allowed_users
        header_banner {
          url
          alt
          dimensions {
            height
            width
          }
        }
        linked_alert_text {
          text
          richText
        }
        linked_alert_image {
          url
          alt
          dimensions {
            height
            width
          }
        }
        body {
          ... on PrismicRegistrationFlowDataBodyStep {
            slice_type
            primary {
              registration_step {
                document {
                  ...RegistrationStepFragment
                }
              }
            }
          }
        }
      }
    }
  }
`;

RegistrationFlow.propTypes = propTypes;
RegistrationFlow.defaultProps = defaultProps;

export default RegistrationFlow;
