import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';
import PropTypes from 'prop-types';
import Collapse from 'reactstrap/lib/Collapse';

import upArrow from '../../../images/arrows/up-arrow-light-grey.svg';
import downArrow from '../../../images/arrows/down-arrow-light-grey.svg';
import linkResolver from '../../../utils/linkResolver';
import useResize from '../../../hooks/useResize';
import { AnchorLink } from '..';

const Submenu = styled.li`
  position: relative;
  padding: 0 16px;
  width: 100%;

  @media (max-width: ${prop('theme.breakpoints.xl')}) {
    padding: 0 4px;
  }

  ${ifProp(
    '$isActive',
    css`
      border-radius: 16px 16px 0 0;

      @media (min-width: ${prop('theme.breakpoints.lg')}) {
        background-color: ${prop('theme.colors.backgroundGreyLightest')};
      }
    `
  )}

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin: 20px auto 0 8px;
    padding-left: 4px;
    padding-right: 4px;
  }
`;

const Toggle = styled.button`
  &&& {
    background: none;
    border: none;
    font-size: 1rem;
    line-height: 1;
    padding: 8px 0 4px;
    text-align: center;
    white-space: nowrap;
    width: max-content;
    color: ${prop('theme.colors.black')};

    @media (max-width: ${prop('theme.breakpoints.lg')}) {
      padding: 0;
      width: 100%;
    }

    ${ifProp(
      '$isActive',
      css`
        font-weight: ${prop('theme.fontWeights.bold')};

        @media (max-width: ${prop('theme.breakpoints.lg')}) {
          font-weight: ${prop('theme.fontWeights.semiBold')};
        }
      `
    )}

    div {
      display: flex;
      justify-content: space-between;
    }

    img {
      @media (min-width: ${prop('theme.breakpoints.lg')}) {
        display: none;
      }
    }

    &::before {
      display: block;
      content: attr(title);
      font-weight: ${prop('theme.fontWeights.bold')};
      height: 0;
      visibility: hidden;
      width: max-content;

      @media (max-width: ${prop('theme.breakpoints.lg')}) {
        font-weight: ${prop('theme.fontWeights.semiBold')};
      }
    }
  }
`;

const StyledCollapse = styled(Collapse)`
  left: 0;
  border-radius: 0 16px 16px 16px;
  padding: 12px 0;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    padding: 20px 20px 12px;
    min-width: ${prop('$minWidth')};
    position: absolute;
    background-color: ${prop('theme.colors.backgroundGreyLightest')};
  }
`;

const SubmenuList = styled.ul`
  list-style-type: none;
  padding: 0;
  color: ${prop('theme.colors.black')};

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    border-left: 1px solid ${prop('theme.colors.borderGrey')};
    padding-left: 20px;
  }
`;

const SubmenuListItem = styled.li`
  margin-top: 12px;

  &:first-child {
    margin-top: 0;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin-top: 16px;

    &:first-child {
      margin-top: 8px;
    }
  }
`;

const propTypes = { data: PropTypes.object };

const defaultProps = { data: {} };

function SubmenuItem({ data }) {
  const submenuRef = useRef();
  const iconRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useResize(isMobile, setIsMobile);

  const { primary, items } = data;
  const { nav_text } = primary;

  const startTransition = () => {
    setIsTransitioning(true);
  };

  const endTransition = () => {
    setIsTransitioning(false);
  };

  const onMouseEvent = (shouldOpen) => {
    if (!isMobile) {
      if (iconRef.current) {
        iconRef.current.className = isOpen ? 'expanded-icon' : 'collapsed-icon';
      }

      if (shouldOpen && !isOpen) {
        setIsOpen(true);
      } else if (!shouldOpen && isOpen) {
        setIsOpen(false);
      }
    }
  };

  const onMouseEnter = () => onMouseEvent(true);
  const onMouseLeave = () => onMouseEvent(false);

  return (
    <Submenu
      className='nav-item'
      ref={submenuRef}
      $isActive={isOpen || isTransitioning}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Toggle
        $isActive={isOpen || isTransitioning}
        title={nav_text}
        onClick={() => {
          if (isMobile) {
            if (iconRef.current) {
              iconRef.current.className = isOpen
                ? 'expanded-icon'
                : 'collapsed-icon';
            }

            setIsOpen((prev) => !prev);
          }
        }}
      >
        <div>
          {nav_text}
          <img ref={iconRef} src={isOpen ? upArrow : downArrow} />
        </div>
      </Toggle>
      <StyledCollapse
        $minWidth={
          submenuRef.current && submenuRef.current.clientWidth + 28 + 'px'
        }
        isOpen={isOpen}
        onEntering={startTransition}
        onEntered={endTransition}
        onExiting={startTransition}
        onExited={endTransition}
      >
        <SubmenuList>
          {items.map((sub_nav_item) => {
            const submenuLink = sub_nav_item.submenu_link;
            const path =
              submenuLink.link_type === 'Document'
                ? linkResolver({
                    ...submenuLink
                  })
                : submenuLink.url;
            return (
              <SubmenuListItem key={submenuLink.uid}>
                <AnchorLink
                  $mode='submenu'
                  activeClassName={'active'}
                  title={sub_nav_item.submenu_link_text}
                  to={path}
                  key={submenuLink.uid}
                >
                  {sub_nav_item.submenu_link_text}
                </AnchorLink>
              </SubmenuListItem>
            );
          })}
        </SubmenuList>
      </StyledCollapse>
    </Submenu>
  );
}

SubmenuItem.propTypes = propTypes;
SubmenuItem.defaultProps = defaultProps;

export default SubmenuItem;
