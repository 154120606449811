import actions from '../actions';

const loadingReducer = (state = false, action) => {
  if (!action || !action.type) {
    return state;
  }

  switch (action.type) {
    case actions.SET_LOADING_STATUS:
      return action.payload;
    default:
      return state;
  }
};

export default loadingReducer;
