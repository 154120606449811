import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, navigate } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import get from 'lodash-es/get';
import { PrismicRichText } from '@prismicio/react';

import SEO from '../components/seo';
import Layout from '../components/layout';
import Sidenav from '../components/Community/Portal/Sidenav';
import CommunityContainer from '../components/layout-components/CommunityContainer';
import MainSection from '../components/layout-components/MainSection';
import HeroCarousel from '../components/HeroCarousel';
import PageBody from '../components/PageBody';
import { Copyright } from '../components/Community/shared-styled-components';
import AuthenticatedWrapper from '../components/Community/AuthenticatedWrapper';
import { useSelector } from 'react-redux';
import { isNullValue } from '../utils/functions';
import {
  PORTAL_ACCESS_INVOICE_STATUSES,
  INVOICE_STATUS_SPONSOR_VERIFYING
} from '../utils/constants';
import { fetchErrors } from '../state/actions/errorTypes';
import ErrorPage from '../components/Community/Registration/ErrorPage';
import redExlamation from '../images/icons/red-exclamation-point.svg';
import { AlertContainer } from '../components/Community/Registration/Alert';
import linkResolver from '../utils/linkResolver';
import FullPageSpinnerContainer from '../components/SessionCheck/FullPageSpinnerContainer';
import Spinner from '../components/SessionCheck/Spinner';

const SponsorAlert = styled(AlertContainer)`
  margin-bottom: 16px;
`;

const DisclaimerText = styled(Copyright)`
  margin-bottom: 16px;
`;

const propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
};

const defaultProps = {
  data: null,
  location: {}
};

function CommunityPortalLandingPage({ data: queryResult, location }) {
  const pageData = queryResult.prismicCommunityPortalLandingPage;

  const {
    header_banner,
    page_title,
    sponsor_verifying_alert,
    body
  } = pageData.data;

  if (!pageData) {
    return null;
  }

  const { identity, invoices, sponsorship } = useSelector(
    (state) => state.memberInfo
  );
  const isLoading = useSelector((state) => state.loading);
  const isAuthLoading = useSelector((state) =>
    state.auth ? state.auth.loading : false
  );
  const [userIsPermitted, setUserIsPermitted] = useState(false);

  // Here we are considering a sponsor_verifying invoice as "complete" in the sense that the user has done everything
  // we ask of them before we need to verify their eligibility
  const noInvoicesComplete =
    isNullValue(invoices) ||
    invoices.every(
      (invoice) => !PORTAL_ACCESS_INVOICE_STATUSES.includes(invoice.status)
    );

  const loadingIsComplete = !isLoading && !isAuthLoading;

  useEffect(() => {
    // There was some weird behavior with this redirect while logging a user in.
    // If a user has logged in, then identity will not be an empty object.
    // As such, we can use the identity object to determine if the user is actually logged in
    if (loadingIsComplete && Object.keys(identity).length > 0) {
      if (noInvoicesComplete) {
        const isSponsored =
          !!sponsorship && Object.keys(sponsorship).length > 0;
        navigate(
          isSponsored
            ? `/sponsorship-registration/?sponsorshipCode=${sponsorship.sponsorship_code}`
            : '/registration'
        );
      } else {
        setUserIsPermitted(true);
      }
    }
  }, [loadingIsComplete, invoices, sponsorship]);

  const apiErrors = useSelector((state) => state.apiErrors);
  const areFetchErrorsPresent = apiErrors
    ? Object.keys(apiErrors).some((error) => fetchErrors.includes(error))
    : false;

  const disclaimer = get(
    queryResult,
    'allPrismicFooterComponent.edges[0].node.data.disclaimer_body'
  );

  const sponsorVerifyingInvoicePresent =
    !isNullValue(invoices) &&
    invoices.some(
      (invoice) => invoice.status === INVOICE_STATUS_SPONSOR_VERIFYING
    );

  return (
    <AuthenticatedWrapper location={location}>
      {!userIsPermitted && !areFetchErrorsPresent ? (
        <FullPageSpinnerContainer>
          <Spinner />
        </FullPageSpinnerContainer>
      ) : (
        <Layout layout='portal'>
          <CommunityContainer>
            <Sidenav title={page_title} />
            <MainSection>
              {areFetchErrorsPresent ? (
                <ErrorPage />
              ) : (
                <div>
                  {sponsorVerifyingInvoicePresent && (
                    <SponsorAlert $borderColor='redPrimary'>
                      <img src={redExlamation} />
                      <PrismicRichText
                        field={sponsor_verifying_alert.richText}
                        linkResolver={linkResolver}
                      />
                    </SponsorAlert>
                  )}
                  <HeroCarousel
                    headerBanner={header_banner}
                    page='community-portal'
                  />
                  {body && (
                    <PageBody
                      body={body}
                      pageUid={pageData.uid}
                      template={'community-portal'}
                    />
                  )}
                </div>
              )}
              <DisclaimerText>
                <PrismicRichText field={disclaimer.richText} />
              </DisclaimerText>
            </MainSection>
          </CommunityContainer>
        </Layout>
      )}
    </AuthenticatedWrapper>
  );
}

export const Head = ({ data: queryResult }) => {
  const pageData = queryResult.prismicCommunityPortalLandingPage.data;

  if (!pageData) {
    return null;
  }

  const { seo_title, seo_meta_description, seo_image, page_title } = pageData;

  return (
    <>
      <meta name='robots' content='noindex' />
      <SEO
        title={seo_title || page_title.text}
        description={seo_meta_description}
        image={seo_image}
      />
    </>
  );
};

export const query = graphql`
  query CommunityPortalLandingPageQuery($id: String!) {
    prismicCommunityPortalLandingPage(id: { eq: $id }) {
      uid
      _previewable
      data {
        page_title {
          text
        }
        sponsor_verifying_alert {
          text
          richText
        }
        header_banner {
          image {
            alt
            url
            dimensions {
              height
              width
            }
            thumbnails {
              mobile {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
          }
          button_link {
            url
            target
          }
          button_text
          title {
            richText
            text
          }
          sub_title {
            richText
            text
          }
          copy_position
        }
        seo_title
        seo_meta_description
        seo_image {
          url
        }
        body {
          ... on PrismicCommunityPortalLandingPageDataBodyContentCardsBlock {
            slice_type
            primary {
              cards_cta_icon {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
            items {
              card_body {
                richText
                text
              }
              card_link {
                tags
                target
                type
                uid
              }
              card_title {
                richText
                text
              }
              card_title_icon {
                alt
                url
              }
            }
          }
        }
      }
    }
    allPrismicFooterComponent {
      edges {
        node {
          data {
            disclaimer_body {
              richText
            }
          }
        }
      }
    }
  }
`;

CommunityPortalLandingPage.propTypes = propTypes;
CommunityPortalLandingPage.defaultProps = defaultProps;

export default withPrismicPreview(CommunityPortalLandingPage);
