import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, ifNotProp, prop } from 'styled-tools';
import get from 'lodash-es/get';

import linkResolver from '../../../utils/linkResolver';
import { trimTeaser } from '../../../utils/functions';
import dayjs from '../../../utils/dayjs';
import ButtonLink from '../../ButtonLink';

const CalloutContainer = styled.div`
  background-color: ${prop('theme.colors.white')};
  border-radius: 1.5rem;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  padding: 2rem 3.75rem;
  
  ${ifNotProp(
    '$displayEmbeddedVideo',
    css`
      max-width: 80%;
      position: absolute;
      bottom: -80px;
      left: -60px;
    `,
    css`
      margin-left: -60px;
    `
  )}


  @media (max-width: ${prop('theme.breakpoints.xl')}) {
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
  }
`;

const TitleLink = styled(Link)`
  color: ${prop('theme.colors.black')};
  display: inline-flex;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
`;

const Arrow = styled.span`
  color: ${prop('theme.colors.redPrimary')};
  font-size: 4rem;
  letter-spacing: 0.02em;
  line-height: 3.5rem;
  margin-left: 0.875rem;
  font-weight: ${prop('theme.fontWeights.medium')};
  margin-bottom: 1rem;

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    font-size: 2.25rem;
    line-height: 2rem;
    margin-left: 0.625rem;
  }
`;

const Title = styled.h1`
  font-weight: ${prop('theme.fontWeights.bold')};
  margin-bottom: 1rem;

  ${ifProp(
    { $page: 'landing' },
    css`
      font-size: 1.875rem;
    `,
    css`
      line-height: 3.5rem;

      @media (max-width: ${prop('theme.breakpoints.sm')}) {
        line-height: 2rem;
        font-size: 2rem;
      }
    `
  )}
`;

const Teaser = styled.p`
  color: ${prop('theme.colors.black')};
  font-weight: ${prop('theme.fontWeights.light')};

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    font-size: 0.875rem;

    ${ifProp(
      { $page: 'category' },
      css`
        margin-bottom: 0;
      `
    )}
  }
`;

const SmallText = styled.p`
  color: ${prop('theme.colors.greyDarker')};
  font-size: 0.875rem;
  font-weight: ${prop('theme.fontWeights.light')};
  margin-bottom: 0;
  text-transform: uppercase;
`;

const AuthorDetails = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${prop('theme.breakpoints.xxs')}) {
    flex-direction: row;
  }

  ${SmallText} {
    &:first-child {
      margin-bottom: 0.25rem;
      padding-right: 0;

      @media (min-width: ${prop('theme.breakpoints.xxs')}) {
        margin-bottom: 0;
        padding-right: 1.75rem;
      }

      @media (min-width: ${prop('theme.breakpoints.sm')}) {
        padding-right: 2.5rem;
      }
    }
  }
`;

const ButtonText = styled.span`
  text-transform: uppercase;
  font-weight: ${prop('theme.fontWeights.bold')};
  letter-spacing: 1px;
  padding: 0.5rem;
`;

const propTypes = {
  primary: PropTypes.object,
  page: PropTypes.string,
  type: PropTypes.string,
  uid: PropTypes.string,
  displayEmbeddedVideo: PropTypes.bool
};

const defaultProps = {
  primary: {},
  page: '',
  type: '',
  uid: '',
  displayEmbeddedVideo: false
};

function HeroCallout({ primary, page, uid, type, displayEmbeddedVideo }) {
  const {
    author,
    post_author,
    post_date,
    seo_meta_description,
    post_title
  } = primary;

  const authorName = get(author, 'document.data.author_name', post_author.text);

  const articleLink = linkResolver({ type: type, uid: uid });
  return (
    <CalloutContainer $displayEmbeddedVideo={displayEmbeddedVideo}>
      {page === 'landing' && (
        <>
          {post_title.text && (
            <TitleLink to={articleLink}>
              <Title $page={page}>{post_title.text}</Title>
              <Arrow>→</Arrow>
            </TitleLink>
          )}
        </>
      )}
      {page === 'single' && (
        <>{post_title.text && <Title>{post_title.text}</Title>}</>
      )}

      {page === 'landing' && (
        <>
          {seo_meta_description && (
            <Teaser $page={page}>{trimTeaser(seo_meta_description)}</Teaser>
          )}
          <ButtonLink
            link={{ url: articleLink }}
            format='fill'
            style='fill-red'
            width='fit-content'
            size={192}
          >
            <ButtonText>Read More</ButtonText>
          </ButtonLink>
        </>
      )}
      {page === 'single' && (
        <AuthorDetails>
          {post_date && (
            <SmallText>{dayjs(post_date).format('MMM D, YYYY')}</SmallText>
          )}
          {authorName && <SmallText>By {authorName}</SmallText>}
        </AuthorDetails>
      )}
    </CalloutContainer>
  );
}

HeroCallout.propTypes = propTypes;
HeroCallout.defaultProps = defaultProps;

export default HeroCallout;
