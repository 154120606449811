import React, { useRef } from 'react';
import get from 'lodash-es/get';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PrismicRichText } from '@prismicio/react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import Avatar, { StyledImg } from '../../../../components/Avatar';
import verifiedIcon from '../../../../images/icons/authenticated-icon.svg';
import defaultAvatar from '../../../../images/blank-profile-picture-alt.svg';
import useElementSize from '../../../../hooks/useElementSize';
import { logout } from '../../../../utils/auth';
import NavMenu from './NavMenu';
import { Copyright } from '../../shared-styled-components';

const SidenavContainer = styled.div`
  display: flex;
  height: 100%;
  position: fixed;
  width: 288px;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    display: none;
  }
`;

const SidenavInterior = styled.div`
  background-color: ${prop('theme.colors.mainBackground')};
  display: flex;
  flex-direction: column;
  height: ${prop('$height')}px;
  justify-content: space-between;
  overflow-y: auto;
  padding: 24px 0 20px;
  width: 100%;
`;

const UserMenuWrapper = styled.div`
  margin-bottom: 36px;
`;

const UserDetails = styled(Link)`
  display: flex;
  margin-bottom: 40px;
  padding: 0 36px;

  &:hover {
    text-decoration: none;
  }

  ${StyledImg} {
    margin-right: 18px;
  }
`;

const UserName = styled.p`
  color: ${prop('theme.colors.black')};
  font-weight: ${prop('theme.fontWeights.medium')};
  margin-bottom: 0;

  img {
    margin-left: 6px;
  }
`;

const MemberStatus = styled.p`
  color: ${prop('theme.colors.black')};
  font-size: 0.75rem;
  font-weight: ${prop('theme.fontWeights.light')};
  margin-bottom: 0;
`;

const SidenavFooter = styled.div`
  padding: 0 36px;
`;

const ButtonLink = styled.button`
  background: transparent;
  color: ${prop('theme.colors.black')};
  border: none;
  margin-bottom: 18px;
  padding: 0;

  &:hover {
    color: ${prop('theme.colors.redPrimary')};
  }
`;

const propTypes = {
  expandedSection: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  toggleNav: PropTypes.func
};

const defaultProps = {
  expandedSection: false,
  toggleNav: () => {}
};

function DesktopMenu({ expandedSection, toggleNav }) {
  const navRef = useRef(null);
  const { height } = useElementSize(navRef);
  const navHeight = height - 108;

  const queryResult = useStaticQuery(graphql`
    query SideNavCopyrightQuery {
      prismicPortalSideNav {
        data {
          copyright_text {
            richText
            text
          }
        }
      }
    }
  `);

  const subscriberData = useSelector((state) =>
    get(state, 'memberInfo.subscriber')
  );
  const isActiveSubscriber = get(
    subscriberData,
    'subscriber_details.has_active_subscription'
  );
  const fullName =
    get(subscriberData, 'first_name') + ' ' + get(subscriberData, 'last_name');

  return (
    <SidenavContainer ref={navRef}>
      <SidenavInterior $height={navHeight}>
        <UserMenuWrapper>
          <UserDetails
            to={'/community-portal/profile-settings/profile-information'}
          >
            <Avatar avatar={{ url: defaultAvatar }} />
            <div>
              <UserName>
                {fullName}
                {isActiveSubscriber && <img src={verifiedIcon} />}
              </UserName>
              {isActiveSubscriber && (
                <MemberStatus>Paid Membership</MemberStatus>
              )}
            </div>
          </UserDetails>
          <NavMenu expandedSection={expandedSection} toggleNav={toggleNav} />
        </UserMenuWrapper>
        <SidenavFooter>
          <ButtonLink
            onClick={() => {
              logout();
            }}
          >
            Sign out
          </ButtonLink>
          <Copyright>
            <PrismicRichText
              render={
                queryResult.prismicPortalSideNav.data.copyright_text.richText
              }
            />
          </Copyright>
        </SidenavFooter>
      </SidenavInterior>
    </SidenavContainer>
  );
}

DesktopMenu.propTypes = propTypes;
DesktopMenu.defaultProps = defaultProps;

export default DesktopMenu;
