import React from 'react';
import PropTypes from 'prop-types';

import FormTitle from '../RegistrationForm/FormTitle';
import Input from '../RegistrationForm/Input';

const propTypes = {
  register: PropTypes.func,
  errors: PropTypes.object,
  defaultValues: PropTypes.object,
  formTitle: PropTypes.object,
  isValid: PropTypes.bool
};

const defaultProps = {
  register: () => {},
  errors: {},
  defaultValues: {},
  formTitle: {},
  isValid: false
};

function PersonalInfoFields({
  register,
  errors,
  defaultValues,
  formTitle,
  isValid
}) {
  return (
    <>
      <FormTitle title={formTitle} isValid={isValid} />
      <Input
        type='text'
        name={'firstName'}
        labelText='First Name*'
        error={errors.firstName}
        defaultValue={defaultValues.firstName}
        {...register('firstName', {
          required: 'First Name is required'
        })}
      />
      <Input
        type='text'
        name={'lastName'}
        labelText='Last Name*'
        error={errors.lastName}
        defaultValue={defaultValues.lastName}
        {...register('lastName', {
          required: 'Last Name is required'
        })}
      />
      <Input
        type='text'
        name={'primaryEmail'}
        labelText='Primary Email*'
        error={errors.primaryEmail}
        disabled={true}
        defaultValue={defaultValues.primaryEmail}
        {...register('primaryEmail', {
          required: 'Primary Email is required'
        })}
      />
    </>
  );
}

PersonalInfoFields.propTypes = propTypes;
PersonalInfoFields.defaultProps = defaultProps;

export default PersonalInfoFields;
