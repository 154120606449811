import { AGREEMENT_STATUS_SIGNED } from '../../utils/constants';
import actions from '../actions';

const memberInfoReducer = (state = {}, action) => {
  if (!action || !action.type) {
    return state;
  }

  switch (action.type) {
    case actions.SET_IDENTITY:
      return {
        ...state,
        identity: action.payload
      };
    case actions.UPDATE_REGISTRATION_STATE:
      return {
        ...state,
        identity: {
          ...state.identity,
          registrationState: action.payload
        }
      };
    case actions.UPDATE_PRIMARY_EMAIL:
      return {
        ...state,
        identity: { ...state.identity, email: action.payload }
      };
    case actions.SET_SUBSCRIBER:
      return {
        ...state,
        subscriber: action.payload
      };
    case actions.ADD_AGREEMENT_TEMPLATE:
      const alreadyAdded = state.agreementTemplates.findIndex(
        (storedTemplate) =>
          storedTemplate.agreementId === action.payload.agreementId
      );
      if (alreadyAdded < 0) {
        return {
          ...state,
          agreementTemplates: [...state.agreementTemplates, action.payload]
        };
      } else {
        return state;
      }
    case actions.UPDATE_AGREEMENT_TEMPLATE:
      if (!state.agreementTemplates) {
        return state;
      } else {
        const existingIndex = state.agreementTemplates.findIndex(
          (template) => template.agreementId === action.payload.agreementId
        );

        if (existingIndex >= 0) {
          const updatedAgreement = {
            ...state.agreementTemplates[existingIndex],
            ...action.payload
          };
          const newAgreements = [...state.agreementTemplates];
          newAgreements.splice(existingIndex, 1, updatedAgreement);

          return {
            ...state,
            agreementTemplates: newAgreements
          };
        } else {
          return state;
        }
      }
    case actions.REMOVE_PENDING_AGREEMENTS:
      if (!state.agreementTemplates) {
        return state;
      } else {
        return {
          ...state,
          agreementTemplates: state.agreementTemplates.filter(
            (template) => template.agreementStatus === AGREEMENT_STATUS_SIGNED
          )
        };
      }
    case actions.SET_CONTACT_INFORMATION:
      return {
        ...state,
        contactInformation: action.payload
      };
    case actions.SET_ADDRESSES:
      return {
        ...state,
        addresses: action.payload
      };
    case actions.ADD_ADDRESSES:
      return {
        ...state,
        addresses: state.addresses
          ? [...state.addresses, ...action.payload]
          : action.payload
      };
    case actions.SET_INVOICES:
      return {
        ...state,
        invoices: action.payload
      };
    case actions.SET_SPONSORSHIP_CODE:
      return {
        ...state,
        sponsorshipCode: action.payload
      };
    case actions.SET_SPONSORSHIP:
      return {
        ...state,
        sponsorship: action.payload
      };
    case actions.SET_BROKERAGE_ACCOUNTS:
      return {
        ...state,
        brokerageAccounts: action.payload
      };
    case actions.ADD_BROKERAGE_ACCOUNT:
      return {
        ...state,
        brokerageAccounts: [...state.brokerageAccounts, action.payload]
      };
    case actions.UPDATE_BROKERAGE_ACCOUNT:
      const existingIndex = state.brokerageAccounts.findIndex(
        (account) => account.id === action.payload.id
      );
      if (existingIndex >= 0) {
        const updatedAccount = {
          ...state.brokerageAccounts[existingIndex],
          ...action.payload
        };
        const newBrokerageAccounts = [...state.brokerageAccounts];
        newBrokerageAccounts.splice(existingIndex, 1, updatedAccount);

        return {
          ...state,
          brokerageAccounts: newBrokerageAccounts
        };
      } else {
        return state;
      }
    case actions.REMOVE_BROKERAGE_ACCOUNT:
      const removeIndex = state.brokerageAccounts.findIndex(
        (account) => account.id === action.payload.id
      );
      if (removeIndex >= 0) {
        const newBrokerageAccounts = [...state.brokerageAccounts];
        newBrokerageAccounts.splice(removeIndex, 1);

        return {
          ...state,
          brokerageAccounts: newBrokerageAccounts
        };
      } else {
        return state;
      }
    case actions.SET_BROKERAGES:
      return {
        ...state,
        brokerages: action.payload
      };
    case actions.SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriber: { ...state.subscriber, subscriptions: action.payload }
      };

    default:
      return state;
  }
};

export default memberInfoReducer;
