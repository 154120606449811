import React from 'react';

import TableBlock from '../TableBlock';

function NewsItemTableRenderer({ data, template }) {
  return (
    <TableBlock head={data.primary} body={data.items} template={template} />
  );
}

export default NewsItemTableRenderer;
