import get from 'lodash-es/get';

import subscriberApiClient, {
  getErrorMessage
} from '../../utils/subscriberApiClient';
import actions from '../actions';
import errorTypes from './errorTypes';
import { DEFAULT_PAYMENT_RETURN_PATH } from '../../utils/constants';

export const fetchInvoices = (
  successCallback = () => {},
  completedCallback = () => {}
) => async (dispatch) => {
  try {
    const response = await subscriberApiClient.get('/subscriber/invoices');

    const invoices = get(response, 'data.invoices');

    dispatch({
      type: actions.SET_INVOICES,
      payload: invoices
    });

    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.INVOICE_FETCH_FAILED
    });

    successCallback();
  } catch (error) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.INVOICE_FETCH_FAILED,
        message: getErrorMessage(error)
      }
    });
  }

  completedCallback();
};

export const fetchPaymentSession = (
  invoiceExternalId,
  successCallback,
  failedCallback,
  successPath = DEFAULT_PAYMENT_RETURN_PATH,
  cancelPath = DEFAULT_PAYMENT_RETURN_PATH
) => async (dispatch) => {
  const origin = typeof window !== 'undefined' ? window.location.origin : '';
  try {
    const response = await subscriberApiClient.get(
      `/subscriber/payment_session/${invoiceExternalId}`,
      {
        params: {
          success_url: `${origin}${successPath}?sessionId={CHECKOUT_SESSION_ID}`,
          cancel_url: `${origin}${cancelPath}`
        }
      }
    );

    const session = get(response, 'data.session');

    if (!session) {
      throw new Error('Could not fetch Stripe payment session');
    }

    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.PAYMENT_SESSION
    });

    successCallback(session);
  } catch (error) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.PAYMENT_SESSION,
        message: getErrorMessage(error)
      }
    });
    failedCallback();
  }
};
