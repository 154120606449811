import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const defaultState = null;
const GatsbyIpfFileContext = React.createContext(defaultState);
GatsbyIpfFileContext.displayName = 'GatsbyIpfFileContext';

const ipfFileQuery = graphql`
  query IpfFileQuery {
    allSmallsProduct {
      nodes {
        productSymbol
        frontMonthSymbol
        frontMonthExpirationMonth
        frontMonthExpirationDate
        frontMonthOptions
        frontMonthLastTradeDate
        backMonthSymbol
        backMonthExpirationMonth
        backMonthExpirationDate
        backMonthOptions
      }
    }
  }
`;

function GatsbyIpfFileProvider({ children }) {
  return (
    <StaticQuery
      query={`${ipfFileQuery}`}
      render={(data) => {
        const ipfFileData = data.allSmallsProduct.nodes;
        const productData = {};

        ipfFileData.forEach((ipfFileEntry) => {
          productData[ipfFileEntry.productSymbol] = {
            ...ipfFileEntry
          };
        });

        return (
          <GatsbyIpfFileContext.Provider value={productData}>
            {children}
          </GatsbyIpfFileContext.Provider>
        );
      }}
    />
  );
}

export { GatsbyIpfFileProvider };

export default GatsbyIpfFileContext;
