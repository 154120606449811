import React from 'react';
import get from 'lodash-es/get';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';

import {
  CardTitle as BaseCardTitle,
  Date,
  ListTitle
} from '../styled/SharedComponents';
import linkResolver from '../../../utils/linkResolver';
import dayjs from '../../../utils/dayjs';
import { NEWS_DATE_FORMAT } from '../../../utils/constants';

const RelatedContainer = styled.div`
  margin-left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    margin-top: 60px;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    min-width: 312px;
  }

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    min-width: 100%;
  }
`;

const RelatedItem = styled.div`
  border-bottom: 0.0625rem solid ${prop('theme.colors.borderGrey')};
  margin-bottom: 18px;
  padding-bottom: 18px;
`;

const CardTitle = styled(BaseCardTitle)`
  font-size: 1rem;
  margin-bottom: 0;
`;

const ArticleLink = styled(Link)`
  &:hover {
    text-decoration: none;

    ${CardTitle} {
      color: ${prop('theme.colors.redPrimary')};
    }
  }
`;

const propTypes = {
  relatedArticles: PropTypes.array.isRequired
};

const defaultProps = {};

function RelatedArticles({ relatedArticles }) {
  return (
    <RelatedContainer>
      <ListTitle>Related Articles</ListTitle>
      <div>
        {relatedArticles.map((article) => {
          const { type, uid } = article.node;
          const articleData = get(article, 'node.data');
          const { post_date, post_title } = articleData;

          return (
            <RelatedItem key={article.node.uid}>
              {post_date && (
                <Date>{dayjs(post_date).format(NEWS_DATE_FORMAT)}</Date>
              )}
              {post_title && (
                <ArticleLink to={linkResolver({ type: type, uid: uid })}>
                  <CardTitle>{post_title.text}</CardTitle>
                </ArticleLink>
              )}
            </RelatedItem>
          );
        })}
      </div>
    </RelatedContainer>
  );
}

RelatedArticles.propTypes = propTypes;
RelatedArticles.defaultProps = defaultProps;

export default RelatedArticles;
