import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import PropTypes from 'prop-types';
import Collapse from 'reactstrap/lib/Collapse';
import { useSelector, useDispatch } from 'react-redux';
import { PrismicRichText } from '@prismicio/react';

import linkResolver from '../../utils/linkResolver';
import MenuLink from './styled/MenuLink';
import { pageModes } from '../../utils/constants';
import sideMenuArrow from 'images/side-menu-arrow.svg';
import downArrow from '../../images/arrows/down-arrow-light-grey.svg';
import upArrow from '../../images/arrows/up-arrow-light-grey.svg';
import { capitalizeFirstLetter } from '../../utils/functions';
import actions from '../../state/actions';

const ProductItemContainer = styled.li`
  line-height: 1;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  color: ${prop('theme.colors.black')};

  strong {
    font-size: 0.875rem;
  }
`;

const RailMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledText = styled.div`
  > * {
    font-size: 0.875rem;
    margin-left: 0.15rem;
  }

  p {
    margin-bottom: 0;
  }
`;

const ButtonList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 0.5rem;
  color: ${prop('theme.colors.black')};

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    border-left: 1px solid ${prop('theme.colors.borderGrey')};
  }
`;

const StyledListItem = styled.li`
  cursor: pointer;
  width: 100%;
  padding: 0.5rem 0 0.5rem 0.3rem;
  display: flex;
  font-size: 0.875rem;

  img {
    align-self: center;
    margin: 0 0.5rem 0.1rem 0;

    @media (max-width: ${prop('theme.breakpoints.lg')}) {
      display: none;
    }
  }

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    padding: 0.5rem 0 0.5rem 1.5rem;
    border-bottom: 1px solid ${prop('theme.colors.borderGreyLight')};
  }
`;

const ListItemText = styled.div`
  margin-left: 15px;
  text-align: left;

  ${ifProp(
    { selected: true },
    css`
      color: ${prop('theme.colors.redPrimary')};

      @media (min-width: ${prop('theme.breakpoints.lg')}) {
        margin-left: 0;
        font-weight: ${prop('theme.fontWeights.semiBold')};
        color: ${prop('theme.colors.black')};
      }
    `
  )}
`;

const MenuButton = styled.button`
  border: none;
  background: none;
  text-align: left;
  padding: 0 0.25rem 0 0;
  line-height: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${prop('theme.colors.black')};

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    font-size: 1rem;
  }

  &:hover {
    color: ${prop('theme.colors.redPrimary')};
    text-decoration: none;
  }

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    padding: 0;

    img {
      display: none;
    }
  }
`;

const propTypes = {
  menuItem: PropTypes.object,
  toggle: PropTypes.func,
  isExpanded: PropTypes.bool,
  toggleExpanded: PropTypes.func
};

const defaultProps = {
  menuItem: {},
  toggle: () => {},
  isExpanded: false,
  toggleExpanded: () => {}
};

function ProductItem({ menuItem, toggle, isExpanded, toggleExpanded }) {
  const ticker = menuItem.node.data.futures_symbol;
  const pageMode = useSelector(
    (state) => state.pageModes && state.pageModes[ticker]
  );
  const dispatch = useDispatch();
  const iconRef = useRef();
  const disclaimer = menuItem.node.data.disclaimer_text;
  const title = menuItem.node.data.title;
  const url = linkResolver({
    type: menuItem.node.type,
    uid: menuItem.node.uid,
    subpage: pageMode
  });
  // gatsby-recommended way to check if currently in browser
  const isBrowser = typeof window !== 'undefined';
  const isActive =
    isBrowser && url.split('/')[2] === window.location.pathname.split('/')[2];

  useEffect(() => {
    if (isActive) {
      toggleExpanded();
    }
  }, []);

  return (
    <ProductItemContainer>
      <MenuButton
        onClick={() => {
          if (iconRef.current) {
            iconRef.current.className = isExpanded
              ? 'expanded-icon'
              : 'collapsed-icon';
          }
          toggleExpanded();
        }}
      >
        <div>
          <RailMenuWrapper>
            <strong>{ticker}</strong>
            {disclaimer.text && (
              <StyledText>
                <PrismicRichText
                  field={disclaimer.richText}
                  linkResolver={linkResolver}
                />
              </StyledText>
            )}
          </RailMenuWrapper>
          {title.text}
        </div>
        <img ref={iconRef} src={isExpanded ? upArrow : downArrow} />
      </MenuButton>
      <Collapse isOpen={isExpanded}>
        <ButtonList>
          {Object.keys(pageModes).map((currentMode) => (
            <MenuLink
              to={linkResolver({
                type: menuItem.node.type,
                uid: menuItem.node.uid,
                subpage: pageModes[currentMode]
              })}
              onClick={(event) => {
                toggle(event);
                dispatch({
                  type: actions.SET_PAGE_MODE,
                  payload: {
                    [ticker]: pageModes[currentMode]
                  }
                });
              }}
              key={`${ticker}-${pageModes[currentMode]}`}
            >
              <StyledListItem>
                {isActive && pageMode === pageModes[currentMode] && (
                  <img src={sideMenuArrow} />
                )}
                <ListItemText
                  selected={isActive && pageMode === pageModes[currentMode]}
                >
                  {capitalizeFirstLetter(pageModes[currentMode])}
                </ListItemText>
              </StyledListItem>
            </MenuLink>
          ))}
        </ButtonList>
      </Collapse>
    </ProductItemContainer>
  );
}

ProductItem.propTypes = propTypes;
ProductItem.defaultProps = defaultProps;

export default ProductItem;
