import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import get from 'lodash-es/get';
import { PrismicRichText } from '@prismicio/react';

import BlockStyles from '../../BlockStyles';
import StepCounter from './RegistrationStepPageBody/StepCounter';
import StepContext from '../../../context/StepContext';
import { graphql, useStaticQuery } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { PENDING_USER_INPUT } from '../../../utils/constants';
import { ButtonLinkStyles, ButtonLinkRedStyles } from '../../ButtonLink';
import { ButtonContainer, SubmitSpinner } from './RegistrationForm';
import { linkSubscriberToIdentity } from '../../../state/actions/identityActions';
import { Spinner } from 'reactstrap';

const Body = styled.div`
  h2 {
    font-weight: ${prop('theme.fontWeights.semiBold')};
    margin-bottom: 24px;
  }
`;

const MainContent = styled.div`
  min-height: 30vh;
  margin-bottom: 112px;
`;

const StyledButton = styled.button`
  ${ButtonLinkStyles}
  ${ButtonLinkRedStyles}

  border: none;
  font-weight: ${prop('theme.fontWeights.semiBold')};
`;

const propTypes = { body: PropTypes.array };

const defaultProps = { body: [] };

function UnconfirmedIdentityPage({ body }) {
  const identity = useSelector(
    (state) => state.memberInfo && state.memberInfo.identity
  );
  const dispatch = useDispatch();

  if (body.length === 0) {
    return null;
  }

  const [isLoading, setIsLoading] = useState(false);
  const queryResult = useStaticQuery(graphql`
    query UnconfirmedPageQuery {
      allPrismicUnconfirmedIdentityPage {
        edges {
          node {
            data {
              confirm_button_text
              post_confirm_text_block {
                richText
                text
              }
              post_confirm_title {
                text
                richText
              }
              pre_confirm_text_block {
                text
                richText
              }
              pre_confirm_title {
                text
                richText
              }
            }
          }
        }
      }
    }
  `);

  // Grabbing index 0 here because only one unconfirmed identity page is allowed in prismic. If the first index doesn't exist, we're missing the unconfirmed identity page.
  const data = get(
    queryResult,
    'allPrismicUnconfirmedIdentityPage.edges[0].node.data',
    {}
  );

  const {
    confirm_button_text,
    post_confirm_text_block,
    post_confirm_title,
    pre_confirm_text_block,
    pre_confirm_title
  } = data;

  const { currentStep } = useContext(StepContext);
  const step = body[currentStep];
  const { step_title = {} } = get(
    step,
    'primary.registration_step.document.data',
    {}
  );

  const onClick = () => {
    setIsLoading(true);
    dispatch(
      linkSubscriberToIdentity(() => {
        setIsLoading(false);
      })
    );
  };

  return (
    <Body>
      <BlockStyles>
        <StepCounter stepName={step_title.text} />
        <MainContent>
          {identity.link_state === PENDING_USER_INPUT ? (
            <>
              {pre_confirm_title.text && (
                <PrismicRichText field={pre_confirm_title.richText} />
              )}
              {pre_confirm_text_block.text && (
                <PrismicRichText field={pre_confirm_text_block.richText} />
              )}
              <ButtonContainer>
                <StyledButton onClick={onClick} disabled={isLoading}>
                  {confirm_button_text}
                </StyledButton>
                {isLoading && (
                  <SubmitSpinner>
                    <Spinner />
                  </SubmitSpinner>
                )}
              </ButtonContainer>
            </>
          ) : (
            <>
              {post_confirm_title.text && (
                <PrismicRichText field={post_confirm_title.richText} />
              )}
              {post_confirm_text_block.text && (
                <PrismicRichText field={post_confirm_text_block.richText} />
              )}
            </>
          )}
        </MainContent>
      </BlockStyles>
    </Body>
  );
}

UnconfirmedIdentityPage.propTypes = propTypes;
UnconfirmedIdentityPage.defaultProps = defaultProps;

export default UnconfirmedIdentityPage;
