import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash-es/get';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { PrismicRichText } from '@prismicio/react';
import { useDispatch, useSelector } from 'react-redux';

import RegistrationForm from '../RegistrationForm';
import StepContext from '../../../../context/StepContext';
import linkResolver from '../../../../utils/linkResolver';
import FormTitle from '../RegistrationForm/FormTitle';
import Input from '../RegistrationForm/Input';
import BlockStyles from '../../../BlockStyles';
import {
  createSubscriber,
  updateSubscriber
} from '../../../../state/actions/subscriberActions';
import { applySponsorshipCode } from '../../../../state/actions/sponsorshipActions';
import PersonalInfoFields from './PersonalInfoFields';

const SponsorText = styled.div`
  margin-top: 4rem;
`;

const propTypes = { data: PropTypes.object };

const defaultProps = { data: {} };

function PersonalInfoForm({ data }) {
  const { currentStep, setCurrentStep } = useContext(StepContext);
  const {
    form_title,
    sponsorship_section_title,
    sponsorship_section_text,
    sponsor_code_form_title,
    previous_link
  } = data.primary;
  const { identity, subscriber, sponsorshipCode, sponsorship } = useSelector(
    (state) => state.memberInfo || {}
  );
  const dispatch = useDispatch();
  const [isSubscriberSubmitting, setIsSubscriberSubmitting] = useState(false);
  const [isSubscriberComplete, setIsSubscriberComplete] = useState(false);
  const [isSponsorshipSubmitting, setIsSponsorshipSubmitting] = useState(false);
  const [isSponsorshipComplete, setIsSponsorshipComplete] = useState(false);

  useEffect(() => {
    if (isSubscriberComplete && isSponsorshipComplete) {
      setCurrentStep((prev) => prev + 1);
    }
  }, [isSubscriberComplete, isSponsorshipComplete]);

  const onSubmit = async (data) => {
    if (subscriber) {
      setIsSubscriberSubmitting(true);
      dispatch(
        updateSubscriber(
          data.firstName,
          data.lastName,
          () => {
            setIsSubscriberComplete(true);
            setSponsorshipIfApplicable();
          },
          () => setIsSubscriberSubmitting(false)
        )
      );
    } else {
      setIsSubscriberSubmitting(true);
      dispatch(
        createSubscriber(
          data.firstName,
          data.lastName,
          data.primaryEmail,
          () => {
            setIsSubscriberComplete(true);
            setSponsorshipIfApplicable();
          },
          () => setIsSubscriberSubmitting(false)
        )
      );
    }
  };

  const setSponsorshipIfApplicable = () => {
    if (!sponsorship && sponsorshipCode && sponsorshipCode !== '') {
      setIsSponsorshipSubmitting(true);
      dispatch(
        applySponsorshipCode(
          sponsorshipCode,
          () => setIsSponsorshipComplete(true),
          () => setIsSponsorshipSubmitting(false)
        )
      );
    } else {
      setIsSponsorshipComplete(true);
    }
  };

  // If this is the first step in the process, then the "previous" button will actually be a link. Otherwise, we want to move back to the previous step in this flow
  const onPreviousBehavior =
    currentStep > 0
      ? {
          type: 'button',
          onClick: () => {
            setCurrentStep((prev) => prev - 1);
          }
        }
      : {
          type: 'link',
          to: linkResolver({ ...previous_link })
        };

  const defaultValues = {
    firstName: get(subscriber, 'first_name', ''),
    lastName: get(subscriber, 'last_name', ''),
    primaryEmail: get(identity, 'email', '')
  };

  if (sponsorshipCode && sponsorshipCode !== '') {
    defaultValues.sponsorshipCode = sponsorshipCode;
  }

  const {
    register,
    handleSubmit,
    formState: { isValid, errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues
  });

  return (
    <BlockStyles template='registration'>
      <RegistrationForm
        handleSubmit={handleSubmit(onSubmit)}
        onPreviousBehavior={onPreviousBehavior}
        isValid={isValid}
        isSubmitting={isSubscriberSubmitting || isSponsorshipSubmitting}
      >
        <PersonalInfoFields
          formTitle={form_title}
          register={register}
          defaultValues={defaultValues}
          errors={errors}
          isValid={isValid}
        />
        {sponsorshipCode && sponsorshipCode !== '' && (
          <>
            <SponsorText>
              <PrismicRichText field={sponsorship_section_title.richText} />
              <PrismicRichText field={sponsorship_section_text.richText} />
            </SponsorText>
            <FormTitle title={sponsor_code_form_title} />
            <Input
              type='text'
              name={'sponsorshipCode'}
              labelText='Sponsorship Code'
              error={errors.sponsorshipCode}
              disabled={true}
              defaultValue={defaultValues.sponsorshipCode}
              {...register('sponsorshipCode', {
                required: 'Sponsorship Code is required'
              })}
            />
          </>
        )}
      </RegistrationForm>
    </BlockStyles>
  );
}

PersonalInfoForm.propTypes = propTypes;
PersonalInfoForm.defaultProps = defaultProps;

export default PersonalInfoForm;
