import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';

import NewsItemPreview from '../NewsItemPreview';
import { ListTitle } from '../styled/SharedComponents';

const LatestArticlesContainer = styled.div`
  background-color: ${prop('theme.colors.backgroundGreyLightest')};
  padding: 3.5rem 1.5rem;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    padding: 3.5rem 3.75rem;
  }
`;

const ArticlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 -0.9375rem;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    flex-direction: row;
  }
`;

const ArticleWrapper = styled.div`
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0.9375rem;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
`;

const propTypes = {
  title: PropTypes.string,
  articles: PropTypes.array
};

const defaultProps = {
  title: '',
  articles: []
};

function LatestArticles({ title, articles }) {
  if (articles.length === 0) {
    return null;
  }

  return (
    <LatestArticlesContainer>
      <ListTitle>{title || 'Latest Articles'}</ListTitle>
      <ArticlesContainer>
        {articles.map((article, index) => (
          <ArticleWrapper key={`latest_article_${index}`}>
            <NewsItemPreview newsItem={article.node} />
          </ArticleWrapper>
        ))}
      </ArticlesContainer>
    </LatestArticlesContainer>
  );
}

LatestArticles.propTypes = propTypes;
LatestArticles.defaultProps = defaultProps;

export default LatestArticles;
