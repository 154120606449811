import actions from '../actions';

const authReducer = (state = {}, action) => {
  if (!action || !action.type) {
    return state;
  }

  switch (action.type) {
    case actions.SET_AUTH_TOKENS:
      return {
        ...state,
        authResult: action.payload
      };
    case actions.SET_AUTH_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case actions.SET_LOGIN_REQUIRED:
      return {
        ...state,
        isLoginRequired: action.payload
      };
    default:
      return state;
  }
};

export default authReducer;
