import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav } from 'reactstrap';

import navbarToggler from '../../images/navbar-toggler.svg';
import { isAuthenticated } from '../../utils/auth';
import LoggedInMenu from './LoggedInMenu';
import LoggedOutMenu from './LoggedOutMenu';
import Search from './Search';

const HeaderContainer = styled.header`
  background: ${prop('theme.colors.white')};
  height: 108px;
  width: 100%;

  .navbar-collapse {
    background: ${prop('theme.colors.white')};
    padding: 0 16px 16px;

    @media (max-width: ${prop('theme.breakpoints.lg')}) {
      max-height: 78vh;
      overflow: auto;
    }

    @media (min-width: ${prop('theme.breakpoints.lg')}) {
      padding: 0 2rem 0 0;
    }

    @media (min-width: ${prop('theme.breakpoints.xl')}) {
      padding: 16px;
    }

    &.show {
      @media (max-width: ${prop('theme.breakpoints.lg')}) {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
`;

const StyledNavBar = styled(Navbar)`
  background: ${prop('theme.colors.white')};
  height: inherit;
  padding: 0;
  max-width: 100vw;
  box-shadow: 0 1px 5px 0 ${prop('theme.colors.borderGrey')};

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    padding: 0 16px;
  }
`;

const StyledNavbarToggler = styled(NavbarToggler)`
  border: none;

  .navbar-toggler-icon {
    background-image: url("${navbarToggler}");
  }

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    margin-right: 16px;
  }

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    margin-right: 4px;
  }
`;

const StyledNavbarBrand = styled(NavbarBrand)`
  height: 100%;
  padding: 0 0 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    padding: 0 0 0 16px;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    padding: 0;
  }
`;

const Logo = styled.picture`
  width: initial;
  height: 100%;
`;

const StyledNav = styled(Nav)`
  display: flex;
  align-items: center;
`;

export const AnchorLink = styled(Link)`
  &&& {
    color: ${prop('theme.colors.black')};
    cursor: pointer;
    font-size: 1rem;
    line-height: 1;
    padding: 0 0 3px 0;
    text-align: center;
    white-space: nowrap;

    ${ifProp(
      { $mode: 'submenu' },
      css`
        text-align: left;

        &:hover {
          margin-right: -2px;
        }
      `,
      css`
        text-align: center;
      `
    )}

    @media (min-width: ${prop('theme.breakpoints.lg')}) {
      padding: 8px 0 5px 0;
    }

    &.active,
    &.active-news-link,
    &:hover {
      color: ${prop('theme.colors.redPrimary')};
      font-weight: ${prop('theme.fontWeights.medium')};
      line-height: 1;
      text-decoration: none;
    }

    &::before {
      display: block;
      content: attr(title);
      font-weight: ${prop('theme.fontWeights.medium')};
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }
`;

const propTypes = {
  headerData: PropTypes.object,
  localSearchItems: PropTypes.object,
  displaySearchBar: PropTypes.bool
};

const defaultProps = {
  headerData: null,
  localSearchItems: {},
  displaySearchBar: true
};

function Header({ headerData: { data }, localSearchItems, displaySearchBar }) {
  if (!data.body) {
    return null;
  }

  const mainLogo = data.navigation_logo;
  const tabletLogo = data.navigation_logo.thumbnails.tablet;

  const [isOpen, setIsOpen] = useState(false);
  const toggle = (e) => {
    e.preventDefault();
    setIsOpen((prev) => !prev);
  };

  return (
    <HeaderContainer>
      <StyledNavBar expand={'lg'} fixed={'top'} light>
        <StyledNavbarBrand tag={Link} to='/'>
          <Logo>
            <source srcSet={tabletLogo.url} media='(max-width: 992px)' />
            <source srcSet={mainLogo.url} media='(min-width: 993px)' />
            <img
              src={mainLogo.url}
              width={'initial'}
              height={'100%'}
              alt='logo'
            />
          </Logo>
        </StyledNavbarBrand>
        {displaySearchBar && <Search localSearchItems={localSearchItems} />}
        <StyledNavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <StyledNav className='ml-auto' navbar>
            {isAuthenticated() ? (
              <LoggedInMenu menus={data.body} />
            ) : (
              <LoggedOutMenu menus={data.body} />
            )}
          </StyledNav>
        </Collapse>
      </StyledNavBar>
    </HeaderContainer>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
