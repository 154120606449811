import React from 'react';
import PropTypes from 'prop-types';
import ButtonLinkBlock from '../../ButtonLink/ButtonLinkBlock';
import TwoColumns from '../../TwoColumns';
import RegistrationTextBlock from './RegistrationTextBlock';

const propTypes = { body: PropTypes.array, sponsorshipCode: PropTypes.string };

const defaultProps = { body: [], sponsorshipCode: '' };

function RegistrationLandingPageBody({ body, sponsorshipCode }) {
  const componentOptions = {
    text_block: RegistrationTextBlock,
    button_link: ButtonLinkBlock,
    two_columns_image: TwoColumns
  };

  function getComponent(item, i) {
    const Component = componentOptions[item.slice_type];

    if (!Component) {
      return null;
    }

    const options = {
      queryParameters: sponsorshipCode
        ? {
            sponsorshipCode: sponsorshipCode
          }
        : null
    };

    return (
      <Component
        key={`${item.slice_type}-${i}`}
        data={item}
        options={options}
        template={'registration-landing'}
      />
    );
  }

  return <>{body.map((item, i) => getComponent(item, i))}</>;
}

RegistrationLandingPageBody.propTypes = propTypes;
RegistrationLandingPageBody.defaultProps = defaultProps;

export default RegistrationLandingPageBody;
