import React, { useState } from 'react';

import dayjs from '../utils/dayjs';

const DateContext = React.createContext({
  selectedDate: '',
  setSelectedDate: () => {},
  selectedTimeframe: '',
  setSelectedTimeframe: () => {}
});
DateContext.displayName = 'DateContext';

function DateProvider({ endDate, children }) {
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTimeframe, setSelectedTimeframe] = useState({
    month: {
      value: `${dayjs(endDate).month()}`,
      label: dayjs(endDate).format('MMMM')
    },
    year: {
      value: dayjs(endDate).year(),
      label: dayjs(endDate).format('YYYY')
    }
  });

  return (
    <DateContext.Provider
      value={{
        selectedDate: selectedDate,
        setSelectedDate: setSelectedDate,
        selectedTimeframe: selectedTimeframe,
        setSelectedTimeframe: setSelectedTimeframe,
        endDate: endDate
      }}
    >
      {children}
    </DateContext.Provider>
  );
}

export { DateProvider };

export default DateContext;
