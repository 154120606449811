import React from 'react';
import styled, { css } from 'styled-components';
import { ifNotProp, ifProp, prop } from 'styled-tools';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash-es/get';

import redExclamationPoint from '../../../../images/icons/red-exclamation-point.svg';
import { AlertContainer } from '../Alert';

const AlertWrapper = styled(AlertContainer)`
  font-weight: ${prop('theme.fontWeights.medium')};
  border: 2px solid ${prop('theme.colors.redPrimary')};
  margin-bottom: 24px;

  ${ifNotProp(
    '$disableBottomMargin',
    css`
      &:last-child {
        margin-bottom: 48px;
      }
    `
  )}

  ${ifProp(
    '$customMargin',
    css`
      margin: ${(props) => props.$customMargin};

      /* Including this to override the last-child above. This allows us to just specify the custom margin without $disableBottomMargin */
      &:last-child {
        margin: ${(props) => props.$customMargin};
      }
    `
  )}
`;

const propTypes = {
  disableBottomMargin: PropTypes.bool,
  customMargin: PropTypes.string
};

const defaultProps = { disableBottomMargin: false, customMargin: null };

function ErrorAlert({ disableBottomMargin, customMargin }) {
  const apiErrors = useSelector((state) => get(state, 'apiErrors', null));

  return (
    <div>
      {apiErrors &&
        Object.keys(apiErrors).map((error, index) => (
          <AlertWrapper
            $disableBottomMargin={disableBottomMargin}
            $customMargin={customMargin}
            key={`${apiErrors[error]}-${index}`}
          >
            <img src={redExclamationPoint} />
            {apiErrors[error]}
          </AlertWrapper>
        ))}
    </div>
  );
}

ErrorAlert.propTypes = propTypes;
ErrorAlert.defaultProps = defaultProps;

export default ErrorAlert;
