import styled, { css } from 'styled-components';
import { ifNotProp, prop } from 'styled-tools';

const PageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;

  ${ifNotProp(
    { $page: 'registration' },
    css`
      @media (min-width: ${prop('theme.breakpoints.lg')}) {
        min-height: calc(100vh - 108px);
      }
    `
  )}

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    flex-direction: column;
  }
`;

export default PageContainer;
