import React, { forwardRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ifProp, ifNotProp, prop } from 'styled-tools';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input/input';
import { Controller } from 'react-hook-form';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${ifProp(
    { $type: 'submit' },
    css`
      @media (max-width: ${prop('theme.breakpoints.md')}) {
        order: 1;
        flex: 1 1 100%;
        width: 100%;
      }
    `,
    css`
      margin-top: 16px;
      flex: 1 1 100%;
      width: 100%;
    `
  )}

  ${ifProp(
    '$disableTopMargin',
    css`
      margin-top: 0;
    `
  )}

  label {
    position: absolute;
    top: 18px;
    left: 24px;
    color: #999;
    transition: 0.15s;
    pointer-events: none;
    background-color: ${prop('theme.colors.white')};
    padding: 0 8px;
    margin-left: -8px;
    border-radius: 4px;

    &.active {
      transform: translateY(-27px);
      transition: 0.15s;
      font-size: 0.8125rem;
      color: ${prop('theme.colors.greyDarker')};
    }
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  border: 1px solid ${prop('theme.colors.inputGrey')};
  width: 100%;
  outline: none;

  &:disabled {
    background-color: ${prop('theme.colors.white')};
  }

  ${ifProp(
    'error',
    css`
      border: 1px solid ${prop('theme.colors.redPrimary')};
    `
  )}
`;

export const NextButtonStyles = css`
  min-width: 200px;
  align-self: flex-end;
  border-radius: 40px;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  text-transform: uppercase;
  color: ${prop('theme.colors.white')};
  letter-spacing: 1px;
  border: none;
  padding: 8px 24px;
  background-color: ${prop('theme.colors.redPrimary')};
  font-size: 0.875rem;

  &:disabled {
    background-color: ${prop('theme.colors.backgroundGrey')};
  }

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    width: 100%;
    font-size: 1.375rem;
    min-height: 56px;
  }
`;

const StyledInput = styled.input`
  &[type='text'] {
    padding: 16px 24px;
    border-radius: 16px;
    border: 1px solid ${prop('theme.colors.inputGrey')};
    width: 100%;
    outline: none;

    &:disabled {
      background-color: ${prop('theme.colors.white')};
    }

    ${ifProp(
      'error',
      css`
        border: 1px solid ${prop('theme.colors.redPrimary')};
      `
    )}
  }

  &[type='submit'] {
    ${NextButtonStyles}
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${prop('theme.colors.redPrimary')};
  margin-right: 0.75rem;
  font-size: 0.875rem;

  ${ifProp(
    '$absolute',
    css`
      position: absolute;
      bottom: -1.5rem;
      right: 0;
    `
  )}
`;

const propTypes = { labelText: PropTypes.string, error: PropTypes.object };

const defaultProps = { labelText: '', error: null };

const Input = forwardRef((props, ref) => {
  const [isActive, setIsActive] = useState(false);
  const [inputValue, setInputValue] = useState(props.defaultValue);
  const { labelText, error, type, setValue, disableTopMargin } = props;

  // This object just removes the custom, camel case props we use in this component so that we avoid noisy console errors that
  // occur when we pass camel case props to the basic input DOM element
  const filteredProps = Object.assign({}, props);
  delete filteredProps.labelText;
  delete filteredProps.setValue;
  delete filteredProps.disableTopMargin;
  delete filteredProps.defaultValue;
  delete filteredProps.absoluteMessagePosition;

  useEffect(() => {
    if (inputValue && inputValue.length > 0) {
      if (!isActive) {
        setIsActive(true);
      }

      // Since the phone value needs to be handled through the Controller, we need to explicitly set the value here, if there is a defaultValue supplied
      // More specifically, we have to force the form to validate the value of the phoneNumber field
      if (type === 'phone') {
        setValue(props.name, inputValue, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true
        });
      }
    }
  }, []);

  return (
    <InputContainer $type={type} $disableTopMargin={disableTopMargin}>
      {type !== 'submit' && (
        <label className={isActive ? 'active' : ''}>{labelText}</label>
      )}
      {type === 'phone' ? (
        <Controller
          {...filteredProps}
          render={({ field }) => (
            <StyledPhoneInput
              {...filteredProps}
              {...field}
              value={inputValue}
              onChange={(value) => {
                setInputValue(value);
                setValue(props.name, value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true
                });
              }}
              ref={ref}
              onFocus={(event) => {
                setIsActive(true);
                props.onFocus && props.onFocus(event);
              }}
              onBlur={(event) => {
                if (!inputValue || inputValue.length === 0) {
                  setIsActive(false);
                }
                props.onBlur && props.onBlur(event);
              }}
            />
          )}
        />
      ) : (
        <StyledInput
          {...props}
          ref={ref}
          onFocus={(event) => {
            setIsActive(true);
            props.onFocus && props.onFocus(event);
          }}
          onBlur={(event) => {
            if (!inputValue || inputValue.length === 0) {
              setIsActive(false);
            }
            props.onBlur && props.onBlur(event);
          }}
          onChange={(event) => {
            setInputValue(event.target.value);
            props.onChange && props.onChange(event);
          }}
        />
      )}

      {error && (
        <ErrorMessage $absolute={props.absoluteMessagePosition}>
          {error.message}
        </ErrorMessage>
      )}
    </InputContainer>
  );
});

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
