const theme = {
  colors: {
    black: '#1A1A1A',
    blackSecondary: '#181824',
    white: '#fff',
    redPrimary: '#D23B35',
    borderRed: '#AB0B0B',
    positiveGreen: '#377D22',
    green: '#2CA14C',
    borderGreyLight: '#E6E6E6',
    borderGrey: '#DADADA',
    borderGreyDark: '#B7B7B7',
    borderGreyCommunity: '#E1E1E1',
    stepCounterGrey: '#D0D0D0',
    addButtonGrey: '#E4E4E4',
    tagBorderGrey: '#C4C4C4',
    titleGrey: '#707070',
    greyUnselected: '#F1F1F3',
    greyDivider: '#D7D7D7',
    backgroundGreyCommunity: '#F6F6F6',
    backgroundGreyLightest: '#F9F9F9',
    backgroundGreyLighter: '#EFEFEF',
    backgroundGreyLight: '#EEEEEE',
    backgroundGrey: '#D2D2D2',
    tableBorderGrey: '#E8E8E9',
    carouselBorderGrey: '#DBDBDB',
    carouselDotGrey: '#E2E2E2',
    cookieConsentBackgroundGrey: '#F2F2F2',
    tagLabelGrey: '#585858',
    tagGrey: '#BDBDBD',
    grey: '#D1D1D3',
    greyMedium: '#BBBBBB',
    greyDark: '#5D5D66',
    greyDarker: '#555555',
    greyDarkest: '#464650',
    twitterFeedGrey: '#f8f9fa',
    textGrey: '#6F7780',
    inputGrey: '#C2C8D0',
    allCapsHeader: '#717171',
    mainBackground: '#E5E5E5',
    yellowHighlight: '#FFE5A3',
    orangeBorderHighlight: '#EFAD76',
    videoBadgeBlue: '#28A8F0'
  },
  breakpoints: {
    xxs: '360px',
    xs: '479px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1280px',
    xxl: '1600px',
    xxxl: '2000px'
  },
  tableBreakpoints: {
    lg: '1040px'
  },
  fonts: {
    default: 'metropolis, sans-serif'
  },
  fontWeights: {
    black: 1000,
    extraBold: 900,
    bold: 800,
    semiBold: 700,
    medium: 600,
    regular: 500,
    light: 400,
    extraLight: 300,
    thin: 200
  },
  buttons: {
    default: {},
    primary: {},
    header: {},
    footer: {}
  }
};

export default theme;
