import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { prop, switchProp, ifProp } from 'styled-tools';

import { getLinkProps } from '../../utils/functions';

export const ButtonLinkStyles = css`
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  line-height: 1;
  text-align: center;
  justify-content: center;
  border-radius: 40px;
  min-height: 40px;
  letter-spacing: 1px;
  padding: 10px 32px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: ${(props) =>
    props.$fontWeight
      ? prop(`theme.fontWeights.${props.$fontWeight}`)
      : prop('theme.fontWeights.regular')};

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    width: 100%;
    min-height: 56px;
    font-size: 1.375rem;
  }
`;

export const ButtonLinkRedStyles = css`
  background-color: ${prop('theme.colors.redPrimary')};
  color: ${prop('theme.colors.white')};
  border-radius: 40px;
  border: none;

  &:hover {
    background-color: ${prop('theme.colors.black')};
    color: ${prop('theme.colors.white')};
  }
`;

export const ButtonLinkBlackOutlineStyles = css`
  border: 1px solid ${prop('theme.colors.black')};
  color: ${prop('theme.colors.black')};

  &:hover {
    background-color: ${prop('theme.colors.black')};
    color: ${prop('theme.colors.white')};
  }
`;

const StyledLink = styled(Link)`
  &&& {
    ${ButtonLinkStyles}

    ${ifProp(
      '$width',
      css`
        width: ${prop('$width')};
      `
    )}

    @media(min-width: ${prop('theme.breakpoints.md')}) {
      min-width: ${prop('$size')}px;
    }

    ${switchProp('$format', {
      fill: css`
        ${switchProp('$style', {
          'fill-red': css`
            ${ButtonLinkRedStyles}
          `,
          'fill-white': css`
            background-color: ${prop('theme.colors.white')};
            border: 1px solid ${prop('theme.colors.white')};
            border-radius: 40px;
            color: ${prop('theme.colors.redPrimary')};

            &:hover {
              background-color: ${prop('theme.colors.redPrimary')};
              color: ${prop('theme.colors.white')};
            }
          `
        })}
      `,
      outline: css`
        ${switchProp('$style', {
          'outline-black': css`
            ${ButtonLinkBlackOutlineStyles}
          `,
          'outline-white': css`
            border: 1px solid ${prop('theme.colors.white')};
            color: ${prop('theme.colors.white')};

            &:hover {
              background-color: ${prop('theme.colors.white')};
              color: initial;
            }
          `
        })}
      `
    })};
  }
`;

const propTypes = {
  link: PropTypes.shape({
    _meta: PropTypes.shape({
      type: PropTypes.string,
      uid: PropTypes.string,
      lang: PropTypes.string
    }),
    url: PropTypes.string
  }),
  format: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.string,
  width: PropTypes.string,
  queryParameters: PropTypes.object
};

function ButtonLink({
  link,
  children,
  format = 'fill',
  size,
  style = 'fill-red',
  width,
  fontWeight = 'regular',
  queryParameters
}) {
  const linkProps = getLinkProps(link, queryParameters);

  return (
    <StyledLink
      $format={format}
      $size={size}
      $style={style}
      $width={width}
      $fontWeight={fontWeight}
      {...linkProps}
    >
      {children}
    </StyledLink>
  );
}

ButtonLink.propTypes = propTypes;

export default ButtonLink;
