import { useState, useEffect } from 'react';

function useFetchSymbolHistory(symbol, timeFrame) {
  const [error, setError] = useState(null);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    let isMounted = true;
    // Don't call to API if no symbol or if we already have historical data
    if (!symbol || history.length > 0) return;
    const fetchData = async () => {
      const params = new URLSearchParams([
        ['symbol', symbol],
        ['period', timeFrame]
      ]);

      const dx_feed_endpoint =
        process.env.GATSBY_DX_FEED_PROXY_URL || process.env.DX_FEED_PROXY_URL;

      const res = await fetch(
        `${dx_feed_endpoint}/api/current/candle-dxfeed?${params.toString()}`
      );

      let x = 0;

      res
        .json()
        .then((res) => {
          if (isMounted) {
            setHistory(
              res.map((row) => ({
                symbol: row.symbol.split('{=d}')[0],
                data: {
                  ...row,
                  x: x++,
                  y: row.close
                }
              }))
            );
          }
        })
        .catch((err) => setError(err));
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  return error ? error : history;
}

export default useFetchSymbolHistory;
