import React from 'react';
import PropTypes from 'prop-types';
import { ifProp, prop, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';

import RichTextWithDictionary from '../RichTextWithDictionary';

const DEFAULT_IMAGE_STYLE = 'with_background';

const StyledCol = styled.div`
  padding: 0 0.9375rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    margin-bottom: 1.5rem;
  }

  h3 {
    padding-bottom: 0;
  }

  h3,
  h4 {
    margin-bottom: 0;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  margin: 0 auto 1rem;
  display: flex;
  justify-content: center;

  // Display style overrides from prismic option.
  // This is set up with switchProp so that additional styles can be tacked on as needed.
  ${switchProp('displayStyle', {
    with_background: css`
      border-radius: 1.25rem;
      box-shadow: -1px 1px 6px #00000029;

      img {
        border-radius: 1.25rem;
      }
    `
  })}
`;

const TextContainer = styled.div`
  && {
    h3 {
      font-size: 1.3rem;
      font-weight: ${prop('theme.fontWeights.regular')};
      padding: 0 0.5rem 1rem 0.5rem;
      text-transform: ${ifProp({ template: 'homepage' }, 'uppercase', '')};
      color: ${ifProp({ template: 'homepage' }, 'red', '')};
    }

    h4 {
      font-size: 0.775rem;
      padding-bottom: 1rem;
    }

    p {
      padding-bottom: ${ifProp({ template: 'homepage' }, '0', '2rem')};
      text-align: center;
      width: max-content;
    }

    ${ifProp(
      { template: 'products-landing' },
      css`
        h3 {
          margin-top: 2.5rem;
          font-weight: ${prop('theme.fontWeights.semiBold')};
        }
        p {
          margin: 0 auto;
          max-width: 200px;
        }
      `
    )}
  }
`;

const propTypes = {
  fields: PropTypes.array,
  template: PropTypes.string
};

const defaultProps = {
  fields: [],
  template: ''
};

function Block({ fields, template }) {
  return (
    <>
      {fields.map((field, index) => {
        const {
          image,
          text_block,
          title,
          image_display_style = DEFAULT_IMAGE_STYLE
        } = field;

        return (
          <StyledCol xs={12} sm={12 / fields.length} key={`column-${index}`}>
            {image && image.url && (
              <ImageContainer displayStyle={image_display_style}>
                <img
                  alt={image.alt}
                  src={image.url}
                  height={image.dimensions && image.dimensions.height}
                  width={image.dimensions && image.dimensions.width}
                />
              </ImageContainer>
            )}
            <TextContainer template={template}>
              <RichTextWithDictionary render={title.richText} />
              <RichTextWithDictionary render={text_block.richText} />
            </TextContainer>
          </StyledCol>
        );
      })}
    </>
  );
}

Block.propTypes = propTypes;
Block.defaultProps = defaultProps;

export default Block;
