import React from 'react';
import { Link } from 'gatsby';

import linkResolver from './linkResolver';

// There are some unused parameters here that are required as part of the function definition by the RichText component.
// Please do not remove unused parameters.
const hyperLinkHelper = (type, element, content, children, index) => {
  if (!element || !element.data) {
    return null;
  }

  const link = element.data;

  if (link.link_type === 'Web' || link.link_type === 'Media') {
    return (
      <a href={link.url} target='__blank'>
        {content}
      </a>
    );
  }

  return <Link to={linkResolver(link)}>{content}</Link>;
};

export default hyperLinkHelper;
