import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { PrismicRichText } from '@prismicio/react';
import PropTypes from 'prop-types';

import { signAgreement } from '../../../../state/actions/agreementActions';
import RegistrationForm from '../RegistrationForm';
import Input from '../RegistrationForm/Input';
import linkResolver from '../../../../utils/linkResolver';
import StepContext from '../../../../context/StepContext';
import Checkbox from '../RegistrationForm/Checkbox';
import greenCheckmark from '../../../../images/icons/green-checkmark.svg';
import { AGREEMENT_STATUS_SIGNED } from '../../../../utils/constants';
import {
  Row,
  AgreementContainer,
  Section,
  SectionHeader,
  StatusIndicator
} from '.';
import useResize from '../../../../hooks/useResize';

const propTypes = { agreement: PropTypes.object };

const defaultProps = { agreement: {} };

function SingleAgreementDisplay({ agreement }) {
  const { setCurrentStep } = useContext(StepContext);
  const dispatch = useDispatch();
  const { id, data, status, signature } = agreement;
  const { title, agreement_body, section } = data;
  const isSigned = status === AGREEMENT_STATUS_SIGNED;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [signatureValue] = useState(signature);
  const [consentValue] = useState(isSigned || undefined);
  const [isMobile, setIsMobile] = useState(false);
  useResize(isMobile, setIsMobile);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid, errors }
  } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    register('signature', {
      required: 'Signature is required'
    });

    register('consent', {
      required: 'Consent is required'
    });

    if (agreement.signature && agreement.signature !== '') {
      setValue('signature', agreement.signature, {
        shouldValidate: true
      });

      setValue('consent', isSigned);
    }
  }, [agreement]);

  const onPreviousBehavior = {
    type: 'button',
    onClick: () => {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const onSubmit = (data) => {
    setIsSubmitting(true);
    if (isSigned) {
      setIsSubmitting(false);
      setCurrentStep((prev) => prev + 1);
    } else {
      const agreement = {
        id: id,
        signature_value: data.signature
      };
      dispatch(
        signAgreement(
          agreement,
          () => {
            window.dataLayer = window.dataLayer || [];
            setCurrentStep((prev) => prev + 1);
            window.dataLayer.push({ event: 'sign_agreement' });
          },
          () => setIsSubmitting(false)
        )
      );
    }
  };

  return (
    <>
      <h2>{title}</h2>
      <PrismicRichText
        field={agreement_body.richText}
        linkResolver={linkResolver}
      />
      <RegistrationForm
        handleSubmit={handleSubmit(onSubmit)}
        onPreviousBehavior={onPreviousBehavior}
        isValid={isValid || isSigned}
        isSubmitting={isSubmitting}
        disableRequiredMessage
        nextButtonText={isSigned ? 'Next Step' : 'Submit'}
      >
        <AgreementContainer>
          <SectionHeader $isExpanded>
            <div>
              <h3>{section[0].section_name}</h3>
              {isSigned && (
                <StatusIndicator $status={AGREEMENT_STATUS_SIGNED}>
                  <img src={greenCheckmark} />
                  <span>{AGREEMENT_STATUS_SIGNED.toUpperCase()}</span>
                </StatusIndicator>
              )}
            </div>
          </SectionHeader>
          <div>
            {section.map((sectionContent, sectionIndex) => (
              <Section
                className={sectionContent.css_classname || ''}
                key={`${title}-${sectionIndex}`}
              >
                {/* The first section name is displayed in the SectionHeader, since the first section name is always the title of the document */}
                {sectionIndex > 0 && <h3>{sectionContent.section_name}</h3>}

                <PrismicRichText
                  field={sectionContent.section_body.richText}
                  linkResolver={linkResolver}
                />
              </Section>
            ))}
          </div>
          <Row>
            <Checkbox
              label='I Consent to the Agreement'
              checked={consentValue}
              error={errors.consent}
              disabled={isSigned}
              {...register('consent', {
                required: 'Consent is required'
              })}
            />
            <Input
              disableTopMargin={!isMobile}
              type='text'
              name={'signature'}
              labelText='Signature (Type Your Name)'
              error={errors.signature}
              defaultValue={signatureValue}
              disabled={isSigned}
              absoluteMessagePosition
              {...register('signature', {
                required: 'Signature is required'
              })}
            />
          </Row>
        </AgreementContainer>
      </RegistrationForm>
    </>
  );
}

SingleAgreementDisplay.propTypes = propTypes;
SingleAgreementDisplay.defaultProps = defaultProps;

export default SingleAgreementDisplay;
