import React from 'react';
import first from 'lodash-es/first';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import { PrismicRichText } from '@prismicio/react';

import hyperLinkHelper from '../../utils/hyperLinkHelper';
import linkResolver from '../../utils/linkResolver';
import RichTextWithDictionary from '../RichTextWithDictionary';
import EmbeddedVideoPlayer from '../EmbeddedVideoPlayer';

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 250px;
  margin: 0.3125rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    font-size: 1.25rem;
    margin-bottom: 0;
  }

  .color-red {
    color: ${prop('theme.colors.redPrimary')};
  }
`;

const propTypes = {
  fields: PropTypes.array
};

const defaultProps = {
  fields: []
};

function VideoBlock({ primary, fields }) {
  return (
    <>
      <EmbeddedVideoPlayer withBorder video={primary.embedded_video} />
      {fields.map((field) => {
        const { text_block, title, additional_text } = field;

        return (
          <StyledCol xs={12} key={`column-${first(title).text}`}>
            <RichTextWithDictionary render={title} />
            {additional_text && (
              <>
                <PrismicRichText
                  field={additional_text.richText}
                  linkResolver={linkResolver}
                />
              </>
            )}
            <RichTextWithDictionary
              render={text_block}
              serializeHyperlink={hyperLinkHelper}
            />
          </StyledCol>
        );
      })}
    </>
  );
}

VideoBlock.propTypes = propTypes;
VideoBlock.defaultProps = defaultProps;

export default VideoBlock;
