exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-email-confirmation-js": () => import("./../../../src/pages/email-confirmation.js" /* webpackChunkName: "component---src-pages-email-confirmation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-all-news-list-page-js": () => import("./../../../src/templates/AllNewsListPage.js" /* webpackChunkName: "component---src-templates-all-news-list-page-js" */),
  "component---src-templates-community-portal-landing-page-js": () => import("./../../../src/templates/CommunityPortalLandingPage.js" /* webpackChunkName: "component---src-templates-community-portal-landing-page-js" */),
  "component---src-templates-community-portal-page-js": () => import("./../../../src/templates/CommunityPortalPage.js" /* webpackChunkName: "component---src-templates-community-portal-page-js" */),
  "component---src-templates-institutions-page-js": () => import("./../../../src/templates/InstitutionsPage.js" /* webpackChunkName: "component---src-templates-institutions-page-js" */),
  "component---src-templates-news-category-page-js": () => import("./../../../src/templates/NewsCategoryPage.js" /* webpackChunkName: "component---src-templates-news-category-page-js" */),
  "component---src-templates-news-item-js": () => import("./../../../src/templates/NewsItem.js" /* webpackChunkName: "component---src-templates-news-item-js" */),
  "component---src-templates-news-landing-page-js": () => import("./../../../src/templates/NewsLandingPage.js" /* webpackChunkName: "component---src-templates-news-landing-page-js" */),
  "component---src-templates-notifications-page-js": () => import("./../../../src/templates/notificationsPage.js" /* webpackChunkName: "component---src-templates-notifications-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-archive-js": () => import("./../../../src/templates/ProductArchive.js" /* webpackChunkName: "component---src-templates-product-archive-js" */),
  "component---src-templates-product-archive-landing-page-js": () => import("./../../../src/templates/ProductArchiveLandingPage.js" /* webpackChunkName: "component---src-templates-product-archive-landing-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-products-landing-page-js": () => import("./../../../src/templates/productsLandingPage.js" /* webpackChunkName: "component---src-templates-products-landing-page-js" */),
  "component---src-templates-promo-landing-page-js": () => import("./../../../src/templates/promoLandingPage.js" /* webpackChunkName: "component---src-templates-promo-landing-page-js" */),
  "component---src-templates-registration-flow-js": () => import("./../../../src/templates/RegistrationFlow.js" /* webpackChunkName: "component---src-templates-registration-flow-js" */)
}

