import { Link } from 'gatsby';
import { ifProp, prop, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';

export const Author = styled.span`
  color: ${prop('theme.colors.greyDarker')};
  font-size: 0.875rem;
  margin-right: 0.375rem;
  text-transform: uppercase;
`;

export const Date = styled.span`
  color: ${prop('theme.colors.greyDarker')};
  font-size: 0.875rem;
  text-transform: uppercase;
`;

export const CardTitle = styled.p`
  color: ${prop('theme.colors.black')};
  font-size: ${prop('$fontSize', '1.125rem')};
  font-weight: ${prop('theme.fontWeights.semiBold')};
  line-height: 1.5rem;
  &&& {
    ${ifProp(
      '$featured',
      css`
        margin-bottom: 0.5rem;
      `,
      css`
        margin-bottom: 0.375rem;
      `
    )}
  }
`;

export const ArticleLink = styled(Link)`
  &:hover {
    text-decoration: none;

    ${CardTitle} {
      color: ${prop('theme.colors.redPrimary')};
    }
  }
`;

export const ListTitle = styled.h3`
  font-weight: ${prop('theme.fontWeights.medium')};
  line-height: 1.75rem;
  margin-bottom: 2.25rem;
`;

export const MoreLink = styled(Link)`
  color: ${prop('theme.colors.redPrimary')};
  font-size: 0.875rem;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const Teaser = styled.p`
  color: ${prop('theme.colors.black')};
  font-size: 0.875rem;
  line-height: 1rem;
  margin-bottom: 0.625rem;
`;

export const Thumbnail = styled.img`
  ${switchProp('$type', {
    featured_video: css`
      object-fit: cover;

      @media (min-width: ${prop('theme.breakpoints.sm')}) {
        /* The thumbnail from youtube has a black border that we don't want to display here, so we scale up the image slightly to hide the border  */
        transform: scale(1.02);
      }
    `,
    featured: css`
      object-fit: cover;
    `,
    default: css`
      background: linear-gradient(
        180deg,
        rgba(195, 195, 195, 1) 35%,
        rgba(255, 255, 255, 1) 100%
      );
      object-fit: contain;
    `
  })}

  ${switchProp('$layout', {
    'full-page-list': css`
      border-radius: 0.5rem;
      margin-right: 1.5rem;
      width: ${prop('$width')}rem;

      @media (max-width: ${prop('theme.breakpoints.sm')}) {
        border-radius: 0.5rem 0.5rem 0 0;
        width: 100%;
      }
    `,
    list: css`
      border-radius: 0.5rem;
      margin-right: 1.5rem;
      width: ${prop('$width')}rem;
    `,
    card: css`
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      width: 100%;
    `
  })}
`;

export const NewsPreviewWrapper = styled.div`
  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin-bottom: 1.5rem;
  }
`;
