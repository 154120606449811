import React from 'react';
import get from 'lodash-es/get';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { prop, ifProp, ifNotProp } from 'styled-tools';

import {
  ArticleLink,
  CardTitle,
  Author,
  Date,
  ListTitle,
  MoreLink,
  Teaser,
  Thumbnail
} from './styled/SharedComponents';
import defaultImage from 'images/smalls-default.png';
import linkResolver from '../../utils/linkResolver';
import { trimTeaser, getYoutubeThumbnailUrl } from '../../utils/functions';
import dayjs from '../../utils/dayjs';
import { NEWS_DATE_FORMAT, YOUTUBE_THUMBNAILS } from '../../utils/constants';

const NewsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  max-width: 100%;

  @media (min-width: ${prop('theme.breakpoints.xl')}) {
    ${ifNotProp(
      '$fullPageMode',
      css`
        height: 100%;
      `
    )}
  }

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    flex: 0 0 ${prop('$width', '100%')};
    max-width: ${prop('$width', '100%')};
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${prop('theme.breakpoints.xl')}) {
    ${ifNotProp(
      '$fullPageMode',
      css`
        height: 100%;
        justify-content: space-between;
      `
    )}
  }

  ${ifProp(
    { $shouldDisplay: false },
    css`
      display: none;
    `
  )}
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  ${ifProp(
    { $fullPageMode: true },
    css`
      margin-bottom: 2.25rem;
    `,
    css`
      margin-bottom: 1.75rem;
    `
  )}

  h3 {
    margin: 0;
  }

  a {
    line-height: 1.75rem;
  }
`;

const NewsItem = styled.div`
  display: flex;
  
  @media (max-width: ${prop('theme.breakpoints.xl')}) {
    margin-bottom: 2.5rem;

    ${ifNotProp(
      '$fullPageMode',
      css`
        &:last-child {
          margin-bottom: 0;
        }
      `
    )}
    
  }

  ${ifProp(
    { $fullPageMode: true },
    css`
      margin-bottom: 2.5rem;
    `
  )}

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    flex-direction: column;
  }
`;

const ContentContainer = styled.div`
  width: 100%;

  @media (min-width: ${prop('theme.breakpoints.xl')}) {
    min-height: 8.5rem;
  }

  ${ifProp(
    { $showDividers: true },
    css`
      padding-bottom: 1rem;
      border-bottom: 1px solid ${prop('theme.colors.borderGrey')};
    `
  )}

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    padding: 1rem;
    border: 1px solid ${prop('theme.colors.borderGrey')};
    border-top: none;
    border-radius: 0 0 1rem 1rem;
  }
`;

const AuthorLine = styled.div`
  display: flex;
  margin-bottom: 0.75rem;
`;

const teaserLengths = {
  short: 100,
  full: 160
};

const propTypes = {
  title: PropTypes.string,
  parentPage: PropTypes.shape({
    type: PropTypes.string,
    uid: PropTypes.string
  }),
  newsList: PropTypes.array.isRequired,
  teaserSetting: PropTypes.string,
  displayMoreLink: PropTypes.bool,
  width: PropTypes.string,
  showDividers: PropTypes.bool,
  viewAllLink: PropTypes.object,
  fullPageMode: PropTypes.bool
};

const defaultProps = {
  title: '',
  parentPage: { uid: 'news', type: 'news_landing_page' },
  teaserSetting: 'full',
  displayMoreLink: false,
  width: undefined,
  showDividers: false,
  viewAllLink: {},
  fullPageMode: false
};

function NewsList({
  title,
  parentPage,
  newsList,
  teaserSetting,
  displayMoreLink,
  width,
  showDividers,
  viewAllLink = {},
  fullPageMode
}) {
  return (
    <NewsListContainer $width={width} $fullPageMode={fullPageMode}>
      <TitleRow $fullPageMode={fullPageMode}>
        <ListTitle>{title}</ListTitle>
        {viewAllLink.uid && (
          <MoreLink to={linkResolver(viewAllLink)}>View All →</MoreLink>
        )}
      </TitleRow>
      <ListWrapper>
        {newsList.map((item) => {
          const { type, uid } = item.node;
          const newsItem = get(item, 'node.data');
          const {
            featured_image,
            featured_video_embed,
            post_title,
            author,
            post_author,
            news_item_type
          } = newsItem;
          const isVideoArticle = news_item_type === 'video';
          const videoThumbnailUrl = getYoutubeThumbnailUrl(
            featured_video_embed,
            YOUTUBE_THUMBNAILS.small
          );
          const altText = isVideoArticle
            ? get(post_title, 'text')
            : featured_image.alt;
          const thumbnail = isVideoArticle
            ? videoThumbnailUrl
            : get(
                featured_image,
                'thumbnails.preview.url',
                get(featured_image, 'url')
              );
          const thumbnailType = thumbnail
            ? isVideoArticle
              ? 'featured_video'
              : 'featured'
            : 'default';
          const authorName = get(
            author,
            'document.data.author_name',
            post_author.text
          );

          return (
            <NewsItem key={uid} $fullPageMode={fullPageMode}>
              <Link
                to={linkResolver({ type: type, uid: uid })}
                state={{ parentPage: parentPage }}
              >
                <Thumbnail
                  alt={thumbnail ? altText : 'placeholder article image'}
                  height={136}
                  width={136}
                  $layout={fullPageMode ? 'full-page-list' : 'list'}
                  src={thumbnail ? thumbnail : defaultImage}
                  $type={thumbnailType}
                />
              </Link>
              <ContentContainer $showDividers={showDividers}>
                <AuthorLine>
                  {authorName && <Author>{authorName}</Author>}
                  {newsItem.post_date && (
                    <Date>
                      {dayjs(newsItem.post_date).format(NEWS_DATE_FORMAT)}
                    </Date>
                  )}
                </AuthorLine>
                {newsItem.post_title.text && (
                  <ArticleLink
                    to={linkResolver({ type: type, uid: uid })}
                    state={{ parentPage: parentPage }}
                  >
                    <CardTitle
                      $fontSize={fullPageMode ? '1.375rem' : undefined}
                    >
                      {newsItem.post_title.text}
                    </CardTitle>
                  </ArticleLink>
                )}
                {teaserSetting !== 'none' && newsItem.seo_meta_description && (
                  <Teaser>
                    {trimTeaser(
                      newsItem.seo_meta_description,
                      teaserLengths[teaserSetting]
                    )}
                  </Teaser>
                )}
                {displayMoreLink && (
                  <MoreLink
                    to={linkResolver({ type: type, uid: uid })}
                    state={{ parentPage: parentPage }}
                  >
                    Read More →
                  </MoreLink>
                )}
              </ContentContainer>
            </NewsItem>
          );
        })}
      </ListWrapper>
    </NewsListContainer>
  );
}

NewsList.propTypes = propTypes;
NewsList.defaultProps = defaultProps;

export default NewsList;
