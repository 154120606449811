import { prop } from 'styled-tools';
import styled from 'styled-components';

const StyledTable = styled.table`
  background-color: ${prop('theme.colors.white')};
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
  padding: 0;
  width: ${(props) =>
    props.$columnWidth && props.$columnWidth === 'fit-content'
      ? 'fit-content'
      : '100%'};

  tr {
    border-left: 1px solid ${prop('theme.colors.borderGrey')};
    border-right: 1px solid ${prop('theme.colors.borderGrey')};

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      display: table-row;
      border: none;
    }
  }

  tbody tr {
    &:last-child {
      td {
        border-bottom: 1px solid ${prop('theme.colors.borderGrey')};

        &:first-child {
          border-bottom-left-radius: 1.5rem;
        }

        &:last-child {
          border-bottom-right-radius: 1.5rem;
        }
      }
    }
  }
`;

export default StyledTable;
