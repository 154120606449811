import subscriberApiClient, {
  getErrorMessage
} from '../../utils/subscriberApiClient';
import actions from '../actions';
import errorTypes from './errorTypes';
import { PRIMARY_PURPOSE } from '../../utils/constants';

export const fetchAddresses = () => async (dispatch) => {
  try {
    const response = await subscriberApiClient.get('/subscriber/addresses');

    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.ADDRESS_FETCH_FAILED
    });

    dispatch({
      type: actions.SET_ADDRESSES,
      payload: formatAddressResponse(response.data.addresses)
    });
  } catch (err) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.ADDRESS_FETCH_FAILED,
        message: getErrorMessage(err)
      }
    });
  }
};

export const setAddresses = (
  successCallback,
  finishedCallback,
  addressesToCreate = [],
  addressesToUpdate = [],
  addressesToDelete = []
) => async (dispatch) => {
  try {
    if (addressesToDelete.length > 0) {
      await deleteAddresses(addressesToDelete);
    }

    let addresses;
    if (addressesToCreate.length > 0) {
      addresses = await createAddresses(addressesToCreate);
    }
    if (addressesToUpdate.length > 0) {
      addresses = await updateAddresses(addressesToUpdate);
    }

    dispatch({
      type: actions.SET_ADDRESSES,
      payload: addresses
    });
    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.ADDRESS
    });

    successCallback();
  } catch (error) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.ADDRESS,
        message: getErrorMessage(error)
      }
    });
  }

  finishedCallback();
};

const createAddresses = async (addresses) => {
  const requestBody = addresses.map((address) => ({
    purpose: PRIMARY_PURPOSE,
    address_type: address.addressType,
    address_1: address.addressLine1,
    address_2: address.addressLine2,
    city: address.city,
    state: address.state,
    postal_code: address.postalCode,
    country: address.country
  }));

  const response = await subscriberApiClient.post(
    '/subscriber/addresses_batch',
    requestBody
  );
  return formatAddressResponse(response.data);
};

const updateAddresses = async (addresses) => {
  const requestBody = addresses.map((address) => ({
    purpose: PRIMARY_PURPOSE,
    id: address.id,
    address_type: address.addressType,
    address_1: address.addressLine1,
    address_2: address.addressLine2,
    city: address.city,
    state: address.state,
    postal_code: address.postalCode,
    country: address.country
  }));

  const response = await subscriberApiClient.patch(
    '/subscriber/addresses_batch',
    requestBody
  );

  return formatAddressResponse(response.data);
};

const deleteAddresses = async (addresses) => {
  const ids = addresses.map((address) => address.id).join(',');

  try {
    await subscriberApiClient.delete('/subscriber/addresses_batch', {
      params: {
        ids: ids
      }
    });
  } catch (err) {
    console.error('There was an error updating address information', err);
  }
};

function formatAddressResponse(response) {
  if (!response) {
    return [];
  }

  return response.map((item) => {
    const address = item.address || item;
    return {
      id: address.id,
      addressType: address.address_type,
      addressLine1: address.address_1,
      addressLine2: address.address_2,
      city: address.city,
      state: address.state,
      postalCode: address.postal_code,
      country: address.country
    };
  });
}
