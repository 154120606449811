import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

import {
  INVOICE_STATUS_PAID,
  MARKET_TIMEZONE_ID
} from '../../../../utils/constants';
import dayjs from '../../../../utils/dayjs';
import greenCheckmark from '../../../../images/icons/green-check-outlined-circle.svg';
import { CurrentStatus } from '../../shared-styled-components';

const InvoiceRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 52px;

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    flex-direction: column;
    margin-bottom: 0;
  }

  &:last-child {
    justify-content: flex-end;
    margin-bottom: 0;
  }
`;

const InvoiceItem = styled.div`
  @media (max-width: ${prop('theme.breakpoints.md')}) {
    margin-bottom: 12px;
  }
`;

const LineItemList = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${prop('theme.colors.borderGrey')};
  padding: 0 0 1rem;
  margin-bottom: 1rem;
`;

const LineItem = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(3, 1fr);

  @media (max-width: ${prop('theme.breakpoints.xs')}) {
    display: flex;
    flex-direction: column;
  }
`;

const Adjustment = styled(LineItem)`
  font-weight: ${prop('theme.fontWeights.semiBold')};
  color: ${prop('theme.colors.green')};

  > div:first-child {
    grid-column: 1 / 4;
    text-align: right;
  }

  @media (max-width: ${prop('theme.breakpoints.xs')}) {
    flex-direction: row;
    justify-content: flex-end;

    > div:first-child {
      margin-right: 8px;
    }
  }
`;

const LineItemInfo = styled.div`
  text-align: right;

  @media (max-width: ${prop('theme.breakpoints.xs')}) {
    text-align: left;
    margin-bottom: 8px;

    ${ifProp(
      { $type: 'costPerUnit' },
      css`
        text-align: right;
      `
    )}
  }

  &:first-child {
    text-align: left;
  }
`;

const Label = styled.div`
  font-weight: ${prop('theme.fontWeights.bold')};
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
`;

const Total = styled.div`
  display: flex;
  padding: 1rem;
  border: 1px solid ${prop('theme.colors.borderGrey')};
  border-radius: 0.25rem;

  div {
    margin-right: 1rem;
  }
`;

const propTypes = {
  invoice: PropTypes.object
};

const defaultProps = {
  invoice: {}
};

function InvoiceDetails({ invoice }) {
  const total = isNaN(parseFloat(invoice.total_cost))
    ? 0
    : parseFloat(invoice.total_cost);

  const formattedDate = invoice
    ? dayjs(invoice.created_at, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
        .tz(MARKET_TIMEZONE_ID)
        .format('M/D/YYYY, h:mm:ss A z')
    : '';

  const getAdjustmentDisplayText = (adjustments) => {
    const negativeSign = adjustments < 0.0 ? '-' : '';
    const absoluteAmount = Math.abs(adjustments);
    return `${negativeSign}$${absoluteAmount.toFixed(2)}`;
  };

  const lineItems = invoice.line_items || [];
  const invoiceStatus = invoice?.status;
  const isPaid = invoiceStatus === INVOICE_STATUS_PAID;
  // The main difference bewteen this and the above variable is that the line item adjustments (from sponsorships) has covered the full cost of the invoice
  // meaning that the user did not actually pay anything
  const sponsorPaidInFull = isPaid && total === 0;

  return (
    <>
      <InvoiceRow>
        <InvoiceItem>
          <Label>Invoice Number:</Label>
          <div>{invoice.external_id}</div>
        </InvoiceItem>
        <InvoiceItem>
          <Label>Status:</Label>
          <div>
            {isPaid ? (
              <CurrentStatus $status='active'>
                <img src={greenCheckmark} />
                Paid
              </CurrentStatus>
            ) : (
              <CurrentStatus>{invoiceStatus}</CurrentStatus>
            )}
          </div>
        </InvoiceItem>
        <InvoiceItem>
          <Label>Date:</Label>
          <div>{formattedDate}</div>
        </InvoiceItem>
      </InvoiceRow>
      <Label>Details:</Label>
      <LineItemList>
        {lineItems.map((lineItem) => {
          const costPerUnit = isNaN(parseInt(lineItem.cost_per_unit))
            ? 0
            : parseInt(lineItem.cost_per_unit);
          const adjustments = isNaN(parseFloat(lineItem.adjustments_subtotal))
            ? 0
            : parseFloat(lineItem.adjustments_subtotal);

          return (
            <React.Fragment key={lineItem.id}>
              <LineItem>
                <LineItemInfo>{lineItem.description}</LineItemInfo>
                <LineItemInfo>({lineItem.quantity}x)</LineItemInfo>
                <LineItemInfo>{lineItem.unit}</LineItemInfo>
                <LineItemInfo $type='costPerUnit'>
                  ${costPerUnit.toFixed(2)}
                </LineItemInfo>
              </LineItem>
              {adjustments !== 0 && (
                <Adjustment>
                  <LineItemInfo>Adjustment:</LineItemInfo>
                  <LineItemInfo>
                    {getAdjustmentDisplayText(adjustments)}
                  </LineItemInfo>
                </Adjustment>
              )}
              {isPaid && !sponsorPaidInFull && (
                <Adjustment>
                  <LineItemInfo>Payment:</LineItemInfo>
                  <LineItemInfo>
                    {getAdjustmentDisplayText(-1 * total)}
                  </LineItemInfo>
                </Adjustment>
              )}
            </React.Fragment>
          );
        })}
      </LineItemList>
      <TotalRow>
        <Total>
          <Label>Amount Due:</Label>${isPaid ? '0.00' : total.toFixed(2)}
        </Total>
      </TotalRow>
    </>
  );
}

InvoiceDetails.propTypes = propTypes;
InvoiceDetails.defaultProps = defaultProps;

export default InvoiceDetails;
