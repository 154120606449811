import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

import BlockStyles from '../BlockStyles';

const MailchimpWrapper = styled.div`
  margin-bottom: 5rem;
  clear: left;
  font-weight: ${prop('theme.fontWeights.regular')};

  ${ifProp(
    { $template: 'community-portal' },
    css`
      input {
        max-width: 650px;
      }
    `
  )}

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    margin-bottom: 2.1875rem;
  }

  form {
    display: block;
    position: relative;
    text-align: left;
  }

  .small-meta {
    font-size: 0.6875rem;
  }

  .nowrap {
    white-space: nowrap;
  }

  .clear {
    clear: both;
  }

  div {
    &#mce-responses {
      display: block;
      overflow: hidden;
      clear: both;
    }

    &.response {
      margin: 1em 0;
      padding: 1em 0.5em 0.5em 0;
      z-index: 1;

      a {
        color: ${prop('theme.colors.redPrimary')};
      }
    }
  }

  #mce-error-response {
    display: none;
    color: ${prop('theme.colors.redPrimary')};

    a {
      display: block;
    }
  }

  #mce-success-response {
    display: none;
  }

  label.error {
    display: block;
    float: none;
    width: auto;
    margin-left: 1.05em;
    text-align: left;
    padding: 0.5em 0;
  }

  #num-subscribers {
    font-size: 1.1em;

    span {
      padding: 0.5em;
      border: 1px solid #ccc;
      margin-right: 0.5em;
      font-weight: ${prop('theme.fontWeights.bold')};
    }
  }

  #__lpform_mce-FNAME {
    display: none;
  }

  div.mce_inline_error {
    font-weight: ${prop('theme.fontWeights.regular')} !important;
    margin: 0 !important;
    padding: 0 !important;
    background-color: ${prop('theme.colors.white')} !important;
    color: ${prop('theme.colors.redPrimary')} !important;
  }

  input.mce_inline_error {
    border: 1px solid ${prop('theme.colors.redPrimary')} !important;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const FieldGroup = styled.div`
  padding-bottom: 1.25rem;

  label {
    display: block;
    margin-bottom: 3px;
    font-size: 1.0625rem;
  }

  select {
    display: inline-block;
    width: 99%;
    padding: 5px 0;
    margin-bottom: 2px;
  }

  &&& {
    div.mce_inline_error {
      /* forced to use !important here to override the default mailchimp styling, which is also using !important */
      background-color: transparent !important;
    }
  }

  &.input-group {
    ul {
      margin: 0;
      padding: 5px 0;
      list-style: none;

      li {
        display: block;
        padding: 3px 0;
        margin: 0;
      }
    }

    label {
      display: inline;
    }

    input {
      display: inline;
      width: auto;
      border: none;
    }
  }
`;

const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  width: 100%;

  &[type='checkbox'] {
    -webkit-appearance: checkbox;
  }

  &[type='text'] {
    background-image: none !important;
    &:focus {
      outline: 0;
      box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
    }
  }

  &[type='radio'] {
    -webkit-appearance: radio;
  }
`;

const Button = styled.input`
  border: 2px solid ${prop('theme.colors.white')};
  border-radius: 2.125rem;
  border-color: ${prop('theme.colors.white')};
  background-color: ${prop('theme.colors.redPrimary')};
  color: ${prop('theme.colors.white')};
  padding: 0.5rem 4rem;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  margin-top: 1.25rem;

  &:hover {
    background-color: ${prop('theme.colors.redPrimary')};
    color: ${prop('theme.colors.white')};
    text-decoration: underline;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;

  input {
    -webkit-appearance: checkbox;
    margin-right: 1rem;
  }
`;

const propTypes = {
  additionalParameters: PropTypes.array,
  primary: PropTypes.object
};

const defaultProps = {
  additionalParameters: [],
  primary: {}
};

/*
  NOTE: For some reason, having multiple Mailchimp forms on one page causes the error handling to only work for
  the first form on the page. I believe this could have something to do with the script that we're adding in the 
  useLayoutEffect call here, but I also don't see this being an issue in a real scenario any time soon. At this
  time we have no need to have multiple Mailchimp forms on one page, so it's not worth devoting more time to this 
  problem.
*/
function MailchimpForm({ primary, additionalParameters, template }) {
  const { mailchimp_form_id, form_type, checkbox_text } = primary;

  useLayoutEffect(() => {
    const existingScript = document.getElementById('mailchimpValidate');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src =
        '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
      script.id = 'mailchimpValidate';
      // script.async = true;
      script.onload = function() {
        (function($) {
          window.fnames = [];
          window.ftypes = [];
          window.fnames[0] = 'EMAIL';
          window.ftypes[0] = 'email';
          window.fnames[1] = 'FNAME';
          window.ftypes[1] = 'text';
          window.fnames[2] = 'LNAME';
          window.ftypes[2] = 'text';
          window.fnames[3] = 'TEXTAREAY';
          window.ftypes[3] = 'text';
        })(window.jQuery);
        window.$mcj = window.jQuery.noConflict(true);
      };
      document.body.appendChild(script);
    }
  }, []);

  const additionalParametersString =
    additionalParameters && additionalParameters.length > 0
      ? `&${additionalParameters
          .map(
            (param) =>
              `${param.additional_parameter_name}=${param.additional_parameter_value}`
          )
          .join('&')}`
      : '';

  return (
    <BlockStyles data={primary} template={template}>
      <div className='block'>
        <MailchimpWrapper id='mc_embed_signup' $template={template}>
          <form
            action={`https://thesmallexchange.us7.list-manage.com/subscribe/post?u=fbd38127cfb37b929203b03d4&amp;id=${mailchimp_form_id}${additionalParametersString}`}
            method='post'
            id='mc-embedded-subscribe-form'
            name='mc-embedded-subscribe-form'
            className='validate'
            target='_blank'
            noValidate
          >
            <div id='mc_embed_signup_scroll'>
              {(form_type === 'nameAndEmail' || form_type === 'broker') && (
                <>
                  <FieldGroup className='mc-field-group'>
                    <label htmlFor='mce-FNAME'>First Name</label>
                    <Input
                      type='text'
                      name='FNAME'
                      className='required'
                      id='mce-FNAME'
                    />
                  </FieldGroup>
                  <FieldGroup className='mc-field-group'>
                    <label htmlFor='mce-LNAME'>Last Name</label>
                    <Input
                      type='text'
                      name='LNAME'
                      className='required'
                      id='mce-LNAME'
                    />
                  </FieldGroup>
                </>
              )}
              <FieldGroup className='mc-field-group'>
                <label htmlFor='mce-EMAIL'>Email Address</label>
                <Input
                  type='email'
                  name='EMAIL'
                  className='required email'
                  id='mce-EMAIL'
                />
              </FieldGroup>
              {form_type === 'broker' && (
                <FieldGroup className='mc-field-group'>
                  <label htmlFor='mce-BROKER'>Broker</label>
                  <Input
                    type='text'
                    name='BROKER'
                    className='required broker'
                    id='mce-BROKER'
                  />
                </FieldGroup>
              )}
              {form_type === 'emailWithConfirmationCheckbox' && (
                <div
                  id='mergeRow-gdpr'
                  className='mergeRow gdpr-mergeRow content__gdprBlock mc-field-group'
                >
                  <div className='content__gdpr'>
                    <fieldset
                      className='mc_fieldset gdprRequired mc-field-group'
                      name='interestgroup_field'
                    >
                      <CheckboxLabel
                        className='checkbox subfield'
                        htmlFor='gdpr_39839'
                      >
                        <input
                          type='checkbox'
                          id='gdpr_39839'
                          name='gdpr[39839]'
                          className='av-checkbox gdpr'
                        />
                        <span>
                          {checkbox_text ||
                            "Yes, I'd like to receive the weekly Community Member newsletter via email."}
                        </span>
                      </CheckboxLabel>
                    </fieldset>
                  </div>
                </div>
              )}
              {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
              <div
                style={{ position: 'absolute', left: '-5000px' }}
                aria-hidden='true'
              >
                <Input
                  type='text'
                  name='b_fbd38127cfb37b929203b03d4_48ac693b81'
                  tabIndex='-1'
                />
              </div>
              <div className='form-actions clear'>
                <Button
                  type='submit'
                  value={
                    form_type === 'broker'
                      ? 'YES, I WANT TO TRADE THE SMALLS'
                      : 'SUBMIT'
                  }
                  name='subscribe'
                  id='mc-embedded-subscribe'
                  className='button'
                />
              </div>
              <Row id='mce-responses' className='clear'>
                <Col sm={12}>
                  <div
                    className='response text-sm-left'
                    id='mce-error-response'
                    style={{ display: 'none' }}
                  />
                  <div
                    className='response'
                    id='mce-success-response'
                    style={{ display: 'none' }}
                  />
                </Col>
              </Row>
            </div>
          </form>
        </MailchimpWrapper>
      </div>
    </BlockStyles>
  );
}

MailchimpForm.propTypes = propTypes;
MailchimpForm.defaultProps = defaultProps;

export default MailchimpForm;
