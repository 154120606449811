import React from 'react';
import styled, { css } from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { NavItem } from 'reactstrap';
import linkResolver from '../../../utils/linkResolver';

export const StyledNavItem = styled(NavItem)`
  display: flex;
  margin: 1.5rem auto 0 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: fit-content;
  color: ${prop('theme.colors.black')};

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin: 0;
  }

  @media (max-width: 1100px) {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  @media (min-width: ${prop('theme.breakpoints.xl')}) {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
  }

  &:last-child {
    @media (min-width: ${prop('theme.breakpoints.md')}) {
      padding-right: 0;
    }
  }
`;

const ButtonLink = styled(Link)`
  &&& {
    border-radius: 2rem;
    border: 1px solid ${prop('theme.colors.redPrimary')};
    background-color: ${prop('theme.colors.white')};
    color: ${prop('theme.colors.redPrimary')};
    font-size: 0.875rem;
    font-weight: ${prop('theme.fontWeights.medium')};
    letter-spacing: 0.08em;
    line-height: 1;
    padding: 12px 20px;
    text-align: center;
    text-transform: uppercase;
    width: max-content;
    white-space: nowrap;

    @media (max-width: ${prop('theme.breakpoints.md')}) {
      margin: 0 auto;
    }

    &:hover {
      background-color: ${prop('theme.colors.redPrimary')};
      color: ${prop('theme.colors.white')};
      text-decoration: none;
    }
  }
`;

export const LinkStyles = css`
  &&& {
    color: ${prop('theme.colors.black')};
    cursor: pointer;
    font-size: 1rem;
    line-height: 1;
    padding: 0 0 0.2rem 0;
    text-align: center;
    white-space: nowrap;

    @media (min-width: ${prop('theme.breakpoints.lg')}) {
      padding: 0.5rem 0 0.3rem 0;
    }

    &.active,
    &.active-news-link,
    &:hover {
      color: ${prop('theme.colors.redPrimary')};
      font-weight: ${prop('theme.fontWeights.medium')};
      line-height: 1;
      text-decoration: none;
    }

    &::before {
      display: block;
      content: attr(title);
      font-weight: ${prop('theme.fontWeights.medium')};
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }
`;

const AnchorLink = styled(Link)`
  ${LinkStyles}
`;

const propTypes = {
  data: PropTypes.object
};

const defaultProps = {
  data: {}
};

function MainMenuItem({ data }) {
  const { primary } = data;
  const { menu_link, link_text, link_style, relative_link } = primary;
  const { url, uid, link_type } = menu_link;

  const isDocumentLink = link_type === 'Document';
  const isButton = link_style === 'Button';

  const urlLink = isDocumentLink ? uid : url;
  const LinkComponent = isButton ? ButtonLink : AnchorLink;
  const linkProps = isDocumentLink
    ? {
        to: linkResolver(menu_link),
        activeClassName: 'active'
      }
    : {
        href: relative_link ? relative_link : urlLink
      };

  if (!isDocumentLink && !isButton) {
    linkProps.as = 'a';
    linkProps.className = 'nav-link';
  }

  return (
    <StyledNavItem className={isButton ? 'button' : ''} key={link_text}>
      <LinkComponent title={link_text} {...linkProps}>
        {link_text}
      </LinkComponent>
    </StyledNavItem>
  );
}

MainMenuItem.propTypes = propTypes;
MainMenuItem.defaultProps = defaultProps;

export default MainMenuItem;
