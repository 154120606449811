import { useEffect } from 'react';
import io from 'socket.io-client';
import reduce from 'lodash-es/reduce';
import groupBy from 'lodash-es/groupBy';

export const SOCKET_IO_URL =
  process.env.GATSBY_DX_FEED_PROXY_URL ||
  process.env.DX_FEED_PROXY_URL ||
  false;

function useSocket(room, messageHandler) {
  useEffect(() => {
    // Don't call to API if still waiting for IPF file API data
    if (!room) return;
    const socket = io(`${SOCKET_IO_URL}`);

    socket.on('connect', () => {
      socket.emit('join-room', room);
    });

    socket.on('event', (message) => {
      const data = groupBy(message, (item) => item.symbol);

      for (let key in data) {
        data[key] = reduce(
          data[key].map((e) => {
            return { [e.event]: e.data };
          }),
          (result, value) => {
            for (let l in value) {
              result = Object.assign(result, { [l]: value[l] });
            }
            return result;
          },
          {}
        );
      }

      messageHandler && messageHandler(data);
    });

    return () => {
      socket.emit('leave-room', room);
    };
  }, [room]);
}

export default useSocket;
