import groupBy from 'lodash-es/groupBy';
import orderBy from 'lodash-es/orderBy';
import range from 'lodash-es/range';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import downArrow from '../../images/arrows/down-arrow.svg';
import upArrow from '../../images/arrows/red-up-arrow.svg';
import documentIcon from '../../images/document.svg';
import { NOTIFICATION_GROUPS } from '../../utils/constants';
import dayjs from '../../utils/dayjs';
import BlockStyles from '../BlockStyles';
import StyledTable from '../TableBlock/styled/StyledTable';
import StyledTd from '../TableBlock/styled/StyledTd';
import StyledTh from '../TableBlock/styled/StyledTh';
import { StyledThead } from '../TableBlock/TableHeader';

const Section = styled.div`
  margin-bottom: 24px;

  &&& {
    h2 {
      margin-bottom: 24px;
    }
  }

  &:first-child {
    margin-top: 0;

    @media (max-width: ${prop('theme.breakpoints.md')}) {
      margin-top: 5rem;
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-bottom: 16px;
`;

const StyledCollapse = styled(Collapse)`
  padding-bottom: 16px;
`;

const Hr = styled.hr`
  border: 1px solid ${prop('theme.colors.borderGrey')};
  margin-top: 0;
`;

const StyledTr = styled.tr`
  td {
    > * {
      text-align: left;
    }

    div {
      width: 7rem;
    }

    img {
      margin-right: 1rem;
    }
  }

  th:first-child {
    width: 11rem;
  }
`;

const propTypes = {
  marketNotices: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.shape({
        _linkType: PropTypes.string,
        name: PropTypes.string,
        size: PropTypes.number,
        url: PropTypes.string
      }),
      link: PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
        target: PropTypes.string
      }),
      notice_date: PropTypes.string,
      notice_title: PropTypes.string,
      type: PropTypes.string
    })
  )
};

const defaultProps = {};

function MarketNotices({ marketNotices }) {
  const years = range(
    2020,
    dayjs()
      .add(1, 'year')
      .year()
  );
  const yearMap = years.reduce(
    (previous, current) => ({
      ...previous,
      [current]: false
    }),
    {}
  );
  const [expandedSections, setExpandedSections] = useState({
    'Market Notices': yearMap,
    'Exchange Updates': yearMap,
    'Rule Filings': yearMap
  });
  const noticesByCategory = groupBy(marketNotices, 'type');

  function getNoticeLink(notice) {
    if (notice.link?.url) {
      return (
        <>
          <img src={documentIcon} width='15' height='20' />
          <a href={notice.link.url} target='_blank'>
            {notice.notice_title}
          </a>
        </>
      );
    } else if (notice.file) {
      return (
        <>
          <img src={documentIcon} width='15' height='20' />
          <a href={notice.file.url} target='_blank'>
            {notice.notice_title}
          </a>
        </>
      );
    } else {
      return <span>{notice.notice_title}</span>;
    }
  }

  return (
    <BlockStyles template='page'>
      <div className='block'>
        {NOTIFICATION_GROUPS.map((groupName, groupIndex) => {
          const noticesByYear = years.reduce((previous, current) => {
            const notices = (
              noticesByCategory[groupName] || []
            ).filter((notice) => notice.notice_date.startsWith(current));
            return {
              ...previous,
              [current]: orderBy(notices, 'notice_date', 'desc')
            };
          }, {});

          return (
            <Section key={`notification_section_${groupIndex}`}>
              <h2
                id={groupName.replace(' ', '-').toLowerCase()}
                className='anchorOffset'
              >
                {groupName}
              </h2>
              {Object.keys(noticesByYear)
                .reverse()
                .map((year) => (
                  <React.Fragment key={`${groupName}_${year}`}>
                    <Title
                      onClick={() =>
                        setExpandedSections((prev) => ({
                          ...prev,
                          [groupName]: {
                            ...prev[groupName],
                            [year]: !prev[groupName][year]
                          }
                        }))
                      }
                    >
                      <div>{year}</div>
                      <img
                        src={
                          expandedSections[groupName][year]
                            ? upArrow
                            : downArrow
                        }
                        className={
                          expandedSections[groupName][year]
                            ? 'expanded-icon'
                            : 'collapsed-icon'
                        }
                      />
                    </Title>
                    <StyledCollapse isOpen={expandedSections[groupName][year]}>
                      {noticesByYear[year].length > 0 ? (
                        <StyledTable>
                          <StyledThead>
                            <StyledTr>
                              <StyledTh>Date</StyledTh>
                              <StyledTh>Description</StyledTh>
                            </StyledTr>
                          </StyledThead>
                          <tbody>
                            {noticesByYear[year].map((notice, noticeIndex) => (
                              <StyledTr
                                key={`${groupName}_table_row_${noticeIndex}`}
                              >
                                <StyledTd>
                                  <div>
                                    {dayjs(
                                      notice.notice_date,
                                      'YYYY-MM-DD'
                                    ).format('MMM DD YYYY')}
                                  </div>
                                </StyledTd>
                                <StyledTd>{getNoticeLink(notice)}</StyledTd>
                              </StyledTr>
                            ))}
                          </tbody>
                        </StyledTable>
                      ) : (
                        <p>There are no {groupName} posted at this time.</p>
                      )}
                    </StyledCollapse>
                    <Hr />
                  </React.Fragment>
                ))}
            </Section>
          );
        })}
      </div>
    </BlockStyles>
  );
}

MarketNotices.propTypes = propTypes;
MarketNotices.defaultProps = defaultProps;

export default MarketNotices;
