import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { prop, ifProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import get from 'lodash-es/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import linkResolver from '../../utils/linkResolver';
import MenuLink from '../SideRailsMenu/styled/MenuLink';

const PanelContainer = styled(MenuLink)`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.625rem;
  border-bottom: 1px solid ${prop('theme.colors.borderGrey')};

  &:first-child {
    border-top: 1px solid ${prop('theme.colors.borderGrey')};
  }

  ${ifProp(
    { $isProductPage: true },
    css`
      padding: 0.75rem;

      &:hover {
        color: ${prop('theme.colors.black')};
      }
    `,
    css`
      margin-left: 1.5rem;
      padding: 0.75rem 3.75rem 0.75rem 0;

      &:last-child {
        border: none;
      }

      @media (max-width: ${prop('theme.breakpoints.lg')}) {
        padding: 1rem 3.75rem 1.5rem 0;
      }
    `
  )}
`;

const PanelRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    line-height: 1.5rem;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
`;

const PanelName = styled.h4`
  &&& {
    font-size: 0.75rem;
    letter-spacing: 0;
    margin-bottom: 0.25rem;
    padding: 0;
    line-height: 1;
    text-transform: unset;

    @media (max-width: ${prop('theme.breakpoints.lg')}) {
      font-size: 1rem;
    }
  }
`;

const PanelSubName = styled.span`
  font-size: 0.875rem;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  margin: 0;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
`;

const RightAlignedRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    align-items: center;
    margin-top: 0.5rem;
  }
`;

const PriceContent = styled.p`
  &&& {
    font-size: 1.375rem;
    font-weight: ${prop('theme.fontWeights.regular')};
    letter-spacing: 0;
    margin-bottom: 0.125rem;
  }
`;

const ChangeContent = styled.p`
  &&& {
    font-size: 0.75rem;
    letter-spacing: 0;
    line-height: normal;
    margin: 0;
  }
`;

const StyledIndicator = styled(FontAwesomeIcon)`
  &&& {
    margin-right: 0.125rem;
    width: 1rem;
  }
`;

const propTypes = {
  data: PropTypes.object,
  prismicSymbol: PropTypes.string,
  prismicTitle: PropTypes.string,
  isProductPage: PropTypes.bool
};

const defaultProps = {
  data: {},
  prismicSymbol: '',
  prismicTitle: '',
  isProductPage: false
};

function Panel({
  data: { Trade, Profile, Summary },
  prismicTitle,
  prismicSymbol,
  isProductPage
}) {
  const eventSymbol = get(Trade, 'eventSymbol', '');
  let change;
  let changePercent;

  // Added additional checks in here b/c dxfeed often returns 'NaN' when there
  // is no data rather than undefined or null
  const dayVolume = !isNaN(get(Trade, 'dayVolume', 0))
    ? get(Trade, 'dayVolume', 0)
    : 0;
  const currentPrice = !isNaN(get(Trade, 'price', 0))
    ? get(Trade, 'price', 0)
    : 0;
  const dayOpenPrice = !isNaN(get(Summary, 'dayOpenPrice'))
    ? get(Summary, 'dayOpenPrice')
    : 0;
  const prevPrice = !isNaN(get(Summary, 'prevDayClosePrice'))
    ? get(Summary, 'prevDayClosePrice')
    : dayOpenPrice;
  const url = Profile && Profile.meta && linkResolver(Profile.meta);
  const notTrading =
    dayVolume === 0 && eventSymbol[0] === '/' && currentPrice === 0;

  // When there hasn't been any activity on a contract, like when the back month just becomes available.
  if (notTrading) {
    change = 0;
    changePercent = 0;
  } else {
    change = currentPrice - prevPrice;
    changePercent = isFinite(change / prevPrice)
      ? (change / prevPrice) * 100
      : 0;
  }

  return (
    <>
      <PanelContainer $isProductPage={isProductPage} to={url} type='stockCard'>
        <PanelRow>
          <PanelName>{prismicTitle}</PanelName>
        </PanelRow>
        <PanelRow>
          <PanelSubName>{prismicSymbol}</PanelSubName>
          {get(Trade, 'price') && (
            <PriceContent>
              {Trade.price !== 'NaN'
                ? currentPrice.toFixed(2)
                : prevPrice.toFixed(2)}
            </PriceContent>
          )}
        </PanelRow>
        <RightAlignedRow>
          <ChangeContent>
            {change !== 0 && (
              <Fragment>
                {change > 0 && (
                  <StyledIndicator
                    icon={faSortUp}
                    style={{ color: 'green' }}
                    transform='down-8 grow-18'
                  />
                )}
                {change < 0 && (
                  <StyledIndicator
                    icon={faSortDown}
                    style={{ color: 'red' }}
                    transform='up-8 grow-18'
                  />
                )}
              </Fragment>
            )}
            {change.toFixed(2).toString()} ({changePercent.toFixed(2)}%)
          </ChangeContent>
        </RightAlignedRow>
      </PanelContainer>
    </>
  );
}

Panel.propTypes = propTypes;
Panel.defaultProps = defaultProps;

export default Panel;
