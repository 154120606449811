import React from 'react';
import PropTypes from 'prop-types';

import MailchimpForm from '.';

const propTypes = { data: PropTypes.object, template: PropTypes.string };

const defaultProps = { data: {}, template: '' };

function NewsletterLiteForm({ data, template }) {
  const defaultMailchimpPrimary = {
    mailchimp_form_id:
      process.env.GATSBY_NEWSLETTER_LITE_FORM_ID ||
      process.env.NEWSLETTER_LITE_FORM_ID ||
      '',
    form_type: 'emailWithConfirmationCheckbox',
    checkbox_text:
      "Yes, I'd like to receive the weekly Newsletter-Lite via email."
  };

  const defaultAdditionalParameters = [
    {
      additional_parameter_name: 'NTIER',
      additional_parameter_value: 'newsletter-lite'
    },
    {
      additional_parameter_name: 'SOURCE',
      additional_parameter_value: 'SMFE'
    },
    {
      additional_parameter_name: 'LEAD_SRCE',
      additional_parameter_value: data.primary.lead_source || 'MainSite'
    }
  ];

  return (
    <MailchimpForm
      primary={{ ...defaultMailchimpPrimary, ...data.primary }}
      additionalParameters={defaultAdditionalParameters}
      template={template}
    />
  );
}

NewsletterLiteForm.propTypes = propTypes;
NewsletterLiteForm.defaultProps = defaultProps;

export default NewsletterLiteForm;
