import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import SEO from '../components/seo';
import Layout from '../components/layout';
import PageContainer from '../components/layout-components/PageContainer';
import PageBody from '../components/PageBody';
import PriceFeeds from '../components/PriceFeeds';
import MainCol from '../components/PageBody/MainCol';
import HeroCarousel from '../components/HeroCarousel';
import SideRailsMenu from '../components/SideRailsMenu';
import ColumnWrapper from '../components/PageBody/styled/ColumnWrapper';
import { GatsbyIpfFileProvider } from '../context/GatsbyIpfFileContext';

const propTypes = {
  data: PropTypes.object
};

const defaultProps = {
  data: null
};

function Homepage({ data: queryResult }) {
  const pageData = queryResult.prismicHomepage.data;

  if (!pageData) {
    return null;
  }

  const {
    seo_title,
    seo_meta_description,
    seo_image,
    page_title,
    body,
    header_banner
  } = pageData;
  const pageTitle = page_title ? page_title.text : '';

  return (
    <Layout>
      <GatsbyIpfFileProvider>
        <HeroCarousel headerBanner={header_banner} />
        <PageContainer>
          <SideRailsMenu title={pageTitle} data={body} />
          <MainCol $page={pageTitle}>
            <ColumnWrapper>
              {body && <PageBody body={body} template={'homepage'} />}
            </ColumnWrapper>
          </MainCol>
          <PriceFeeds title={pageTitle} />
        </PageContainer>
      </GatsbyIpfFileProvider>
    </Layout>
  );
}

export const Head = ({ data: queryResult }) => {
  const pageData = queryResult.prismicHomepage.data;

  if (!pageData) {
    return null;
  }

  const { seo_title, seo_meta_description, seo_image } = pageData;

  return (
    <SEO
      title={seo_title}
      description={seo_meta_description}
      image={seo_image}
    />
  );
};

export const homepageQuery = graphql`
  query homeQuery($id: String) {
    prismicHomepage(id: { eq: $id }) {
      _previewable
      data {
        seo_title
        seo_meta_description
        seo_image {
          url
        }
        header_banner {
          image {
            alt
            url
            dimensions {
              height
              width
            }
            thumbnails {
              mobile {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
          }
          button_link {
            url
            target
            type
            uid
            link_type
            tags
          }
          button_text
          title {
            richText
            text
          }
          title_line_2 {
            richText
          }
          title_font_size
          sub_title {
            richText
            text
          }
          sub_title_font_size
          copy_position
        }
        page_title {
          richText
          text
        }
        body {
          ... on PrismicHomepageDataBodyEmbedVideo {
            slice_type
            primary {
              embed_video {
                html
                embed_url
                title
                provider_name
              }
            }
          }
          ... on PrismicHomepageDataBodyArticlesBlock {
            slice_type
            primary {
              header {
                richText
                text
              }
              featured_article {
                type
                uid
                url
                document {
                  ...NewsInfo
                }
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
              view_more_link {
                url
              }
            }
          }
          ... on PrismicHomepageDataBodyCardGroup {
            slice_type
            primary {
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              feature_card
              feature_card_color
              card_header {
                richText
                text
              }
              card_title {
                richText
                text
              }
              sub_card_title_content {
                richText
                text
              }
              main_card_content {
                richText
                text
              }
              sub_main_card_content {
                richText
                text
              }
              cta {
                richText
                text
              }
              cta_link {
                url
                target
                type
                uid
                link_type
                tags
              }
              card_content_list {
                richText
                text
              }
              card_content_list_type
              card_footer {
                richText
                text
              }
            }
          }
          ... on PrismicHomepageDataBodyTextBlock {
            slice_type
            primary {
              sidenav_only
              text_block_header {
                richText
                text
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              text_block_content {
                richText
                text
                html
              }
            }
          }
          ... on PrismicHomepageDataBodyTwoColumns {
            slice_type
            slice_label
            items {
              text_block {
                richText
                text
              }
              title {
                richText
                text
              }
              image {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
              image_display_style
            }
          }
          ... on PrismicHomepageDataBodyTwoColumnsImage {
            slice_type
            slice_label
            primary {
              block_cta_text
              cta_position
              block_cta {
                link_type
                target
                url
                type
                uid
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              additional_text {
                richText
                text
              }
              image {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
              text_block {
                richText
                text
              }
              title {
                richText
                text
              }
              button_link_text {
                richText
                text
              }
              image_margin
            }
          }
          ... on PrismicHomepageDataBodyCtaBlock {
            ...HomeCtaBlock
          }
          ... on PrismicHomepageDataBodyDisclaimerText {
            slice_type
            slice_label
            primary {
              title {
                richText
                text
              }
              text_content {
                richText
                text
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
          }
        }
      }
    }
  }
`;

Homepage.propTypes = propTypes;
Homepage.defaultProps = defaultProps;

export default withPrismicPreview(Homepage);
