import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';

const StyledTd = styled.td`
  empty-cells: show;
  padding: 0.5rem;
  display: table-cell;
  text-align: left;

  ${ifProp(
    { $noRightBorder: true },
    css`
      border: none;
    `,
    css`
      border-right: 1px solid ${prop('theme.colors.borderGrey')};
    `
  )}

  &:first-child {
    border-left: 1px solid ${prop('theme.colors.borderGrey')};
  }

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    padding: 0.875rem 2rem;
    vertical-align: top;

    ${ifProp(
      { $tableType: 'full-borders' },
      css`
        border-bottom: 1px solid ${prop('theme.colors.borderGrey')};
      `
    )}
  }

  ${ifProp(
    { withtooltip: 'true' },
    css`
      padding-top: 0;
    `
  )}

  > * {
    font-size: 0.75rem;
    line-height: inherit;

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      font-size: 0.875rem;
    }

    &:last-child {
      ${ifProp(
        { withtooltip: 'true' },
        css`
          @media (max-width: ${prop('theme.breakpoints.md')}) {
            padding-top: 0.625rem;
          }
        `
      )}
    }
  }

  h4 {
    font-size: 0.875rem;
    line-height: inherit;
    margin: 0;

    @media (max-width: ${prop('theme.breakpoints.md')}) {
      font-weight: ${prop('theme.fontWeights.regular')};
    }

    ${ifProp(
      { withtooltip: 'true' },
      css`
        display: inline-block;
        padding-top: 0.625rem;
      `
    )}
  }

  a {
    font-weight: ${prop('theme.fontWeights.medium')};
  }

  .block-img {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p {
    strong {
      font-weight: ${prop('theme.fontWeights.semiBold')};
    }
  }
`;

export default StyledTd;
