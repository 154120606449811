import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  label {
    width: max-content;
    margin-bottom: 0;
    margin-right: 2.25rem;
  }
`;

// Custom checkbox code courtesy of https://www.w3schools.com/howto/howto_css_custom_checkbox.asp
const CustomCheckboxContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 2.5rem;
  cursor: pointer;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: ${prop('theme.colors.backgroundGreyCommunity')};
  }

  /* When the checkbox is checked, add a red background */
  input:checked ~ .checkmark {
    background-color: ${prop('theme.colors.redPrimary')};
    border-color: ${prop('theme.colors.borderRed')};
  }

  input:disabled ~ .checkmark {
    background-color: ${prop('theme.colors.inputGrey')};
    border-color: ${prop('theme.colors.borderGrey')};
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  border: 1px solid ${prop('theme.colors.borderGrey')};
  border-radius: 0.25rem;
  background-color: ${prop('theme.colors.white')};

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

const propTypes = {};

const defaultProps = {};

const Checkbox = forwardRef((props, ref) => {
  return (
    <CheckboxContainer>
      <CustomCheckboxContainer>
        {props.label}
        <input type='checkbox' name='consent' {...props} ref={ref} />
        <Checkmark className='checkmark' />
      </CustomCheckboxContainer>
    </CheckboxContainer>
  );
});

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
