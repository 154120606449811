import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import sortBy from 'lodash-es/sortBy';
import keyBy from 'lodash-es/keyBy';
import { Spinner } from 'reactstrap';
import dayjs from 'dayjs';

import Spacer from '../Spacer';
import SpinnerContainer from '../SpinnerContainer';
import Panel from './Panel';
import CarouselPanels from './CarouselPanels';
import useSocket from '../../hooks/useSocket';
import GatsbyIpfFileContext from '../../context/GatsbyIpfFileContext';
import {
  formatTime,
  findLastTradeTime,
  trimFuturesSymbols
} from '../../utils/functions';
import { DATA_SOURCE_INDEX } from '../../utils/constants';

export const FootNoteText = styled.h4`
  &&& {
    font-size: 0.75rem;
    letter-spacing: 0;
    margin: 0.5rem 0 1.2rem;
    padding: 0 1.5rem;
    line-height: 1;
    text-transform: unset;
  }
`;

const PanelContainer = styled.div`
  max-height: 65vh;
  overflow: auto;
`;

const propTypes = {
  isMobile: PropTypes.bool,
  symbolObject: PropTypes.object
};

const defaultProps = {
  isMobile: false,
  symbolObject: {}
};

function Feeds({ isMobile, symbolObject }) {
  const [loading, setLoading] = useState(true);
  const [feedData, setFeedData] = useState({});
  const productData = useContext(GatsbyIpfFileContext);

  const indexSymbols = [];
  const futuresSymbols = [];
  let symbolsToLoad = [];

  Object.keys(symbolObject)
    // This is just in case Prismic Data Source Settings is set to Index for a product that has no index.
    .filter((feed) => feed !== 'null')
    .forEach((key) => {
      if (symbolObject[key].data_source === DATA_SOURCE_INDEX) {
        indexSymbols.push(key);
      } else {
        futuresSymbols.push(symbolObject[key].symbol);
      }
    });

  if (futuresSymbols.length > 0) {
    symbolsToLoad = Object.values(productData).map((value) =>
      dayjs(value.frontMonthLastTradeDate, 'YYYY-MM-DD').isBefore(
        dayjs()
          .tz()
          .startOf('day')
      )
        ? value.backMonthSymbol
        : value.frontMonthSymbol
    );
    if (indexSymbols.length > 0) {
      symbolsToLoad.push(...indexSymbols);
    }
  } else {
    symbolsToLoad = Object.keys(symbolObject);
  }

  const room = symbolsToLoad
    ? `caxy/rest/eventSource.json?events=Summary,Profile,Trade&symbols=${symbolsToLoad}&lastEvent=1`
    : null;

  useSocket(room, (message) => {
    const tempData = {};

    symbolsToLoad.forEach((symbol) => {
      // Just in case nothing returns from dxfeed, return early so that the rest of the price cards can render.
      if (!message[symbol].Profile) return;

      tempData[symbol] = message[symbol];

      // If displaying futures data
      if (symbol.startsWith('/')) {
        const trimSymbol = trimFuturesSymbols(symbol);

        Object.assign(tempData[symbol].Profile, {
          sortOrder: symbolObject[trimSymbol]
            ? symbolObject[trimSymbol].sort_order
            : null,
          meta: symbolObject[trimSymbol] ? symbolObject[trimSymbol].meta : null,
          dataSource: symbolObject[trimSymbol]
            ? symbolObject[trimSymbol].data_source
            : null
        });
      }
      // If displaying index data
      else {
        Object.assign(tempData[symbol].Profile, {
          sortOrder: symbolObject[symbol]
            ? symbolObject[symbol].sort_order
            : null,
          meta: symbolObject[symbol] ? symbolObject[symbol].meta : null,
          dataSource: symbolObject[symbol]
            ? symbolObject[symbol].data_source
            : null
        });
      }
    });

    // New products become available the Friday before they start trading, but the website still points to index data.
    // The IPF file has them, but the website doesn't, which causes the website to not render.
    for (let entry in tempData) {
      if (!tempData[entry].Profile.meta) {
        delete tempData[entry];
      }
    }

    setFeedData(tempData);
    setLoading(false);
  });

  const sortedData = keyBy(
    sortBy(Object.values(feedData), [
      'Profile.prismicSymbol',
      'Profile.sortOrder'
    ]),
    'Profile.eventSymbol'
  );

  const lastTradeTime = findLastTradeTime(Object.values(sortedData)) || null;

  if (loading) {
    return (
      <>
        <Spacer size={16} />
        <SpinnerContainer height='100'>
          <Spinner size='lg' />
        </SpinnerContainer>
      </>
    );
  }

  if (isMobile) {
    return <CarouselPanels data={sortedData} symbolObject={symbolObject} />;
  } else {
    return (
      <>
        <FootNoteText>
          {lastTradeTime
            ? `${formatTime(lastTradeTime)}`
            : 'No trade data available right now'}
        </FootNoteText>
        <PanelContainer>
          {Object.keys(sortedData)
            .filter((feed) => feed !== 'undefined')
            .map((feed_key) => {
              const trimSymbol = trimFuturesSymbols(feed_key);

              return (
                <React.Fragment key={feed_key}>
                  <Panel
                    loadingData={loading}
                    key={`panel_${feed_key}`}
                    data={sortedData[feed_key]}
                    prismicSymbol={
                      symbolObject[trimSymbol]
                        ? symbolObject[trimSymbol].symbol
                        : sortedData[feed_key].Profile.eventSymbol
                    }
                    prismicTitle={
                      symbolObject[trimSymbol]
                        ? symbolObject[trimSymbol].title
                        : sortedData[feed_key].Profile.description
                    }
                  />
                </React.Fragment>
              );
            })}
        </PanelContainer>
      </>
    );
  }
}

Feeds.propTypes = propTypes;
Feeds.defaultProps = defaultProps;

export default Feeds;
