import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';

import greenCheckmark from '../../../../images/icons/green-checkmark-in-circle.svg';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 0 0.3rem;

  img {
    margin-left: 0.5rem;
  }
`;

const propTypes = {
  title: PropTypes.object,
  isValid: PropTypes.bool
};

const defaultProps = {
  title: {},
  isValid: false
};

function FormTitle({ title, isValid }) {
  if (!title.text) {
    return null;
  }

  return (
    <TitleContainer>
      {title.text && <PrismicRichText field={title.richText} />}
      {isValid && <img src={greenCheckmark} height={17} width={17} />}
    </TitleContainer>
  );
}

FormTitle.propTypes = propTypes;
FormTitle.defaultProps = defaultProps;

export default FormTitle;
