import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import PageBody from '../PageBody';
import RichTextWithDictionary from '../RichTextWithDictionary';
import MonthToggle from '../Widgets/SharedComponents/MonthToggle';
import VideoPlayer from '../EmbeddedVideoPlayer/components/VideoPlayer';
import hyperLinkHelper from '../../utils/hyperLinkHelper';
import MonthContext from '../../context/MonthContext';
import {
  pageModes,
  BLOCK_STYLES_SIDE_PADDING_ONLY
} from '../../utils/constants';
import BlockStyles from '../BlockStyles';
import { capitalizeFirstLetter } from '../../utils/functions';

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const TitleWrapper = styled.div`
  ${ifProp(
    '$withVideo',
    css`
      flex: 1 0 60%;
    `
  )};

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    padding-right: 2rem;
  }

  p {
    line-height: inherit;
  }
`;

const Title = styled.h2`
  font-size: 1.875rem;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  margin-bottom: 0.5rem;
  margin-top: 1.625rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Message = styled.div`
  font-style: italic;
`;

const VideoContainer = styled.div`
  flex: 1 0 40%;
`;

const VideoWrapper = styled.div`
  @media (min-width: ${prop('theme.breakpoints.xxs')}) {
    min-height: 185px;
    min-width: 336px;
  }
`;

const propTypes = {
  productName: PropTypes.string,
  futures_symbol: PropTypes.string,
  pageBodyMapping: PropTypes.object,
  embeddedVideo: PropTypes.object,
  displayTitle: PropTypes.bool,
  overviewDescription: PropTypes.object,
  template: PropTypes.string,
  pageTitle: PropTypes.object,
  pageBody: PropTypes.array
};

const defaultProps = {
  productName: '',
  futures_symbol: '',
  pageBodyMapping: {},
  embeddedVideo: {},
  displayTitle: true,
  overviewDescription: {},
  template: '',
  pageTitle: {},
  pageBody: null
};

function ProductBody({
  productName,
  futures_symbol,
  pageBodyMapping,
  embeddedVideo,
  displayTitle,
  overviewDescription,
  template,
  pageTitle,
  pageBody
}) {
  const pageMode = useSelector(
    (state) => state.pageModes && state.pageModes[futures_symbol]
  );

  const {
    selectedMonth,
    monthOptions,
    todayIsAfterFrontMonthLastTradeDate
  } = useContext(MonthContext);

  const body = pageBody || pageBodyMapping[pageMode];
  const overviewVideo =
    pageMode === pageModes.OVERVIEW && embeddedVideo && embeddedVideo.html;

  return (
    <>
      {displayTitle && (
        <BlockStyles data={BLOCK_STYLES_SIDE_PADDING_ONLY} template={template}>
          <TitleContainer>
            <TitleWrapper $withVideo={overviewVideo}>
              {pageTitle && pageTitle.text ? (
                <Title>{pageTitle.text}</Title>
              ) : (
                <Title>{`${futures_symbol} ${capitalizeFirstLetter(
                  pageMode
                )}`}</Title>
              )}
              {pageMode === pageModes.OVERVIEW &&
                overviewDescription &&
                overviewDescription.text && (
                  <RichTextWithDictionary
                    render={overviewDescription.richText}
                    serializeHyperlink={hyperLinkHelper}
                  />
                )}
            </TitleWrapper>
            {overviewVideo && (
              <VideoContainer>
                <VideoWrapper>
                  <VideoPlayer fullWidth video={embeddedVideo} />
                </VideoWrapper>
              </VideoContainer>
            )}
          </TitleContainer>
        </BlockStyles>
      )}
      {body && (
        <>
          {pageMode === pageModes.QUOTE && (
            <BlockStyles template={template}>
              <Row>
                <MonthToggle symbol={futures_symbol} />
                {todayIsAfterFrontMonthLastTradeDate &&
                  selectedMonth.symbol === monthOptions[0].symbol && (
                    <Message>
                      {selectedMonth.symbol.substr(1).split(':')[0]} is no
                      longer trading.
                    </Message>
                  )}
              </Row>
            </BlockStyles>
          )}
          <PageBody
            body={body}
            productSymbol={futures_symbol}
            template={template}
            productName={productName}
          />
        </>
      )}
    </>
  );
}

ProductBody.propTypes = propTypes;
ProductBody.defaultProps = defaultProps;

export default ProductBody;
