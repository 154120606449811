import styled from 'styled-components';
import { prop } from 'styled-tools';

const AddButton = styled.button`
  width: max-content;
  font-weight: ${prop('theme.fontWeights.medium')};
  border-radius: 0.5rem;
  border: none;
  text-transform: uppercase;
  font-size: 0.6875rem;
  height: max-content;
  padding: 0.25rem 1.5rem;
  align-self: flex-end;
  background-color: ${prop('theme.colors.addButtonGrey')};
`;

export default AddButton;
