import React from 'react';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';

import NavItemContainer from './NavItemContainer';
import { isNullValue } from '../../../../utils/functions';
import { INVOICE_STATUS_PAID } from '../../../../utils/constants';

const sideNavQuery = graphql`
  query SideNavQuery {
    prismicPortalSideNav {
      data {
        copyright_text {
          richText
          text
        }
        body {
          ... on PrismicPortalSideNavDataBodyMainNavItem {
            primary {
              link_text
              nav_link {
                type
                uid
              }
              nav_icon {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicPortalSideNavDataBodySubnavItem {
            primary {
              link_text
              nav_link {
                type
                uid
                url
              }
              nav_icon {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
            items {
              subnav_link_text
              subnav_link {
                type
                uid
                url
              }
            }
          }
        }
      }
    }
  }
`;

const propTypes = {
  expandedSection: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  toggleNav: PropTypes.func
};

const defaultProps = {
  expandedSection: false,
  toggleNav: () => {}
};

function NavMenu({ expandedSection, toggleNav }) {
  const { invoices } = useSelector((state) => state.memberInfo || {});
  const noInvoicesPaid =
    isNullValue(invoices) ||
    invoices.every((invoice) => invoice.status !== INVOICE_STATUS_PAID);

  return (
    <StaticQuery
      query={`${sideNavQuery}`}
      render={(data) => {
        const navItems = get(data, 'prismicPortalSideNav.data.body', []);

        return (
          <>
            {navItems.map((item, index) => (
              <div key={index}>
                <NavItemContainer
                  item={item}
                  expandedSection={expandedSection}
                  toggleNav={toggleNav}
                  noInvoicesPaid={noInvoicesPaid}
                />
              </div>
            ))}
          </>
        );
      }}
    />
  );
}

NavMenu.propTypes = propTypes;
NavMenu.defaultProps = defaultProps;

export default NavMenu;
