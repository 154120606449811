import styled, { css } from 'styled-components';
import { ifProp, prop, switchProp } from 'styled-tools';

import ColumnWrapper from '../PageBody/styled/ColumnWrapper';

export const PanelContainer = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    flex-direction: column;
  }
`;

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 ${prop('$width', '50%')};
  max-width: ${prop('$width', '50%')};
  padding-bottom: 48px;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    flex: 1 1 100%;
    max-width: 100%;
    align-items: center;
  }

  &:first-child {
    @media (min-width: ${prop('theme.breakpoints.lg')}) {
      padding-right: 80px;
      padding-left: 96px;
      align-items: flex-end;
    }

    @media (min-width: ${prop('theme.breakpoints.xl')}) {
      padding-left: 260px;
    }
  }

  &:last-child {
    background-color: ${prop('theme.colors.backgroundGreyCommunity')};

    @media (min-width: ${prop('theme.breakpoints.lg')}) {
      padding-right: 48px;
    }

    @media (min-width: ${prop('theme.breakpoints.xl')}) {
      padding-right: 128px;
    }
  }
`;

export const BodyContainer = styled.div`
  max-width: 752px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const PageTitle = styled.div`
  margin-top: 96px;
  margin-bottom: 16px;
  margin-left: 24px;
  h1 {
    font-weight: ${prop('theme.fontWeights.semiBold')};
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    ${ifProp(
      '$sponsored',
      css`
        margin-top: 250px;
      `,
      css`
        margin-top: 32px;
      `
    )}
  }
`;

export const StyledColumnWrapper = styled(ColumnWrapper)`
  position: relative;

  &&& {
    margin-top: 0;

    h2 {
      font-size: 24px;
      margin-bottom: 16px;
    }

    ul {
      padding-left: 27px;
    }
  }
`;

export const Copyright = styled.div`
  &&& {
    p {
      font-size: 0.59375rem;
      line-height: 9px;
      color: ${prop('theme.colors.greyDarker')};
      margin: 0;
    }
  }
`;

export const CurrentStatus = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${prop('theme.fontWeights.medium')};

  ${switchProp('$status', {
    active: css`
      color: ${prop('theme.colors.green')};
    `,
    cancelled: css`
      color: ${prop('theme.colors.redPrimary')};
    `
  })}

  img {
    height: 13px;
    margin-right: 6px;
    width: 13px;
    margin-bottom: 2px;
  }
`;
