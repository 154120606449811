import actions from '../actions';

const errorReducer = (state = {}, action) => {
  if (!action || !action.type) {
    return state;
  }

  switch (action.type) {
    case actions.ADD_API_ERROR:
      return {
        ...state,
        [action.payload.type]: action.payload.message
      };
    case actions.REMOVE_API_ERROR:
      const newState = { ...state };
      delete newState[action.payload];
      return newState;
    default:
      return state;
  }
};

export default errorReducer;
