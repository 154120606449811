import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import BlockStyles from '../BlockStyles';
import { PrismicRichText } from '@prismicio/react';
import RichTextWithDictionary from '../RichTextWithDictionary';

const DisclaimerContainer = styled.div`
  margin: 2rem 0 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h5 {
    color: ${prop('theme.colors.black')};
    text-transform: uppercase;
    font-weight: ${prop('theme.fontWeights.semiBold')};
    margin-bottom: 1rem;
  }

  p {
    font-size: 0.875rem;
  }
`;

const ContentContainer = styled.div`
  width: 66.5%;

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    width: 89.5%;
  }
`;

const propTypes = { primary: PropTypes.object, template: PropTypes.string };

const defaultProps = { primary: {}, template: '' };

function Disclaimer({ primary, template }) {
  const { title, text_content } = primary;

  return (
    <BlockStyles data={primary} template={template}>
      <DisclaimerContainer>
        <ContentContainer>
          {title && title.text && <PrismicRichText field={title.richText} />}
          {text_content && text_content.text && (
            <RichTextWithDictionary render={text_content.richText} />
          )}
        </ContentContainer>
      </DisclaimerContainer>
    </BlockStyles>
  );
}

Disclaimer.propTypes = propTypes;
Disclaimer.defaultProps = defaultProps;

export default Disclaimer;
