import React from 'react';
import get from 'lodash-es/get';
import { graphql, Link, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';

import linkResolver from '../../../utils/linkResolver';
import SideRailsMenu from '../../SideRailsMenu';

const SubNavContainer = styled.div`
  position: absolute;
`;

const SubNav = styled.div`
  display: flex;
  padding: 2.75rem 0;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  border-bottom: 3px solid transparent;
  color: ${prop('theme.colors.black')};
  padding-bottom: 0.625rem;

  @media (max-width: ${prop('theme.breakpoints.xs')}) {
    font-size: 0.875rem;
  }

  @media (max-width: ${prop('theme.breakpoints.xxs')}) {
    font-size: 0.875rem;
  }

  &:not(:last-child) {
    margin-right: 0.875rem;

    @media (min-width: ${prop('theme.breakpoints.xxs')}) {
      margin-right: 1.5rem;
    }

    @media (min-width: ${prop('theme.breakpoints.xs')}) {
      margin-right: 2.5rem;
    }
  }

  &.active,
  &:hover {
    color: ${prop('theme.colors.black')};
    font-weight: ${prop('theme.fontWeights.semiBold')};
  }

  &.active {
    border-bottom: 3px solid ${prop('theme.colors.redPrimary')};
  }

  &:hover {
    text-decoration: none;
  }

  &::before {
    display: block;
    content: attr(title);
    font-weight: ${prop('theme.fontWeights.semiBold')};
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;

const MobileMenu = styled.div`
  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    display: none;
  }
`;

const propTypes = {
  pageUid: PropTypes.string
};

const defaultProps = {
  pageUid: ''
};

function NewsSubnav({ pageUid }) {
  const homepageUid = 'news';

  return (
    <StaticQuery
      query={`${query}`}
      render={(data) => {
        const allArticles = get(data, 'allPrismicNewsItem.edges', []);
        const newsLandingPage = get(
          data,
          'allPrismicNewsLandingPage.edges[0].node'
        );
        const categoryNodes = get(data, 'allPrismicNewsCategory.edges', [])
          .filter((edge) => {
            const articleIndex = allArticles.findIndex((article) => {
              const categoryIndex = article.node.data.post_categories.findIndex(
                (articleCategory) => {
                  return articleCategory.category.uid === edge.node.uid;
                }
              );
              return categoryIndex > -1;
            });
            return articleIndex > -1;
          })
          .map((entry) => ({
            ...entry.node,
            title: entry.node.data.category_title
          }));

        const landingPageItem = {
          ...newsLandingPage,
          title: newsLandingPage.data.news_subnav_title || 'All News'
        };

        const menuItems = [landingPageItem, ...categoryNodes];

        return (
          <SubNavContainer>
            <SubNav>
              {menuItems.map((menuItem) => {
                const { title, type, uid } = menuItem;

                return (
                  <NavLink
                    className={pageUid === uid ? 'active' : ''}
                    key={uid}
                    title={title}
                    to={linkResolver({ type: type, uid: uid })}
                  >
                    {title}
                  </NavLink>
                );
              })}
            </SubNav>
            <MobileMenu>
              <SideRailsMenu
                title='News'
                data={{
                  isNewsListMenu: true,
                  menuItems: menuItems,
                  pageUid: pageUid
                }}
              />
            </MobileMenu>
          </SubNavContainer>
        );
      }}
    />
  );
}

const query = graphql`
  query NewsSubNavQuery {
    allPrismicNewsItem {
      edges {
        node {
          data {
            post_categories {
              category {
                uid
              }
            }
          }
        }
      }
    }
    allPrismicNewsLandingPage {
      edges {
        node {
          uid
          type
          data {
            news_subnav_title
          }
        }
      }
    }
    allPrismicNewsCategory(sort: { fields: data___subnav_order, order: ASC }) {
      edges {
        node {
          type
          uid
          data {
            category_title
          }
        }
      }
    }
  }
`;

NewsSubnav.propTypes = propTypes;
NewsSubnav.defaultProps = defaultProps;

export default NewsSubnav;
