import React, { useEffect, useRef, useState } from 'react';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import SEO from '../components/seo';
import Layout from '../components/layout';
import MainCol from '../components/PageBody/MainCol';
import NewsHero from '../components/NewsComponents/NewsHero';
import NewsSubnav from '../components/NewsComponents/NewsSubnav';
import NewsItemPreview from '../components/NewsComponents/NewsItemPreview';
import TwitterTimeline from '../components/NewsComponents/TwitterTimeline';
import ColumnWrapper from '../components/PageBody/styled/ColumnWrapper';
import PageContainer from '../components/layout-components/PageContainer';
import { NewsPreviewWrapper } from '../components/NewsComponents/styled/SharedComponents';
import NewsList from '../components/NewsComponents/NewsList';

const StyledColumnWrapper = styled(ColumnWrapper)`
  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    margin-top: 5rem;
  }
`;

const NewsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, auto);
    margin-top: 2.5rem;
  }

  @media (min-width: ${prop('theme.breakpoints.xl')}) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);
    margin-top: 7.5rem;
  }
`;

const TwitterWrapper = styled.div`
  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    grid-column: 2;
    grid-row: 3 / 5;
  }

  @media (min-width: ${prop('theme.breakpoints.xl')}) {
    grid-column: 3;
    grid-row: 2 / 4;
  }
`;

const MentionsWrapper = styled.div`
  display: none;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    display: flex;
    grid-row: 4 / 5;
  }

  @media (min-width: ${prop('theme.breakpoints.xl')}) {
    grid-column: 1 / 3;
    grid-row: ${prop('$gridRow')};
  }
`;

const propTypes = {
  data: PropTypes.object
};

const defaultProps = {
  data: null
};

function NewsLandingPage({ data: queryResult }) {
  const twitterRef = useRef();
  const [twitterLoaded, setTwitterLoaded] = useState(false);
  const [twitterTimelineHeight, setTwitterTimelineHeight] = useState(720);
  const pageData = queryResult.prismicNewsLandingPage;
  const allNews = get(queryResult, 'allPrismicNewsItem.edges', []);
  const featuredCategoryItems = get(
    queryResult,
    'featuredCategoryItems.edges',
    []
  );
  const {
    news_hero_list_title,
    news_hero_view_all_link,
    featured_category
  } = pageData.data;

  const featuredCategoryTitle = get(
    featured_category,
    'document.data.category_title',
    'Press'
  );

  if (!pageData) {
    return null;
  }

  useEffect(() => {
    if (twitterRef.current) {
      setTwitterTimelineHeight(twitterRef.current.clientHeight);
    }
  }, [twitterRef]);

  return (
    <Layout>
      <PageContainer>
        <MainCol>
          <StyledColumnWrapper $news>
            <NewsSubnav pageUid={pageData.uid} />
            <NewsHero
              primary={get(allNews[0], 'node.data')}
              newsList={allNews.slice(1, 4)}
              newsListTitle={news_hero_list_title}
              newsListViewAllLink={news_hero_view_all_link}
              page='landing'
              type={get(allNews[0], 'node.type')}
              uid={get(allNews[0], 'node.uid')}
            />
            <NewsContainer>
              {allNews.length > 0 && (
                <>
                  {allNews.slice(4, 9).map((item) => {
                    return (
                      <NewsPreviewWrapper key={item.node.id}>
                        <NewsItemPreview newsItem={item.node} />
                      </NewsPreviewWrapper>
                    );
                  })}
                </>
              )}
              <TwitterWrapper
                $gridRow={allNews.length < 4 ? 2 : 3}
                ref={twitterRef}
              >
                <TwitterTimeline
                  height={twitterTimelineHeight}
                  setTwitterLoaded={setTwitterLoaded}
                  twitterLoaded={twitterLoaded}
                />
              </TwitterWrapper>
              <MentionsWrapper $gridRow={allNews.length < 4 ? 2 : 3}>
                <NewsList
                  title={featuredCategoryTitle}
                  newsList={featuredCategoryItems}
                  showDividers
                  teaserSetting='none'
                  viewAllLink={
                    featured_category.uid
                      ? { ...featured_category }
                      : { type: 'news_category', uid: 'press' }
                  }
                />
              </MentionsWrapper>
            </NewsContainer>
          </StyledColumnWrapper>
        </MainCol>
      </PageContainer>
    </Layout>
  );
}

export const Head = ({ data: queryResult }) => {
  const pageData = queryResult.prismicNewsLandingPage.data;

  if (!pageData) {
    return null;
  }

  const { seo_title, seo_meta_description, seo_image } = pageData;

  return (
    <SEO
      title={seo_title}
      description={seo_meta_description}
      image={seo_image}
    />
  );
};

export const query = graphql`
  query NewsLandingPageQuery($id: String!, $featured_category: String!) {
    prismicNewsLandingPage(id: { eq: $id }) {
      uid
      _previewable
      data {
        seo_title
        seo_meta_description
        seo_image {
          url
        }
        news_subnav_title
        news_hero_list_title
        news_hero_view_all_link {
          uid
          type
        }
        featured_category {
          uid
          type
          document {
            ... on PrismicNewsCategory {
              data {
                category_title
              }
            }
          }
        }
      }
    }
    allPrismicNewsItem(
      filter: {
        data: {
          post_categories: {
            elemMatch: { category: { uid: { ne: $featured_category } } }
          }
        }
      }
      sort: { fields: data___post_date, order: DESC }
    ) {
      edges {
        node {
          type
          uid
          ...NewsInfo
        }
      }
    }
    featuredCategoryItems: allPrismicNewsItem(
      filter: {
        data: {
          post_categories: {
            elemMatch: { category: { uid: { eq: $featured_category } } }
          }
        }
      }
      sort: { fields: data___post_date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          type
          uid
          ...NewsInfo
        }
      }
    }
  }
`;

NewsLandingPage.propTypes = propTypes;
NewsLandingPage.defaultProps = defaultProps;

export default withPrismicPreview(NewsLandingPage);
