import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';

const StyledImg = styled.img`
  border-radius: ${(props) => props.$borderRadius};
  ${(props) =>
    props.$boxShadow
      ? 'box-shadow: -0.0625rem 0.0625rem 0.375rem rgba(0, 0, 0, 0.16)'
      : null};
  ${switchProp('$imgBorder', {
    'Solid black': css`
      border: 2px solid black;
    `,
    'Solid gray': css`
      border: 3px solid gray;
    `
  })}
  margin-bottom: 0.25rem;
`;

function GraphicContainer({ graphicContainer, containerAttributes }) {
  const { border_radius, box_shadow, graphic_border } = containerAttributes;

  return (
    <StyledImg
      alt={graphicContainer.alt}
      src={graphicContainer.url}
      width='100%'
      height='auto'
      $borderRadius={border_radius}
      $boxShadow={box_shadow}
      $imgBorder={graphic_border}
    />
  );
}

GraphicContainer.propTypes = {
  graphicContainer: PropTypes.object.isRequired,
  containerAttributes: PropTypes.object
};
GraphicContainer.defaultProps = {
  graphicContainer: {},
  containerAttributes: {}
};

export default GraphicContainer;
