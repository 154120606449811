// -- The Link Resolver
// This function will be used to generate links to Prismic documents

const standardPathTypes = [
  'products_landing_page',
  'notifications_page',
  'news_landing_page',
  'futures_education_landing_page',
  'registration_landing_page',
  'registration_flow',
  'community_portal_landing_page'
];

module.exports = function linkResolver(doc) {
  const tags = doc.tags || [];

  if (standardPathTypes.includes(doc.type)) {
    return `/${doc.uid}`;
  } else if (doc.type === 'standard_page') {
    const parentTag = tags.find((tag) => tag.startsWith('__parent'));
    if (parentTag) {
      const parentPathSegment = parentTag.split(':')[1];
      return `/${parentPathSegment}/${doc.uid}`;
    }
    return `/${doc.uid}`;
  } else if (doc.type === 'institutions_page') {
    const parentTag = tags.find((tag) => tag.startsWith('__parent'));
    if (parentTag) {
      const parentPathSegment = parentTag.split(':')[1];
      return `/${parentPathSegment}/${doc.uid}`;
    }
    return `/reference/${doc.uid}`;
  } else if (doc.type === 'product') {
    return `/futures-markets/${doc.uid}`;
  } else if (doc.type === 'product_archive') {
    return `/futures-markets/archive/${doc.uid}`;
  } else if (doc.type === 'product_archive_landing_page') {
    return `/futures-markets/archive`;
  } else if (doc.type === 'news_item') {
    return '/news/' + doc.uid;
  } else if (doc.type === 'news_item_list') {
    return doc.pageNumber && doc.pageNumber > 1
      ? `/news/${doc.uid}/${doc.pageNumber}`
      : `/news/${doc.uid}`;
  } else if (doc.type === 'news_category') {
    return doc.pageNumber && doc.pageNumber > 1
      ? `/news/category/${doc.uid}/${doc.pageNumber}`
      : `/news/category/${doc.uid}`;
  } else if (doc.type === 'futures_education_category') {
    return `/futures-education/category/${doc.uid}`;
  } else if (doc.type === 'futures_education_article') {
    return doc.category
      ? `/futures-education/category/${doc.category}/${doc.uid}`
      : `/futures-education/${doc.uid}`;
  } else if (doc.type === 'promo_landing_page') {
    return '/promo/' + doc.uid;
  } else if (doc.type === 'community_portal_page') {
    const parentTag = tags.find((tag) => tag.startsWith('__parent'));
    if (parentTag) {
      const parentPathSegment = parentTag.split(':')[1];
      return `/community-portal/${parentPathSegment}/${doc.uid}`;
    } else if (doc.parentUid) {
      return `/community-portal/${doc.parentUid}/${doc.uid}`;
    } else {
      return `/community-portal/${doc.uid}`;
    }
  } else {
    // Homepage route fallback
    return '/';
  }
};
