import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ifNotProp, ifProp, prop, switchProp } from 'styled-tools';
import 'smoothscroll-anchor-polyfill';
import { graphql, StaticQuery } from 'gatsby';
import styled, { ThemeProvider, css } from 'styled-components';
import { config } from '@fortawesome/fontawesome-svg-core';
import loadable from '@loadable/component';

import { DictionaryProvider } from '../context/DictionaryContext';
import { SiteSettingsProvider } from '../context/SiteSettingsContext';
import CombinedContextProvider from '../context/CombinedContextProvider';
import GlobalStyle from '../styles/GlobalStyle';
import Header from './Header';
import Footer from './Footer';
import theme from '../styles/theme';
import { ScrollProvider } from '../context/ScrollContext';

const CookieConsentAlert = loadable(() => import('./CookieConsentAlert'));

// This addresses an issue where font-awesome icons sometimes render ridiculously large before resizing back to normal
// The css is now imported in gatsby-browser.js
config.autoAddCss = false;

const Banner = styled.div`
  background-color: ${prop('theme.colors.backgroundGreyCommunity')};
  padding: 16px 0;

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    display: none;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    ${ifNotProp(
      { $page: 'Registration' },
      css`
        margin-top: 64px;
      `
    )}
  }
`;

const BannerItems = styled.div`
  display: flex;
  align-items: center;
  max-width: 1504px;
  margin: 0 auto;
`;

const ImageContainer = styled.div`
  width: 260px;
  display: flex;
  justify-content: center;
  div {
    background-color: ${prop('theme.colors.backgroundGrey')};
    padding: 36px;
    border-radius: 100%;

    @media (min-width: ${prop('theme.breakpoints.lg')}) {
      margin-left: 44px;
    }
  }

  @media (max-width: ${prop('theme.breakpoints.xl')}) {
    width: 196px;
  }
`;

const Divider = styled.span`
  color: ${prop('theme.colors.redPrimary')};
  padding: 0 10px;
`;

const BannerHeading = styled.h1`
  color: ${prop('theme.colors.titleGrey')};
  font-family: ${prop('theme.fonts.default')};
  font-weight: ${prop('theme.fontWeights.semiBold')};
  line-height: normal;
  margin-bottom: 0;
  font-size: 2.5rem;
  margin-left: 20px;

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    font-size: 1.5rem;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    font-size: 2.25rem;
    margin-left: 0;
  }

  @media (min-width: ${prop('theme.breakpoints.lg')}) and (max-width: ${prop(
      'theme.breakpoints.xl'
    )}) {
    margin-left: 32px;
  }
`;

const LayoutContainer = styled.div`
  color: ${prop('theme.colors.black')};
  font-family: ${prop('theme.fonts.default')};
  font-style: normal;
  ${switchProp('$layout', {
    'one-column': css`
      margin: 16px auto 0;
      max-width: 1504px; // 1440px (desired width of content) + 64px gutters
    `,
    portal: css`
      margin: 0 auto;
    `
  })}
`;

const MainSection = styled.main`
  min-height: 420px;
  padding: 0;

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    ${ifProp(
      { $layout: 'one-column' },
      css`
        padding-bottom: 32px;
      `
    )}
  }
`;

const navAndFooterQuery = graphql`
  query NavFootQuery {
    allPrismicNavigationMenu {
      edges {
        node {
          data {
            navigation_logo {
              url
              thumbnails {
                tablet {
                  url
                  dimensions {
                    height
                    width
                  }
                }
              }
              dimensions {
                height
                width
              }
              fixed {
                srcSet
              }
            }
            search_icon {
              url
            }
            body {
              ... on PrismicNavigationMenuDataBodyMainMenuItem {
                slice_type
                primary {
                  link_text
                  link_style
                  relative_link
                  special_behavior
                  visibility
                  menu_link {
                    url
                    uid
                    tags
                    type
                    link_type
                    target
                  }
                }
              }
              ... on PrismicNavigationMenuDataBodySubmenuItem {
                slice_type
                primary {
                  nav_text
                  visibility
                }
                items {
                  submenu_link {
                    link_type
                    uid
                    type
                    url
                    tags
                    target
                  }
                  submenu_link_text
                }
              }
            }
          }
        }
      }
    }
    localSearchItems {
      index
      store
    }
    allPrismicFooterComponent {
      edges {
        node {
          data {
            disclaimer {
              text
            }
            disclaimer_body {
              richText
            }
            disclaimer_footer {
              text
            }
            footer_logo {
              alt
              url
              dimensions {
                width
                height
              }
            }
            contact_address {
              text
            }
            copyright {
              text
            }
            information_group {
              item_link {
                url
              }
              item_name {
                text
              }
            }
            client_group {
              item_link {
                url
              }
              item_name {
                text
              }
            }
            body {
              ... on PrismicFooterComponentDataBodyFormSlice {
                slice_type
                primary {
                  form_button_title {
                    text
                  }
                  form_field_name {
                    text
                  }
                  form_subtitle {
                    text
                  }
                  form_title {
                    text
                  }
                }
              }
              ... on PrismicFooterComponentDataBodySocialLinks {
                slice_type
                primary {
                  social_icon_title {
                    richText
                    text
                  }
                }
                items {
                  font_awesome_icon {
                    text
                  }
                  social_link {
                    url
                  }
                  social_icon {
                    alt
                    url
                    dimensions {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

function Layout({
  children,
  layout = 'one-column',
  displaySearchBar = true,
  page,
  headerBanner,
  title,
  symbol = ''
}) {
  // Some products have a registered icon after the symbol, and we want to display that as superscript
  const registeredIndex = symbol.indexOf('®');
  let displaySymbol = symbol;
  let shouldDisplayRegisteredIcon = false;
  if (registeredIndex > -1) {
    displaySymbol = displaySymbol.substring(0, registeredIndex);
    shouldDisplayRegisteredIcon = true;
  }

  return (
    <StaticQuery
      query={`${navAndFooterQuery}`}
      render={(data) => {
        const headDoc = data.allPrismicNavigationMenu.edges[0];
        const footDoc = data.allPrismicFooterComponent.edges[0].node.data;
        const bodyRef = useRef();

        return (
          <ThemeProvider theme={theme}>
            <ScrollProvider bodyRef={bodyRef}>
              <CombinedContextProvider
                contexts={[SiteSettingsProvider, DictionaryProvider]}
              >
                <GlobalStyle />
                <Header
                  headerData={headDoc.node}
                  localSearchItems={data.localSearchItems}
                  displaySearchBar={displaySearchBar}
                />
                {title && (
                  <Banner $page={page}>
                    <BannerItems>
                      <ImageContainer>
                        <div>
                          <img src={headerBanner} height={64} width={64} />
                        </div>
                      </ImageContainer>
                      <BannerHeading>
                        {displaySymbol ? (
                          <>
                            {displaySymbol}
                            {shouldDisplayRegisteredIcon && <sup>®</sup>}
                            <Divider>\</Divider>
                            {title}
                          </>
                        ) : (
                          <>{title}</>
                        )}
                      </BannerHeading>
                    </BannerItems>
                  </Banner>
                )}
                <LayoutContainer $layout={layout} ref={bodyRef}>
                  <MainSection $layout={layout}>{children}</MainSection>
                  <CookieConsentAlert
                    message='This site employs cookies to improve your experience while using it.'
                    link='Learn more about our Cookie Policy'
                  />
                </LayoutContainer>
                {layout !== 'portal' && <Footer data={footDoc} />}
              </CombinedContextProvider>
            </ScrollProvider>
          </ThemeProvider>
        );
      }}
    />
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  layout: PropTypes.string,
  displaySearchBar: PropTypes.bool,
  page: PropTypes.string,
  headerBanner: PropTypes.string,
  title: PropTypes.string,
  symbol: PropTypes.string
};

export default Layout;
