import React, { Fragment } from 'react';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MainMenuItem from '../MainMenuItem';
import SubmenuItem from '../SubmenuItem';
import { NAV_VISIBILITY, NAV_SPECIAL } from '../../../utils/constants';

const propTypes = {
  menus: PropTypes.array
};

const defaultProps = {
  menus: []
};

function LoggedOutMenu({ menus }) {
  const loggedOutItems = menus.filter((menu) => {
    return get(menu, 'primary.visibility') !== NAV_VISIBILITY.SHOW_IF_LOGGED_IN;
  });

  return (
    <>
      {loggedOutItems.map((item, index) => {
        const specialBehavior = get(item, 'primary.special_behavior');

        return (
          <Fragment key={`header_menu_item_${index}`}>
            {specialBehavior === NAV_SPECIAL.SIGN_IN && (
              <MainMenuItem
                data={{
                  primary: {
                    link_text: get(item, 'primary.link_text'),
                    link_style: 'Button',
                    menu_link: {
                      url: '/community-registration',
                      uid: 'community-registration'
                    }
                  }
                }}
              />
            )}
            {item.slice_type === 'main_menu_item' && !specialBehavior && (
              <MainMenuItem data={item} />
            )}
            {item.slice_type === 'submenu_item' && <SubmenuItem data={item} />}
          </Fragment>
        );
      })}
    </>
  );
}

LoggedOutMenu.propTypes = propTypes;
LoggedOutMenu.defaultProps = defaultProps;

export default LoggedOutMenu;
