const errorTypes = {
  IDENTITY: 'IDENTITY',
  IDENTITY_FETCH_FAILED: 'IDENTITY_FETCH_FAILED',
  SUBSCRIBER: 'SUBSCRIBER',
  SPONSORSHIP: 'SPONSORSHIP',
  SPONSORSHIP_FETCH_FAILED: 'SPONSORSHIP_FETCH_FAILED',
  CONTACT_INFO: 'CONTACT_INFO',
  CONTACT_INFO_FETCH_FAILED: 'CONTACT_INFO_FETCH_FAILED',
  ADDRESS: 'ADDRESS',
  ADDRESS_FETCH_FAILED: 'ADDRESS_FETCH_FAILED',
  AGREEMENT: 'AGREEMENT',
  AGREEMENT_FETCH_FAILED: 'AGREEMENT_FETCH_FAILED',
  INVOICE_FETCH_FAILED: 'INVOICE_FETCH_FAILED',
  PAYMENT_SESSION: 'PAYMENT_SESSION',
  IDENTITY_LINK: 'IDENTITY_LINK_FAILED',
  EMAIL_CONFIRMATION: 'EMAIL_CONFIRMATION_FAILED',
  EMAIL_CHANGE: 'EMAIL_CHANGE_FAILED',
  BROKERAGE_ACCOUNT_FETCH: 'BROKERAGE_ACCOUNT_FETCH_FAILED',
  BROKERAGE_ACCOUNT_EDIT: 'BROKERAGE_ACCOUNT_CREATE_UPDATE',
  BROKERAGE_FETCH: 'BROKERAGE_FETCH',
  SUBSCRIPTIONS_FETCH_FAILED: 'SUBSCRIPTIONS_FETCH_FAILED',
  SPONSORSHIP_AFTER_PAYMENT: 'SPONSORSHIP_AFTER_PAYMENT'
};

export const fetchErrors = [
  errorTypes.IDENTITY_FETCH_FAILED,
  errorTypes.SPONSORSHIP_FETCH_FAILED,
  errorTypes.CONTACT_INFO_FETCH_FAILED,
  errorTypes.ADDRESS_FETCH_FAILED,
  errorTypes.AGREEMENT_FETCH_FAILED,
  errorTypes.INVOICE_FETCH_FAILED,
  errorTypes.BROKERAGE_FETCH,
  errorTypes.BROKERAGE_ACCOUNT_FETCH,
  errorTypes.SUBSCRIPTIONS_FETCH_FAILED
];

export default errorTypes;
