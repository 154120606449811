import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { login, isAuthenticated } from '../../utils/auth';
import FullPageSpinnerContainer from '../SessionCheck/FullPageSpinnerContainer';
import Spinner from '../SessionCheck/Spinner';
import { useSelector } from 'react-redux';

const RedirectMessage = styled.div`
  margin-top: 1rem;
  font-size: 1.125rem;
`;

const propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

const defaultProps = {
  location: {},
  children: {}
};

function AuthenticatedWrapper({ location, children }) {
  const params = new URLSearchParams(location.search);
  const sponsorshipCode = params.get('sponsorshipCode');
  const {
    loading: isAuthLoading = false,
    isLoginRequired = false
  } = useSelector((state) => state.auth);
  const isLoadingUserData = useSelector((state) => state.loading);

  if (!isAuthenticated() || isLoginRequired) {
    const sponsorshipCodeParam =
      location.pathname.includes('registration') && sponsorshipCode
        ? `&sponsorshipCode=${sponsorshipCode}`
        : '';
    const loginParams = `callbackRedirectPath=${location.pathname}${sponsorshipCodeParam}`;

    login(loginParams);
    return (
      <FullPageSpinnerContainer>
        <Spinner />
        <RedirectMessage>Logging in...</RedirectMessage>
      </FullPageSpinnerContainer>
    );
  }

  if (isAuthLoading || isLoadingUserData) {
    return (
      <FullPageSpinnerContainer>
        <Spinner />
      </FullPageSpinnerContainer>
    );
  }

  return children;
}

AuthenticatedWrapper.propTypes = propTypes;
AuthenticatedWrapper.defaultProps = defaultProps;

export default AuthenticatedWrapper;
