import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash-es/get';
import { Spinner } from 'reactstrap';

import Tooltip from '../../../Tooltip';
import SpinnerContainer from '../../../SpinnerContainer';
import { UNLOCKED_INVOICE_STATUSES } from '../../../../utils/constants';
import { fetchInvoices } from '../../../../state/actions/invoiceActions';
import Invoice from './Invoice';

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;

  h3 {
    font-size: 1rem;
    font-weight: ${prop('theme.fontWeights.semiBold')};
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0 1rem 0 0;
  }
`;

const propTypes = { data: PropTypes.object };

const defaultProps = { data: {} };

function InvoiceDisplay({ data }) {
  const { invoice_title, tooltip_title, tooltip_content } = data.primary;
  // TODO: clean this up
  const invoice = useSelector(
    (state) => get(state, 'memberInfo.invoices', [])[0] || {}
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const locked = invoice
    ? !UNLOCKED_INVOICE_STATUSES.includes(invoice.status)
    : true;

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      fetchInvoices(
        () => {},
        () => setIsLoading(false)
      )
    );
  }, []);

  return (
    <>
      <TitleRow>
        <PrismicRichText field={invoice_title.richText} />
        {locked && (
          <Tooltip
            title={tooltip_title.text || ''}
            content={tooltip_content}
            place='bottom'
            triggerValue={invoice_title.text}
          />
        )}
      </TitleRow>
      {isLoading ? (
        <SpinnerContainer height='500'>
          <Spinner style={{ width: '64px', height: '64px' }} />
        </SpinnerContainer>
      ) : (
        <Invoice invoice={invoice} locked={locked} />
      )}
    </>
  );
}

InvoiceDisplay.propTypes = propTypes;
InvoiceDisplay.defaultProps = defaultProps;

export default InvoiceDisplay;
