import React from 'react';
import { Element } from '@prismicio/react';
import reactStringReplace from 'react-string-replace';
import escapeRegExp from 'lodash-es/escapeRegExp';
import memoize from 'memoize-one';

import Match from '../RichTextWithDictionary/Match';

// -- Function to add unique key to props
const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key });
};

/**
 * Create an html serializer function to be used with prismic RichText component.
 *
 * This serializer checks node content for any definition term matches and
 * replaces matches with definition tooltips.
 *
 * For prismic documentation on the html serializer: https://prismic.io/docs/reactjs/beyond-the-api/html-serializer
 */
const createDictionaryHtmlSerializer = memoize((terms) => {
  // Make regex pattern to search for terms.
  const allTermsEscaped = terms.map((term) => escapeRegExp(term.term_name));
  const pattern = new RegExp(
    `(?:^|\\b)(${allTermsEscaped.join('|')})(?!\\w)`,
    'gi'
  );

  // Make map of lowercase terms to their definitions for quick lookup.
  const termDefinitionMap = {};
  terms.forEach((term) => {
    termDefinitionMap[term.term_name.toLowerCase()] = term.term_definition;
  });

  // -- HTML Serializer
  // This function will be used to change the way the HTML is loaded
  return function dictionaryHtmlSerializer(
    type,
    element,
    content,
    children,
    key
  ) {
    switch (type) {
      // Find dictionary term matches in text content and add definitions.
      case Element.span:
        if (content) {
          // First, run default behavior of RichText serializer.
          const contentList = content.split('\n').reduce((acc, p) => {
            if (acc.length === 0) {
              return [p];
            } else {
              const brIndex = (acc.length + 1) / 2 - 1;
              const br = React.createElement(
                'br',
                propsWithUniqueKey({}, brIndex)
              );
              return acc.concat([br, p]);
            }
          }, []);

          // Then, run our custom logic to find term matches and replace them with tooltips.
          return reactStringReplace(contentList, pattern, (match, i) => {
            const definition = termDefinitionMap[match.toLowerCase()];

            if (!definition) {
              console.error(
                `Definition mapping not found for term match ${match} (${i})`
              );

              return match;
            }

            return React.createElement(
              Match,
              propsWithUniqueKey({ text: match, definition }, i)
            );
          });
        } else {
          return null;
        }

      // Return null to stick with the default behavior
      default:
        return null;
    }
  };
});

export default createDictionaryHtmlSerializer;
