import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import PropTypes from 'prop-types';

import searchIcon from '../../../images/icons/search-icon.svg';
import useResize from '../../../hooks/useResize';

const Query = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${prop('theme.colors.greyDivider')};
  padding-bottom: 8px;
  width: ${prop('$width', '240px')};

  img {
    margin-bottom: 2px;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    ${ifProp(
      '$mobileCollapse',
      css`
        margin-right: 12px;
        border: none;
      `
    )}
    ${ifProp(
      '$isOpen',
      css`
        border-bottom: 1px solid ${prop('theme.colors.greyDivider')};
        width: 100%;
      `,
      css`
        width: initial;
      `
    )}
  }
`;

const MobileExpandButton = styled.button`
  border: none;
  background: none;
  padding: 0 0 0 8px;

  img {
    height: 16px;
    width: 16px;

    @media (max-width: ${prop('theme.breakpoints.lg')}) {
      height: 24px;
      width: 24px;
    }
  }
`;

const MobileCollapse = styled.div`
  width: 100%;
  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    ${ifProp(
      { $isOpen: false },
      css`
        display: none;
      `,
      css`
        display: initial;
      `
    )}
  }
`;

const Input = styled.input`
  border: none;
  outline: none;
  padding: 0;
  margin-left: 14px;
  font-weight: ${prop('theme.fontWeights.regular')};
  color: ${prop('theme.colors.black')};
  line-height: 24px;
  width: 100%;
`;

const propTypes = {
  query: PropTypes.string,
  setQuery: PropTypes.func,
  width: PropTypes.string,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  mobileCollapse: PropTypes.bool
};

const defaultProps = {
  query: '',
  setQuery: () => {},
  width: '240px',
  onFocus: () => {},
  onKeyDown: () => {},
  mobileCollapse: false
};

function QueryInput({
  query,
  setQuery,
  width,
  onFocus,
  onKeyDown,
  mobileCollapse
}) {
  const inputRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useResize(isMobile, setIsMobile);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <Query
      $width={width}
      $isOpen={mobileCollapse ? isOpen : true}
      $mobileCollapse={mobileCollapse}
    >
      <MobileExpandButton
        onClick={() => setIsOpen((prev) => !prev)}
        $isOpen={mobileCollapse ? isOpen : true}
        disabled={mobileCollapse ? !isMobile : true}
        type='button'
      >
        <img src={searchIcon} height='auto' width='100%' />
      </MobileExpandButton>
      <MobileCollapse $isOpen={mobileCollapse ? isOpen : true}>
        <Input
          type='text'
          placeholder='Search'
          value={query}
          onChange={(event) => {
            setQuery(event.target.value);
          }}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          ref={inputRef}
        />
      </MobileCollapse>
    </Query>
  );
}

QueryInput.propTypes = propTypes;
QueryInput.defaultProps = defaultProps;

export default QueryInput;
