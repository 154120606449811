import get from 'lodash-es/get';

import subscriberApiClient, {
  getErrorMessage
} from '../../utils/subscriberApiClient';
import actions from '../actions';
import errorTypes from './errorTypes';
import {
  DEFAULT_AGREEMENT_TEMPLATE,
  registrationStates
} from '../../utils/constants';

export const fetchOrCreateAgreements = () => async (dispatch, getState) => {
  try {
    const response = await subscriberApiClient.get('/subscriber/agreements');
    const agreements = get(response, 'data.agreements', []);

    if (agreements.length === 0) {
      const requestBody = {
        agreement: {
          agreement_type: DEFAULT_AGREEMENT_TEMPLATE
        }
      };

      const createdResponse = await subscriberApiClient.post(
        'subscriber/agreements',
        requestBody
      );
      await fetchAgreementTemplate(
        createdResponse.data.agreement.agreement_template_id,
        createdResponse.data.agreement,
        dispatch
      );
    } else {
      const templatePromises = agreements.map(async (agreement) => {
        return fetchAgreementTemplate(
          agreement.agreement_template_id,
          agreement,
          dispatch
        );
      });

      const templates = await Promise.all(templatePromises);

      createSponsorAgreementIfNecessary(dispatch, getState, templates);
    }
  } catch (error) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.AGREEMENT,
        message: getErrorMessage(error)
      }
    });
  }
};

export const fetchAgreements = () => async (dispatch) => {
  try {
    const response = await subscriberApiClient.get('/subscriber/agreements');
    const agreements = get(response, 'data.agreements', []);

    agreements.forEach(async (agreement) => {
      await fetchAgreementTemplate(
        agreement.agreement_template_id,
        agreement,
        dispatch
      );
    });
  } catch (error) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.AGREEMENT,
        message: getErrorMessage(error)
      }
    });
  }
};

const createSponsorAgreementIfNecessary = async (
  dispatch,
  getState,
  agreementTemplates
) => {
  const { sponsorship } = get(getState(), 'memberInfo', {});

  if (sponsorship) {
    const sponsoredAgreementIsPresent = agreementTemplates.some(
      (template) => template.sponsorship_code === sponsorship.sponsorship_code
    );

    if (!sponsoredAgreementIsPresent) {
      // if we have existing agreements that have not been signed, we should remove those from redux,
      // since the following API call will delete them in the database
      dispatch({
        type: actions.REMOVE_PENDING_AGREEMENTS
      });

      const createdResponse = await subscriberApiClient.post(
        'subscriber/agreements'
      );

      await fetchAgreementTemplate(
        createdResponse.data.agreement.agreement_template_id,
        createdResponse.data.agreement,
        dispatch
      );
    }
  }
};

const fetchAgreementTemplate = async (id, agreement, dispatch) => {
  try {
    const response = await subscriberApiClient.get(
      `/agreement_templates/${id}`
    );
    const docUid = get(response, 'data.agreement_template.cms_identifier');
    dispatch({
      type: actions.ADD_AGREEMENT_TEMPLATE,
      payload: {
        docUid: docUid,
        agreementId: agreement.id,
        agreementStatus: agreement.status,
        signature: agreement.signature_value
      }
    });

    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.AGREEMENT
    });

    return response.data.agreement_template;
  } catch (error) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.AGREEMENT,
        message: getErrorMessage(error)
      }
    });
  }
};

export const signAgreement = (
  agreement = { id: '', signature_value: '' },
  successCallback,
  finishedCallback
) => async (dispatch) => {
  try {
    const response = await subscriberApiClient.put(
      `/subscriber/agreements/${agreement.id}`,
      { agreement: agreement }
    );
    const signedAgreement = response.data.agreement;
    dispatch({
      type: actions.UPDATE_AGREEMENT_TEMPLATE,
      payload: {
        agreementId: signedAgreement.id,
        agreementStatus: signedAgreement.status,
        signature: signedAgreement.signature_value
      }
    });
    dispatch({
      type: actions.UPDATE_REGISTRATION_STATE,
      payload: registrationStates.COMPLETE
    });

    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.AGREEMENT
    });

    successCallback();
  } catch (error) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.AGREEMENT,
        message: getErrorMessage(error)
      }
    });
  }

  finishedCallback();
};
