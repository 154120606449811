import React from 'react';
import PropTypes from 'prop-types';

import Graphic from '../Graphic';
import NewsItemText from '../NewsItemText';
import EmbeddedVideoPlayer from '../EmbeddedVideoPlayer';
import NewsItemTableRenderer from '../NewsItemTableRenderer';
import Footnote from '../Footnote';
import CtaBlock from '../CtaBlock';
import ButtonLinkBlock from '../ButtonLink/ButtonLinkBlock';
import NewsletterLiteForm from '../MailchimpForm/NewsletterLiteForm';

const componentOptions = {
  text: NewsItemText,
  graphic: Graphic,
  embed_video: EmbeddedVideoPlayer,
  table__3_cols_: NewsItemTableRenderer,
  footnote: Footnote,
  button_link: ButtonLinkBlock,
  newsletter_lite_form: NewsletterLiteForm,
  cta_block: CtaBlock
};

function getComponent(item, i, template) {
  const Component = componentOptions[item.slice_type];

  if (!Component) {
    return null;
  }

  return (
    <Component
      key={i}
      data={{ ...item, source: 'news_item' }}
      template={template}
    />
  );
}

function NewsItemPageBody({ body, twoColumns }) {
  const template = twoColumns ? 'news-item-two-column' : 'news-item';
  return <>{body.map((item, i) => getComponent(item, i, template))}</>;
}

NewsItemPageBody.propTypes = {
  body: PropTypes.array,
  twoColumns: PropTypes.bool
};
NewsItemPageBody.defaultProps = {
  body: [],
  twoColumns: false
};

export default NewsItemPageBody;
