import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ButtonLink from '../../ButtonLink';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 1.75rem;
`;

const propTypes = {
  cta: PropTypes.object
};

const defaultProps = {
  cta: {}
};

function ImageBlock({ cta }) {
  const { block_cta_text: blockCtaText, block_cta: blockCta } = cta;

  return (
    <>
      {blockCtaText && (
        <ButtonContainer>
          <ButtonLink
            link={blockCta}
            format='outline'
            size={312}
            style='outline-black'
          >
            {blockCtaText}
          </ButtonLink>
        </ButtonContainer>
      )}
    </>
  );
}

ImageBlock.propTypes = propTypes;
ImageBlock.defaultProps = defaultProps;

export default ImageBlock;
