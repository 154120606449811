import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from 'reactstrap/lib/Collapse';
import { prop } from 'styled-tools';
import styled from 'styled-components';

import RailMenus from './RailMenus';
import ProductsRailMenu from './ProductsRailMenu';
import chevron from '../../images/mobile-chevron.svg';
import NewsListRailMenu from './NewsListRailMenu';
import ScrollContext from '../../context/ScrollContext';

const Container = styled.div`
  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    display: none;
  }
`;

const MenuToggler = styled.div`
  align-items: center;
  color: ${prop('theme.colors.titleGrey')};
  cursor: pointer;
  display: flex;
  font-size: 1.25rem;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  justify-content: space-between;
  letter-spacing: 1px;
  line-height: 24px;
  padding-right: 2rem;

  // Fix to prevent the mobile menu from flashing on screen momentarily during desktop page load
  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    display: none;
  }
`;

const propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  renderMenu: PropTypes.element,
  title: PropTypes.string
};

const StyledCollapse = styled(Collapse)`
  &.show,
  &.collapsing {
    margin-top: 2rem;
  }
`;

const defaultProps = {
  data: [],
  renderMenu: null,
  title: ''
};

function MobileMenu({ data, renderMenu, title }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { disableBodyScroll, enableBodyScroll } = useContext(ScrollContext);

  const toggle = useCallback((event) => {
    // Inputs with labels trigger two events, and we only want one in order for the MobileMenu component to keep toggling correctly.
    if (
      event.nativeEvent.target.hasAttribute('name') &&
      event.nativeEvent.target.getAttribute('name') === 'radioToggle'
    ) {
      return;
    }

    setIsExpanded((prev) => !prev);
  }, []);

  useEffect(() => {
    if (isExpanded) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
  }, [isExpanded]);

  return (
    <Container>
      <MenuToggler id='mobileMenu' onClick={toggle}>
        {title}
        <img src={chevron} width={20} height={10} alt='Mobile menu chevron' />
      </MenuToggler>
      <StyledCollapse isOpen={isExpanded}>
        {renderMenu &&
          React.cloneElement(renderMenu, {
            mobileMenuToggle: toggle
          })}
        {!renderMenu && data.isProductsMenu && (
          <ProductsRailMenu toggle={toggle} />
        )}
        {!renderMenu && data.isNewsListMenu && (
          <NewsListRailMenu
            toggle={toggle}
            menuItems={data.menuItems}
            pageUid={data.pageUid}
          />
        )}
        {!renderMenu && !data.isProductsMenu && !data.isNewsListMenu && (
          <RailMenus menus={data} toggle={toggle} />
        )}
      </StyledCollapse>
    </Container>
  );
}

MobileMenu.propTypes = propTypes;
MobileMenu.defaultProps = defaultProps;

export default MobileMenu;
