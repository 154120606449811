import { prop } from 'styled-tools';
import styled from 'styled-components';

const MenuItem = styled.li`
  margin-bottom: 1rem;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin-bottom: 1.5rem;
  }

  &.active {
    a {
      color: ${prop('theme.colors.redPrimary')};
      text-decoration: none;
    }
  }
`;

export default MenuItem;
