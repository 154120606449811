import { prop } from 'styled-tools';
import styled from 'styled-components';

const StyledTextBlock = styled.div`
  margin: 0;
  scroll-margin-top: 12rem;

  ul,
  ol {
    padding-left: 5rem;
    font-weight: ${prop('theme.fontWeights.regular')};

    @media (max-width: ${prop('theme.breakpoints.md')}) {
      padding-left: 3rem;
    }

    @media (max-width: ${prop('theme.breakpoints.sm')}) {
      padding-left: 2rem;
    }

    li {
      color: ${prop('theme.colors.blackSecondary')};
      font-family: ${prop('theme.fonts.default')};
      font-size: 1rem;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.005rem;
      margin-bottom: 0.25rem;
    }
  }

  ol li {
    margin-bottom: 1rem;
  }

  ol li {
    margin-bottom: 1rem;
  }

  h1,
  h2 {
    font-size: 1.875rem;
    font-weight: ${prop('theme.fontWeights.semiBold')};
    margin-bottom: 1rem;
    margin-top: 0.625rem;
  }

  h3 {
    font-size: 1.125rem;
    font-weight: ${prop('theme.fontWeights.bold')};
  }

  h4 {
    font-size: 1rem;
    font-weight: ${prop('theme.fontWeights.semiBold')};
    margin-top: 2rem;
  }

  h5 {
    font-size: 1rem;
    font-weight: ${prop('theme.fontWeights.semiBold')};
    text-transform: none;
  }

  h6 {
    font-size: 0.875rem;
  }

  h5,
  h6 {
    color: ${prop('theme.colors.blackSecondary')};
    font-family: ${prop('theme.fonts.default')};
    font-weight: ${prop('theme.font_weights.condensed_bold')};
    margin-bottom: 0.5rem;
  }

  .block-img {
    width: fit-content;
    margin-top: 2rem;
    margin: 2rem auto 0;

    img {
      padding: 0.75rem;
      border: 1px solid ${prop('theme.colors.borderGrey')};
      border-radius: 1.5rem;
      max-width: 100%;
      height: auto;
    }
  }

  p {
    overflow: auto;
    font-size: 1rem;
    line-height: 24px;
  }

  div {
    margin-bottom: 1rem;
  }

  em {
    font-weight: ${prop('theme.fontWeights.medium')};
  }

  a {
    font-weight: ${prop('theme.fontWeights.semiBold')};
    color: ${prop('theme.colors.redPrimary')};
  }

  .list-item-red {
    color: ${prop('theme.colors.redPrimary')};
    display: list-item;
    list-style-type: disc;
    margin-left: 5rem;
  }

  .text-indented {
    margin-left: 5rem;
    display: block;

    @media (max-width: ${prop('theme.breakpoints.md')}) {
      margin-left: 3rem;
    }

    @media (max-width: ${prop('theme.breakpoints.sm')}) {
      margin-left: 2rem;
    }

    strong {
      font-weight: ${prop('theme.fontWeights.semiBold')};
    }

    em {
      font-weight: ${prop('theme.fontWeights.regular')};
    }
  }

  .text-indented-red-title {
    margin-left: 5rem;
    display: block;

    @media (max-width: ${prop('theme.breakpoints.md')}) {
      margin-left: 3rem;
    }

    @media (max-width: ${prop('theme.breakpoints.sm')}) {
      margin-left: 2rem;
    }

    strong {
      font-weight: ${prop('theme.fontWeights.semiBold')};
      color: ${prop('theme.colors.redPrimary')};
    }
  }

  .text-small {
    font-size: 0.875rem;
  }
`;

export default StyledTextBlock;
