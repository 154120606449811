import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import BlockStyles from '../../BlockStyles';
import { ButtonLinkStyles, ButtonLinkRedStyles } from '../../ButtonLink';
import { graphql, useStaticQuery } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';

const ErrorContainer = styled.div`
  margin-bottom: 104px;

  h1 {
    margin: 80px 0 40px;
    color: ${prop('theme.colors.redPrimary')};
    font-weight: ${prop('theme.fontWeights.semiBold')};
  }

  > * {
    margin-left: 8px;
  }

  p {
    &:last-of-type {
      margin-top: 24px;
    }
  }
`;

const ErrorDisplay = styled.div`
  margin-top: 24px;
  div {
    display: flex;

    span {
      flex: 1 1 100%;
      max-width: 100%;

      &:first-child {
        font-weight: ${prop('theme.fontWeights.semiBold')};
        flex: 1 1 12%;
        max-width: 12%;
      }
    }
  }
`;

const StyledButton = styled.button`
  ${ButtonLinkStyles}
  ${ButtonLinkRedStyles}
  border: none;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  margin-top: 56px;
`;

const propTypes = {};

const defaultProps = {};

function ErrorPage() {
  const apiErrors = useSelector((state) => get(state, 'apiErrors', {}));

  const queryResult = useStaticQuery(graphql`
    query ErrorQuery {
      allPrismicCommunityErrorPage {
        edges {
          node {
            data {
              refresh_button_text
              page_title {
                richText
                text
              }
              post_error_list_message {
                text
                richText
              }
              pre_error_list_message {
                text
                richText
              }
            }
          }
        }
      }
    }
  `);

  // Grabbing index 0 here because only one error page is allowed in prismic. If the first index doesn't exist, we're missing the error page.
  const data = get(
    queryResult,
    'allPrismicCommunityErrorPage.edges[0].node.data',
    {}
  );

  const {
    page_title,
    pre_error_list_message,
    post_error_list_message,
    refresh_button_text
  } = data;

  return (
    <BlockStyles>
      <ErrorContainer>
        {page_title && page_title.text ? (
          <PrismicRichText field={page_title.richText} />
        ) : (
          <h1>There was an error processing your request</h1>
        )}
        {pre_error_list_message && pre_error_list_message.text ? (
          <PrismicRichText field={pre_error_list_message.richText} />
        ) : (
          <p>
            We ran into the following error(s) while processing your request:
          </p>
        )}
        {Object.keys(apiErrors).map((error, index) => (
          <ErrorDisplay key={`${error}-${index}`}>
            <div>
              <span>Error Code: </span>
              <span>{error}</span>
            </div>
            <div>
              <span>Message: </span>
              <span>{apiErrors[error]}</span>
            </div>
          </ErrorDisplay>
        ))}
        {post_error_list_message && post_error_list_message.text ? (
          <PrismicRichText field={post_error_list_message.richText} />
        ) : (
          <p>
            Please try again later. If the problem persists, try contacting
            support here:{' '}
            <a href='mailto:support@thesmallexchange.com'>
              support@thesmallexchange.com
            </a>
          </p>
        )}
        <StyledButton
          onClick={() => {
            if (typeof window !== 'undefined') {
              window.location.reload(false);
            }
          }}
        >
          {refresh_button_text || 'Refresh Page'}
        </StyledButton>
      </ErrorContainer>
    </BlockStyles>
  );
}

ErrorPage.propTypes = propTypes;
ErrorPage.defaultProps = defaultProps;

export default ErrorPage;
