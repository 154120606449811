import { prop } from 'styled-tools';
import styled from 'styled-components';

const MainSection = styled.div`
  padding: 24px 40px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin-left: 288px;
    width: calc(100% - 288px);
  }

  @media (min-width: ${prop('theme.breakpoints.xxxl')}) {
    max-width: 1440px;
  }

  @media (max-width: ${prop('theme.breakpoints.xxxl')}) and (min-width: ${prop(
      'theme.breakpoints.lg'
    )}) {
    max-width: 1200px;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin-top: 48px;
    height: 100%;
  }

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export default MainSection;
