import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';
import { PrismicRichText } from '@prismicio/react';

import VanillaImageBlock from './ImageBlocks/VanillaImageBlock';
import ContestImageBlock from './ImageBlocks/ContestImageBlock';
import BlockCta from './ImageBlocks/BlockCta';
import { CTA_POSITIONS } from '../../utils/constants';
import RichTextWithDictionary from '../RichTextWithDictionary';

const ImageBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;

  ${ifProp(
    { $template: 'products-landing' },
    css`
      align-items: center;
    `
  )}
`;

const TitleContainer = styled.div`
  margin-bottom: 3rem;

  ${ifProp(
    { $template: 'products-landing' },
    css`
      width: 80%;

      @media (max-width: ${prop('theme.breakpoints.xl')}) {
        width: 85%;
      }

      @media (max-width: ${prop('theme.breakpoints.lg')}) {
        width: 80%;
      }

      @media (max-width: ${prop('theme.breakpoints.md')}) {
        width: 85%;
      }

      @media (max-width: ${prop('theme.breakpoints.sm')}) {
        width: 90%;
      }
    `
  )}

  h2 {
    font-weight: ${prop('theme.fontWeights.semiBold')};
    margin-bottom: 2rem;
  }
`;

const propTypes = {
  primary: PropTypes.object,
  fields: PropTypes.array,
  label: PropTypes.string,
  template: PropTypes.string
};

const defaultProps = {
  primary: {},
  fields: [],
  label: '',
  template: ''
};

function ImageBlock({ primary, label, fields, template }) {
  const productContestType =
    label === 'product_contest' || label === 'product_contest_standalone_image';
  const ctaPosition = primary && primary.cta_position;

  return (
    <ImageBlockContainer $template={template}>
      {primary.title && primary.title.text && (
        <TitleContainer $template={template}>
          <PrismicRichText field={primary.title.richText} />
          {primary.description && primary.description.text && (
            <RichTextWithDictionary render={primary.description.richText} />
          )}
        </TitleContainer>
      )}
      {CTA_POSITIONS['TOP'] === ctaPosition && <BlockCta cta={primary} />}
      {!productContestType &&
        fields.map((field, index) => (
          <VanillaImageBlock
            key={`${
              field.title ? field.title.text : 'VanillaImageBlock'
            }-${index}`}
            primary={primary}
            field={field}
            label={label}
            template={template}
          />
        ))}
      {productContestType &&
        fields.map((field, index) => (
          <ContestImageBlock key={index} field={field} label={label} />
        ))}
      {CTA_POSITIONS['BOTTOM'] === ctaPosition && <BlockCta cta={primary} />}
    </ImageBlockContainer>
  );
}

ImageBlock.propTypes = propTypes;
ImageBlock.defaultProps = defaultProps;

export default ImageBlock;
