import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

const siteMetaQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

const defaultProps = {
  description: '',
  title: '',
  image: null
};

const propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.object
};

function SEO({ description, title, image }) {
  const { site } = useStaticQuery(siteMetaQuery);
  const metaDescription = description || site.siteMetadata.description;
  const imageTag = (image && image.url) || '';

  return (
    <>
      <title>{`${title} | ${site.siteMetadata.title}`}</title>
      <meta name='description' content={metaDescription} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={metaDescription} />
      <meta property='og:image' content={imageTag} />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content={site.siteMetadata.author} />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={metaDescription} />
      <meta name='twitter:image' content={imageTag} />
    </>
  );
}

SEO.defaultProps = defaultProps;
SEO.propTypes = propTypes;

export default SEO;
