import React from 'react';
import Tooltip from '../Tooltip';
import PropTypes from 'prop-types';

import RichTextWithDictionary from '../RichTextWithDictionary';

const propTypes = {
  column: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ])
};

const defaultProps = {
  column: []
};

function HeaderContent({ column, triggerValue, tableTooltips }) {
  return (
    <>
      {Array.isArray(column) ? (
        <RichTextWithDictionary render={column} />
      ) : (
        <>{column}</>
      )}
      {tableTooltips && tableTooltips.length > 0 && (
        <Tooltip
          content={tableTooltips}
          inTable={true}
          triggerValue={triggerValue}
        />
      )}
    </>
  );
}

HeaderContent.propTypes = propTypes;
HeaderContent.defaultProps = defaultProps;

export default HeaderContent;
