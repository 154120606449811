import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import SEO from '../components/seo';
import Layout from '../components/layout';
import PageContainer from '../components/layout-components/PageContainer';
import MainCol from '../components/PageBody/MainCol';
import ColumnWrapper from '../components/PageBody/styled/ColumnWrapper';
import PageBody from '../components/PageBody';
import HeroCarousel from '../components/HeroCarousel';

const propTypes = {
  data: PropTypes.object
};

const defaultProps = {
  data: null
};

function PromoLandingPage({ data: queryResult }) {
  const landingPageData = queryResult.prismicPromoLandingPage.data;
  const { header_banner, body } = landingPageData;

  if (!landingPageData) {
    return null;
  }

  return (
    <Layout>
      <HeroCarousel headerBanner={header_banner} />
      <PageContainer>
        <MainCol>
          <ColumnWrapper>{body && <PageBody body={body} />}</ColumnWrapper>
        </MainCol>
      </PageContainer>
    </Layout>
  );
}

export const Head = ({ data: queryResult }) => {
  const pageData = queryResult.prismicPromoLandingPage.data;

  if (!pageData) {
    return null;
  }

  const { seo_title, seo_meta_description, seo_image } = pageData;

  return (
    <SEO
      title={seo_title}
      description={seo_meta_description}
      image={seo_image}
    />
  );
};

export const query = graphql`
  query LandingPageQuery($id: String!) {
    prismicPromoLandingPage(id: { eq: $id }) {
      _previewable
      data {
        seo_title
        seo_meta_description
        seo_image {
          url
        }
        header_banner {
          image {
            alt
            url
            dimensions {
              height
              width
            }
          }
          button_link {
            url
            target
            type
            uid
            link_type
            tags
          }
          button_text
          title {
            richText
            text
          }
          title_font_size
          sub_title {
            richText
            text
          }
          sub_title_font_size
          copy_position
        }
        page_title {
          richText
          text
        }
        body {
          ... on PrismicPromoLandingPageDataBodyEmbedVideo {
            slice_type
            primary {
              embed_video {
                html
                embed_url
                title
                provider_name
              }
            }
          }
          ... on PrismicPromoLandingPageDataBodyCardGroup {
            slice_type
            primary {
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              feature_card
              feature_card_color
              card_header {
                richText
                text
              }
              card_title {
                richText
                text
              }
              sub_card_title_content {
                richText
                text
              }
              main_card_content {
                richText
                text
              }
              sub_main_card_content {
                richText
                text
              }
              cta {
                richText
                text
              }
              cta_link {
                url
                target
                type
                uid
                link_type
                tags
              }
              card_content_list {
                richText
                text
              }
              card_content_list_type
              card_footer {
                richText
                text
              }
            }
          }
          ... on PrismicPromoLandingPageDataBodyTextBlock {
            slice_type
            primary {
              sidenav_only
              text_block_header {
                richText
                text
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              text_block_content {
                richText
                text
                html
              }
            }
          }
          ... on PrismicPromoLandingPageDataBodyTwoColumns {
            slice_type
            slice_label
            items {
              text_block {
                richText
                text
              }
              title {
                richText
                text
              }
              image {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
              image_display_style
            }
          }
          ... on PrismicPromoLandingPageDataBodyTwoColumnsImage {
            slice_type
            slice_label
            primary {
              block_cta_text
              cta_position
              block_cta {
                link_type
                target
                url
                type
                uid
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              additional_text {
                richText
                text
              }
              image {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
              text_block {
                richText
                text
              }
              title {
                richText
                text
              }
              button_link_text {
                richText
                text
              }
              image_margin
            }
          }
          ... on PrismicPromoLandingPageDataBodyCtaBlock {
            slice_label
            slice_type
            primary {
              background_image {
                alt
                url
              }
              title {
                richText
                text
              }
              text_block {
                richText
                text
              }
              image_overlay
            }
            items {
              cta_style
              cta_text
              cta_link {
                link_type
                target
                url
                type
                uid
                tags
              }
            }
          }
          ... on PrismicPromoLandingPageDataBodyDisclaimerText {
            slice_type
            slice_label
            primary {
              title {
                richText
                text
              }
              text_content {
                richText
                text
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
          }
          ... on PrismicPromoLandingPageDataBodyProductCta {
            slice_type
            primary {
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
              button_link {
                url
              }
              button_text {
                text
              }
              cta_description {
                text
              }
              cta_header {
                text
              }
            }
            items {
              column_image {
                url
                dimensions {
                  height
                  width
                }
              }
              column_description {
                text
              }
              column_title {
                text
              }
            }
          }
          ... on PrismicPromoLandingPageDataBodyTable10Cols {
            slice_type
            primary {
              table_title {
                richText
                text
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
              table_type
              table_description {
                richText
                text
              }
              tooltip {
                richText
                text
              }
              column_width
              block_styles_side_nav
              col1 {
                richText
                text
              }
              col1_span
              col2 {
                richText
                text
              }
              col3 {
                richText
                text
              }
              col3_span
              col4 {
                richText
                text
              }
              col5 {
                richText
                text
              }
              col6 {
                richText
                text
              }
              col7 {
                richText
                text
              }
              col8 {
                richText
                text
              }
              col9 {
                richText
                text
              }
              col10 {
                richText
                text
              }
              tooltip_col1 {
                richText
                text
              }
              tooltip_col2 {
                richText
                text
              }
            }
            items {
              col1_data {
                richText
                text
              }
              col2_data {
                richText
                text
              }
              col3_data {
                richText
                text
              }
              col4_data {
                richText
                text
              }
              col5_data {
                richText
                text
              }
              col6_data {
                richText
                text
              }
              col7_data {
                richText
                text
              }
              col8_data {
                richText
                text
              }
              col9_data {
                richText
                text
              }
              col10_data {
                richText
                text
              }
            }
          }
          ... on PrismicPromoLandingPageDataBodyMailchimpForm {
            slice_type
            primary {
              mailchimp_form_id
              form_type
              checkbox_text
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              additional_parameter_name
              additional_parameter_value
            }
          }
        }
      }
    }
  }
`;

PromoLandingPage.propTypes = propTypes;
PromoLandingPage.defaultProps = defaultProps;

export default withPrismicPreview(PromoLandingPage);
