import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PrismicRichText } from '@prismicio/react';

import linkResolver from '../../utils/linkResolver';
import RichTextWithDictionary from '../RichTextWithDictionary';
import GraphicContainer from './GraphicContainer';

const GraphicWrapper = styled.section`
  break-inside: avoid;
  ${(props) => props.pageSpan && 'column-span: all;'}
  p {
    text-align: center;
  }
`;

const HeaderContainer = styled.div`
  margin-top: 1rem;
`;

function Graphic({ data }) {
  const {
    span_entire_page,
    graphic_header,
    graphic_container,
    graphic_caption,
    ...graphicAttributes
  } = data.primary;

  return (
    <GraphicWrapper pageSpan={span_entire_page}>
      <HeaderContainer>
        <PrismicRichText
          field={graphic_header.richText}
          linkResolver={linkResolver}
        />
      </HeaderContainer>
      <GraphicContainer
        graphicContainer={graphic_container}
        containerAttributes={graphicAttributes}
      />
      {graphic_caption && graphic_caption.text && (
        <RichTextWithDictionary render={graphic_caption.richText} />
      )}
    </GraphicWrapper>
  );
}

Graphic.propTypes = {
  data: PropTypes.object
};
Graphic.defaultProps = {
  data: {}
};

export default Graphic;
