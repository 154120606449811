import React from 'react';
import PropTypes from 'prop-types';

import TableRows from './TableRows';
import TableHeader from './TableHeader';
import StyledTable from './styled/StyledTable';

const propTypes = {
  columns: PropTypes.object,
  head: PropTypes.object,
  rows: PropTypes.array
};

const defaultProps = {
  columns: {},
  head: {},
  rows: []
};

function ResponsiveTable({ columns, head, rows }) {
  const headerKeys = Object.keys(head);

  return (
    <StyledTable $columnWidth={head.column_width}>
      <TableHeader columns={columns} head={head} headerKeys={headerKeys} />
      <tbody>
        <TableRows
          columns={columns}
          head={head}
          headerKeys={headerKeys}
          rows={rows}
        />
      </tbody>
    </StyledTable>
  );
}

ResponsiveTable.propTypes = propTypes;
ResponsiveTable.defaultProps = defaultProps;

export default ResponsiveTable;
