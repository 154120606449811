import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop, switchProp } from 'styled-tools';
import { useDispatch, useSelector } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import { Spinner } from 'reactstrap';

import { fetchOrCreateAgreements } from '../../../../state/actions/agreementActions';
import SpinnerContainer from '../../../SpinnerContainer';
import BlockStyles from '../../../BlockStyles';
import SingleAgreementDisplay from './SingleAgreementDisplay';
import MultipleAgreementDisplay from './MultipleAgreementDisplay';

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-left: 16px;

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
  }
`;

export const AgreementContainer = styled.div`
  padding: 16px 40px;
  border: 1px solid ${prop('theme.colors.borderGrey')};
  border-radius: 16px;
  margin: 24px 0;
`;

export const Section = styled.div`
  margin-bottom: 64px;

  &:last-of-type {
    margin: 0;
  }

  h3 {
    font-weight: ${prop('theme.fontWeights.bold')};
    font-size: 1rem;
    margin-bottom: 24px;
    text-transform: initial;
    letter-spacing: initial;
  }

  &.fine-print {
    p {
      font-size: 0.75rem;
    }
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${ifProp(
    '$canBeExpanded',
    css`
      cursor: pointer;
    `
  )}

  ${ifProp(
    '$isExpanded',
    css`
      margin-bottom: 48px;
    `
  )}

  h3 {
    font-weight: ${prop('theme.fontWeights.bold')};
    font-size: 1rem;
    text-transform: initial;
    letter-spacing: initial;
    margin: 0;
  }

  div {
    display: flex;
    align-items: center;
  }}

  .expanded {
    transform: rotate(-180deg);
    transition: transform 300ms ease;
  }

  .collapsed {
    transform: rotate(0);
    transition: transform 300ms ease;
  }
`;

export const StatusIndicator = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.6875rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border: 1px solid;
  border-radius: 8px;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  margin: 0 16px;
  padding: 4px 12px 0;

  &&& {
    width: max-content;
  }

  span {
    margin-bottom: 2px;
  }

  ${switchProp('$status', {
    signed: css`
      border-color: ${prop('theme.colors.green')};
      color: ${prop('theme.colors.green')};

      img {
        margin-bottom: 4px;
        margin-right: 4px;
      }
    `,
    'action required': css`
      border-color: ${prop('theme.colors.redPrimary')};
      color: ${prop('theme.colors.redPrimary')};
    `
  })}
`;

const propTypes = {};

const defaultProps = {};

function AgreementForm() {
  const dispatch = useDispatch();
  const {
    memberInfo: { agreementTemplates },
    apiErrors
  } = useSelector((state) => state);
  const [agreementDocuments, setAgreementDocuments] = useState([]);

  const queryResult = useStaticQuery(graphql`
    query AgreementQuery {
      allPrismicSubscriberAgreement {
        edges {
          node {
            uid
            data {
              title
              section {
                css_classname
                section_body {
                  text
                  richText
                }
                section_name
              }
              logo_url
              agreement_body {
                text
                richText
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    dispatch(fetchOrCreateAgreements());
  }, []);

  useEffect(() => {
    if (agreementTemplates) {
      setAgreementDocuments(
        queryResult.allPrismicSubscriberAgreement.edges
          .map((edge) => edge.node)
          .filter(
            (node) =>
              agreementTemplates.findIndex(
                (template) => template.docUid === node.uid
              ) > -1
          )
          .map((node) => {
            const index = agreementTemplates.findIndex(
              (template) => template.docUid === node.uid
            );
            return {
              ...node,
              status: agreementTemplates[index].agreementStatus,
              id: agreementTemplates[index].agreementId,
              signature: agreementTemplates[index].signature
            };
          })
      );
    }
  }, [agreementTemplates]);

  const noErrorsPresent = !apiErrors || Object.keys(apiErrors).length === 0;

  return (
    <BlockStyles template='registration'>
      <div>
        {agreementDocuments.length === 0 && (
          <SpinnerContainer height='500'>
            {noErrorsPresent && (
              <Spinner style={{ width: '64px', height: '64px' }} />
            )}
          </SpinnerContainer>
        )}
        {agreementDocuments.length === 1 && (
          <SingleAgreementDisplay agreement={agreementDocuments[0]} />
        )}
        {agreementDocuments.length > 1 && (
          <MultipleAgreementDisplay agreements={agreementDocuments} />
        )}
      </div>
    </BlockStyles>
  );
}

AgreementForm.propTypes = propTypes;
AgreementForm.defaultProps = defaultProps;

export default AgreementForm;
