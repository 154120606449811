import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import { useFlexSearch } from 'react-use-flexsearch';
import { Link, navigate } from 'gatsby';

import linkResolver from '../../../utils/linkResolver';
import QueryInput from './QueryInput';
import useClickOutside from '../../../hooks/useClickOutside';

const SearchContainer = styled.div`
  margin: 12px 0 0 16px;
  flex-grow: 1;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    display: flex;
    justify-content: flex-end;
    margin-right: 16px;
  }

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const Results = styled.div`
  position: absolute;
  background-color: white;
  padding: 16px 16px 24px;
  list-style: none;
  background-color: ${prop('theme.colors.backgroundGreyLightest')};
  border-radius: 0 16px 16px 16px;
  min-width: 256px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    left: 0;
    top: 108px;
    border-radius: 0 0 16px 16px;
    width: 100%;
  }
`;

const Result = styled(Link)`
  padding: 6px 8px;
  color: ${prop('theme.colors.black')};
  border-radius: 8px;

  &.active,
  &:hover {
    color: ${prop('theme.colors.redPrimary')};
    text-decoration: none;

    background-color: ${prop('theme.colors.backgroundGreyLighter')};
  }
`;

const AllResultsLink = styled(Result)`
  color: ${prop('theme.colors.redPrimary')};
  font-weight: ${prop('theme.fontWeights.semiBold')};

  &:after {
    content: ' →';
    font-size: 1rem;
  }

  &.active,
  &:hover {
    text-decoration: underline;
  }
`;

const propTypes = { localSearchItems: PropTypes.object };

const defaultProps = { localSearchItems: {} };

const KEYCODE_DOWN = 38;
const KEYCODE_UP = 40;
const KEYCODE_ENTER = 13;
const KEYBOARD_CONTROL_CODES = [KEYCODE_DOWN, KEYCODE_UP, KEYCODE_ENTER];

function Search({ localSearchItems }) {
  const [query, setQuery] = useState('');
  const [hasFocus, setHasFocus] = useState(false);
  const [cursor, setCursor] = useState(-1);
  const { index, store } = localSearchItems;
  const results = useFlexSearch(query, index, store);
  const ref = useRef();

  useClickOutside(ref, () => setHasFocus(false));

  useEffect(() => {
    if (results.length <= cursor) {
      setCursor(results.length - 1);
    }
  }, [results]);

  const handleKeyDown = (e) => {
    // checking if the keycode is in the list of codes to make sure we only prevent the default behavior for the specific key presses we're looking for
    if (hasFocus && KEYBOARD_CONTROL_CODES.includes(e.keyCode)) {
      const max = results.length > 10 ? 10 : results.length - 1;

      e.preventDefault();
      if (e.keyCode === KEYCODE_DOWN && cursor > -1) {
        setCursor((prev) => prev - 1);
      } else if (e.keyCode === KEYCODE_UP && cursor < max) {
        setCursor((prev) => prev + 1);
      } else if (e.keyCode === KEYCODE_ENTER && cursor > -1) {
        if (cursor === 10) {
          navigate(`/search/?query=${query}`);
        } else {
          navigate(linkResolver(results[cursor]));
        }
      }
    }
  };

  return (
    <SearchContainer ref={ref}>
      <QueryInput
        query={query}
        setQuery={setQuery}
        results={results}
        onFocus={() => setHasFocus(true)}
        onKeyDown={handleKeyDown}
        mobileCollapse
      />
      {hasFocus && results.length > 0 && (
        <Results>
          {results.slice(0, 10).map((result, index) => (
            <Result
              key={result.uid}
              to={linkResolver(result)}
              className={cursor === index ? 'active' : ''}
            >
              {result.title.text}
            </Result>
          ))}
          {results.length > 10 && (
            <AllResultsLink
              to={`/search/?query=${query}`}
              className={cursor === 10 ? 'active' : ''}
            >
              See all results
            </AllResultsLink>
          )}
        </Results>
      )}
    </SearchContainer>
  );
}

Search.propTypes = propTypes;
Search.defaultProps = defaultProps;

export default Search;
