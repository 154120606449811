import axios from 'axios';
import get from 'lodash-es/get';

const subscriberApiClient = axios.create({
  baseURL:
    process.env.GATSBY_SUBSCRIBER_API_URL ||
    process.env.SUBSCRIBER_API_URL ||
    'https://subscribers.thesmallexchange.com',
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
});

export default subscriberApiClient;

// TODO remove this in favor of an interceptor that uses the redux store?
export const setAuthToken = (token) => {
  if (!token) {
    return;
  }

  subscriberApiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeAuthToken = () => {
  delete subscriberApiClient.defaults.headers.common.Authorization;
};

export const getErrorMessage = (error, excludeHttpStatus) => {
  const errors = get(error, 'response.data.errors', []);

  if (errors.length > 0) {
    return excludeHttpStatus ? errors[0] : `${error.message}: ${errors[0]}`;
  } else {
    return error.message;
  }
};
