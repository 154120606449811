import React from 'react';
import { Link } from 'gatsby';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import Collapse from 'reactstrap/lib/Collapse';

import linkResolver from '../../../../utils/linkResolver';
import chevronDown from '../../../../images/arrows/chevron-down.svg';
import chevronUp from '../../../../images/arrows/chevron-up.svg';
import notificationDot from '../../../../images/notification-dot.svg';

const NavLink = styled(Link)`
  align-items: center;
  display: flex;
  height: 42px;
  justify-content: space-between;
  padding: 0 32px 0 28px;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    padding: 0 36px;
  }

  &:hover,
  &.active {
    background-color: ${prop('theme.colors.backgroundGrey')};
    text-decoration: none;
  }
`;

const NavButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
`;

const NavIcon = styled.img`
  margin-right: 20px;
`;

const NavText = styled.div`
  align-items: center;
  color: ${prop('theme.colors.black')};
  display: flex;
  font-size: 0.875rem;

  ${switchProp('$type', {
    main: css`
      font-weight: ${prop('theme.fontWeights.medium')};
    `,
    sub: css`
      font-weight: ${prop('theme.fontWeights.light')};
      margin-left: 44px;
    `
  })}
`;

const NotificationDot = styled.img`
  margin-left: 8px;
`;

const propTypes = {
  item: PropTypes.shape({
    primary: PropTypes.object,
    items: PropTypes.array
  }),
  expandedSection: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  toggleNav: PropTypes.func,
  noInvoicesPaid: PropTypes.bool
};

const defaultProps = {
  item: {},
  expandedSection: false,
  toggleNav: () => {},
  noInvoicesPaid: false
};

function NavItemContainer({
  item,
  expandedSection,
  toggleNav,
  noInvoicesPaid
}) {
  const mainLinkText = get(item, 'primary.link_text');
  const mainLink = get(item, 'primary.nav_link');
  const navIcon = get(item, 'primary.nav_icon');
  const subNavItems = get(item, 'items');

  const isBrowser = typeof window !== 'undefined';
  const isActive = isBrowser && mainLink.url === window.location.pathname;

  const link = linkResolver({
    type: mainLink.type,
    uid: mainLink.uid
  });
  return (
    <div>
      <NavLink
        className={isActive && 'active'}
        onClick={(event) => {
          toggleNav(event, mainLink.uid, link);
        }}
        to={link}
      >
        <NavText $type='main'>
          <NavIcon alt={navIcon?.alt} src={navIcon?.url} />
          {mainLinkText}
          {noInvoicesPaid && mainLinkText === 'Account' && (
            <NotificationDot height={8} width={8} src={notificationDot} />
          )}
        </NavText>

        {subNavItems && subNavItems.length > 0 && (
          <NavButton>
            <img
              src={expandedSection === mainLink.uid ? chevronUp : chevronDown}
            />
          </NavButton>
        )}
      </NavLink>
      {subNavItems && subNavItems.length > 0 && (
        <Collapse isOpen={expandedSection === mainLink.uid}>
          {subNavItems.map((subNavItem, index) => {
            const subNavUid = get(subNavItem, 'subnav_link.uid');
            const isActiveSub =
              isBrowser &&
              subNavItem.subnav_link.uid ===
                window.location.pathname.split('/')[3];
            const subNavText = subNavItem.subnav_link_text.toLowerCase();
            const shouldDisplayNotificationDot =
              noInvoicesPaid &&
              (subNavText.includes('membership') ||
                subNavText.includes('invoice') ||
                subNavText.includes('payment'));

            return (
              <NavLink
                className={isActiveSub && 'active'}
                key={index}
                to={linkResolver({
                  type: mainLink.type,
                  uid: subNavUid ? subNavUid : '/',
                  parentUid: mainLink.uid
                })}
              >
                <NavText $type='sub'>{subNavItem.subnav_link_text}</NavText>
                {shouldDisplayNotificationDot && (
                  <NotificationDot height={8} width={8} src={notificationDot} />
                )}
              </NavLink>
            );
          })}
        </Collapse>
      )}
    </div>
  );
}

NavItemContainer.propTypes = propTypes;
NavItemContainer.defaultProps = defaultProps;

export default NavItemContainer;
