import React from 'react';
import {
  PrismicPreviewProvider,
  componentResolverFromMap
} from 'gatsby-plugin-prismic-previews';
import { Provider } from 'react-redux';

import SessionCheck from './src/components/SessionCheck';
import createStore from './src/state/createStore';
import Homepage from './src/pages';
import NewsItem from './src/templates/NewsItem';
import NewsLandingPage from './src/templates/NewsLandingPage';
import NotificationsPage from './src/templates/notificationsPage';
import Page from './src/templates/page';
import Product from './src/templates/product';
import ProductsLandingPage from './src/templates/productsLandingPage';
import PromoLandingPage from './src/templates/promoLandingPage';
import linkResolver from './src/utils/linkResolver';
import CommunityPortalLandingPage from './src/templates/CommunityPortalLandingPage';
import CommunityPortalPage from './src/templates/CommunityPortalPage';
import InstitutionsPage from './src/templates/InstitutionsPage';
import RegistrationLandingPage from './src/templates/RegistrationLandingPage';
import RegistrationFlow from './src/templates/RegistrationFlow';
import ProductArchive from './src/templates/ProductArchive';
import ProductArchiveLandingPage from './src/templates/ProductArchiveLandingPage';

export default ({ element, extractor }) => {
  const store = createStore();
  const isBrowser = typeof window !== 'undefined';

  return (
    <>
      {isBrowser ? (
        <Provider store={store}>
          <SessionCheck>
            <PrismicPreviewProvider
              repositoryConfigs={[
                {
                  repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
                  linkResolver,
                  componentResolver: componentResolverFromMap({
                    homepage: Homepage,
                    news_item: NewsItem,
                    news_landing_page: NewsLandingPage,
                    notifications_page: NotificationsPage,
                    standard_page: Page,
                    institutions_page: InstitutionsPage,
                    product: Product,
                    products_landing_page: ProductsLandingPage,
                    product_archive: ProductArchive,
                    product_archive_landing_page: ProductArchiveLandingPage,
                    promo_landing_page: PromoLandingPage,
                    community_portal_landing_page: CommunityPortalLandingPage,
                    community_portal_page: CommunityPortalPage,
                    registration_landing_page: RegistrationLandingPage,
                    registration_flow: RegistrationFlow
                  })
                }
              ]}
            >
              {element}
            </PrismicPreviewProvider>
          </SessionCheck>
        </Provider>
      ) : (
        <Provider store={store}>
          <PrismicPreviewProvider
            repositoryConfigs={[
              {
                repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
                linkResolver,
                componentResolver: componentResolverFromMap({
                  homepage: Homepage,
                  news_item: NewsItem,
                  news_landing_page: NewsLandingPage,
                  notifications_page: NotificationsPage,
                  standard_page: Page,
                  institutions_page: InstitutionsPage,
                  product: Product,
                  products_landing_page: ProductsLandingPage,
                  product_archive: ProductArchive,
                  product_archive_landing_page: ProductArchiveLandingPage,
                  promo_landing_page: PromoLandingPage,
                  community_portal_landing_page: CommunityPortalLandingPage,
                  community_portal_page: CommunityPortalPage,
                  registration_landing_page: RegistrationLandingPage,
                  registration_flow: RegistrationFlow
                })
              }
            ]}
          >
            {/* extractor.collectChunks() will wrap the application in a ChunkExtractorManager */}
            {extractor.collectChunks(element)}
          </PrismicPreviewProvider>
        </Provider>
      )}
    </>
  );
};
