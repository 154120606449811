import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';

import redX from '../../../../../images/icons/red-x.svg';

const Button = styled.button`
  margin-top: 16px;
  align-self: center;
  border: none;
  background: none;
  padding: 0;
  position: absolute;
  right: -40px;

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    right: 10.5px;
    top: -44px;
  }
`;

const propTypes = { onClick: PropTypes.func };

const defaultProps = { onClick: () => {} };

function DeleteButton({ onClick }) {
  return (
    <Button type='button' onClick={onClick}>
      <img src={redX} height='16' width='16' />
    </Button>
  );
}

DeleteButton.propTypes = propTypes;
DeleteButton.defaultProps = defaultProps;

export default DeleteButton;
