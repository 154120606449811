import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';

const MainCol = styled.div`
  position: relative;
  flex: 0 0 100%;
  max-width: 100%;

  ${ifProp(
    { $page: 'Homepage' },
    css`
      padding-bottom: 0;
    `
  )}

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    display: flex;
  }

  .block {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    @media (max-width: ${prop('theme.breakpoints.sm')}) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    * {
      &:last-child {
        margin-bottom: 0;
      }

      &:only-child {
        margin-bottom: 0;
      }
    }
  }

  > div {
    &:first-child {
      padding-top: 0;
    }
  }

  > p {
    margin-bottom: 0.625rem;
  }
`;

export default MainCol;
