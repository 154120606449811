import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// whatwg-fetch is a polyfill for Promise-based fetch calls
import 'whatwg-fetch';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { loadableReady } from '@loadable/component';
import { render } from 'react-dom';

import wrapProvider from './wrapProvider';

export const wrapRootElement = wrapProvider;

export const replaceHydrateFunction = () => (element, container, callback) => {
  loadableReady(() => {
    render(element, container, callback);
  });
};
