import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import SEO from '../components/seo';
import Layout from '../components/layout';
import PageContainer from '../components/layout-components/PageContainer';
import PageBody from '../components/PageBody';
import PriceFeeds from '../components/PriceFeeds';
import MainCol from '../components/PageBody/MainCol';
import SideRailsMenu from '../components/SideRailsMenu';
import ColumnWrapper from '../components/PageBody/styled/ColumnWrapper';
import { GatsbyIpfFileProvider } from '../context/GatsbyIpfFileContext';

const propTypes = {
  data: PropTypes.object
};

const defaultProps = {
  data: null
};

function ProductsLandingPage({ data: queryResult }) {
  const pageData = queryResult.prismicProductsLandingPage.data;
  const sideRailsMenuData = { isProductsMenu: true };

  if (!pageData) {
    return null;
  }

  const { page_title, side_nav_header, header_banner, body } = pageData;
  const pageTitle = page_title.text ? page_title.text : '';

  return (
    <Layout headerBanner={header_banner && header_banner.url} title={pageTitle}>
      <GatsbyIpfFileProvider>
        <PageContainer>
          <SideRailsMenu
            title={side_nav_header || pageTitle}
            data={sideRailsMenuData}
          />
          <MainCol>
            <ColumnWrapper>
              {body && <PageBody body={body} template={'products-landing'} />}
            </ColumnWrapper>
          </MainCol>
          <PriceFeeds />
        </PageContainer>
      </GatsbyIpfFileProvider>
    </Layout>
  );
}

export const Head = ({ data: queryResult }) => {
  const pageData = queryResult.prismicProductsLandingPage.data;

  if (!pageData) {
    return null;
  }

  const { seo_title, seo_meta_description, seo_image, page_title } = pageData;

  return (
    <SEO
      title={seo_title || page_title.text}
      description={seo_meta_description}
      image={seo_image}
    />
  );
};

export const query = graphql`
  query ProductsLandingPageQuery($id: String!) {
    prismicProductsLandingPage(id: { eq: $id }) {
      _previewable
      data {
        page_title {
          text
        }
        side_nav_header
        header_banner {
          url
          dimensions {
            height
            width
          }
        }
        seo_title
        seo_meta_description
        seo_image {
          url
        }
        body {
          ... on PrismicProductsLandingPageDataBodyTextBlock {
            slice_type
            primary {
              text_block_header {
                richText
                text
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              text_block_content {
                richText
                text
                html
              }
            }
          }
          ... on PrismicProductsLandingPageDataBodyTwoColumnsVideo {
            slice_type
            primary {
              embedded_video {
                uri
              }
            }
            items {
              text_block {
                richText
                text
              }
              title {
                richText
                text
              }
              additional_text {
                richText
                text
              }
            }
          }
          ... on PrismicProductsLandingPageDataBodyTwoColumns {
            slice_type
            slice_label
            items {
              image {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
              text_block {
                richText
                text
              }
              title {
                richText
                text
              }
              image_display_style
            }
          }
          ... on PrismicProductsLandingPageDataBodyTwoColumnsImage {
            slice_type
            slice_label
            primary {
              title {
                richText
                text
              }
              description {
                richText
                text
              }
              orientation
              block_cta_text
              cta_position
              block_cta {
                link_type
                target
                url
                type
                uid
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              additional_text {
                richText
                text
              }
              image {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
              text_block {
                richText
                text
              }
              title {
                richText
                text
              }
              image_margin
              image_link {
                url
              }
              background_style
            }
          }
          ... on PrismicProductsLandingPageDataBodyLimitedTimeOffer {
            slice_label
            slice_type
            primary {
              background_image {
                url
                dimensions {
                  height
                  width
                }
              }
              offer_list {
                richText
                text
              }
              text_block {
                richText
                text
              }
              offer_button {
                url
              }
              offer_type
            }
          }
          ... on PrismicProductsLandingPageDataBodyProductCta {
            slice_type
            primary {
              button_link {
                url
              }
              button_text {
                text
              }
              cta_description {
                text
              }
              cta_header {
                text
              }
            }
            items {
              column_image {
                url
                dimensions {
                  height
                  width
                }
              }
              column_description {
                text
              }
              column_title {
                text
              }
            }
          }
          ... on PrismicProductsLandingPageDataBodyTable10Cols {
            slice_type
            primary {
              table_title {
                richText
                text
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
              table_type
              table_description {
                richText
                text
              }
              tooltip {
                richText
                text
              }
              column_width
              block_styles_side_nav
              col1 {
                richText
                text
              }
              col1_span
              col2 {
                richText
                text
              }
              col3 {
                richText
                text
              }
              col3_span
              col4 {
                richText
                text
              }
              col5 {
                richText
                text
              }
              col6 {
                richText
                text
              }
              col7 {
                richText
                text
              }
              col8 {
                richText
                text
              }
              col9 {
                richText
                text
              }
              col10 {
                richText
                text
              }
              tooltip_col1 {
                richText
                text
              }
              tooltip_col2 {
                richText
                text
              }
            }
            items {
              col1_data {
                richText
                text
              }
              col2_data {
                richText
                text
              }
              col3_data {
                richText
                text
              }
              col4_data {
                richText
                text
              }
              col5_data {
                richText
                text
              }
              col6_data {
                richText
                text
              }
              col7_data {
                richText
                text
              }
              col8_data {
                richText
                text
              }
              col9_data {
                richText
                text
              }
              col10_data {
                richText
                text
              }
            }
          }
        }
      }
    }
  }
`;

ProductsLandingPage.propTypes = propTypes;
ProductsLandingPage.defaultProps = defaultProps;

export default withPrismicPreview(ProductsLandingPage);
