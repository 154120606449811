import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';

import ButtonLink from '../ButtonLink';

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    flex-direction: row;
  }

  a {
    &:last-child {
      margin-left: 0;
      margin-top: 1rem;

      @media (min-width: ${prop('theme.breakpoints.sm')}) {
        margin-left: 1.5rem;
        margin-top: 0;
      }
    }
  }
`;

const propTypes = {
  fields: PropTypes.array
};

const defaultProps = {
  fields: []
};

function CtaButtons({ fields }) {
  const ctaButtons = fields && fields.length;

  return (
    <>
      {ctaButtons > 0 && (
        <ButtonContainer>
          {fields.map((field, index) => {
            const ctaStyle = field.cta_style;
            const ctaFormat = ctaStyle.includes('outline') ? 'outline' : 'fill';

            return (
              <ButtonLink
                format={ctaFormat}
                link={field.cta_link}
                key={index}
                size={200}
                style={field.cta_style}
              >
                {field.cta_text}
              </ButtonLink>
            );
          })}
        </ButtonContainer>
      )}
    </>
  );
}

CtaButtons.propTypes = propTypes;
CtaButtons.defaultProps = defaultProps;

export default CtaButtons;
