import React from 'react';
import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';

const ListItem = styled.li`
  display: list-item;
  font-weight: ${prop('theme.fontWeights.regular')};
  line-height: 1.5rem;

  ${ifProp(
    'highlight',
    css`
      font-size: 1rem;
      font-weight: ${prop('theme.fontWeights.semiBold')};
    `
  )}
`;

export default ListItem;
