import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SiteSettingsContext from '../../../context/SiteSettingsContext';
import {
  DATA_SOURCE_FUTURES,
  DATA_SOURCE_INDEX
} from '../../../utils/constants';
import MonthContext from '../../../context/MonthContext';
import MultiButtonToggle from '../../MultiButtonToggle';

export const ToggleContainer = styled.div`
  margin: 0 1.5rem;
`;

export const displayModes = {
  SYMBOL: 'symbol',
  MONTH_NAME: 'month_name'
};

const propTypes = {
  symbol: PropTypes.string,
  displayMode: PropTypes.string,
  justify: PropTypes.string
};

const defaultProps = {
  symbol: '',
  displayMode: displayModes.SYMBOL,
  justify: 'right'
};

function MonthToggle({ symbol, displayMode, justify }) {
  const siteSettings = useContext(SiteSettingsContext);
  const { selectedMonth, setSelectedMonth, monthOptions } = useContext(
    MonthContext
  );

  // Determine whether we show index data or futures data based on global site setting value.
  const dataSource =
    (siteSettings.products[symbol] &&
      siteSettings.products[symbol].price_movement_widget_data_source) ||
    DATA_SOURCE_INDEX;

  return (
    <>
      {dataSource === DATA_SOURCE_FUTURES ? (
        <ToggleContainer>
          <MultiButtonToggle
            justify={justify}
            options={monthOptions.map((item) => {
              const trimmedSymbol = item.symbol.split(':')[0].substr(1);
              const displaySymbol = `${trimmedSymbol.substr(
                0,
                trimmedSymbol.length - 3
              )} ${trimmedSymbol.substr(trimmedSymbol.length - 3)}`;

              return {
                text:
                  displayMode === displayModes.SYMBOL
                    ? displaySymbol
                    : item.month,
                selected: selectedMonth.month === item.month,
                onClick: () => {
                  setSelectedMonth(item);
                }
              };
            })}
          />
        </ToggleContainer>
      ) : null}
    </>
  );
}

MonthToggle.propTypes = propTypes;
MonthToggle.defaultProps = defaultProps;

export default MonthToggle;
