import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${props => `${props.height}px`};
  ${props =>
    props.boxShadow
      ? 'box-shadow: -0.0625rem 0.0625rem 0.375rem rgba(0, 0, 0, 0.16)'
      : null};
`;

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  height: PropTypes.string,
  boxShadow: PropTypes.bool,
};

const defaultProps = {
  children: [],
  height: '100',
  boxShadow: false,
};

function SpinnerContainer({ children, height, boxShadow }) {
  return (
    <StyledDiv height={height} boxShadow={boxShadow}>
      {children}
    </StyledDiv>
  );
}

SpinnerContainer.propTypes = propTypes;
SpinnerContainer.defaultProps = defaultProps;

export default SpinnerContainer;
