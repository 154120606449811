import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';

import BlockStyles from '../../../BlockStyles';
import StripeCheckoutButton from '../StripeCheckoutButton';

const propTypes = { data: PropTypes.object, template: PropTypes.string };

const defaultProps = { data: {}, template: '' };

function StripeCheckoutButtonBlock({ data, template }) {
  return (
    <BlockStyles data={data.primary} template={template}>
      <StripeCheckoutButton buttonText={data.primary.button_text} />
    </BlockStyles>
  );
}

StripeCheckoutButtonBlock.propTypes = propTypes;
StripeCheckoutButtonBlock.defaultProps = defaultProps;

export default StripeCheckoutButtonBlock;
