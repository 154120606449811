import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';
import { ifProp, prop, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import { Collapse } from 'reactstrap';
import get from 'lodash-es/get';

import BlockStyles from '../../BlockStyles';
import upArrow from '../../../images/arrows/red-up-arrow.svg';
import downArrow from '../../../images/arrows/down-arrow.svg';
import { isNullValue } from '../../../utils/functions';
import linkResolver from '../../../utils/linkResolver';
import { graphql, useStaticQuery } from 'gatsby';
import { TAG_PORTAL_CHILD_PAGE } from '../../../utils/constants';

const BlockContainer = styled.div`
  &&& {
    h2 {
      ${ifProp(
        { $template: 'community-portal' },
        css`
          font-weight: ${prop('theme.fontWeights.semiBold')};
          font-size: 1.625rem;
        `,
        css`
          margin-bottom: 44px;
        `
      )}
    }

    h4 {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 1.125rem;
      font-weight: ${prop('theme.fontWeights.semiBold')};
    }
  }

  ${ifProp(
    '$collapsible',
    css`
      border-bottom: 1px solid ${prop('theme.colors.borderGrey')};
    `
  )}

  ${switchProp('$backgroundColor', {
    grey: css`
      background-color: ${prop('theme.colors.backgroundGreyCommunity')};
    `,
    white: css`
      background-color: ${prop('theme.colors.white')};
    `
  })}
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${ifProp(
    '$clickable',
    css`
      cursor: pointer;
      padding-bottom: 16px;
    `
  )}

  &&& {
    h3 {
      font-size: 1.125rem;
      line-height: 32px;
      font-weight: ${prop('theme.fontWeights.medium')};
      margin: 0;
    }

    h4 {
      line-height: 20px;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: ${(props) => props.$alignment || 'flex-start'};

  img {
    margin-left: 20px;
    margin-right: 44px;
  }

  ul {
    padding-left: 22.4px;
  }

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    flex-direction: column;

    img {
      margin: 0 0 32px;
    }
  }
`;

const TextContainer = styled.div`
  ${ifProp(
    { $template: 'registration-landing' },
    css`
      p {
        @media (min-width: ${prop('theme.breakpoints.xl')}) {
          font-size: 1.125rem;
          line-height: 24px;
        }
      }
    `
  )}

  h3 {
    font-size: 1.375rem;
    font-weight: ${prop('theme.fontWeights.semiBold')};
  }

  h4 {
    margin-top: 48px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.125rem;
    font-weight: ${prop('theme.fontWeights.semiBold')};
  }

  img {
    height: auto;
    width: 100%;
  }
`;

const propTypes = { data: PropTypes.object, template: PropTypes.string };

const defaultProps = { data: {}, template: 'registration' };

/*
  The existing TextBlock component allows for a lot of functionality that the registration form just doesn't need.
  As a result, we created this component to just render a basic text block using RichText. We can expand this if
  needed, or just get rid of it altogether if we want to just use the existing TextBlock in the future.
*/
function RegistrationTextBlock({ data, template = 'registration' }) {
  const {
    text_block_content,
    text_block_title,
    image,
    background_color,
    collapsible,
    alignment
  } = data.primary;
  if (!text_block_content || isNullValue(text_block_content.text)) {
    return null;
  }

  const [isExpanded, setIsExpanded] = useState(!collapsible);

  const queryResult = useStaticQuery(graphql`
    query CommunityPortalQuery {
      allPrismicCommunityPortalPage {
        edges {
          node {
            uid
            data {
              has_parent_page
              parent_page {
                uid
              }
            }
          }
        }
      }
    }
  `);
  const communityPortalPages = get(
    queryResult,
    'allPrismicCommunityPortalPage.edges',
    []
  ).map((edge) => edge.node);

  return (
    <BlockStyles data={data.primary} template={template}>
      <BlockContainer
        className={template === 'registration-landing' ? 'block' : ''}
        $backgroundColor={background_color}
        $collapsible={collapsible}
        $template={template}
      >
        <Title
          $clickable={collapsible}
          onClick={() => {
            if (collapsible) {
              setIsExpanded((prev) => !prev);
            }
          }}
        >
          {text_block_title && text_block_title.text && (
            <PrismicRichText field={text_block_title.richText} />
          )}
          {collapsible && (
            <img
              src={isExpanded ? upArrow : downArrow}
              className={isExpanded ? 'expanded-icon' : 'collapsed-icon'}
            />
          )}
        </Title>
        <Collapse isOpen={isExpanded}>
          <ContentContainer $alignment={alignment}>
            {image && image.url && (
              <img
                src={image.url}
                height={image.dimensions && image.dimensions.height}
                width={image.dimensions && image.dimensions.width}
                alt={image.alt}
              />
            )}
            <TextContainer>
              <PrismicRichText
                field={text_block_content.richText}
                linkResolver={(doc) => {
                  let parentUid;
                  if (doc.tags.includes(TAG_PORTAL_CHILD_PAGE)) {
                    const pageIndex = communityPortalPages.findIndex(
                      (page) => page.uid === doc.uid
                    );
                    if (pageIndex >= 0) {
                      parentUid = get(
                        communityPortalPages[pageIndex],
                        'data.parent_page.uid'
                      );
                    }
                  }

                  return linkResolver({
                    uid: doc.uid,
                    type: doc.type,
                    parentUid: parentUid
                  });
                }}
              />
            </TextContainer>
          </ContentContainer>
        </Collapse>
      </BlockContainer>
    </BlockStyles>
  );
}

RegistrationTextBlock.propTypes = propTypes;
RegistrationTextBlock.defaultProps = defaultProps;

export default RegistrationTextBlock;
