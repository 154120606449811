import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';
import BlockStyles from '../../BlockStyles';

export const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding: 16px 32px;
  border: 2px solid
    ${(props) =>
      props.$borderColor
        ? prop(`theme.colors.${props.$borderColor}`)
        : prop('theme.colors.green')};

  img {
    margin-right: 16px;
  }

  &&& {
    p {
      margin: 0;
    }
  }
`;

const propTypes = { data: PropTypes.object, template: PropTypes.string };

const defaultProps = { data: {}, template: 'registration' };

function Alert({ data, template }) {
  if (!data) {
    return null;
  }

  const { image, text_content, border_color } = data.primary;

  return (
    <BlockStyles template={template || 'registration'}>
      <AlertContainer $borderColor={border_color}>
        {image && image.url && (
          <img
            src={image.url}
            alt={image.alt}
            height={image.dimensions && image.dimensions.height}
            width={image.dimensions && image.dimensions.width}
          />
        )}
        <PrismicRichText field={text_content.richText} />
      </AlertContainer>
    </BlockStyles>
  );
}

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;

export default Alert;
