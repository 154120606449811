import { useEffect } from 'react';

import theme from '../styles/theme';

function useResize(isMobile, setIsMobile) {
  useEffect(() => {
    let currentMobile = isMobile;
    const resizeMobile = () => {
      const mobileBreak = parseInt(theme.breakpoints.lg, 10);
      const vw = window.innerWidth;
      const mobileViewport = vw <= mobileBreak;
      if (mobileViewport !== currentMobile) {
        setIsMobile(mobileViewport);
        currentMobile = mobileViewport;
      }
    };

    if (window && window.innerWidth) {
      resizeMobile();
      window.addEventListener('resize', resizeMobile);
    }
    return () => {
      window.removeEventListener('resize', resizeMobile);
    };
  }, []);
}

export default useResize;
