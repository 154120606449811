import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

const ButtonContainer = styled.div`
  display: flex;
  height: fit-content;
  align-items: center;

  ${ifProp(
    { $justify: 'left' },
    css`
      justify-content: flex-start;
    `,
    css`
      justify-content: flex-end;
    `
  )}
`;

const ToggleButton = styled.button`
  min-width: ${(props) => (props.$minWidth ? props.$minWidth : 100)}px;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  text-transform: uppercase;
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : 0.75)}rem;
  display: flex;
  justify-content: center;
  padding: 1px 4px 0;
  vertical-align: text-bottom;

  ${ifProp(
    { selected: true },
    css`
      background-color: ${prop('theme.colors.white')};
      border: 1px solid ${prop('theme.colors.black')};
    `,
    css`
      background-color: ${prop('theme.colors.greyUnselected')};
      border: 1px solid ${prop('theme.colors.borderGrey')};
      font-weight: ${prop('theme.fontWeights.regular')};
    `
  )}

  &:first-child {
    border-radius: 24px 0 0 24px;
  }

  &:last-child {
    border-radius: 0 24px 24px 0;
  }

  &:only-child {
    border-radius: 24px;
  }
`;

const propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      selected: PropTypes.bool,
      onClick: PropTypes.fun
    })
  ),
  fontSize: PropTypes.number,
  minWidth: PropTypes.number,
  justify: PropTypes.string
};

const defaultProps = {
  options: { text: '', selected: true, onClick: () => {} },
  fontSize: 0.75,
  minWidth: 100,
  justify: 'right'
};

function MultiButtonToggle({ options, fontSize, minWidth, justify }) {
  return (
    <ButtonContainer $justify={justify}>
      {options.map((option) => (
        <ToggleButton
          key={option.text}
          selected={option.selected}
          onClick={option.onClick}
          $fontSize={fontSize}
          $minWidth={minWidth}
        >
          <span>{option.text}</span>
        </ToggleButton>
      ))}
    </ButtonContainer>
  );
}

MultiButtonToggle.propTypes = propTypes;
MultiButtonToggle.defaultProps = defaultProps;

export default MultiButtonToggle;
