import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { useSelector } from 'react-redux';

import SideRailHeader from '../SideRailsMenu/styled/SideRailHeader';
import Tooltip from '../Tooltip';
import { pageModes } from '../../utils/constants';

const HeaderContainer = styled.div`
  padding: 1.75rem 1.5rem 0;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    display: none;
  }
`;

const IndexPriceHeader = styled(SideRailHeader)`
  position: relative;

  &&& {
    font-size: 1.125rem;
    margin-bottom: 0;
  }

  a {
    font-size: 0.875rem;
    position: absolute;
    top: -0.375rem;
  }
`;

const propTypes = {
  indexTooltip: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  symbol: PropTypes.string
};

const defaultProps = {
  indexTooltip: {},
  symbol: ''
};

function FeedHeader({ indexTooltip, symbol }) {
  const triggerValue = 'index-price-' + Math.floor(Math.random() * 200);
  const pageMode = useSelector(
    (state) => state.pageModes && state.pageModes[symbol]
  );

  return (
    <HeaderContainer>
      <IndexPriceHeader>
        {pageMode === pageModes.QUOTE ? 'Time Series' : 'Prices'}
        {indexTooltip && (
          <Tooltip content={indexTooltip} triggerValue={triggerValue} />
        )}
      </IndexPriceHeader>
    </HeaderContainer>
  );
}

FeedHeader.propTypes = propTypes;
FeedHeader.defaultProps = defaultProps;

export default FeedHeader;
