import React from 'react';
import PropTypes from 'prop-types';
import { ifProp, prop, switchProp } from 'styled-tools';
import styled, { css } from 'styled-components';
import { PrismicRichText } from '@prismicio/react';

import CtaButtons from './CtaButtons';

const CtaBlockContainer = styled.div`
  text-align: center;
`;

const OverlayContainer = styled.div`
  position: relative;
`;

const ImageOverlay = styled.img`
  display: block;
  height: 100%;
  min-height: 25rem;
  object-fit: cover;
  width: 100%;
`;

const ContentContainer = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  padding: 1.25rem;
  position: absolute;
  right: 0;
  top: 0;

  ${switchProp('$overlay', {
    dark: css`
      background-color: rgba(0 0 0 / 25%);
    `,
    light: css`
      background-color: rgba(255 255 255 / 25%);
    `
  })}

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    padding: 1.25rem 17.5rem;
  }
`;

const Title = styled.div`
  h2,
  h3,
  h4 {
    font-weight: ${prop('theme.fontWeights.regular')};
    margin-bottom: 2rem;

    ${switchProp('$overlay', {
      dark: css`
        color: ${prop('theme.colors.white')};
      `,
      light: css`
        color: ${prop('theme.colors.black')};
      `
    })}
  }
`;

const Body = styled.div`
  margin: 0 auto;
  max-width: 40.5rem;

  p {
    font-weight: ${prop('theme.fontWeights.light')};
    line-height: 1.5rem;
    margin-bottom: 2rem;

    ${switchProp('$overlay', {
      dark: css`
        color: ${prop('theme.colors.white')};
      `,
      light: css`
        color: ${prop('theme.colors.black')};
      `
    })}
  }
`;

const propTypes = {
  content: PropTypes.object,
  fields: PropTypes.array,
  template: PropTypes.string
};

const defaultProps = {
  content: {},
  fields: [],
  template: ''
};

function CtaBlock({ content, fields, template }) {
  const {
    background_image: backgroundImage,
    image_overlay: imageOverlay,
    text_block: textBlock,
    title
  } = content;
  const blockTitle = title && title.text;
  const blockId = blockTitle ? blockTitle.replace(/\s/g, '') : '';

  return (
    <CtaBlockContainer>
      <OverlayContainer>
        <ImageOverlay
          alt={backgroundImage.alt}
          src={backgroundImage.url}
          height={
            backgroundImage.dimensions && backgroundImage.dimensions.height
          }
          width={backgroundImage.dimensions && backgroundImage.dimensions.width}
          loading='lazy'
        />
        <ContentContainer
          id={blockId}
          $overlay={imageOverlay}
          className='anchorOffset'
        >
          {title.text && (
            <Title $overlay={imageOverlay}>
              <PrismicRichText field={title.richText} />
            </Title>
          )}
          {textBlock.text && (
            <Body $overlay={imageOverlay}>
              <PrismicRichText field={textBlock.richText} />
            </Body>
          )}
          <CtaButtons fields={fields} />
        </ContentContainer>
      </OverlayContainer>
    </CtaBlockContainer>
  );
}

CtaBlock.propTypes = propTypes;
CtaBlock.defaultProps = defaultProps;

export default CtaBlock;
