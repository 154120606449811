import React from 'react';
import PropTypes from 'prop-types';

import BlockStyles from '../BlockStyles';
import ButtonLink from '.';
import styled, { css } from 'styled-components';
import { switchProp } from 'styled-tools';
import { TAG_PORTAL_CHILD_PAGE } from '../../utils/constants';

const ButtonContainer = styled.div`
  display: flex;

  ${switchProp('$alignment', {
    left: css`
      justify-content: flex-start;
    `,
    right: css`
      justify-content: flex-end;
    `
  })}
`;

const propTypes = {
  data: PropTypes.object,
  primary: PropTypes.object,
  template: PropTypes.string,
  options: PropTypes.object
};

const defaultProps = { data: {}, primary: {}, template: '', options: {} };

function ButtonLinkBlock({ data, primary, template, options = {} }) {
  const primaryObject =
    primary && Object.keys(primary).length > 0 ? primary : data.primary;
  const {
    button_link,
    button_text,
    button_style,
    font_weight,
    min_width,
    alignment
  } = primaryObject;

  const { queryParameters } = options;

  if (!button_text || !button_link.url) {
    return null;
  }

  if (button_link.tags && button_link.tags.includes(TAG_PORTAL_CHILD_PAGE)) {
    button_link.parentUid = button_link.document.data.parent_page.uid;
  }

  const format = button_style.split('-')[0];
  return (
    <BlockStyles data={primaryObject} template={template}>
      <ButtonContainer
        className={template !== 'registration' ? 'block' : ''}
        $alignment={alignment}
      >
        <ButtonLink
          link={button_link}
          format={format}
          style={button_style}
          width={'fit-content'}
          size={min_width}
          fontWeight={font_weight}
          queryParameters={queryParameters}
        >
          {button_text}
        </ButtonLink>
      </ButtonContainer>
    </BlockStyles>
  );
}

ButtonLinkBlock.propTypes = propTypes;
ButtonLinkBlock.defaultProps = defaultProps;

export default ButtonLinkBlock;
