import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import get from 'lodash-es/get';
import { useDispatch, useSelector } from 'react-redux';

import RegistrationForm from '../RegistrationForm';
import StepContext from '../../../../context/StepContext';
import BlockStyles from '../../../BlockStyles';
import {
  createContactInformation,
  updateContactInformation
} from '../../../../state/actions/contactInfoActions';
import { setAddresses } from '../../../../state/actions/addressActions';
import ContactInfoFields from './ContactInfoFields';

const propTypes = { data: PropTypes.object };

const defaultProps = { data: {} };

function ContactInfoForm({ data }) {
  const { setCurrentStep } = useContext(StepContext);
  const {
    form_title,
    home_address_title,
    additional_address_title
  } = data.primary;
  const dispatch = useDispatch();
  const { storedAddresses, contactInformation } = useSelector((state) => ({
    storedAddresses: get(state, 'memberInfo.addresses', []),
    contactInformation: get(state, 'memberInfo.contactInformation', null)
  }));
  const [isContactInfoSubmitting, setIsContactInfoSubmitting] = useState(false);
  const [isContactInfoComplete, setIsContactInfoComplete] = useState(false);
  const [isAddressSubmitting, setIsAddressSubmitting] = useState(false);
  const [isAddressComplete, setIsAddressComplete] = useState(false);

  const useFormObject = useForm({
    mode: 'onChange'
  });
  const {
    handleSubmit,
    formState: { isValid }
  } = useFormObject;

  useEffect(() => {
    if (isContactInfoComplete && isAddressComplete) {
      setCurrentStep((prev) => prev + 1);
    }
  }, [isContactInfoComplete, isAddressComplete]);

  const onPreviousBehavior = {
    type: 'button',
    onClick: () => {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const createOrUpdateContactInformation = (phoneNumber) => {
    setIsContactInfoSubmitting(true);
    if (contactInformation && contactInformation.phoneNumber) {
      dispatch(
        updateContactInformation(
          contactInformation,
          { phoneNumber: phoneNumber },
          () => setIsContactInfoComplete(true),
          () => setIsContactInfoSubmitting(false)
        )
      );
    } else {
      dispatch(
        createContactInformation(
          { phoneNumber: phoneNumber },
          () => setIsContactInfoComplete(true),
          () => setIsContactInfoSubmitting(false)
        )
      );
    }
  };

  const createUpdateOrDeleteAddresses = (data) => {
    setIsAddressSubmitting(true);
    const formattedAddresses = data.addresses.map((address) => {
      const newAddress = { ...address };
      newAddress.addressType = newAddress.addressType.value;
      newAddress.country = newAddress.country.value;

      // if country is not US, state will be text input, not a select
      // extra formatting will only be needed if the state is a select (i.e. if it has a value property)
      if (newAddress.state.value) {
        newAddress.state = newAddress.state.value;
      }
      return newAddress;
    });

    if (storedAddresses.length > 0) {
      const existingAddresses = [];
      const newAddresses = [];

      formattedAddresses.forEach((formattedAddress) => {
        const foundIndex = storedAddresses.findIndex(
          (storedAddress) =>
            storedAddress.addressType === formattedAddress.addressType
        );

        if (foundIndex > -1) {
          existingAddresses.push({
            id: storedAddresses[foundIndex].id,
            ...formattedAddress
          });
        } else {
          newAddresses.push(formattedAddress);
        }
      });

      const deletedAddresses = storedAddresses.filter((storedAddress) => {
        const foundIndex = existingAddresses.findIndex(
          (existing) => existing.addressType === storedAddress.addressType
        );
        return foundIndex < 0;
      });

      dispatch(
        setAddresses(
          () => setIsAddressComplete(true),
          () => setIsAddressSubmitting(false),
          newAddresses,
          existingAddresses,
          deletedAddresses
        )
      );
    } else {
      dispatch(
        setAddresses(
          () => setIsAddressComplete(true),
          () => setIsAddressSubmitting(false),
          formattedAddresses
        )
      );
    }
  };

  const onSubmit = (data) => {
    createOrUpdateContactInformation(data.phoneNumber);
    createUpdateOrDeleteAddresses(data);

    if (isContactInfoComplete && isAddressComplete) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  return (
    <BlockStyles template='registration'>
      <RegistrationForm
        handleSubmit={handleSubmit(onSubmit)}
        onPreviousBehavior={onPreviousBehavior}
        isSubmitting={isContactInfoSubmitting || isAddressSubmitting}
        isValid={isValid}
      >
        <ContactInfoFields
          contactInformation={contactInformation}
          storedAddresses={storedAddresses}
          useFormObject={useFormObject}
          phoneTitle={form_title}
          homeAddressTitle={home_address_title}
          additionalAddressTitle={additional_address_title}
        />
      </RegistrationForm>
    </BlockStyles>
  );
}

ContactInfoForm.propTypes = propTypes;
ContactInfoForm.defaultProps = defaultProps;

export default ContactInfoForm;
