import actions from '../actions';

const pageModeReducer = (state = {}, action) => {
  if (!action || !action.type) {
    return state;
  }

  switch (action.type) {
    case actions.SET_PAGE_MODE:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default pageModeReducer;
