import { prop } from 'styled-tools';
import styled from 'styled-components';

import PageContainer from './PageContainer';

const CommunityContainer = styled(PageContainer)`
  background-color: ${prop('theme.colors.backgroundGreyCommunity')};
  min-height: calc(100vh - 108px);
`;

export default CommunityContainer;
