import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import theme from '../../../../styles/theme';
import { Controller } from 'react-hook-form';

const StyledSelect = styled(ReactSelect)`
  margin-top: 1rem;
  min-width: ${prop('$minWidth', 200)}px;

  ${ifProp(
    '$fillWidth',
    css`
      flex: 1 1 100%;
    `
  )}

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    flex: 1 1 100%;
    width: 100%;
  }
`;

const propTypes = {};

const defaultProps = {};

function Select(props) {
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      border: `1px solid ${theme.colors.inputGrey}`,
      borderRadius: '1rem',
      margin: '0'
    }),
    valueContainer: (provided) => ({
      ...provided,
      display: 'flex',
      padding: '1rem 1.5rem',
      maxHeight: '3.5rem',
      fontWeight: theme.fontWeights.medium
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: 0
    }),
    input: (provided) => ({
      ...provided,
      margin: 0,
      padding: 0
    }),
    placeholder: (provided) => ({
      ...provided,
      fontWeight: theme.fontWeights.regular,
      width: 'max-content'
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none'
    }),
    dropdownIndicator: (provided) => {
      return {
        ...provided,
        svg: {
          path: {
            fill: theme.colors.redPrimary
          }
        }
      };
    },
    menu: (provided) => ({
      ...provided,
      margin: '0.25rem 0 0 0',
      borderRadius: '1rem'
    }),
    menuList: (provided) => ({
      ...provided,
      margin: '1rem 0'
    })
  };

  return (
    <>
      {props.control ? (
        <Controller
          {...props}
          render={({ field }) => (
            <StyledSelect {...field} {...props} styles={selectStyles} />
          )}
        />
      ) : (
        <StyledSelect {...props} styles={selectStyles} />
      )}
    </>
  );
}

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
