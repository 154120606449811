import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { GatsbyIpfFileProvider } from '../context/GatsbyIpfFileContext';
import PageContainer from '../components/layout-components/PageContainer';
import { MonthProvider } from '../context/MonthContext';
import MainCol from '../components/PageBody/MainCol';
import ColumnWrapper from '../components/PageBody/styled/ColumnWrapper';
import ProductBody from '../components/ProductBody';
import { DateProvider } from '../context/DateContext';
import SideRailsMenu from '../components/SideRailsMenu';
import PriceFeeds from '../components/PriceFeeds';

const propTypes = { data: PropTypes.object };

const defaultProps = { data: {} };

function ProductArchive({ data: queryResult }) {
  const pageData = queryResult.prismicProductArchive.data;
  const {
    product_title,
    futures_symbol,
    end_date,
    header_banner,
    header_banner_symbol,
    seo_title,
    seo_meta_description,
    seo_image,
    body
  } = pageData;

  const bannerSymbol = header_banner_symbol
    ? header_banner_symbol
    : futures_symbol;

  return (
    <Layout
      symbol={bannerSymbol}
      title={product_title.text}
      headerBanner={header_banner && header_banner.url}
    >
      <GatsbyIpfFileProvider>
        <DateProvider endDate={end_date}>
          <MonthProvider symbol={futures_symbol}>
            <PageContainer>
              <SideRailsMenu
                title='Markets'
                titleUrl='/futures-markets'
                data={{ isProductsMenu: true }}
              />
              <MainCol>
                <ColumnWrapper>
                  <ProductBody
                    productName={product_title.text}
                    futures_symbol={futures_symbol}
                    pageBody={body}
                    template={'product'}
                    displayTitle
                    pageTitle={product_title}
                  />
                </ColumnWrapper>
              </MainCol>
              <PriceFeeds />
            </PageContainer>
          </MonthProvider>
        </DateProvider>
      </GatsbyIpfFileProvider>
    </Layout>
  );
}

export const Head = ({ data: queryResult }) => {
  const pageData = queryResult.prismicProductArchive.data;

  if (!pageData) {
    return null;
  }

  const {
    seo_title,
    seo_meta_description,
    seo_image,
    product_title
  } = pageData;

  return (
    <SEO
      title={seo_title || product_title.text}
      description={seo_meta_description}
      image={seo_image}
    />
  );
};

export const query = graphql`
  query ProductArchiveQuery($id: String!) {
    prismicProductArchive(id: { eq: $id }) {
      uid
      data {
        product_title {
          richText
          text
        }
        futures_symbol
        header_banner_symbol
        end_date
        header_banner {
          url
          dimensions {
            height
            width
          }
        }
        seo_title
        seo_meta_description
        seo_image {
          url
        }
        body {
          ... on PrismicProductArchiveDataBodyTextBlock {
            slice_type
            primary {
              text_block_header {
                richText
                text
              }
              tooltip {
                richText
                text
              }
              disclaimer_text {
                richText
                text
              }
              embedded_video {
                html
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              text_block_content {
                richText
                text
                html
              }
              button_text {
                text
              }
              button_link {
                url
                target
                type
                uid
                link_type
                tags
              }
            }
          }
          ... on PrismicProductArchiveDataBodyFuturesContractData {
            slice_type
            slice_label
            primary {
              contract_title {
                richText
                text
              }
              contract_description {
                richText
                text
              }
              contract_tooltip_title {
                richText
                text
              }
              contract_tooltip {
                richText
                text
              }
            }
          }
          ... on PrismicProductArchiveDataBodyHistoricalOptionsData {
            slice_type
            slice_label
            primary {
              options_title {
                richText
                text
              }
              description {
                richText
                text
              }
              tooltip_title {
                richText
                text
              }
              tooltip {
                richText
                text
              }
            }
            items {
              name
              dxfeed_value
              decimal_points
              default_value
            }
          }
        }
      }
    }
  }
`;

ProductArchive.propTypes = propTypes;
ProductArchive.defaultProps = defaultProps;

export default ProductArchive;
