import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { Follow } from 'react-twitter-widgets';
import { Spinner } from 'reactstrap';
import { Script } from 'gatsby';

import verifiedIcon from 'images/icons/twitter-verified-badge.svg';
import smallsLogo from 'images/smalls-default.png';
import SpinnerContainer from '../../SpinnerContainer';

const TimelineContainer = styled.div`
  background: ${prop('theme.colors.twitterFeedGrey')};
  border-radius: 8px;
  min-width: 260px;
  padding: 0 36px 36px;
  height: 100%;
`;

const TimelineHeader = styled.div`
  padding: 14px 0;

  > div {
    display: flex;
  }

  iframe {
    margin-left: 64px;
  }
`;

const Timeline = styled.a`
  color: ${prop('theme.colors.black')};
  pointer-events: none;

  &:hover {
    color: ${prop('theme.colors.black')};
  }
`;

const SmallsLogo = styled.img`
  height: 56px;
  margin-right: 8px;
  width: 56px;
`;

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
`;

const Title = styled.p`
  font-weight: ${prop('theme.fontWeights.bold')};
  margin-bottom: 0;
  padding-right: 4.8px;

  span {
    text-transform: uppercase;
  }
`;

const VerifiedIcon = styled.img`
  height: 16px;
  width: 16px;
`;

const FollowContainer = styled.div`
  margin-top: -14px;
`;

const propTypes = {
  height: PropTypes.number
};

const defaultProps = {
  height: 600
};

function TwitterTimeline({ height }) {
  const headerRef = useRef();
  const [totalHeight, setTotalHeight] = useState(height);

  useEffect(() => {
    if (
      headerRef.current &&
      height - headerRef.current.clientHeight !== totalHeight
    ) {
      setTotalHeight(height - headerRef.current.clientHeight);
    } else if (height !== totalHeight) {
      setTotalHeight(height);
    }
  }, [headerRef, height]);

  return (
    <>
      <Script>
        {`(window.twttr = (function(d, s, id) {
              var js,
                fjs = d.getElementsByTagName(s)[0],
                t = window.twttr || {};
              if (d.getElementById(id)) return t;
              js = d.createElement(s);
              js.id = id;
              js.src = 'https://platform.twitter.com/widgets.js';
              fjs.parentNode.insertBefore(js, fjs);

              t._e = [];
              t.ready = function(f) {
                t._e.push(f);
              };

              return t;
            })(document, 'script', 'twitter-wjs'));`}
      </Script>
      <TimelineContainer>
        <TimelineHeader ref={headerRef}>
          <div>
            <SmallsLogo alt='' src={smallsLogo} />
            <TitleContainer>
              <Title>
                the <span>small</span> exchange
              </Title>
              <VerifiedIcon alt='' src={verifiedIcon} />
            </TitleContainer>
          </div>
          <FollowContainer>
            <Follow
              username='small_exchange'
              options={{ showScreenName: false, showCount: false }}
            />
          </FollowContainer>
        </TimelineHeader>
        <Timeline
          className='twitter-timeline'
          href='https://twitter.com/small_exchange'
          data-height={totalHeight}
          data-chrome='transparent, noheader'
        >
          <SpinnerContainer>
            <Spinner variant='dark' />
          </SpinnerContainer>
        </Timeline>
      </TimelineContainer>
    </>
  );
}

TwitterTimeline.propTypes = propTypes;
TwitterTimeline.defaultProps = defaultProps;

export default TwitterTimeline;
