import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { PrismicRichText } from '@prismicio/react';

import linkResolver from '../../utils/linkResolver';

const TermIndicator = styled.span`
  border-bottom: 0.125rem dotted ${prop('theme.colors.greyDark')};
`;

const StyledTooltip = styled(Tooltip)`
  .tooltip {
    &.show {
      opacity: 1;
    }

    .arrow {
      &:before {
        border-top-color: ${prop('theme.colors.grey')};
      }
    }
  }

  .tooltip-inner {
    background-color: ${prop('theme.colors.grey')};
    max-width: 25rem;
    max-height: 25rem;
    overflow-y: auto;

    p {
      color: ${prop('theme.colors.black')};

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;

const propTypes = {
  text: PropTypes.string.isRequired,
  definition: PropTypes.object.isRequired
};

function Match({ text, definition }) {
  /*  This used to be a random string that was generated and passed down from
  dictionaryHtmlSerializer. But, it started to break the Tooltip upon hard reloads in Gatsby v3. 
  It's probably related to this issue (https://github.com/reactstrap/reactstrap/issues/773). 
  The random string was removed to make the Tooltip work. Plus, a random string 
  isn't needed since each dictionary term is unique. */
  const triggerValue = `dictionaryTerm-${text.replace(/ /g, '-')}`;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <TermIndicator id={triggerValue} href='#'>
        {text}
      </TermIndicator>
      <StyledTooltip
        trigger='click hover focus'
        placement='top'
        isOpen={tooltipOpen}
        target={triggerValue}
        toggle={toggle}
        flip={false}
        boundariesElement='window'
        autohide={false}
        // This modifier is required and behavior must match the placement prop above due to bug in reactstrap: https://github.com/reactstrap/reactstrap/issues/1482
        modifiers={{
          flip: { behavior: ['top'] }
        }}
      >
        <PrismicRichText
          field={definition.richText}
          linkResolver={linkResolver}
        />
      </StyledTooltip>
    </>
  );
}

Match.propTypes = propTypes;

export default Match;
