import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import { graphql, navigate } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import get from 'lodash-es/get';
import { PrismicRichText } from '@prismicio/react';
import { useSelector } from 'react-redux';

import SEO from '../components/seo';
import Layout from '../components/layout';
import PageBody from '../components/PageBody';
import Sidenav from '../components/Community/Portal/Sidenav';
import Breadcrumbs from '../components/Community/Portal/Breadcrumbs';
import { SubscriptionsProvider } from '../context/SubscriptionsContext';
import CommunityContainer from '../components/layout-components/CommunityContainer';
import MainSection from '../components/layout-components/MainSection';
import { Copyright } from '../components/Community/shared-styled-components';
import AuthenticatedWrapper from '../components/Community/AuthenticatedWrapper';
import ErrorPage from '../components/Community/Registration/ErrorPage';
import { fetchErrors } from '../state/actions/errorTypes';
import { isNullValue } from '../utils/functions';
import { PORTAL_ACCESS_INVOICE_STATUSES } from '../utils/constants';
import FullPageSpinnerContainer from '../components/SessionCheck/FullPageSpinnerContainer';
import Spinner from '../components/SessionCheck/Spinner';

const Header = styled.h1`
  font-size: 1.625rem;
  font-weight: ${prop('theme.fontWeights.medium')};
  line-height: 2rem;
  margin-bottom: 24px;
`;

const DisclaimerText = styled(Copyright)`
  margin-bottom: 16px;
`;

const propTypes = {
  data: PropTypes.object
};

const defaultProps = {
  data: null
};

function CommunityPortalPage({ data: queryResult, location }) {
  const pageData = queryResult.prismicCommunityPortalPage;

  if (!pageData) {
    return null;
  }

  const { body, has_parent_page, parent_page, page_title } = pageData.data;

  const disclaimer = get(
    queryResult,
    'allPrismicFooterComponent.edges[0].node.data.disclaimer_body'
  );

  const { identity, invoices, sponsorship } = useSelector(
    (state) => state.memberInfo
  );
  const isLoading = useSelector((state) => state.loading);
  const isAuthLoading = useSelector((state) =>
    state.auth ? state.auth.loading : false
  );
  const [userIsPermitted, setUserIsPermitted] = useState(false);

  // Here we are considering a sponsor_verifying invoice as "complete" in the sense that the user has done everything
  // we ask of them before we need to verify their eligibility
  const noInvoicesComplete =
    isNullValue(invoices) ||
    invoices.every(
      (invoice) => !PORTAL_ACCESS_INVOICE_STATUSES.includes(invoice.status)
    );
  const loadingIsComplete = !isLoading && !isAuthLoading;

  useEffect(() => {
    // There was some weird behavior with this redirect while logging a user in.
    // If a user has logged in, then identity will not be an empty object.
    // As such, we can use the identity object to determine if the user is actually logged in
    if (loadingIsComplete && Object.keys(identity).length > 0) {
      if (noInvoicesComplete) {
        const isSponsored =
          !!sponsorship && Object.keys(sponsorship).length > 0;
        navigate(
          isSponsored
            ? `/sponsorship-registration/?sponsorshipCode=${sponsorship.sponsorship_code}`
            : '/registration/'
        );
      } else {
        setUserIsPermitted(true);
      }
    }
  }, [loadingIsComplete, invoices, , sponsorship]);

  const apiErrors = useSelector((state) => state.apiErrors);
  const areFetchErrorsPresent = apiErrors
    ? Object.keys(apiErrors).some((error) => fetchErrors.includes(error))
    : false;

  // For some of the Info & FAQ pages, we want to allow unauthenticated users to view the information. We handle this by embedding the page body of a portal page into a Standard Page
  // However, we also have some components that we only want to display if the user is authenticated, so we filter them below
  const authenticatedDisplayModes = [
    'authenticated_only',
    'authenticated_and_unauthenticated'
  ];
  const filteredBody = (body || []).filter((bodySection) => {
    const displayMode = get(bodySection, 'primary.display_mode', null);

    // if display_mode is null/undefined, that means the component should show up everywhere, since we did not add a display_mode field in prismic
    return (
      isNullValue(displayMode) ||
      authenticatedDisplayModes.includes(displayMode)
    );
  });

  return (
    <AuthenticatedWrapper location={location}>
      {!userIsPermitted && !areFetchErrorsPresent ? (
        <FullPageSpinnerContainer>
          <Spinner />
        </FullPageSpinnerContainer>
      ) : (
        <Layout layout='portal'>
          <SubscriptionsProvider>
            <CommunityContainer>
              <Sidenav
                parentPage={parent_page}
                pageUid={pageData.uid}
                title={page_title}
              />
              <MainSection>
                {areFetchErrorsPresent ? (
                  <ErrorPage />
                ) : (
                  <div>
                    <Breadcrumbs
                      hasParentPage={has_parent_page}
                      parentPage={parent_page}
                      pageTitle={page_title}
                    />
                    <Header>{page_title.text}</Header>
                    {body && (
                      <PageBody
                        body={filteredBody}
                        pageUid={pageData.uid}
                        template={'community-portal'}
                      />
                    )}
                  </div>
                )}
                <DisclaimerText>
                  <PrismicRichText field={disclaimer.richText} />
                </DisclaimerText>
              </MainSection>
            </CommunityContainer>
          </SubscriptionsProvider>
        </Layout>
      )}
    </AuthenticatedWrapper>
  );
}

export const Head = ({ data: queryResult }) => {
  const pageData = queryResult.prismicCommunityPortalPage.data;

  if (!pageData) {
    return null;
  }

  const { seo_title, seo_meta_description, seo_image, page_title } = pageData;

  return (
    <>
      <meta name='robots' content='noindex' />
      <SEO
        title={seo_title || page_title.text}
        description={seo_meta_description}
        image={seo_image}
      />
    </>
  );
};

export const query = graphql`
  query CommunityPortalPageQuery($id: String!) {
    prismicCommunityPortalPage(id: { eq: $id }) {
      _previewable
      ...CommunityPortalPageFragment
    }
    allPrismicFooterComponent {
      edges {
        node {
          data {
            disclaimer_body {
              richText
            }
          }
        }
      }
    }
  }
`;

CommunityPortalPage.propTypes = propTypes;
CommunityPortalPage.defaultProps = defaultProps;

export default withPrismicPreview(CommunityPortalPage);
