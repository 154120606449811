import React from 'react';
import map from 'lodash-es/map';
import PropTypes from 'prop-types';

import StyledTd from './styled/StyledTd';
import { getTableTooltips } from '../../utils/functions';
import RichTextWithDictionary from '../RichTextWithDictionary';

const propTypes = {
  columns: PropTypes.object,
  head: PropTypes.object,
  headerKeys: PropTypes.array,
  rows: PropTypes.array
};

const defaultProps = {
  columns: {},
  head: {},
  headerKeys: [],
  rows: []
};

function TableRows({ columns, head, headerKeys, rows }) {
  const tableType = head.table_type || 'default';
  return map(rows, (row, index) => {
    const values = map(row, (column, columnKey) => {
      const matchColumnKey = columnKey.substring(0, 4);
      const columnSpan = head[`${matchColumnKey}_span`];

      const columnValue =
        (column &&
          column.richText.length > 0 &&
          column.richText[0].text !== '' &&
          column.richText[0]) ||
        undefined;
      const tableTooltips = getTableTooltips(
        columns[matchColumnKey],
        head,
        headerKeys
      );
      const cellArrayRich = [];
      const cellArrayRegular = [];

      if (columnValue) {
        if (typeof columnValue === 'object') {
          cellArrayRich.push(columnValue);
        } else {
          cellArrayRegular.push(column.text);
        }

        return (
          <StyledTd
            key={`tr_${index}_td_${columnKey}`}
            withtooltip={
              tableTooltips && tableTooltips.length > 0 ? 'true' : 'false'
            }
            withRichText={cellArrayRich.length > 0 ? 'true' : 'false'}
            $noRightBorder={columnSpan && columnSpan > 1}
            $tableType={tableType}
          >
            {cellArrayRich.length > 0 ? (
              <RichTextWithDictionary render={cellArrayRich} />
            ) : (
              <span>{cellArrayRegular}</span>
            )}
          </StyledTd>
        );
      }
    });

    return <tr key={`row-${index}`}>{values}</tr>;
  });
}

TableRows.propTypes = propTypes;
TableRows.defaultProps = defaultProps;

export default TableRows;
