import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { prop, ifNotProp, ifProp } from 'styled-tools';
import PropTypes from 'prop-types';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql, Link } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import get from 'lodash-es/get';

import SEO from '../components/seo';
import Layout from '../components/layout';
import PageContainer from '../components/layout-components/PageContainer';
import PageBody from '../components/PageBody';
import MainCol from '../components/PageBody/MainCol';
import {
  PageTitle,
  Panel,
  PanelContainer,
  StyledColumnWrapper,
  BodyContainer
} from '../components/Community/shared-styled-components';
import linkResolver from '../utils/linkResolver';
import RichTextWithDictionary from '../components/RichTextWithDictionary';
import { useDispatch } from 'react-redux';
import actions from '../state/actions';
import RegistrationLandingPageBody from '../components/Community/Registration/RegistrationLandingPageBody';
import { TAG_PORTAL_CHILD_PAGE } from '../utils/constants';

const Ribbon = styled.div`
  display: flex;
  position: absolute;
  padding: 0 16px;
  background-color: ${prop('theme.colors.white')};
  box-shadow: 0 0 5px ${prop('theme.colors.greyMedium')};
  border-radius: 24px 0 0 24px;
  width: 44%;
  top: 72px;
  right: 0;
  max-height: ${(props) =>
    props.$imageHeight ? props.$imageHeight + 64 : 128}px;
  padding: 32px 56px;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    top: 40px;
    width: 88%;

    ${ifNotProp(
      '$sponsored',
      css`
        display: none;
      `
    )}
  }

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    width: 96.5%;
  }

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    img {
      height: auto;
      width: 100%;
    }
  }
`;

const StyledPanelContainer = styled(PanelContainer)`
  ${ifProp(
    '$sponsored',
    css`
      @media (max-width: ${prop('theme.breakpoints.lg')}) {
        padding-top: 96px;
      }
    `
  )}
`;

const RightBodyContainer = styled(BodyContainer)`
  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    align-items: center;
  }

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin-top: 216px;
    margin-left: 40px;
  }
`;

const PreFooterLinks = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1 1 100%;
  margin-left: 24px;

  p {
    color: ${prop('theme.colors.textGrey')};
    margin-bottom: 0;
  }

  a {
    color: ${prop('theme.colors.textGrey')};
    font-size: 1rem;
    text-decoration: none;
    margin-right: 56px;

    &:last-child {
      margin: 0;
    }

    &:hover {
      text-decoration: underline;
      color: ${prop('theme.colors.textGrey')};
    }
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin-left: 0;
    justify-content: center;
  }
`;

const propTypes = { data: PropTypes.object };

const defaultProps = { data: null };

function RegistrationLandingPage({ data }) {
  const {
    page_title,
    sponsored,
    fine_print,
    ribbon_image,
    left_panel_pre_footer_links,
    right_panel_pre_footer_links,
    left_panel_body,
    right_panel_body
  } = data.prismicRegistrationLandingPage.data;

  const { uid } = data.prismicRegistrationLandingPage;

  const dispatch = useDispatch();

  if (sponsored) {
    dispatch({
      type: actions.SET_SPONSORSHIP_CODE,
      payload: uid.toUpperCase()
    });
  }

  const imageRef = useRef();
  const [imageHeight, setImageHeight] = useState(128);

  const getLinks = (preFooterLink) => {
    const { link_text, link } = preFooterLink;
    const { uid, type, link_type, url, target, tags, document } = link;
    const isDocumentLink = link_type === 'Document';
    const parentPage =
      tags &&
      tags.includes(TAG_PORTAL_CHILD_PAGE) &&
      get(document, 'data.parent_page.uid');

    const urlLink = isDocumentLink
      ? linkResolver({ uid: uid, type: type, parentUid: parentPage })
      : url;

    return isDocumentLink ? (
      <Link key={link_text} to={urlLink} target={target}>
        {link_text}
      </Link>
    ) : (
      <a key={link_text} href={urlLink} target={target}>
        {link_text}
      </a>
    );
  };

  useEffect(() => {
    if (imageRef.current) {
      setImageHeight(imageRef.current.clientHeight);
    }
  }, [imageRef]);

  const ribbonImageHeight =
    ribbon_image.dimensions && ribbon_image.dimensions.height;

  return (
    <Layout layout='two-column'>
      <PageContainer $page='registration-landing'>
        <MainCol>
          <StyledColumnWrapper>
            <Ribbon $sponsored={sponsored} $imageHeight={imageHeight}>
              <img
                src={ribbon_image.url}
                height={ribbonImageHeight > 100 ? 100 : ribbonImageHeight}
                width='auto'
                ref={imageRef}
                alt={ribbon_image.alt}
              />
            </Ribbon>
            <StyledPanelContainer>
              <Panel $width='60%'>
                <BodyContainer>
                  <PageTitle $sponsored={sponsored}>
                    <PrismicRichText field={page_title.richText} />
                  </PageTitle>
                  {left_panel_body && (
                    <RegistrationLandingPageBody
                      body={left_panel_body}
                      sponsorshipCode={sponsored ? uid.toUpperCase() : null}
                    />
                  )}
                  <PreFooterLinks>
                    {fine_print.text && (
                      <RichTextWithDictionary render={fine_print.richText} />
                    )}
                    {left_panel_pre_footer_links.map(getLinks)}
                  </PreFooterLinks>
                </BodyContainer>
              </Panel>
              <Panel $width='40%'>
                <RightBodyContainer>
                  {right_panel_body && (
                    <PageBody
                      body={right_panel_body}
                      template={'registration-landing'}
                    />
                  )}
                  <PreFooterLinks>
                    {right_panel_pre_footer_links.map(getLinks)}
                  </PreFooterLinks>
                </RightBodyContainer>
              </Panel>
            </StyledPanelContainer>
          </StyledColumnWrapper>
        </MainCol>
      </PageContainer>
    </Layout>
  );
}

export const Head = ({ data: queryResult }) => {
  const pageData = queryResult.prismicRegistrationLandingPage.data;

  if (!pageData) {
    return null;
  }

  const { seo_title, seo_meta_description, seo_image, page_title } = pageData;

  return (
    <SEO
      title={seo_title || page_title.text}
      description={seo_meta_description}
      image={seo_image}
    />
  );
};

export const query = graphql`
  query RegistrationLandingPage($id: String!) {
    prismicRegistrationLandingPage(id: { eq: $id }) {
      uid
      _previewable
      data {
        page_title {
          richText
          text
        }
        sponsored
        fine_print {
          text
          richText
        }
        left_panel_pre_footer_links {
          link_text
          link {
            url
            tags
            target
            link_type
            type
            uid
            document {
              ... on PrismicCommunityPortalPage {
                data {
                  has_parent_page
                  parent_page {
                    uid
                  }
                }
              }
            }
          }
        }
        ribbon_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        right_panel_pre_footer_links {
          link_text
          link {
            url
            tags
            target
            link_type
            type
            uid
            document {
              ... on PrismicCommunityPortalPage {
                data {
                  has_parent_page
                  parent_page {
                    uid
                  }
                }
              }
            }
          }
        }
        left_panel_body {
          ... on PrismicRegistrationLandingPageDataLeftPanelBodyTextBlock {
            slice_type
            primary {
              text_block_title {
                richText
                text
              }
              text_block_content {
                richText
                text
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
          }
          ... on PrismicRegistrationLandingPageDataLeftPanelBodyButtonLink {
            slice_type
            primary {
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
              button_text
              button_link {
                uid
                url
                type
                link_type
              }
              button_style
              font_weight
              min_width
              sponsored_link
            }
          }
        }
        right_panel_body {
          ... on PrismicRegistrationLandingPageDataRightPanelBodyTwoColumnsImage {
            slice_type
            primary {
              justify
              alignment
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              image_margin
              image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              text_block {
                richText
                text
              }
              title {
                richText
                text
              }
            }
          }
        }
      }
    }
  }
`;

RegistrationLandingPage.propTypes = propTypes;
RegistrationLandingPage.defaultProps = defaultProps;

export default withPrismicPreview(RegistrationLandingPage);
