import { prop, ifProp } from 'styled-tools';
import styled, { css } from 'styled-components';

const SideRailContainer = styled.div`
  @media (min-width: ${prop('theme.breakpoints.lg')}) and (max-width: ${prop(
      'theme.breakpoints.xl'
    )}) {
    max-width: 150px;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    padding-top: 0.625rem;
  }

  &&& {
    max-height: 70vh;
    overflow: auto;
  }
`;

export default SideRailContainer;
