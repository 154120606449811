import React from 'react';
import styled, { css } from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import StyledForm from './styled/StyledForm';
import Input from './Input';
import { Spinner } from 'reactstrap';

export const SubmitSpinner = styled.div`
  position: absolute;
  right: -48px;
  display: flex;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    right: initial;
    position: relative;
    margin-left: 16px;
  }

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    margin-left: 0;
    margin-top: 24px;
    order: 3;
  }
`;

const Footnote = styled.div`
  margin-top: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  align-items: center;
  width: 100%;
  margin: 16px 0 112px;

  input {
    margin-left: 24px;
  }

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    flex-direction: column;

    input {
      margin: 0;
    }
  }
`;

const PreviousButtonStyles = css`
  padding: 8px 24px;
  min-width: 200px;
  text-align: center;
  text-decoration: none;
  background-color: ${prop('theme.colors.backgroundGreyLighter')};
  border-radius: 40px;
  color: ${prop('theme.colors.textGrey')};
  font-weight: ${prop('theme.fontWeights.semiBold')};
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 0.875rem;
  border: none;

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    flex: 1 1 100%;
    width: 100%;
    order: 2;
    margin-top: 12px;
    font-size: 1.375rem;
    min-height: 56px;
  }
`;

const PreviousButtonLink = styled(Link)`
  ${PreviousButtonStyles}

  &:hover {
    text-decoration: none;
    color: ${prop('theme.colors.textGrey')};
  }
`;

export const PreviousButton = styled.button`
  ${PreviousButtonStyles}
`;

const propTypes = {
  handleSubmit: PropTypes.func,
  onPreviousBehavior: PropTypes.object,
  isValid: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  disableRequiredMessage: PropTypes.bool,
  nextButtonText: PropTypes.string,
  removePreviousButton: PropTypes.bool,
  customRequiredMessage: PropTypes.string
};

const defaultProps = {
  handleSubmit: () => {},
  onPreviousBehavior: {},
  isValid: false,
  isSubmitting: false,
  disableRequiredMessage: false,
  nextButtonText: '',
  removePreviousButton: false,
  customRequiredMessage: null
};

function RegistrationForm({
  handleSubmit,
  onPreviousBehavior,
  isValid,
  isSubmitting,
  disableRequiredMessage,
  nextButtonText,
  removePreviousButton,
  customRequiredMessage,
  children
}) {
  const requiredMessage = customRequiredMessage
    ? customRequiredMessage
    : 'Required for registration';

  const onPreviousButton =
    onPreviousBehavior.type === 'button' ? (
      <PreviousButton type='button' onClick={onPreviousBehavior.onClick}>
        Previous Step
      </PreviousButton>
    ) : (
      <PreviousButtonLink to={onPreviousBehavior.to}>
        Go Back
      </PreviousButtonLink>
    );

  return (
    <StyledForm onSubmit={handleSubmit}>
      {children}
      {!disableRequiredMessage && <Footnote>{`* ${requiredMessage}`}</Footnote>}
      <ButtonContainer>
        {!removePreviousButton && onPreviousButton}
        <Input
          type='submit'
          value={nextButtonText ? nextButtonText : 'Next Step'}
          disabled={!isValid || isSubmitting}
        />
        {isSubmitting && (
          <SubmitSpinner>
            <Spinner />
          </SubmitSpinner>
        )}
      </ButtonContainer>
    </StyledForm>
  );
}

RegistrationForm.propTypes = propTypes;
RegistrationForm.defaultProps = defaultProps;

export default RegistrationForm;
