import React, { useState } from 'react';
import sortBy from 'lodash-es/sortBy';
import { graphql, StaticQuery, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import get from 'lodash-es/get';

import SideRailContainer from './styled/SideRailContainer';
import RailMenuList from './styled/RailMenuList';
import ProductItem from './ProductItem';
import MenuItem from './styled/MenuItem';
import MenuLink from './styled/MenuLink';
import linkResolver from '../../utils/linkResolver';

const ArchiveLink = styled(MenuLink)`
  font-weight: ${prop('theme.fontWeights.semiBold')};
`;

const productsQuery = graphql`
  query ProductsQuery {
    allPrismicProduct {
      edges {
        node {
          id
          uid
          type
          data {
            title {
              text
            }
            disclaimer_text {
              richText
              text
            }
            futures_symbol
            sort_order
          }
        }
      }
    }
    allPrismicProductArchiveLandingPage {
      edges {
        node {
          uid
          type
          data {
            page_title {
              text
            }
          }
        }
      }
    }
  }
`;

const propTypes = {
  toggle: PropTypes.func
};

function ProductsRailMenu({ toggle = () => {} }) {
  const queryResult = useStaticQuery(productsQuery);
  const products = sortBy(queryResult.allPrismicProduct.edges, [
    'node.data.sort_order',
    'node.data.title.text'
  ]);
  const archiveLandingPage = get(
    queryResult,
    'allPrismicProductArchiveLandingPage.edges[0].node'
  );
  const [expandedProducts, setExpandedProducts] = useState({});

  return (
    <SideRailContainer>
      <RailMenuList>
        {products.map((menuItem) => {
          const symbol = menuItem.node.data.futures_symbol;
          return (
            <ProductItem
              menuItem={menuItem}
              toggle={toggle}
              key={menuItem.node.id}
              isExpanded={expandedProducts[symbol]}
              toggleExpanded={() => {
                setExpandedProducts((prev) => {
                  const newState = Object.keys(prev).reduce(
                    (previous, current) => ({
                      ...previous,
                      [current]: false
                    }),
                    {}
                  );
                  return {
                    ...newState,
                    [symbol]: !prev[symbol]
                  };
                });
              }}
            />
          );
        })}
        {archiveLandingPage && (
          <MenuItem>
            <ArchiveLink href={linkResolver(archiveLandingPage)}>
              {get(
                archiveLandingPage,
                'data.page_title.text',
                'Markets Archive'
              )}
            </ArchiveLink>
          </MenuItem>
        )}
      </RailMenuList>
    </SideRailContainer>
  );
}

ProductsRailMenu.propTypes = propTypes;

export default ProductsRailMenu;
