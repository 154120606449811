import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
import { Script } from 'gatsby';

export const VideoWrapper = styled.div`
  &&& {
    margin: ${ifProp(
      { $border: true },
      '0.3125rem 0.5rem 2rem 1rem',
      '0 auto 2rem'
    )};
  }

  border: ${ifProp({ $border: true }, '1px solid black')};
  border-radius: ${ifProp({ $borderRadius: true }, '0.5rem')};
  position: relative;
  overflow: hidden;

  ${ifProp(
    '$fullWidth',
    css`
      max-width: none;
    `
  )};

  &:before {
    content: '';
    display: block;
    // 1.777 is 16:9 aspect ratio of the embedded video.
    padding-bottom: calc(100% / 1.777);
  }

  iframe,
  object,
  embed,
  lite-vimeo,
  lite-youtube {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding-bottom: 0;
  }

  lite-youtube {
    &:before {
      background: none;
    }
  }
`;

const propTypes = {
  data: PropTypes.object,
  fullWidth: PropTypes.bool,
  video: PropTypes.object,
  withBorder: PropTypes.bool
};

const defaultProps = {
  fullWidth: false,
  video: {},
  withBorder: false
};

const VideoPlayer = ({ data, fullWidth, video, withBorder }) => {
  // PageBody_NewsItem passes in a generic data prop
  if (data && data.source === 'news_item') {
    video = data.primary.embed_video;
  }
  const videoUrl = video && video.embed_url;

  const getYoutubeFacade = (videoUrl) => {
    const params = new URLSearchParams(videoUrl.split('?')[1]);
    const id = params.get('v');
    const thumbnailUrl = `https://i\.ytimg\.com/vi/${id}/maxresdefault.jpg`;
    params.delete('v');

    return (
      <lite-youtube
        videoid={id}
        params={params.toString()}
        style={{
          backgroundImage: `url('${thumbnailUrl}')`,
          maxWidth: '100%'
        }}
        onClick={() => {
          if (window && window.dataLayer) {
            window.dataLayer.push({
              event: 'gtm.video',
              gtm: {
                videoProvider: 'youtube',
                videoStatus: 'start',
                videoTitle: video.title,
                videoUrl: videoUrl,
                videoId: id
              }
            });
          }
        }}
      >
        <button type='button' className='lty-playbtn'>
          <span className='lyt-visually-hidden'>Play</span>
        </button>
      </lite-youtube>
    );
  };

  const isVimeoVideo = videoUrl && videoUrl.includes('vimeo');
  return (
    <>
      <Script src='https://unpkg.com/lite-youtube-embed@0.2.0/src/lite-yt-embed.js'></Script>
      <Script
        type='module'
        src='https://cdn.jsdelivr.net/npm/@slightlyoff/lite-vimeo@0.1.1/lite-vimeo.js'
      ></Script>
      {video && (
        <VideoWrapper
          $border={withBorder}
          $borderRadius={isVimeoVideo}
          $fullWidth={fullWidth}
        >
          {isVimeoVideo ? (
            <lite-vimeo videoid={videoUrl.split('/')[3]} />
          ) : videoUrl && videoUrl.includes('youtube') ? (
            getYoutubeFacade(videoUrl)
          ) : (
            <div dangerouslySetInnerHTML={{ __html: video.html }} />
          )}
        </VideoWrapper>
      )}
    </>
  );
};

VideoPlayer.propTypes = propTypes;
VideoPlayer.defaultProps = defaultProps;

export default VideoPlayer;
