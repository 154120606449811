import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { PrismicRichText } from '@prismicio/react';
import { Collapse, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

import { signAgreement } from '../../../../state/actions/agreementActions';
import { ButtonContainer, PreviousButton } from '../RegistrationForm';
import StepContext from '../../../../context/StepContext';
import Input, { NextButtonStyles } from '../RegistrationForm/Input';
import linkResolver from '../../../../utils/linkResolver';
import Checkbox from '../RegistrationForm/Checkbox';
import greenCheckmark from '../../../../images/icons/green-checkmark.svg';
import chevron from '../../../../images/mobile-chevron.svg';
import {
  AGREEMENT_STATUS_ACTION_REQUIRED,
  AGREEMENT_STATUS_SIGNED
} from '../../../../utils/constants';
import {
  Row,
  AgreementContainer,
  Section,
  SectionHeader,
  StatusIndicator
} from '.';
import useResize from '../../../../hooks/useResize';

const SubmitRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 24px 0 16px 16px;
  position: relative;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin-left: 0;
  }
`;

const SubmitSpinner = styled.div`
  position: absolute;
  right: -28px;
  display: flex;
`;

const NextButton = styled.button`
  ${NextButtonStyles}

  margin-left: 24px;
`;

const propTypes = { agreements: PropTypes.array };

const defaultProps = { agreements: [] };

function MultipleAgreementDisplay({ agreements }) {
  const { setCurrentStep } = useContext(StepContext);
  const [isSubmitting, setIsSubmitting] = useState(
    new Array(agreements.length).fill(false)
  );
  const [isExpanded, setIsExpanded] = useState(
    new Array(agreements.length).fill(false)
  );
  const [isTransitioning, setIsTransitioning] = useState(
    new Array(agreements.length).fill(false)
  );
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  useResize(isMobile, setIsMobile);

  const startTransition = (index) => {
    setIsTransitioning((prev) => {
      const newIsTransitioning = [...prev];
      newIsTransitioning.splice(index, 1, true);
      return newIsTransitioning;
    });
  };

  const endTransition = (index) => {
    setIsTransitioning((prev) => {
      const newIsTransitioning = [...prev];
      newIsTransitioning.splice(index, 1, false);
      return newIsTransitioning;
    });
  };

  return (
    <>
      {agreements.map((agreement, index) => {
        const {
          register,
          handleSubmit,
          formState: { isValid, errors }
        } = useForm({
          mode: 'onChange'
        });

        const onSubmit = (data) => {
          setIsSubmitting((prev) => {
            const newIsSubmitting = [...prev];
            newIsSubmitting.splice(index, 1, true);
            return newIsSubmitting;
          });

          const agreementRequest = {
            id: agreement.id,
            signature_value: data.signature
          };
          dispatch(
            signAgreement(
              agreementRequest,
              () => {
                window.dataLayer = window.dataLayer || [];
                setIsExpanded((prev) => {
                  const newIsExpanded = [...prev];
                  newIsExpanded.splice(index, 1, !prev[index]);
                  return newIsExpanded;
                });
                window.dataLayer.push({ event: 'sign_agreement' });
              },
              () =>
                setIsSubmitting((prev) => {
                  const newIsSubmitting = [...prev];
                  newIsSubmitting.splice(index, 1, false);
                  return newIsSubmitting;
                })
            )
          );
        };

        const { title, agreement_body, section } = agreement.data;
        const isSigned = agreement.status === AGREEMENT_STATUS_SIGNED;

        return (
          <React.Fragment key={title}>
            <AgreementContainer>
              <SectionHeader
                $canBeExpanded
                $isExpanded={isExpanded[index] || isTransitioning[index]}
                onClick={() => {
                  setIsExpanded((prev) => {
                    const newIsExpanded = [...prev];
                    newIsExpanded.splice(index, 1, !prev[index]);
                    return newIsExpanded;
                  });
                }}
              >
                <div>
                  <h3>{title}</h3>
                  {isSigned ? (
                    <StatusIndicator $status={AGREEMENT_STATUS_SIGNED}>
                      <img src={greenCheckmark} />
                      <span>{AGREEMENT_STATUS_SIGNED}</span>
                    </StatusIndicator>
                  ) : (
                    <StatusIndicator $status={AGREEMENT_STATUS_ACTION_REQUIRED}>
                      <span>{AGREEMENT_STATUS_ACTION_REQUIRED}</span>
                    </StatusIndicator>
                  )}
                </div>
                <img
                  src={chevron}
                  className={isExpanded[index] ? 'expanded' : 'collapsed'}
                />
              </SectionHeader>
              <Collapse
                isOpen={isExpanded[index]}
                onEntering={() => startTransition(index)}
                onEntered={() => endTransition(index)}
                onExiting={() => startTransition(index)}
                onExited={() => endTransition(index)}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <PrismicRichText
                      field={agreement_body.richText}
                      linkResolver={linkResolver}
                    />
                    {section.map((sectionContent, sectionIndex) => (
                      <Section
                        className={sectionContent.css_classname || ''}
                        key={`${title}-${sectionIndex}`}
                      >
                        <h3>{sectionContent.section_name}</h3>

                        <PrismicRichText
                          field={sectionContent.section_body.richText}
                          linkResolver={linkResolver}
                        />
                      </Section>
                    ))}
                  </div>
                  <>
                    <Row>
                      <Checkbox
                        label='I Consent to the Agreement'
                        {...register('consent', {
                          required: 'Consent is required'
                        })}
                        // the undefined is needed here because setting checked to false makes the checkbox uncheckable
                        checked={isSigned || undefined}
                        disabled={isSigned}
                      />
                      <Input
                        disableTopMargin={!isMobile}
                        type='text'
                        name={'signature'}
                        labelText='Signature (Type Your Name)'
                        defaultValue={agreement.signature}
                        disabled={isSigned}
                        error={errors.signature}
                        {...register('signature', {
                          required: 'Signature is required'
                        })}
                      />
                    </Row>
                    {!isSigned && (
                      <SubmitRow>
                        <Input
                          type='submit'
                          value='Submit'
                          disabled={!isValid || isSubmitting[index]}
                        />
                        {isSubmitting[index] && (
                          <SubmitSpinner>
                            <Spinner size='sm' />
                          </SubmitSpinner>
                        )}
                      </SubmitRow>
                    )}
                  </>
                </form>
              </Collapse>
            </AgreementContainer>
          </React.Fragment>
        );
      })}
      <ButtonContainer>
        <PreviousButton onClick={() => setCurrentStep((prev) => prev - 1)}>
          Previous Step
        </PreviousButton>
        <NextButton
          onClick={() => setCurrentStep((prev) => prev + 1)}
          disabled={
            agreements.findIndex(
              (agreement) => agreement.status !== AGREEMENT_STATUS_SIGNED
            ) > -1
          }
        >
          Next Step
        </NextButton>
      </ButtonContainer>
    </>
  );
}

MultipleAgreementDisplay.propTypes = propTypes;
MultipleAgreementDisplay.defaultProps = defaultProps;

export default MultipleAgreementDisplay;
