import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { prop } from 'styled-tools';
import styled from 'styled-components';

const FormWrapper = styled.div`
  margin-top: 3.5rem;
  text-align: center;

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    margin-top: 1.5rem;
  }
`;

const FormTitle = styled.div`
  font-size: 1rem;
  justify-content: flex-end;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  margin-bottom: 0.6rem;
`;

const FormSubtitle = styled.div`
  margin-bottom: 1.5rem;
`;

const InputButtonConatiner = styled.div`
  line-height: auto;
  @media (min-width: ${prop('theme.breakpoints.md')}) {
    margin: 0 10%;
  }

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin: 0;
  }
`;

const FormInput = styled.input`
  border: none;
  border-radius: 2rem 0 0 2rem;
  width: 75%;
  padding: 0.875rem 1.375rem 0.75rem;
  font-size: 0.875rem;

  font-weight: ${prop('theme.fontWeights.regular')};

  ::placeholder {
    color: ${prop('theme.colors.greyDarker')};
    opacity: 1;
  }
`;

const FormButton = styled.input`
  border: none;
  width: 25%;
  border-radius: 0 2rem 2rem 0;
  color: ${prop('theme.colors.white')};
  background-color: ${prop('theme.colors.black')};
  padding: 0.875rem 4% 0.75rem;
  letter-spacing: 1px;
  font-weight: ${prop('theme.fontWeights.semiBold')};

  @media (min-width: ${prop('theme.breakpoints.xl')}) {
    padding: 0.875rem 6.9% 0.75rem;
  }
`;

const propTypes = {
  formData: PropTypes.object
};

const defaultProps = {
  formData: {}
};

const FooterForm = ({ formData }) => {
  if (isEmpty(formData)) {
    return null;
  }

  const {
    form_title,
    form_subtitle,
    form_button_title,
    form_field_name
  } = formData;
  const formTitle = form_title ? form_title.text : '';
  const formSubtitle = form_subtitle ? form_subtitle.text : '';
  const formButtonTitle = form_button_title ? form_button_title.text : '';
  const formFieldName = form_field_name ? form_field_name.text : '';

  return (
    <FormWrapper>
      <form
        action='https://thesmallexchange.us7.list-manage.com/subscribe/post?u=fbd38127cfb37b929203b03d4&id=2dd1a28bb9&NTIER=newsletter-lite&SOURCE=SMFE&LEAD_SRCE=MainSiteHomepage'
        method='post'
        id='mc-embedded-subscribe-form'
        name='mc-embedded-subscribe-form'
        className='validate'
        target='_blank'
        noValidate
      >
        <FormTitle>{formTitle}</FormTitle>
        <FormSubtitle>{formSubtitle}</FormSubtitle>
        <InputButtonConatiner>
          <FormInput
            placeholder={formFieldName}
            type='email'
            name='EMAIL'
            id='mce-EMAIL'
          />
          {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups  */}
          <div
            style={{ position: 'absolute', left: '-5000px' }}
            aria-hidden='true'
          >
            <input
              type='text'
              name='b_fbd38127cfb37b929203b03d4_48ac693b81'
              tabIndex='-1'
            />
          </div>
          <FormButton
            type='submit'
            name={formButtonTitle}
            value={formButtonTitle}
            id='mc-embedded-subscribe'
          />
        </InputButtonConatiner>
      </form>
    </FormWrapper>
  );
};

FooterForm.defaultProps = defaultProps;
FooterForm.propTypes = propTypes;

export default FooterForm;
