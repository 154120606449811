import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from 'reactstrap/lib/Collapse';
import { prop } from 'styled-tools';
import styled from 'styled-components';

import chevron from '../../../../images/mobile-chevron.svg';
import ScrollContext from '../../../../context/ScrollContext';
import NavMenu from './NavMenu';

const Container = styled.div`
  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    display: none;
  }
`;

const MenuToggler = styled.div`
  align-items: center;
  color: ${prop('theme.colors.titleGrey')};
  cursor: pointer;
  display: flex;
  font-size: 1.25rem;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  justify-content: space-between;
  letter-spacing: 1px;
  line-height: 24px;
  padding: 0 32px;

  @media (max-width: ${prop('theme.breakpoints.xxs')}) {
    font-size: 1rem;
    padding: 0 16px;
  }

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    display: none;
  }
`;

const StyledCollapse = styled(Collapse)`
  &.show,
  &.collapsing {
    margin-top: 2rem;
  }
`;

const propTypes = {
  expandedSection: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  title: PropTypes.object,
  toggleNav: PropTypes.func
};

const defaultProps = {
  expandedSection: false,
  title: {},
  toggleNav: () => {}
};

function MobileMenu({ expandedSection, title, toggleNav }) {
  const [isMainExpanded, setIsMainExpanded] = useState(false);
  const { disableBodyScroll, enableBodyScroll } = useContext(ScrollContext);

  const toggle = useCallback((event) => {
    // Inputs with labels trigger two events, and we only want one in order for
    // the MobileMenu component to keep toggling correctly.
    if (
      event.nativeEvent.target.hasAttribute('name') &&
      event.nativeEvent.target.getAttribute('name') === 'radioToggle'
    ) {
      return;
    }

    setIsMainExpanded((prev) => !prev);
  }, []);

  useEffect(() => {
    if (isMainExpanded) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
  }, [isMainExpanded]);

  return (
    <Container>
      <MenuToggler onClick={toggle}>
        {title.text}
        <img src={chevron} width={20} height={10} alt='Mobile menu chevron' />
      </MenuToggler>
      <StyledCollapse isOpen={isMainExpanded}>
        <NavMenu expandedSection={expandedSection} toggleNav={toggleNav} />
      </StyledCollapse>
    </Container>
  );
}

MobileMenu.propTypes = propTypes;
MobileMenu.defaultProps = defaultProps;

export default MobileMenu;
