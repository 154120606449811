import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import range from 'lodash-es/range';
import PropTypes from 'prop-types';

import StepContext from '../../../../context/StepContext';
import whiteCheckmark from '../../../../images/icons/white-checkmark.svg';

const StepsContainer = styled.div`
  position: relative;
  margin-bottom: 80px;
`;

const StepsRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Line = styled.hr`
  border: none;
  color: ${prop('theme.colors.stepCounterGrey')};
  background-color: ${prop('theme.colors.stepCounterGrey')};
  height: 1.5px;
  width: 95%;
  margin: 0;
  top: 50%;
  z-index: -1;
  position: absolute;
`;

const ActiveStep = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
`;

const StepNumber = styled.div`
  border-radius: 100%;
  background-color: ${prop('theme.colors.white')};
  color: ${prop('theme.colors.stepCounterGrey')};
  border: 1.5px solid ${prop('theme.colors.stepCounterGrey')};
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.625rem;
  line-height: 24px;

  ${switchProp('$state', {
    active: css`
      background-color: ${prop('theme.colors.redPrimary')};
      border: 1.5px solid ${prop('theme.colors.redPrimary')};
      color: ${prop('theme.colors.white')};
    `,
    completed: css`
      background-color: ${prop('theme.colors.stepCounterGrey')};
      border: 1.5px solid ${prop('theme.colors.stepCounterGrey')};
      color: ${prop('theme.colors.white')};
    `
  })}

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
  }
`;

const StepName = styled.div`
  position: absolute;
  top: 110%;
  left: -56px;
  width: 160px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.875rem;
  font-weight: ${prop('theme.fontWeights.semiBold')};

  ${switchProp('$step', {
    first: css`
      @media (max-width: ${prop('theme.breakpoints.lg')}) {
        left: 0;
        text-align: left;
      }
    `,
    last: css`
      @media (max-width: ${prop('theme.breakpoints.lg')}) {
        left: initial;
        right: 0;
        text-align: right;
      }
    `
  })}
`;

const propTypes = { stepName: PropTypes.string };

const defaultProps = { stepName: '' };

function StepCounter({ stepName }) {
  const { currentStep, totalSteps } = useContext(StepContext);

  return (
    <StepsContainer>
      <Line />
      <StepsRow>
        {range(totalSteps).map((step, index) => {
          if (step === currentStep) {
            return (
              <ActiveStep key={`registration-step-${index}`}>
                <StepNumber $state={'active'}>{step + 1}</StepNumber>
                <StepName
                  $step={
                    step === 0 ? 'first' : step === totalSteps - 1 ? 'last' : ''
                  }
                >
                  {stepName}
                </StepName>
              </ActiveStep>
            );
          }

          if (step < currentStep) {
            return (
              <StepNumber
                $state={'completed'}
                key={`registration-step-${index}`}
              >
                <img src={whiteCheckmark} />
              </StepNumber>
            );
          }

          return (
            <StepNumber key={`registration-step-${index}`}>
              {step + 1}
            </StepNumber>
          );
        })}
      </StepsRow>
    </StepsContainer>
  );
}

StepCounter.propTypes = propTypes;
StepCounter.defaultProps = defaultProps;

export default StepCounter;
