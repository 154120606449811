import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';

import Tooltip from '../Tooltip';
import BlockStyles from '../BlockStyles';

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: ${(props) =>
    props.$collapse || props.$noTopMargin ? '0' : '3rem'};
`;

const WrapperHeader = styled.h2`
  font-weight: ${prop('theme.fontWeights.semiBold')};
  font-size: 1.5rem;
`;

const propTypes = {
  blockHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  primary: PropTypes.object,
  tooltip: PropTypes.object,
  template: PropTypes.string,
  collapse: PropTypes.bool,
  first: PropTypes.bool
};

const defaultProps = {
  blockHeader: '',
  primary: {},
  tooltip: {},
  template: '',
  collapse: false,
  first: false
};

function BodySectionTitle({
  blockHeader,
  primary,
  tooltip,
  template,
  collapse,
  first
}) {
  const title = blockHeader && blockHeader.text;

  const header = (
    <TitleContainer $collapse={collapse} $noTopMargin={first}>
      {tooltip && tooltip.length > 0 ? (
        <>
          <WrapperHeader>{title}</WrapperHeader>
          {tooltip && <Tooltip content={tooltip} triggerValue={title} />}
        </>
      ) : (
        <WrapperHeader>{title}</WrapperHeader>
      )}
    </TitleContainer>
  );
  return (
    <>
      {blockHeader &&
        title &&
        blockHeader.type === 'heading2' &&
        (template ? (
          <BlockStyles template={template} data={primary}>
            <div className='block'>{header}</div>
          </BlockStyles>
        ) : (
          <>{header}</>
        ))}
    </>
  );
}

BodySectionTitle.propTypes = propTypes;
BodySectionTitle.defaultProps = defaultProps;

export default BodySectionTitle;
