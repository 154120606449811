import React, { useState } from 'react';

const StepContext = React.createContext({
  currentStep: 0,
  setCurrentStep: () => {},
  totalSteps: 0
});
StepContext.displayName = 'StepContext';

function StepProvider({ children, stepNumber, numberOfSteps }) {
  const [currentStep, setCurrentStep] = useState(stepNumber);

  return (
    <StepContext.Provider
      value={{
        currentStep: currentStep,
        setCurrentStep: setCurrentStep,
        totalSteps: numberOfSteps
      }}
    >
      {children}
    </StepContext.Provider>
  );
}

export { StepProvider };

export default StepContext;
