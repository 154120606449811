import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import get from 'lodash-es/get';

import BlockStyles from '../../../BlockStyles';
import InvoiceDisplay from '.';
import {
  UNLOCKED_INVOICE_STATUSES,
  INVOICE_STATUS_PAID
} from '../../../../utils/constants';
import ButtonLink from '../../../ButtonLink';
import StripeCheckoutButton from '../StripeCheckoutButton';
import Alert from '../Alert';
import RegistrationTextBlock from '../RegistrationTextBlock';
import { PrismicRichText } from '@prismicio/react';

const Wrapper = styled.div`
  margin-top: 32px;
`;

const ButtonContainer = styled.div`
  margin: 2rem 0 4rem;
  display: flex;
  justify-content: flex-end;
`;

const propTypes = { data: PropTypes.object, template: PropTypes.string };

const defaultProps = { data: {}, template: '' };

function InvoiceBlock({ data, template }) {
  const invoice = useSelector(
    (state) => get(state, 'memberInfo.invoices', [])[0]
  );
  const locked = invoice
    ? !UNLOCKED_INVOICE_STATUSES.includes(invoice.status)
    : true;
  const paid = invoice ? invoice.status === INVOICE_STATUS_PAID : false;
  const {
    locked_title,
    unlocked_title,
    locked_alert_image,
    locked_alert_text,
    text_block,
    payment_required_text,
    locked_button_link,
    locked_button_text
  } = data.primary;

  return (
    <>
      <BlockStyles template={template}>
        <PrismicRichText
          render={
            locked || paid ? locked_title.richText : unlocked_title.richText
          }
        />
      </BlockStyles>
      {locked && (
        <Alert
          data={{
            primary: {
              image: locked_alert_image,
              text_content: locked_alert_text
            }
          }}
        />
      )}
      <Wrapper>
        <RegistrationTextBlock
          data={{
            primary: {
              text_block_content:
                locked || paid ? text_block : payment_required_text
            }
          }}
        />
      </Wrapper>
      <BlockStyles template={template}>
        <Wrapper>
          <InvoiceDisplay data={data} />
        </Wrapper>
        <ButtonContainer>
          {locked || paid ? (
            <ButtonLink
              link={locked_button_link}
              format='fill'
              style='fill-red'
              width='fit-content'
              font-weight='semiBold'
              size={200}
            >
              {locked_button_text}
            </ButtonLink>
          ) : (
            <StripeCheckoutButton buttonText='Pay Online' />
          )}
        </ButtonContainer>
      </BlockStyles>
    </>
  );
}

InvoiceBlock.propTypes = propTypes;
InvoiceBlock.defaultProps = defaultProps;

export default InvoiceBlock;
