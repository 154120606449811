import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';

import authReducer from './reducers/authReducer';
import errorReducer from './reducers/errorReducer';
import loadingReducer from './reducers/loadingReducer';
import memberInfoReducer from './reducers/memberInfoReducer';
import pageModeReducer from './reducers/pageModeReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['pageModes']
};

const rootReducer = combineReducers({
  pageModes: pageModeReducer,
  auth: authReducer,
  memberInfo: memberInfoReducer,
  apiErrors: errorReducer,
  loading: loadingReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const defaultState = {
  pageModes: {},
  auth: {
    accessToken: '',
    idToken: '',
    expiresAt: null,
    isLoginRequired: false,
    loading: false
  },
  memberInfo: {
    identity: {},
    agreementTemplates: []
  }
};

let persistor;
export default (preloadedState = defaultState) => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
    preloadedState
  });
  persistor = persistStore(store);
  return store;
};

export const getPersistor = () => persistor;
