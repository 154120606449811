import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { ifNotProp, prop } from 'styled-tools';

import RailMenus from './RailMenus';
import ProductsRailMenu from './ProductsRailMenu';
import SideRailHeader from './styled/SideRailHeader';

const Container = styled.div`
  ${ifNotProp(
    { $page: 'Homepage' },
    css`
      margin-top: 2rem;
    `
  )}

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    display: none;
  }
`;

const propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  renderMenu: PropTypes.element,
  title: PropTypes.string,
  titleUrl: PropTypes.string,
  toggle: PropTypes.func
};

const defaultProps = {
  data: [],
  renderMenu: null,
  title: '',
  titleUrl: null
};

function DesktopMenu({ data, renderMenu, title, titleUrl }) {
  return (
    <Container $page={title}>
      <SideRailHeader>
        {titleUrl ? <Link to={titleUrl}>{title}</Link> : title}
      </SideRailHeader>
      {renderMenu && renderMenu}
      {!renderMenu && data.isProductsMenu && <ProductsRailMenu />}
      {!renderMenu && !data.isProductsMenu && !data.isNewsListMenu && (
        <RailMenus menus={data} />
      )}
    </Container>
  );
}

DesktopMenu.propTypes = propTypes;
DesktopMenu.defaultProps = defaultProps;

export default DesktopMenu;
