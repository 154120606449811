import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import StandardPage from '../components/standardPage';
import SEO from '../components/seo';

const propTypes = {
  data: PropTypes.object
};

const defaultProps = {
  data: null
};

function Page({ data: queryResult }) {
  const doc = queryResult.prismicStandardPage.data;
  if (!doc) {
    return null;
  }

  return <StandardPage doc={doc} />;
}

export const Head = ({ data: queryResult }) => {
  const pageData = queryResult.prismicStandardPage.data;

  if (!pageData) {
    return null;
  }

  const { seo_title, seo_meta_description, seo_image, page_title } = pageData;

  return (
    <SEO
      title={seo_title || page_title.text}
      description={seo_meta_description}
      image={seo_image}
    />
  );
};

export const query = graphql`
  query PageQuery($id: String!) {
    prismicStandardPage(id: { eq: $id }) {
      _previewable
      data {
        seo_title
        seo_meta_description
        seo_image {
          url
        }
        page_title {
          text
        }
        page_description {
          text
        }
        header_banner {
          url
        }
        display_side_nav
        side_nav_header
        body {
          ... on PrismicStandardPageDataBodyTextBlock {
            slice_type
            primary {
              content_collapse
              orientation
              text_block_header {
                richText
                text
              }
              tooltip {
                richText
                text
              }
              display_in_side_nav
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              text_block_content {
                richText
                text
                html
              }
              button_text {
                text
              }
              button_link {
                url
                target
                type
                uid
                link_type
                tags
              }
            }
          }
          ... on PrismicStandardPageDataBodyMenuLink {
            slice_type
            primary {
              menu_link {
                url
              }
              menu_link_text {
                richText
                text
              }
            }
          }
          ... on PrismicStandardPageDataBodyTwoColumns {
            slice_type
            items {
              text_block {
                richText
                text
              }
              title {
                richText
                text
              }
              image {
                url
                alt
              }
            }
          }
          ... on PrismicStandardPageDataBodyTwoColumnsImage {
            slice_type
            items {
              title {
                richText
                text
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              text_block {
                richText
                text
              }
              image_position
              image_border
            }
          }
          ... on PrismicStandardPageDataBodyCalendarSlice {
            slice_type
            primary {
              calendar_title {
                richText
                text
              }
              content_collapse
              calendar_description {
                richText
                text
              }
              calendar_events {
                document {
                  ...EventsInfo
                }
              }
            }
          }
          ... on PrismicStandardPageDataBodyTable10Cols {
            slice_type
            primary {
              table_title {
                richText
                text
              }
              table_type
              table_description {
                richText
                text
              }
              tooltip {
                richText
                text
              }
              column_width
              block_styles_side_nav
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
              col1 {
                richText
                text
              }
              col1_span
              col2 {
                richText
                text
              }
              col3 {
                richText
                text
              }
              col3_span
              col4 {
                richText
                text
              }
              col5 {
                richText
                text
              }
              col6 {
                richText
                text
              }
              col7 {
                richText
                text
              }
              col8 {
                richText
                text
              }
              col9 {
                richText
                text
              }
              col10 {
                richText
                text
              }
              tooltip_col1 {
                richText
                text
              }
              tooltip_col2 {
                richText
                text
              }
            }
            items {
              col1_data {
                richText
                text
              }
              col2_data {
                richText
                text
              }
              col3_data {
                richText
                text
              }
              col4_data {
                richText
                text
              }
              col5_data {
                richText
                text
              }
              col6_data {
                richText
                text
              }
              col7_data {
                richText
                text
              }
              col8_data {
                richText
                text
              }
              col9_data {
                richText
                text
              }
              col10_data {
                richText
                text
              }
            }
          }
          ... on PrismicStandardPageDataBodyMailchimpForm {
            slice_type
            primary {
              mailchimp_form_id
              form_type
              checkbox_text
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              additional_parameter_name
              additional_parameter_value
            }
          }
          ... on PrismicStandardPageDataBodyCurrentStatus {
            slice_type
            primary {
              button_text
              button_link {
                link_type
                url
                target
                type
                uid
                tags
              }
            }
          }
          ... on PrismicStandardPageDataBodyCardGroup {
            slice_type
            primary {
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
              card_group_title {
                richText
                text
              }
            }
            items {
              feature_card
              feature_card_color
              card_header {
                richText
                text
              }
              card_title {
                richText
                text
              }
              sub_card_title_content {
                richText
                text
              }
              main_card_content {
                richText
                text
              }
              sub_main_card_content {
                richText
                text
              }
              cta {
                richText
                text
              }
              cta_link {
                url
                target
                type
                uid
                link_type
                tags
              }
              card_content_list {
                richText
                text
              }
              card_content_list_type
              card_footer {
                richText
                text
              }
              card_footer_main_content {
                richText
                text
              }
            }
          }
          ... on PrismicStandardPageDataBodyTextTable {
            slice_type
            items {
              text_cell {
                text
                richText
              }
            }
          }
          ... on PrismicStandardPageDataBodyDocumentList {
            slice_type
            primary {
              list_title {
                richText
                text
              }
            }
            items {
              document_link {
                url
                target
                type
                uid
                link_type
                tags
              }
              document_title {
                text
              }
            }
          }
          ... on PrismicStandardPageDataBodyEmbedVideo {
            slice_type
            primary {
              embed_video {
                html
                embed_url
                title
                provider_name
              }
            }
          }
          ... on PrismicStandardPageDataBodyEmbeddedDocument {
            slice_type
            primary {
              document_link {
                document {
                  ...RegistrationStepFragment
                  ...CommunityPortalPageFragment
                }
              }
            }
          }
          ... on PrismicStandardPageDataBodyButtonLink {
            slice_type
            primary {
              button_text
              button_link {
                url
                uid
                target
                type
                link_type
              }
              button_style
              font_weight
              min_width
              alignment
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
          }
          ... on PrismicStandardPageDataBodyLatestArticlesInCategory {
            slice_type
            primary {
              title {
                richText
                text
              }
              category {
                uid
                type
              }
            }
          }
          ... on PrismicStandardPageDataBodyLatestArticlesWithTags {
            slice_type
            primary {
              title {
                richText
                text
              }
            }
            items {
              tag
            }
          }
        }
      }
    }
  }
`;

Page.propTypes = propTypes;
Page.defaultProps = defaultProps;

export default withPrismicPreview(Page);
