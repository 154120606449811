import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash-es/get';

import StepContext from '../../../context/StepContext';
import RegistrationStepPageBody from './RegistrationStepPageBody';

const propTypes = {
  body: PropTypes.array,
  linkedAlert: PropTypes.object,
  isLinked: PropTypes.bool
};

const defaultProps = { body: [], linkedAlert: {}, isLinked: false };

function RegistrationFlowBody({ body, linkedAlert, isLinked }) {
  if (body.length === 0) {
    return null;
  }

  const { currentStep } = useContext(StepContext);
  const step = body[currentStep];
  const { step_title = {}, display_title = true, body: stepBody } = get(
    step,
    'primary.registration_step.document.data',
    {}
  );

  return (
    <RegistrationStepPageBody
      title={step_title}
      shouldDisplayTitle={display_title}
      linkedAlert={linkedAlert}
      isLinked={isLinked}
      body={stepBody}
    />
  );
}

RegistrationFlowBody.propTypes = propTypes;
RegistrationFlowBody.defaultProps = defaultProps;

export default RegistrationFlowBody;
