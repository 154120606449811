import React from 'react';
import PropTypes from 'prop-types';
import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import breadcrumbArrow from 'images/arrows/breadcrumb-arrow.svg';
import linkResolver from '../../../../utils/linkResolver';

const BreadcrumbsContainer = styled.div`
  color: ${prop('theme.colors.titleGrey')};
  font-size: 0.8125rem;
  line-height: 1.5rem;
  margin-bottom: 8px;
`;

const BreadcrumbStyles = css`
  color: ${prop('theme.colors.titleGrey')};

  &:hover {
    color: ${prop('theme.colors.titleGrey')};
  }

  ${ifProp(
    '$capitalize',
    css`
      text-transform: capitalize;
    `
  )};
`;

const BreadcrumbLink = styled(Link)`
  ${BreadcrumbStyles}
`;

const Breadcrumb = styled.span`
  ${BreadcrumbStyles}
`;

const ChevronLeft = styled.img`
  padding-left: 12px;
  padding-right: 10px;
`;

const propTypes = {
  hasParentPage: PropTypes.bool,
  parentPage: PropTypes.shape({
    uid: PropTypes.string
  }),
  pageTitle: PropTypes.shape({
    raw: PropTypes.array,
    text: PropTypes.string
  })
};

const defaultProps = {
  hasParentPage: false,
  parentPage: {},
  pageTitle: {}
};

function Breadcrumbs({ hasParentPage, parentPage, pageTitle }) {
  const parentPageName = parentPage?.uid?.replace('-', ' ');
  const parentPageLink = linkResolver({
    uid: parentPage.uid,
    type: parentPage.type
  });
  const dashboardLink = linkResolver({
    uid: 'community-portal',
    type: 'community_portal_landing_page'
  });

  return (
    <BreadcrumbsContainer>
      {hasParentPage ? (
        <>
          <BreadcrumbLink to={dashboardLink}>Dashboard</BreadcrumbLink>
          <ChevronLeft src={breadcrumbArrow} />
          <BreadcrumbLink $capitalize to={parentPageLink}>
            {parentPageName}
          </BreadcrumbLink>
          <ChevronLeft src={breadcrumbArrow} />
          <Breadcrumb>{pageTitle.text}</Breadcrumb>
        </>
      ) : (
        <>
          <BreadcrumbLink to={dashboardLink}>Dashboard</BreadcrumbLink>
          <ChevronLeft src={breadcrumbArrow} />
          <Breadcrumb>{pageTitle.text}</Breadcrumb>
        </>
      )}
    </BreadcrumbsContainer>
  );
}

Breadcrumbs.propTypes = propTypes;
Breadcrumbs.defaultProps = defaultProps;

export default Breadcrumbs;
