import React from 'react';
import PropTypes from 'prop-types';
import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';
import { config, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';

// This addresses an issue where font-awesome icons sometimes render ridiculously large before resizing back to normal
// The css is now imported in gatsby-browser.js
config.autoAddCss = false;
library.add(faFacebookF, faLinkedinIn, faTwitter, faYoutube);

const StyledSocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${ifProp(
    { isSideRails: true },
    css`
      margin: 0 0 0.5rem;

      @media (min-width: ${prop('theme.breakpoints.lg')}) {
        margin: 1rem 0 1.25rem;
      }
    `
  )}

  a {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const CircleImg = styled.div`
  align-items: center;
  border-radius: 50%;
  background-color: ${prop('theme.colors.greyDarkest')};
  display: flex;
  height: ${(props) => props.width};
  justify-content: center;
  margin-right: 0.625rem;
  width: ${(props) => props.height};

  ${ifProp(
    { isSideRails: true },
    css`
      background-color: ${prop('theme.colors.white')};

      @media (min-width: ${prop('theme.breakpoints.lg')}) {
        background-color: ${prop('theme.colors.greyDarkest')};
      }

      img {
        height: 1.15rem;
        width: 1.15rem;
      }
    `
  )}
`;

const SocialIcon = styled(FontAwesomeIcon)`
  color: ${prop('theme.colors.white')};
  font-size: ${(props) => props.fontSize};

  // styled-components passes through all props on custom components, which
  // causes React to give noisy console errors. Making the prop all lower-case
  // and a string causes it be treated as a custom HTML attribute, which passes React's checks.
  ${ifProp(
    { issiderails: 'true' },
    css`
      color: ${prop('theme.colors.black')};

      @media (min-width: ${prop('theme.breakpoints.lg')}) {
        color: ${prop('theme.colors.white')};
      }
    `
  )}
`;

const propTypes = {
  socials: PropTypes.array,
  isSideRails: PropTypes.bool
};

const defaultProps = {
  socials: null,
  isSideRails: false
};

function SocialLinks({ socials, isSideRails }) {
  if (!socials) {
    return null;
  }

  const socialMediaLinks = socials.map((social, index) => {
    const { social_link, social_icon, font_awesome_icon } = social;
    const socialLink = social_link ? social_link.url : '';
    const iconImage = social_icon && social_icon.url;
    const fontAwesome = font_awesome_icon && font_awesome_icon.text;

    return (
      <React.Fragment key={index}>
        {socialLink && (
          <a href={socialLink} rel='noopener noreferrer' target='_blank'>
            {iconImage ? (
              <img
                src={iconImage}
                alt={social_icon.alt}
                height={social_icon.dimensions && social_icon.dimensions.height}
                width={social_icon.dimensions && social_icon.dimensions.width}
              />
            ) : (
              <CircleImg
                width={isSideRails ? '1.5625rem' : '2.25rem'}
                height={isSideRails ? '1.5625rem' : '2.25rem'}
                isSideRails={isSideRails}
              >
                <SocialIcon
                  fontSize={isSideRails ? '1rem' : '1.5rem'}
                  icon={['fab', `${fontAwesome}`]}
                  issiderails={isSideRails.toString()}
                />
              </CircleImg>
            )}
          </a>
        )}
      </React.Fragment>
    );
  });

  return (
    <StyledSocialLinks isSideRails={isSideRails}>
      {socialMediaLinks}
    </StyledSocialLinks>
  );
}

SocialLinks.propTypes = propTypes;
SocialLinks.defaultProps = defaultProps;

export default SocialLinks;
