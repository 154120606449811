import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';

import Tooltip from '../Tooltip';
import RichTextWithDictionary from '../RichTextWithDictionary';

const StyledCardHeader = styled.div`
  margin-top: 2rem;

  h2 {
    color: ${prop('theme.colors.blackSecondary')};
    font-family: ${prop('theme.fonts.default')};
    font-weight: ${prop('theme.fontWeights.semiBold')};
    margin: 0;
    padding: 0.625rem 0 2rem;
  }

  h3 {
    font-weight: ${prop('theme.fontWeights.semiBold')};
    font-size: 1.25rem;
    padding: 0.625rem 0 2rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
`;

const propTypes = {
  description: PropTypes.string,
  isBoxHeader: PropTypes.bool,
  title: PropTypes.object,
  tooltip: PropTypes.object
};

const defaultProps = {
  description: '',
  isBoxHeader: false,
  title: null,
  tooltip: {}
};

function TableTitle({ description, isBoxHeader, title, tooltip }) {
  if (!title || !title.text) {
    return null;
  }

  return (
    <>
      {!isBoxHeader && (
        <>
          {tooltip && tooltip.length > 0 ? (
            <TitleContainer>
              <StyledCardHeader>
                <RichTextWithDictionary render={title.richText} />
              </StyledCardHeader>
              {tooltip && (
                <Tooltip content={tooltip} triggerValue={title.text} />
              )}
            </TitleContainer>
          ) : (
            <StyledCardHeader>
              <RichTextWithDictionary render={title.richText} />
            </StyledCardHeader>
          )}
        </>
      )}
      {description && !isBoxHeader && <p>{description}</p>}
    </>
  );
}

TableTitle.propTypes = propTypes;
TableTitle.defaultProps = defaultProps;

export default TableTitle;
