const actions = {
  SET_PAGE_MODE: 'SET_PAGE_MODE',
  SET_AUTH_TOKENS: 'SET_AUTH_TOKENS',
  SET_AUTH_LOADING: 'SET_AUTH_LOADING',
  SET_IDENTITY: 'SET_IDENTITY',
  UPDATE_REGISTRATION_STATE: 'UPDATE_REGISTRATION_STATE',
  UPDATE_PRIMARY_EMAIL: 'UPDATE_PRIMARY_EMAIL',
  SET_SUBSCRIBER: 'SET_SUBSCRIBER',
  SET_CONTACT_INFORMATION: 'SET_CONTACT_INFORMATION',
  SET_ADDRESSES: 'SET_ADDRESSES',
  ADD_ADDRESSES: 'ADD_ADDRESSES',
  ADD_AGREEMENT_TEMPLATE: 'ADD_AGREEMENT_TEMPLATE',
  UPDATE_AGREEMENT_TEMPLATE: 'UPDATE_AGREEMENT_TEMPLATE',
  REMOVE_PENDING_AGREEMENTS: 'REMOVE_PENDING_AGREEMENTS',
  SET_INVOICES: 'SET_INVOICES',
  SET_SPONSORSHIP_CODE: 'SET_SPONSORSHIP_CODE',
  SET_SPONSORSHIP: 'SET_SPONSORSHIP',
  ADD_API_ERROR: 'ADD_API_ERROR',
  REMOVE_API_ERROR: 'REMOVE_API_ERROR',
  SET_LOADING_STATUS: 'SET_LOADING_STATUS',
  SET_BROKERAGE_ACCOUNTS: 'SET_BROKERAGE_ACCOUNTS',
  ADD_BROKERAGE_ACCOUNT: 'ADD_BROKERAGE_ACCOUNT',
  UPDATE_BROKERAGE_ACCOUNT: 'UPDATE_BROKERAGE_ACCOUNT',
  REMOVE_BROKERAGE_ACCOUNT: 'REMOVE_BROKERAGE_ACCOUNT',
  SET_BROKERAGES: 'SET_BROKERAGES',
  SET_LOGIN_REQUIRED: 'SET_LOGIN_REQUIRED',
  SET_SUBSCRIPTIONS: 'SET_SUBSCRIPTIONS'
};

export default actions;
