import subscriberApiClient, {
  getErrorMessage
} from '../../utils/subscriberApiClient';
import actions from '../actions';
import errorTypes from './errorTypes';

export const applySponsorshipCode = (
  sponsorshipCode,
  successCallback,
  finishedCallback
) => async (dispatch) => {
  try {
    const requestBody = { sponsorship_code: sponsorshipCode };
    const response = await subscriberApiClient.post(
      '/subscriber/sponsorships',
      requestBody
    );

    dispatch({
      type: actions.SET_SPONSORSHIP,
      payload: response.data.sponsorship
    });

    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.SPONSORSHIP
    });

    successCallback();
  } catch (error) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.SPONSORSHIP,
        message: getErrorMessage(error)
      }
    });
  }

  finishedCallback();
};

export const fetchSponsorship = () => async (dispatch) => {
  try {
    const response = await subscriberApiClient.get('/subscriber/sponsorships');
    const sponsorship =
      response.data.sponsorships.length > 0
        ? response.data.sponsorships[0]
        : null;

    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.SPONSORSHIP_FETCH_FAILED
    });

    dispatch({
      type: actions.SET_SPONSORSHIP,
      payload: sponsorship
    });
  } catch (error) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.SPONSORSHIP_FETCH_FAILED,
        message: getErrorMessage(error)
      }
    });
  }
};
