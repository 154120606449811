import { Link } from 'gatsby';
import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';

const MenuLink = styled.span.attrs((props) => ({
  as: (props.to && Link) || (props.href && 'a')
}))`
  color: ${prop('theme.colors.black')};
  display: block;
  font-family: ${prop('theme.fonts.default')};
  font-weight: ${prop('theme.fontWeights.regular')};
  line-height: 1rem;
  font-size: 0.875rem;
  text-decoration: none;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    font-size: 1rem;
  }

  &:hover {
    color: ${prop('theme.colors.redPrimary')};
    text-decoration: none;
  }

  ${ifProp(
    { type: 'stockCard' },
    css`
      color: ${prop('theme.colors.black')};
    `
  )}
`;

export default MenuLink;
