import 'react-responsive-carousel/lib/styles/carousel.min.css';
import React from 'react';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';
import { PrismicRichText } from '@prismicio/react';
import { Carousel as BaseCarousel } from 'react-responsive-carousel';

import linkResolver from '../../utils/linkResolver';

const Carousel = styled(BaseCarousel)`
  margin-bottom: 32px;
  min-height: 448px;

  .carousel-slider {
    ${ifProp(
      { $page: 'community-portal' },
      css`
        background-color: ${prop('theme.colors.white')};
        border: 1px solid ${prop('theme.colors.carouselBorderGrey')};
        border-radius: 16px;
      `,
      css`
        background: linear-gradient(
          360deg,
          rgba(196, 196, 196, 0.2) 0%,
          rgba(196, 196, 196, 0) 100%
        );
      `
    )}
    min-height: 448px;
  }

  .control-dots .dot {
    background: ${prop('theme.colors.carouselDotGrey')};
    opacity: 1;
    box-shadow: none;

    &.selected {
      background: ${prop('theme.colors.redPrimary')};
    }
  }
`;

const Slide = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    flex-direction: column;
  }
`;

const Panel = styled.div`
  display: flex;
  align-items: center;

  &:first-of-type {
    @media (max-width: ${prop('theme.breakpoints.lg')}) {
      order: 2;
      padding-bottom: 48px;
    }

    @media (min-width: ${prop('theme.breakpoints.lg')}) {
      padding: 84px 16px 84px 48px;
      text-align: left;
      width: 50%;
    }
  }

  &:last-of-type {
    @media (max-width: ${prop('theme.breakpoints.lg')}) {
      order: 1;
      padding: 84px 16px 20px;
    }

    @media (min-width: ${prop('theme.breakpoints.lg')}) {
      padding: 16px 48px 16px 0;
      width: 50%;
      display: flex;
      justify-content: center;
    }
  }
`;

const PanelImage = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;

  ${ifProp(
    '$maxWidth',
    css`
      max-width: ${prop('$maxWidth')}px;
    `
  )}
`;

const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: 512px;

  p {
    margin-bottom: 0;
  }

  a {
    margin-top: 1.25rem;
  }
`;

const ButtonLink = styled.a`
  background-color: ${prop('theme.colors.redPrimary')};
  border-radius: 40px;
  color: ${prop('theme.colors.white')};
  display: inline-block;
  font-size: 1rem;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  letter-spacing: 1px;
  line-height: 0.875rem;
  padding: 12px 48px;
  text-transform: uppercase;

  &:hover {
    color: ${prop('theme.colors.white')};
  }
`;

const propTypes = {
  headerBanner: PropTypes.array.isRequired,
  page: PropTypes.string
};

const defaultProps = {
  headerBanner: [],
  page: 'homepage'
};

function HeroCarousel({ headerBanner, page = 'homepage' }) {
  return (
    <Carousel
      autoPlay={true}
      interval={5000}
      infiniteLoop={true}
      showArrows={true}
      showIndicators={headerBanner.length > 1}
      showStatus={false}
      showThumbs={false}
      stopOnHover={true}
      $page={page}
    >
      {headerBanner.map((slide, index) => {
        const buttonLink = slide.button_link || {};
        const imageUrl = get(slide, 'image.url');
        const dimensions = slide.image.dimensions || {};
        const mobileImage =
          (slide.image.thumbnails && slide.image.thumbnails.mobile) ||
          slide.image;
        const mobileDimensions = mobileImage.dimensions || {};

        return (
          <Slide key={index}>
            <Panel>
              <ContentContainer>
                {slide.title.text && (
                  <PrismicRichText
                    field={slide.title.richText}
                    linkResolver={linkResolver}
                  />
                )}
                {slide.sub_title.text && (
                  <PrismicRichText
                    field={slide.sub_title.richText}
                    linkResolver={linkResolver}
                  />
                )}
                {buttonLink.url && slide.button_text && (
                  <ButtonLink href={buttonLink.url} target={buttonLink.target}>
                    {slide.button_text}
                  </ButtonLink>
                )}
              </ContentContainer>
            </Panel>
            <Panel>
              <PanelImage
                alt={slide.image.alt}
                height={dimensions.height}
                width={dimensions.width}
                src={imageUrl}
                srcSet={`${mobileImage.url} ${mobileDimensions.width}w, ${imageUrl} ${dimensions.width}w`}
                loading={index === 0 ? 'eager' : 'lazy'}
                $maxWidth={slide.image_max_width}
              />
            </Panel>
          </Slide>
        );
      })}
    </Carousel>
  );
}

HeroCarousel.propTypes = propTypes;
HeroCarousel.defaultProps = defaultProps;

export default HeroCarousel;
