import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';

import SEO from '../components/seo';
import Layout from '../components/layout';
import PageContainer from '../components/layout-components/PageContainer';
import PageBody from '../components/PageBody';
import MainCol from '../components/PageBody/MainCol';
import SideRailsMenu from '../components/SideRailsMenu';

const StyledColumnWrapper = styled.div`
  width: 100%;

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    margin-top: ${(props) => (props.$displaySideNav !== false ? '3.75rem' : 0)};
  }

  > * {
    &:first-child {
      > * {
        margin-top: 0;

        > h2 {
          padding-top: 0;
        }
      }
    }
  }
`;

const PageDescription = styled.div`
  padding: 2rem 1.5rem;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    padding-left: 17.75rem;
    padding-right: 17.75rem;
  }
`;

const propTypes = {
  doc: PropTypes.object
};

const defaultProps = {
  doc: {}
};

function StandardPage({ doc }) {
  const {
    page_title,
    page_description,
    side_nav_header,
    header_banner,
    display_side_nav,
    body
  } = doc;

  const pageName = page_title.text ? page_title.text : '';
  const pageDescription = page_description.text ? page_description.text : '';

  return (
    <Layout headerBanner={header_banner && header_banner.url} title={pageName}>
      <PageContainer>
        {display_side_nav !== false && (
          <SideRailsMenu title={side_nav_header || pageName} data={body} />
        )}
        <MainCol>
          <StyledColumnWrapper $displaySideNav={display_side_nav}>
            {pageDescription && (
              <PageDescription>{pageDescription}</PageDescription>
            )}
            {body && <PageBody body={body} template={'page'} />}
          </StyledColumnWrapper>
        </MainCol>
      </PageContainer>
    </Layout>
  );
}

StandardPage.propTypes = propTypes;
StandardPage.defaultProps = defaultProps;

export default StandardPage;
