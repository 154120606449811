import React from 'react';
import omit from 'lodash-es/omit';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import TableTitle from './TableTitle';
import BoxTableTitle from './BoxTableTitle';
import ResponsiveTable from './ResponsiveTable';
import BlockStyles from '../BlockStyles';

const TableContainer = styled.div`
  padding-top: 1rem;

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    overflow-x: scroll;
  }
`;

const propTypes = {
  head: PropTypes.object,
  body: PropTypes.array,
  template: PropTypes.string
};

const defaultProps = {
  head: null,
  body: [],
  template: ''
};

function TableBlock({ head, body, template }) {
  let tableTitle = !isEmpty(head.table_title) && head.table_title.richText[0];
  let tableDescription =
    !isEmpty(head.table_description) && head.table_description.richText[0];

  const { tooltip } = head;
  const title = tableTitle ? tableTitle.text : '';
  const description = tableDescription ? tableDescription.text : '';
  const blockId = title ? title.replace(/\s/g, '') : '';
  const label = tableTitle ? tableTitle.spans : '';
  const data = label && label.length !== 0 ? label[0].data : null;
  const isBoxHeader = data && data.label === 'Box';

  const filteredHead = omit(head, [
    'table_title',
    'table_description',
    'table_type',
    'tooltip',
    'tooltip_col1',
    'tooltip_col2',
    'col1_span',
    'col3_span',
    'content_collapse',
    'column_width',
    'block_styles_side_nav'
  ]);

  return (
    <BlockStyles
      sideNav={
        head.block_styles_side_nav != null ? head.block_styles_side_nav : true
      }
      template={template}
      data={head}
    >
      <div id={blockId} className='block anchorOffset'>
        {title && isBoxHeader && (
          <BoxTableTitle
            banner={description}
            blockId={blockId}
            title={title}
            tooltip={tooltip}
          />
        )}
        {((!isBoxHeader && title) || (!isBoxHeader && description)) && (
          <TableTitle
            description={description}
            isBoxHeader={isBoxHeader}
            title={head.table_title}
            tooltip={tooltip}
          />
        )}
        <TableContainer>
          <ResponsiveTable columns={filteredHead} head={head} rows={body} />
        </TableContainer>
      </div>
    </BlockStyles>
  );
}

TableBlock.propTypes = propTypes;
TableBlock.defaultProps = defaultProps;

export default TableBlock;
