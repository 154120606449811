import { useCallback, useEffect, useState } from 'react';

function useElementSize(elementRef) {
  const [size, setSize] = useState({
    width: null,
    height: null,
    offsetTop: 0
  });

  // Prevent too many rendering using useCallback
  const updateSize = useCallback(() => {
    const node = elementRef?.current;
    if (node !== null && typeof node !== 'undefined') {
      setSize({
        width: node.offsetWidth || size.width,
        height: node.offsetHeight || size.height,
        offsetTop: node.offsetTop || size.offsetTop
      });
    }
  }, [elementRef]);

  // Initial size on mount
  useEffect(() => {
    // Add event listener
    window.addEventListener('resize', updateSize);

    // Setting brief delay since elements initially load as null;
    // we want values to populate on initial load
    const timer = setTimeout(() => {
      updateSize();
    }, 50);

    return () => {
      window.removeEventListener('resize', updateSize);
      clearTimeout(timer);
    };
  }, [elementRef.current]);

  return size;
}

export default useElementSize;
