import auth0 from 'auth0-js';

import { removeAuthToken } from './subscriberApiClient';
import dayjs from './dayjs';

const isBrowser = () => typeof window !== 'undefined';

const defaultRedirectUri =
  process.env.GATSBY_AUTH0_CALLBACK || process.env.AUTH0_CALLBACK;
// We have duplicate env variables here because gatsby requires env vars with a GATSBY prefix for use in browser mode
// For server-side rendering, we use the non-GATSBY env vars
const auth = isBrowser()
  ? new auth0.WebAuth({
      domain: process.env.GATSBY_AUTH0_DOMAIN || process.env.AUTH0_DOMAIN,
      clientID: process.env.GATSBY_AUTH0_CLIENTID || process.env.AUTH0_CLIENTID,
      redirectUri: defaultRedirectUri,
      responseType: 'token id_token',
      audience:
        process.env.GATSBY_AUTH0_AUDIENCE_ID || process.env.AUTH0_AUDIENCE_ID,
      scope: 'openid profile email'
    })
  : {};

const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false
};

export const isAuthenticated = () => {
  if (!isBrowser()) {
    return false;
  }

  const expiresAt = parseInt(localStorage.getItem('expiresAt'));
  const sessionIsActive =
    !isNaN(expiresAt) && dayjs(expiresAt).isAfter(dayjs());

  return localStorage.getItem('isLoggedIn') === 'true' && sessionIsActive;
};

export const login = (params) => {
  if (!isBrowser()) {
    return;
  }

  if (params) {
    auth.authorize({
      redirectUri: `${defaultRedirectUri}?${params}`
    });
  } else {
    auth.authorize();
  }
};

export const handleAuthentication = () => {
  if (!isBrowser()) {
    return;
  }

  return new Promise((resolve, reject) => {
    auth.parseHash((err, authResult) => {
      if (err) {
        reject(err);
      } else if (authResult && authResult.accessToken && authResult.idToken) {
        authenicate(authResult);
        resolve(authResult);
      }
    });
  });
};

export const silentAuth = () => {
  return new Promise((resolve, reject) => {
    if (!isAuthenticated()) {
      reject({ message: 'Session expired' });
    }
    auth.checkSession({}, (err, authResult) => {
      if (err) {
        reject(err);
      } else if (authResult && authResult.accessToken && authResult.idToken) {
        authenicate(authResult);
        resolve(authResult);
      }
    });
  });
};

const authenicate = (authResult) => {
  const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
  tokens.accessToken = authResult.accessToken;
  tokens.idToken = authResult.idToken;
  tokens.expiresAt = expiresAt;
  localStorage.setItem('isLoggedIn', true);
  localStorage.setItem('expiresAt', expiresAt);
};

export const logout = () => {
  localStorage.setItem('isLoggedIn', false);
  removeAuthToken();
  auth.logout();
};
