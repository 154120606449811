import React from 'react';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import { PrismicRichText } from '@prismicio/react';
import { graphql, StaticQuery } from 'gatsby';

import RecentArticles from './RecentArticles';
import BlockStyles from '../BlockStyles';
import NewsItemPreview from '../NewsComponents/NewsItemPreview';

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: stretch;

  &:first-child {
    margin-bottom: 1rem;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    flex-direction: column;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 50%;
  max-width: 50%;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 1rem;
  }
`;

const MoreLink = styled.a`
  margin-top: 1rem;
  color: ${prop('theme.colors.redPrimary')};
  font-size: 0.875rem;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const propTypes = {
  className: PropTypes.string,
  primary: PropTypes.object,
  template: PropTypes.string
};

const defaultProps = {
  className: '',
  primary: {},
  template: ''
};

function ArticlesBlock({ className, primary, template }) {
  const { featured_article, header, view_more_link } = primary;
  const title = header && header.text;
  const blockId = title ? title.replace(/\s/g, '') : '';

  return (
    <BlockStyles data={primary} template={template}>
      <div id={blockId} className={className + ' block anchorOffset'}>
        {header.text && <PrismicRichText field={header.richText} />}
        <Row>
          <StaticQuery
            query={`${recentArticlesQuery}`}
            render={(data) => {
              const articles = get(data, 'allPrismicNewsItem.edges');
              const setFeatured = get(featured_article, 'uid');
              const featuredArticle = setFeatured
                ? get(featured_article, 'document')
                : get(articles[0], 'node');
              const start = setFeatured ? 0 : 1;
              const end = setFeatured ? 4 : 5;

              return (
                <>
                  {featuredArticle && (
                    <NewsItemPreview newsItem={featuredArticle} featured />
                  )}
                  <Col>
                    <RecentArticles articles={articles.slice(start, end)} />
                  </Col>
                </>
              );
            }}
          />
        </Row>
        <Row>
          <Col>
            <MoreLink
              href={view_more_link ? view_more_link.url : '/news/category/usa'}
            >
              View More →
            </MoreLink>
          </Col>
        </Row>
      </div>
    </BlockStyles>
  );
}

export const recentArticlesQuery = graphql`
  query RecentArticlesQuery {
    allPrismicNewsItem(
      limit: 5
      sort: { fields: data___post_date, order: DESC }
    ) {
      edges {
        node {
          url
          ...NewsInfo
        }
      }
    }
  }
`;

ArticlesBlock.propTypes = propTypes;
ArticlesBlock.defaultProps = defaultProps;

export default ArticlesBlock;
