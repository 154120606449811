import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import { DEFAULT_PAYMENT_RETURN_PATH } from '../../../../utils/constants';
import {
  fetchInvoices,
  fetchPaymentSession
} from '../../../../state/actions/invoiceActions';
import errorTypes from '../../../../state/actions/errorTypes';
import { SubmitSpinner } from '../RegistrationForm';

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    flex-direction: column;
  }
`;

const Button = styled.button`
  min-width: 200px;
  align-self: flex-end;
  border-radius: 40px;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  text-transform: uppercase;
  color: ${prop('theme.colors.white')};
  letter-spacing: 1px;
  border: none;
  padding: 8px 24px;
  background-color: ${prop('theme.colors.redPrimary')};
  font-size: 0.875rem;

  &:disabled {
    background-color: ${prop('theme.colors.backgroundGrey')};
  }

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    font-size: 1.375rem;
    min-height: 56px;
    width: 100%;
  }
`;

const propTypes = { buttonText: PropTypes.string };

const defaultProps = { buttonText: '' };

function StripeCheckoutButton({ buttonText }) {
  const invoices = useSelector(
    (state) => state.memberInfo && state.memberInfo.invoices
  );
  const dispatch = useDispatch();
  const [stripe, setStripe] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // We have a duplicate env variable here because gatsby requires env vars with a GATSBY prefix for use in browser mode
    // For server-side rendering, we use the non-GATSBY env vars
    if (typeof window !== 'undefined') {
      setStripe(
        window.Stripe(
          process.env.GATSBY_STRIPE_PUBLISHABLE_KEY ||
            process.env.STRIPE_PUBLISHABLE_KEY
        )
      );
    }

    dispatch(fetchInvoices());
  }, []);

  const redirectToCheckout = async () => {
    if (stripe && invoices.length > 0) {
      setIsLoading(true);
      // If a user is paying during sponsorship registration, we need to send them to a standalone success page after payment
      let successPath = DEFAULT_PAYMENT_RETURN_PATH;
      if (typeof window !== 'undefined') {
        if (window.location.pathname.includes('/sponsorship-registration')) {
          successPath = '/payment-successful/';
        }
      }
      const successCallback = (session) => {
        stripe
          .redirectToCheckout({
            sessionId: session.id
          })
          .then((result) => {
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            dispatch({
              type: actions.ADD_API_ERROR,
              payload: {
                type: errorTypes.PAYMENT_SESSION,
                message: getErrorMessage(error)
              }
            });
          });
      };
      dispatch(
        fetchPaymentSession(
          invoices[0].external_id,
          successCallback,
          () => {
            setIsLoading(false);
          },
          successPath
        )
      );
    }
  };

  return (
    <ButtonContainer>
      <Button onClick={redirectToCheckout} disabled={isLoading}>
        {buttonText}
      </Button>
      {isLoading && (
        <SubmitSpinner>
          <Spinner />
        </SubmitSpinner>
      )}
    </ButtonContainer>
  );
}

StripeCheckoutButton.propTypes = propTypes;
StripeCheckoutButton.defaultProps = defaultProps;

export default StripeCheckoutButton;
