import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray } from 'react-hook-form';
import get from 'lodash-es/get';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

import FormTitle from '../RegistrationForm/FormTitle';
import Input from '../RegistrationForm/Input';
import Select from '../RegistrationForm/Select';
import InputRow from '../RegistrationForm/styled/InputRow';
import TitleRow from '../RegistrationForm/styled/TitleRow';
import AddButton from '../RegistrationForm/styled/AddButton';
import DeleteButton from '../RegistrationForm/styled/DeleteButton';
import { COUNTRY_LIST, STATE_LIST } from '../../../../utils/selectLists';
import {
  capitalizeFirstLetter,
  isNullValue
} from '../../../../utils/functions';
import {
  COUNTRY_CODE_US,
  ADDRESS_TYPE_HOME,
  ADDRESS_TYPE_MAILING,
  ADDRESS_TYPE_BILLING
} from '../../../../utils/constants';

const propTypes = {
  storedAddresses: PropTypes.array,
  contactInformation: PropTypes.object,
  useFormObject: PropTypes.object,
  phoneTitle: PropTypes.object,
  homeAddressTitle: PropTypes.object,
  additionalAddressTitle: PropTypes.object
};

const defaultProps = {
  storedAddresses: [],
  contactInformation: {},
  useFormObject: {},
  phoneTitle: {},
  homeAddressTitle: {},
  additionalAddressTitle: {}
};

function ContactInfoFields({
  storedAddresses,
  contactInformation,
  useFormObject,
  phoneTitle,
  homeAddressTitle,
  additionalAddressTitle
}) {
  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues
  } = useFormObject;

  const { fields: addresses, append, remove, update, replace } = useFieldArray({
    control,
    name: 'addresses'
  });

  let defaultPhoneCountry = {
    value: COUNTRY_CODE_US,
    label: 'United States'
  };
  if (contactInformation && contactInformation.phoneNumber) {
    const phoneObject = parsePhoneNumber(
      get(contactInformation, 'phoneNumber.information', '')
    );
    if (phoneObject.country) {
      defaultPhoneCountry = COUNTRY_LIST.find(
        (countryOption) => countryOption.value === phoneObject.country
      );
    }
  }
  const [phoneCountry, setPhoneCountry] = useState(defaultPhoneCountry);
  const defaultAddressCountry = {
    value: COUNTRY_CODE_US,
    label: 'United States'
  };
  const [addressCountries, setAddressCountries] = useState([
    defaultAddressCountry
  ]);

  function populateAddressFields(storedAddresses) {
    const newFields = [...storedAddresses]
      .sort((a, b) => (a.id <= b.id ? -1 : 1))
      .map((address) => {
        const formattedAddress = { ...address };

        const addressTypeLabel =
          capitalizeFirstLetter(formattedAddress.addressType) +
          (formattedAddress.addressType === ADDRESS_TYPE_HOME ? '*' : '');
        formattedAddress.addressType = {
          label: addressTypeLabel,
          value: formattedAddress.addressType
        };

        formattedAddress.country = COUNTRY_LIST.find(
          (country) => country.value === formattedAddress.country
        );

        if (address.country === COUNTRY_CODE_US) {
          formattedAddress.state = STATE_LIST.find(
            (state) => state.value === formattedAddress.state
          );
        }

        return formattedAddress;
      });

    replace(newFields);
    setAddressCountries(() => newFields.map((field) => field.country));
  }

  useEffect(() => {
    if (addresses.length === 0) {
      if (storedAddresses.length > 0) {
        populateAddressFields(storedAddresses);
      } else {
        append(
          {
            addressType: { label: 'Home*', value: ADDRESS_TYPE_HOME },
            country: defaultAddressCountry,
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            postalCode: ''
          },
          { shouldFocus: false }
        );
      }
    }
  }, []);

  useEffect(() => {
    if (
      addresses.length === 3 &&
      get(addresses[1], 'addressType.value', '') ===
        get(addresses[2], 'addressType.value', '')
    ) {
      update(2, {
        addressType:
          get(addresses[1], 'addressType.value', '') === ADDRESS_TYPE_MAILING
            ? addressTypes[1]
            : addressTypes[0],
        country: defaultAddressCountry,
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: ''
      });
    }
  }, [addresses]);

  const isAddressValid = (addressIndex) => {
    if (errors.addresses && errors.addresses[addressIndex]) {
      return false;
    }

    const { addressLine1, city, postalCode, state } = getValues(
      `addresses.${addressIndex}`
    );

    if (
      addressLine1 === '' ||
      city === '' ||
      postalCode === '' ||
      state === ''
    ) {
      return false;
    }

    return true;
  };

  const isPhoneValid =
    isNullValue(errors.phoneNumber) && !!getValues('phoneNumber');

  const addressTypes = [
    { label: 'Mailing', value: ADDRESS_TYPE_MAILING },
    { label: 'Billing', value: ADDRESS_TYPE_BILLING }
  ];

  return (
    <>
      <FormTitle title={phoneTitle} isValid={isPhoneValid} />
      <InputRow>
        <Select
          options={COUNTRY_LIST}
          placeholder='Choose Country*'
          value={phoneCountry}
          onChange={(option) => {
            setPhoneCountry(option);
          }}
          $minWidth={270}
        />
        <Input
          type='phone'
          name={'phoneNumber'}
          labelText='Phone Number*'
          error={errors.phoneNumber}
          country={phoneCountry ? phoneCountry.value : ''}
          control={control}
          setValue={setValue}
          defaultValue={get(contactInformation, 'phoneNumber.information', '')}
          // 10 numeric values + 2 parentheses + 1 space + 1 hyphen = 14 total characters for a US phone number
          // If it's not a US number, we cap it to 20 because we don't want to handle every country's specific formatting
          maxLength={
            phoneCountry && phoneCountry.value === COUNTRY_CODE_US ? 14 : 20
          }
          rules={{
            required: { value: true, message: 'Phone Number is required' },
            validate: (value) =>
              isValidPhoneNumber(value) || 'Phone Number is invalid'
          }}
        />
      </InputRow>
      {addresses.map((field, index) => {
        const isUsAddress =
          addressCountries[index] &&
          addressCountries[index].value === COUNTRY_CODE_US;
        const additionalAddressTitleCopy = Object.assign(
          {},
          additionalAddressTitle
        );
        additionalAddressTitleCopy.richText = additionalAddressTitleCopy.richText
          ? additionalAddressTitleCopy.richText.map((rawItem) => {
              const newRawItem = Object.assign({}, rawItem);
              newRawItem.text = `${rawItem.text} #${index}`;
              return newRawItem;
            })
          : additionalAddressTitleCopy.richText;

        return (
          <React.Fragment key={field.id}>
            <TitleRow>
              <FormTitle
                title={
                  field.addressType &&
                  field.addressType.value === ADDRESS_TYPE_HOME
                    ? homeAddressTitle
                    : additionalAddressTitleCopy
                }
                isValid={isAddressValid(index)}
              />
              {index === 0 && (
                <AddButton
                  type='button'
                  disabled={addresses.length === 3}
                  onClick={() => {
                    setAddressCountries((prev) => [
                      ...prev,
                      defaultAddressCountry
                    ]);
                    append(
                      {
                        addressType: addressTypes[0],
                        country: defaultAddressCountry,
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        state: '',
                        postalCode: ''
                      },
                      { shouldFocus: false }
                    );
                  }}
                >
                  Add Address
                </AddButton>
              )}
            </TitleRow>
            <InputRow>
              <Select
                name={`addresses.${index}.addressType`}
                control={control}
                options={addressTypes}
                defaultValue={field.addressType || addressTypes[index]}
                setValue={setValue}
                rules={{ required: true }}
                isDisabled={index === 0}
                isOptionDisabled={(option) => {
                  const found = addresses.findIndex(
                    (address) =>
                      address.addressType &&
                      address.addressType.value === option.value
                  );

                  return found >= 0 && found !== index;
                }}
              />
              <Select
                name={`addresses.${index}.country`}
                control={control}
                options={COUNTRY_LIST}
                defaultValue={field.country}
                placeholder='Choose Country*'
                setValue={setValue}
                rules={{ required: true }}
                $fillWidth
                value={addressCountries[index]}
                onChange={(option) => {
                  setAddressCountries((prev) => {
                    const newCountries = [...prev];
                    newCountries.splice(index, 1, option);
                    return newCountries;
                  });
                }}
              />
              {index > 0 && (
                <DeleteButton
                  onClick={() => {
                    remove(index);
                    setAddressCountries((prev) => {
                      const newCountries = [...prev];
                      newCountries.splice(index - 1, 1);
                      return newCountries;
                    });
                  }}
                />
              )}
            </InputRow>
            <Input
              type='text'
              name={`addresses.${index}.addressLine1`}
              labelText='Address Line 1*'
              defaultValue={field.addressLine1}
              error={get(errors, `addresses.${index}.addressLine1`)}
              {...register(`addresses.${index}.addressLine1`, {
                required: 'Address Line 1 is required',
                pattern: {
                  value: /[A-Za-z0-9.,]+/i,
                  message: 'Address Line 1 is invalid'
                }
              })}
            />
            <Input
              type='text'
              name={`addresses.${index}.addressLine2`}
              labelText='Address Line 2'
              defaultValue={field.addressLine2}
              error={get(errors, `addresses.${index}.addressLine2`)}
              {...register(`addresses.${index}.addressLine2`, {
                pattern: {
                  value: /[A-Za-z0-9.,]+/i,
                  message: 'Address Line 2 is invalid'
                }
              })}
            />
            <InputRow>
              <Input
                type='text'
                name={`addresses.${index}.city`}
                labelText='City*'
                defaultValue={field.city}
                error={get(errors, `addresses.${index}.city`)}
                {...register(`addresses.${index}.city`, {
                  required: 'City is required',
                  pattern: {
                    value: /[A-Za-z0-9.,]+/i,
                    message: 'City is invalid'
                  }
                })}
              />
              {/* Need to use a state value here instead of form value, since form value does not cause a re-render like state does */}
              {isUsAddress ? (
                <Select
                  name={`addresses.${index}.state`}
                  control={control}
                  options={STATE_LIST}
                  placeholder='State / Territory / Province*'
                  defaultValue={field.state}
                  setValue={setValue}
                  rules={{ required: true }}
                  $fillWidth
                />
              ) : (
                <Input
                  type='text'
                  name={`addresses.${index}.state`}
                  labelText='State / Territory / Province*'
                  defaultValue={field.state}
                  error={get(errors, `addresses.${index}.state`)}
                  {...register(`addresses.${index}.state`, {
                    required: 'State / Territory / Province is required',
                    pattern: {
                      value: /[A-Za-z]+/i,
                      message: 'State / Territory / Province is invalid'
                    }
                  })}
                />
              )}
              <Input
                type='text'
                name={`addresses.${index}.postalCode`}
                labelText='Postal Code*'
                defaultValue={field.postalCode}
                maxLength={isUsAddress && 5}
                error={get(errors, `addresses.${index}.postalCode`)}
                {...register(`addresses.${index}.postalCode`, {
                  required: 'Postal Code is required',
                  pattern: {
                    value: isUsAddress ? /[0-9]{5}/i : /[A-Z0-9]+/i,
                    message: 'Postal Code is invalid'
                  }
                })}
              />
            </InputRow>
          </React.Fragment>
        );
      })}
    </>
  );
}

ContactInfoFields.propTypes = propTypes;
ContactInfoFields.defaultProps = defaultProps;

export default ContactInfoFields;
