import React, { useEffect, useRef, useState } from 'react';
import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import get from 'lodash-es/get';

import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';
import { HEADER_HEIGHT, FOOTER_HEIGHT } from '../../utils/constants';

const StickyDiv = styled.div`
  background-color: transparent;
  height: inherit;
  margin-top: ${(props) => (props.top ? '8rem' : '0')};
  z-index: 100;
  width: inherit;
  padding-left: 1.4rem;
  flex: 0 0 16.666667%;
  max-width: 16.25rem;
  position: absolute;
  top: 0;

  ${ifProp(
    { $page: 'Homepage' },
    css`
      @media (min-width: ${prop('theme.breakpoints.lg')}) {
        background-color: ${prop('theme.colors.white')};
        border-radius: 0 24px 24px 0;
        padding-bottom: 1.25rem;
        padding-right: 1.5rem;
        padding-top: 0.8rem;
      }
    `
  )}

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    padding-left: 2.75rem;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    background-color: ${prop('theme.colors.backgroundGreyLight')};
    left: 0;
    margin-top: 0;
    max-width: 100vw;
    padding: 1.25rem 0 1.25rem 2rem;
    width: 100%;
    flex: 0 0 100%;
    position: fixed;
    top: 108px;
  }

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    ${ifProp(
      '$scrolling',
      css`
        position: fixed;
        top: 128px;

        ${ifProp(
          '$fixBottom',
          css`
            position: absolute;
            bottom: 40px;
            top: auto;
          `
        )}
      `
    )}
  }
`;

const propTypes = {
  title: PropTypes.string,
  titleUrl: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  renderMenu: PropTypes.element
};

const defaultProps = {
  title: '',
  titleUrl: null,
  data: [],
  renderMenu: null
};

function SideRailsMenu({ title, data, titleUrl, renderMenu }) {
  const sideRailRef = useRef(null);
  const [scrolling, setScrolling] = useState(false);
  const [fixBottom, setFixBottom] = useState(false);
  const offsetTop = title === 'Homepage' ? 450 : 144;

  useEffect(() => {
    const onScroll = (event) => {
      const sideRailHeight = get(sideRailRef, 'current.offsetHeight');
      const viewportHeight =
        window.innerHeight - (HEADER_HEIGHT + FOOTER_HEIGHT);
      const fixPoint =
        sideRailHeight + HEADER_HEIGHT + FOOTER_HEIGHT - window.innerHeight;
      const distFromBottom =
        document.body.scrollHeight - window.innerHeight - window.scrollY;

      setScrolling(window.pageYOffset > offsetTop ? true : false);
      setFixBottom(
        sideRailHeight > viewportHeight && distFromBottom <= fixPoint
          ? true
          : false
      );
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrolling, fixBottom]);

  if (!data && !renderMenu) {
    return null;
  }

  return (
    <StickyDiv
      id='railsMenu'
      $fixBottom={fixBottom}
      $page={title}
      ref={sideRailRef}
      $scrolling={scrolling}
    >
      {/* 
        We had a lot of trouble trying to programmatically display the correct component here, so now the
        components just use display: none with media queries to determine if they should display or not
      */}
      <MobileMenu data={data} renderMenu={renderMenu} title={title} />
      <DesktopMenu
        data={data}
        renderMenu={renderMenu}
        title={title}
        titleUrl={titleUrl}
      />
    </StickyDiv>
  );
}

SideRailsMenu.propTypes = propTypes;
SideRailsMenu.defaultProps = defaultProps;

export default SideRailsMenu;
