import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

const ColumnWrapper = styled.div`
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    margin-top: ${(props) => (props.subNav ? '70px' : '8px')};
  }

  ${ifProp(
    '$news',
    css`
      display: flex;
      flex-direction: column;
      margin-bottom: 124px;

      @media (max-width: ${prop('theme.breakpoints.xxl')}) {
        padding: 0 60px;
      }

      @media (max-width: ${prop('theme.breakpoints.md')}) {
        padding: 0 24px;
      }
    `
  )}
`;

ColumnWrapper.defaultProps = {
  subNav: true
};

export default ColumnWrapper;
