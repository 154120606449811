import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MenuItem from './styled/MenuItem';
import MenuLink from './styled/MenuLink';
import RailMenuList from './styled/RailMenuList';
import SideRailContainer from './styled/SideRailContainer';
import last from 'lodash-es/last';

const propTypes = {
  menus: PropTypes.array,
  toggle: PropTypes.func
};

const defaultProps = {
  menus: null
};

function RailMenus({ menus, toggle }) {
  const [currentSection, setCurrentSection] = useState('');

  useEffect(() => {
    const setCurrentSectionListener = () => {
      if (typeof window !== 'undefined') {
        setCurrentSection(window.location.hash);
      }
    };

    window.addEventListener('scroll', setCurrentSectionListener);
    return () =>
      window.removeEventListener('scroll', setCurrentSectionListener);
  }, [currentSection]);

  if (!menus) {
    return;
  }

  const railsMenus = menus.map((elem, index) => {
    let sliceTitle = '';
    let titleType = null;

    if (elem.slice_type === 'calendar_slice') {
      const { calendar_title } = elem.primary;
      sliceTitle = calendar_title.text ? calendar_title.text : '';
      titleType = calendar_title.text && calendar_title.richText[0].type;
    } else if (elem.slice_type === 'text_block') {
      const { text_block_header, display_in_side_nav } = elem.primary;
      sliceTitle = text_block_header.text ? text_block_header.text : '';
      titleType =
        text_block_header.text &&
        display_in_side_nav !== false &&
        text_block_header.richText[0].type;
    } else if (elem.slice_type === 'table__10_cols_') {
      const { table_title } = elem.primary;
      sliceTitle = table_title.text ? table_title.text : '';
      titleType = table_title.text && table_title.richText[0].type;
    } else if (elem.slice_type === 'menu_link') {
      const { menu_link_text } = elem.primary;
      sliceTitle = menu_link_text ? menu_link_text.text : '';
      titleType = 'heading2';
    } else if (elem.slice_type === 'articles_block') {
      const { header } = elem.primary;
      sliceTitle = header ? header.text : '';
      titleType = 'heading2';
    } else if (elem.slice_type === 'cta_block') {
      const { title } = elem.primary;
      sliceTitle = title ? title.text : '';
      titleType = 'heading2';
    }

    // Only render heading level 2 in siderail menu.
    if (!titleType || titleType !== 'heading2') {
      return null;
    }

    const anchorTitle = sliceTitle ? sliceTitle.replace(/\s/g, '') : '';
    const menuLink =
      elem.slice_type === 'menu_link'
        ? elem.primary.menu_link.url
        : `#${anchorTitle}`;

    return sliceTitle ? (
      <MenuItem
        key={`anchor_${index}`}
        className={
          currentSection && currentSection.includes(anchorTitle) ? 'active' : ''
        }
      >
        <MenuLink
          href={menuLink}
          target={elem.slice_type === 'menu_link' ? '_blank' : '_self'}
          onClick={toggle}
        >
          {sliceTitle}
        </MenuLink>
      </MenuItem>
    ) : (
      ''
    );
  });

  return (
    <SideRailContainer>
      <RailMenuList>{railsMenus}</RailMenuList>
    </SideRailContainer>
  );
}

RailMenus.propTypes = propTypes;
RailMenus.defaultProps = defaultProps;

export default RailMenus;
