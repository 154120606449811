import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { ifProp, ifNotProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { PrismicRichText } from '@prismicio/react';

import linkResolver from '../../utils/linkResolver';
import tooltipIcon from 'images/tooltip-icon.svg';

export const TooltipIcon = styled.a`
  height: fit-content;
  cursor: pointer;
  margin-top: -4px;

  ${ifProp(
    'inTable',
    css`
      margin-left: 0.25rem;
      position: unset;

      @media (min-width: ${prop('theme.breakpoints.md')}) {
        position: absolute;
        right: auto;
      }
    `
  )}
`;

export const StyledReactTooltip = styled(ReactTooltip)`
  max-width: 15.625rem;

  &&& {
    background-color: ${prop('theme.colors.white')};
    border-radius: 1.5rem;
    box-shadow: 0 0 5px ${prop('theme.colors.borderGrey')};
    opacity: 1;
    padding: 1.5rem;

    @media (max-width: ${prop('theme.breakpoints.xxs')}) {
      margin-left: ${ifNotProp('inTable', '-5.5rem', '0')};
    }

    @media (min-width: ${prop('theme.breakpoints.xxs')}) {
      margin-left: ${ifNotProp('inTable', '-6rem', '0')};
    }

    @media (min-width: ${prop('theme.breakpoints.xs')}) {
      max-width: 18.65rem;
    }

    @media (max-width: ${prop('theme.breakpoints.md')}) {
      margin-left: ${ifNotProp('inTable', '-10.5rem', '0')};
      max-width: 25rem;
    }

    @media (min-width: ${prop('theme.breakpoints.lg')}) {
      margin-left: 0;
      max-width: 31.25rem;
    }

    &:after {
      border-style: none;
    }
  }

  h4,
  h5,
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    font-size: 0.875rem;
  }
`;

const TooltipTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: ${prop('theme.fontWeights.semiBold')};
`;

const propTypes = {
  content: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  count: PropTypes.number,
  inTable: PropTypes.bool,
  place: PropTypes.string,
  triggerValue: PropTypes.string
};

const defaultProps = {
  content: {},
  count: null,
  inTable: false,
  place: '',
  triggerValue: ''
};

function Tooltip({ title, content, count, inTable, place, triggerValue }) {
  let mobileCount = '';

  if (count != null) {
    mobileCount = count;
  }

  return (
    <>
      {content.text && (
        <LazyLoad debounce={100} once>
          <TooltipIcon
            data-tip
            data-event='click'
            data-place={place}
            data-for={`${triggerValue}${mobileCount}`}
            inTable={inTable}
          >
            <img src={tooltipIcon} />
          </TooltipIcon>
          <StyledReactTooltip
            clickable={true}
            effect='solid'
            globalEventOff='click'
            id={`${triggerValue}${mobileCount}`}
            inTable={inTable}
            multiline={true}
          >
            <TooltipTitle>{title}</TooltipTitle>
            <PrismicRichText
              field={content.richText}
              linkResolver={linkResolver}
            />
          </StyledReactTooltip>
        </LazyLoad>
      )}
    </>
  );
}

Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;

export default Tooltip;
