import styled from 'styled-components';
import { prop } from 'styled-tools';

const SideRailHeader = styled.h3`
  &&& {
    display: none;
    font-family: ${prop('theme.fonts.default')};
    font-weight: ${prop('theme.fontWeights.medium')};
    font-style: normal;
    font-size: 1.375rem;
    letter-spacing: 0.02rem;
    margin-bottom: 1.5rem;

    @media (min-width: ${prop('theme.breakpoints.lg')}) {
      display: block;
    }

    a {
      color: ${prop('theme.colors.black')};

      &:hover {
        color: ${prop('theme.colors.redPrimary')};
        text-decoration: none;
      }
    }
  }
`;

export default SideRailHeader;
