import { PrismicRichText } from '@prismicio/react';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import linkResolver from '../../utils/linkResolver';
import SocialLinks from '../SocialLinks';
import FooterAddress from './FooterAddress';
import FooterForm from './FooterForm';
import ItemList from './ItemList';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  order: 2;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    width: 33.333%;
  }

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    text-align: left;
  }
`;

const LogoColumn = styled(Column)`
  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    order: 1;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1.5rem 1.875rem;

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    flex-direction: row;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
    justify-content: center;

    div:first-child {
      margin-right: 3rem;
    }
  }
`;

const StyledFooter = styled.div`
  border-top: solid 1px ${prop('theme.colors.greyDivider')};
  background-color: ${prop('theme.colors.backgroundGreyLighter')};
  font-style: normal;
  font-family: ${prop('theme.fonts.default')};
  font-size: 1rem;
  line-height: 1.5rem;
  position: relative;
  width: 100%;
  z-index: 2;

  @media (max-width: ${prop('theme.breakpoints.xl')}) {
    padding: 0 11.35%;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    padding: 0 16.35%;
  }
`;

const Disclaimer = styled.div`
  margin-top: 1.875rem;
  padding-bottom: 3.5rem;

  h4 {
    color: ${prop('theme.colors.black')};
    line-height: 1.2;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 800;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 0.875rem;
    line-height: 1rem;
  }
`;

const Main = styled.div`
  width: 65.5%;
  margin: 4.375rem auto 0;
  max-width: 72.75rem;

  @media (max-width: ${prop('theme.breakpoints.xl')}) {
    width: 100%;
    max-width: 100%;
  }
`;

const MediaLinks = styled.div`
  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    display: flex;
    justify-content: center;
  }

  img {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0.3125rem 0 1.5rem;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    align-items: flex-start;
    flex-direction: row;
  }

  a {
    color: ${prop('theme.colors.black')};
    text-decoration: none;
  }
`;

const Copyright = styled.div`
  font-weight: ${prop('theme.fontWeights.regular')};
  text-align: center;
  margin-top: 1.125rem;
  font-size: 0.6875rem;
`;

const StyledHr = styled.hr`
  margin: 0 5px;
  color: ${prop('theme.colors.greyDivider')};
`;

const propTypes = {
  data: PropTypes.object
};

const defaultProps = {
  data: null
};

function Footer({ data }) {
  if (!data) {
    return null;
  }

  const {
    disclaimer,
    disclaimer_body,
    disclaimer_footer,
    information_group,
    client_group,
    footer_logo,
    contact_address,
    copyright,
    body
  } = data;

  const disclaimerHead = disclaimer ? disclaimer.text : '';
  const disclaimerBody = disclaimer_body ? disclaimer_body.richText : '';
  const disclaimerFoot = disclaimer_footer ? disclaimer_footer.text : '';
  const address = contact_address ? contact_address.text : '';
  const copyrightText = copyright ? copyright.text : '';

  let socialLinks = '';
  let form = '';
  if (body) {
    socialLinks = body.map((body, index) => {
      if (body && body.slice_type === 'social_links') {
        return <SocialLinks key={`body-${index}`} socials={body.items} />;
      }

      return null;
    });
    form = body.map((body, index) => {
      if (body && body.slice_type === 'form_slice') {
        return <FooterForm key={`body-${index}`} formData={body.primary} />;
      }

      return null;
    });
  }

  return (
    <StyledFooter>
      <Main>
        <MainContent>
          <Column>
            <ListContainer>
              <ItemList title='INFORMATION' items={information_group} />
              <ItemList title='CLIENT' items={client_group} />
            </ListContainer>
          </Column>
          <LogoColumn>
            <FooterAddress logo={footer_logo} address={address} />
            <MediaLinks>{socialLinks}</MediaLinks>
            <Copyright>{copyrightText}</Copyright>
          </LogoColumn>
          <Column>{form}</Column>
        </MainContent>
        <StyledHr />
        <Disclaimer>
          <h4>{disclaimerHead}</h4>
          <PrismicRichText field={disclaimerBody} linkResolver={linkResolver} />
          <p>{disclaimerFoot}</p>
        </Disclaimer>
      </Main>
    </StyledFooter>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
