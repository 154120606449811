import subscriberApiClient, {
  getErrorMessage
} from '../../utils/subscriberApiClient';
import actions from '../actions';
import errorTypes from './errorTypes';

export const createSubscriber = (
  firstName,
  lastName,
  email,
  successCallback,
  finishedCallback
) => async (dispatch) => {
  const requestBody = {
    first_name: firstName,
    last_name: lastName,
    email: email
  };
  try {
    const response = await subscriberApiClient.post('/subscriber', requestBody);
    dispatch({
      type: actions.SET_SUBSCRIBER,
      payload: response.data.subscriber
    });

    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.SUBSCRIBER
    });

    successCallback();
  } catch (error) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.SUBSCRIBER,
        message: getErrorMessage(error)
      }
    });
  }

  finishedCallback();
};

export const updateSubscriber = (
  firstName,
  lastName,
  successCallback,
  finishedCallback
) => async (dispatch) => {
  const requestBody = {
    first_name: firstName,
    last_name: lastName
  };
  try {
    const response = await subscriberApiClient.patch(
      '/subscriber',
      requestBody
    );

    dispatch({
      type: actions.SET_SUBSCRIBER,
      payload: response.data.subscriber
    });

    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.SUBSCRIBER
    });

    successCallback();
  } catch (err) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.SUBSCRIBER,
        message: getErrorMessage(err)
      }
    });
  }

  finishedCallback();
};

export const fetchSubscriptions = (completedCallback) => async (dispatch) => {
  try {
    const response = await subscriberApiClient.get('/subscriber/subscriptions');
    const subscriptions =
      response.data.subscriptions.length > 0
        ? response.data.subscriptions
        : null;

    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.SUBSCRIPTIONS_FETCH_FAILED
    });

    dispatch({
      type: actions.SET_SUBSCRIPTIONS,
      payload: subscriptions
    });
  } catch (error) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.SUBSCRIPTIONS_FETCH_FAILED,
        message: getErrorMessage(error)
      }
    });
  }

  completedCallback();
};
