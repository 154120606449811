import React from 'react';
import get from 'lodash-es/get';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { prop, switchProp, ifProp } from 'styled-tools';

import {
  ArticleLink,
  Author,
  CardTitle,
  Date,
  MoreLink,
  Teaser,
  Thumbnail
} from './styled/SharedComponents';
import defaultImage from 'images/smalls-default.png';
import linkResolver from '../../utils/linkResolver';
import { trimTeaser } from '../../utils/functions';
import dayjs from '../../utils/dayjs';
import { NEWS_DATE_FORMAT, YOUTUBE_THUMBNAILS } from '../../utils/constants';
import { getYoutubeThumbnailUrl } from '../../utils/functions';

const NewsItemPreviewContainer = styled.div`
  background: ${prop('theme.colors.white')};
  border: 0.0625rem solid ${prop('theme.colors.borderGrey')};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  position: relative;

  ${ifProp(
    '$featured',
    css`
      margin-right: 1rem;
    `,
    css`
      height: 100%;
    `
  )}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 1rem 1.5rem;

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    max-height: 11.5rem;
  }
`;

const AuthorLine = styled.div`
  ${ifProp(
    '$featured',
    css`
      margin-bottom: 0.325rem;
    `
  )}
`;

const Badge = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.3rem 0.9rem 0.25rem;
  text-transform: uppercase;
  font-weight: ${prop('theme.fontWeights.bold')};
  letter-spacing: 1px;
  font-size: 0.75rem;

  ${switchProp('$type', {
    video: css`
      color: ${prop('theme.colors.white')};
      background-color: ${prop('theme.colors.videoBadgeBlue')};
    `
  })}
`;

const propTypes = {
  parentPage: PropTypes.string,
  newsItem: PropTypes.object,
  featured: PropTypes.bool
};

const defaultProps = {
  parentPage: '',
  newsItem: {},
  featured: false
};

function NewsItemPreview({ parentPage, newsItem, featured }) {
  const { type, uid } = newsItem;
  const {
    featured_image,
    featured_video_embed,
    post_title,
    author,
    post_author,
    post_date,
    seo_meta_description,
    news_item_type
  } = newsItem.data;
  const isVideoArticle = news_item_type === 'video';
  const videoThumbnailUrl = getYoutubeThumbnailUrl(
    featured_video_embed,
    YOUTUBE_THUMBNAILS.medium
  );
  const altText = isVideoArticle ? get(post_title, 'text') : featured_image.alt;
  const thumbnail = isVideoArticle
    ? videoThumbnailUrl
    : get(featured_image, 'thumbnails.preview.url', get(featured_image, 'url'));
  const thumbnailType = thumbnail
    ? isVideoArticle
      ? 'featured_video'
      : 'featured'
    : 'default';

  const authorName = get(author, 'document.data.author_name', post_author.text);

  return (
    <NewsItemPreviewContainer $featured={featured}>
      <Link
        to={linkResolver({ type: type, uid: uid })}
        state={{ parentPage: parentPage }}
      >
        <Thumbnail
          alt={thumbnail ? altText : 'placeholder article image'}
          height={featured ? 245 : 216}
          width={500}
          $layout='card'
          src={thumbnail ? thumbnail : defaultImage}
          $type={thumbnailType}
        />
      </Link>
      {isVideoArticle && <Badge $type='video'>Video</Badge>}
      <ContentContainer>
        <div>
          <AuthorLine $featured={featured}>
            {authorName && <Author>{authorName}</Author>}
            {post_date && (
              <Date>{dayjs(post_date).format(NEWS_DATE_FORMAT)}</Date>
            )}
          </AuthorLine>
          {post_title && (
            <ArticleLink
              to={linkResolver({ type: type, uid: uid })}
              state={{ parentPage: parentPage }}
            >
              <CardTitle $featured={featured}>{post_title.text}</CardTitle>
            </ArticleLink>
          )}
          {seo_meta_description && (
            <Teaser>{trimTeaser(seo_meta_description)}</Teaser>
          )}
        </div>
        <MoreLink
          to={linkResolver({ type: type, uid: uid })}
          state={{ parentPage: parentPage }}
        >
          Read More →
        </MoreLink>
      </ContentContainer>
    </NewsItemPreviewContainer>
  );
}

NewsItemPreview.propTypes = propTypes;
NewsItemPreview.defaultProps = defaultProps;

export default NewsItemPreview;
