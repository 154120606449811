import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import Layout from '../components/layout';
import PageContainer from '../components/layout-components/PageContainer';
import PageBody from '../components/PageBody';
import PriceFeeds from '../components/PriceFeeds';
import MainCol from '../components/PageBody/MainCol';
import SideRailsMenu from '../components/SideRailsMenu';
import ColumnWrapper from '../components/PageBody/styled/ColumnWrapper';
import { GatsbyIpfFileProvider } from '../context/GatsbyIpfFileContext';

const propTypes = { data: PropTypes.object };

const defaultProps = { data: null };

function ProductArchiveLandingPage({ data: queryResult }) {
  const pageData = queryResult.prismicProductArchiveLandingPage.data;
  const sideRailsMenuData = { isProductsMenu: true };

  if (!pageData) {
    return null;
  }

  const { page_title, side_nav_header, header_banner, body } = pageData;
  return (
    <Layout
      title={page_title.text}
      headerBanner={header_banner && header_banner.url}
    >
      <GatsbyIpfFileProvider>
        <PageContainer>
          <SideRailsMenu
            title={side_nav_header || 'Markets'}
            data={sideRailsMenuData}
          />
          <MainCol>
            <ColumnWrapper>
              {body && <PageBody body={body} template={'products-landing'} />}
            </ColumnWrapper>
          </MainCol>
          <PriceFeeds />
        </PageContainer>
      </GatsbyIpfFileProvider>
    </Layout>
  );
}

export const Head = ({ data: queryResult }) => {
  const pageData = queryResult.prismicProductArchiveLandingPage.data;

  if (!pageData) {
    return null;
  }

  const { seo_title, seo_meta_description, seo_image, page_title } = pageData;

  return (
    <SEO
      title={seo_title || page_title.text}
      description={seo_meta_description}
      image={seo_image}
    />
  );
};

export const query = graphql`
  query ProductArchiveLandingPageQuery($id: String!) {
    prismicProductArchiveLandingPage(id: { eq: $id }) {
      data {
        page_title {
          text
        }
        side_nav_header
        header_banner {
          url
          dimensions {
            height
            width
          }
        }
        seo_title
        seo_meta_description
        seo_image {
          url
        }
        body {
          ... on PrismicProductArchiveLandingPageDataBodyTwoColumnsImage {
            slice_type
            slice_label
            primary {
              title {
                richText
                text
              }
              description {
                richText
                text
              }
              orientation
              block_cta_text
              cta_position
              block_cta {
                link_type
                target
                url
                type
                uid
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
            items {
              additional_text {
                richText
                text
              }
              image {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
              text_block {
                richText
                text
              }
              title {
                richText
                text
              }
              image_margin
              image_link {
                url
              }
              background_style
            }
          }
        }
      }
    }
  }
`;

ProductArchiveLandingPage.propTypes = propTypes;
ProductArchiveLandingPage.defaultProps = defaultProps;

export default ProductArchiveLandingPage;
