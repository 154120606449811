import React from 'react';
import PropTypes from 'prop-types';
import ListItem from './styled/ListItem';
import FooterList from './styled/FooterList';
import styled from 'styled-components';

const CenterAlignedList = styled(FooterList)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;

  img {
    margin-bottom: 1rem;
  }
`;

const propTypes = {
  address: PropTypes.string
};

const defaultProps = {
  address: null
};

function FooterAddress({ logo, address }) {
  if (!address && !logo) {
    return null;
  }

  const addresses = address.split(',');
  return (
    <CenterAlignedList>
      {logo && (
        <img
          src={logo.url}
          height={logo.dimensions && logo.dimensions.height}
          width={logo.dimensions && logo.dimensions.width}
          alt={logo.alt}
          loading='lazy'
        />
      )}
      {addresses.map((elem, index) => {
        let addressOutput = elem;
        if (index > 1) {
          addressOutput = addressOutput.slice(1, elem.length);
        }

        return (
          <ListItem key={`address_element_${index}`}>{addressOutput}</ListItem>
        );
      })}
    </CenterAlignedList>
  );
}

FooterAddress.propTypes = propTypes;
FooterAddress.defaultProps = defaultProps;

export default FooterAddress;
