import React from 'react';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifNotProp, ifProp, prop } from 'styled-tools';

import NewsItemPreview from '../NewsItemPreview';
import VideoPlayer from '../../EmbeddedVideoPlayer/components/VideoPlayer';
import HeroCallout from './HeroCallout';
import NewsList from '../NewsList';
import { NewsPreviewWrapper } from '../styled/SharedComponents';
import { getYoutubeThumbnailUrl } from '../../../utils/functions';

const HeroContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    flex-direction: row;
    margin-top: 7.8rem;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${prop('theme.breakpoints.xl')}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const PrimaryContainer = styled.div`
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  grid-column: 1 / 4;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin-bottom: 2rem;
  }
  

  ${ifProp(
    '$fullWidth',
    css`
      @media (min-width: ${prop('theme.breakpoints.xl')}) {
        margin-bottom: 7rem;
      }
    `
  )}

  ${ifProp(
    '$displayEmbeddedVideo',
    css`
      flex-direction: column;
      margin-bottom: 0;
    `
  )}

  ${ifNotProp(
    '$fullWidth',
    css`
      @media (min-width: ${prop('theme.breakpoints.lg')}) {
        grid-column: 1 / 2;
      }

      @media (min-width: ${prop('theme.breakpoints.xl')}) {
        grid-column: 1 / 3;
      }
    `
  )}
`;

const ImageContainer = styled.div`
  width: 100%;

  ${ifNotProp(
    '$isEmbeddedVideo',
    ifNotProp(
      '$fullWidth',
      css`
        height: 34.5rem;
      `,
      css`
        min-height: 34.5rem;
      `
    )
  )}

  ${ifNotProp(
    '$featured',
    css`
      background: linear-gradient(
        180deg,
        rgba(195, 195, 195, 1) 35%,
        rgba(255, 255, 255, 1) 100%
      );
      border-radius: 0.5rem;
    `
  )}

  img {
    border-radius: 0.5rem;
    height: 100%;
    min-height: 20rem;
    object-fit: cover;
    width: 100%;
    min-height: 34.5rem;

    @media (max-width: ${prop('theme.breakpoints.xl')}) {
      border-bottom-left-radius: 4rem;
      border-bottom-right-radius: 4rem;
    }
  }
`;

const NewsListContainer = styled.div`
  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    display: none;
  }
`;

const MobileList = styled.div`
  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    display: none;
  }
`;

const ThumbnailContainer = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
`;

const Thumbnail = styled.img`
  /* The thumbnail from youtube has a black border that we don't want to display here, so we scale up the image slightly to hide the border  */
  transform: scale(1.01);
`;

const propTypes = {
  primary: PropTypes.object,
  newsList: PropTypes.array,
  newsListTitle: PropTypes.string,
  newsListViewAllLink: PropTypes.object,
  page: PropTypes.string,
  type: PropTypes.string,
  uid: PropTypes.string
};

const defaultProps = {
  primary: {},
  newsList: [],
  newsListTitle: '',
  newsListViewAllLink: {},
  page: '',
  type: '',
  uid: ''
};

function NewsHero({
  primary,
  newsList,
  newsListTitle,
  newsListViewAllLink,
  page,
  uid,
  type
}) {
  const {
    featured_image,
    post_title,
    news_item_type,
    featured_video_embed
  } = primary;
  const featuredImage = get(featured_image, 'url');
  const showNewsList = newsList.length > 0;
  const isVideoArticle = news_item_type === 'video';
  const embedUrl = get(featured_video_embed, 'embed_url');
  const videoThumbnailUrl = getYoutubeThumbnailUrl(featured_video_embed);

  const shouldDisplayEmbeddedVideo = isVideoArticle && !showNewsList;
  const shouldDisplayVideoThumbnail = isVideoArticle && showNewsList;

  return (
    <>
      <HeroContainer>
        {primary && (
          <PrimaryContainer
            $fullWidth={!showNewsList}
            $displayEmbeddedVideo={shouldDisplayEmbeddedVideo}
          >
            <ImageContainer
              $featured={(isVideoArticle && embedUrl) || featuredImage}
              $isEmbeddedVideo={shouldDisplayEmbeddedVideo}
              $fullWidth={!showNewsList}
            >
              {shouldDisplayEmbeddedVideo && featured_video_embed && (
                <VideoPlayer video={featured_video_embed} fullWidth />
              )}
              {shouldDisplayVideoThumbnail && featured_video_embed && (
                <ThumbnailContainer>
                  <Thumbnail
                    alt={get(post_title, 'text')}
                    src={videoThumbnailUrl}
                    height={560}
                    width={995}
                  />
                </ThumbnailContainer>
              )}
              {!isVideoArticle && featuredImage && (
                <img
                  alt={featured_image.alt}
                  src={featuredImage}
                  height={get(featuredImage, 'dimensions.height')}
                  width={get(featuredImage, 'dimensions.width')}
                />
              )}
            </ImageContainer>
            {post_title && (
              <HeroCallout
                page={page}
                primary={primary}
                type={type}
                uid={uid}
                displayEmbeddedVideo={shouldDisplayEmbeddedVideo}
              />
            )}
          </PrimaryContainer>
        )}
        {showNewsList && (
          <NewsListContainer>
            <NewsList
              title={newsListTitle ? newsListTitle : 'Recent News'}
              teaserSetting='short'
              newsList={newsList}
              viewAllLink={
                newsListViewAllLink
                  ? newsListViewAllLink
                  : { type: 'news_item_list', uid: 'all-articles' }
              }
            />
          </NewsListContainer>
        )}
      </HeroContainer>
      {showNewsList && (
        <MobileList>
          {newsList.map((item) => {
            return (
              <NewsPreviewWrapper key={item.node.id}>
                <NewsItemPreview newsItem={item.node} />
              </NewsPreviewWrapper>
            );
          })}
        </MobileList>
      )}
    </>
  );
}

NewsHero.propTypes = propTypes;
NewsHero.defaultProps = defaultProps;

export default NewsHero;
