import React from 'react';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';
import PropTypes from 'prop-types';

import lockIcon from '../../../../images/icons/semi-transparent-lock.svg';
import InvoiceDetails from './InvoiceDetails';

const InvoicePanel = styled.div`
  padding: 2rem;
  /* Using inset box-shadow instead of border to make sure it stays inside the locked overlay */
  box-shadow: inset 0px 0px 0px 1px ${prop('theme.colors.borderGreyDark')};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${prop('theme.colors.white')};

  ${ifProp(
    '$locked',
    css`
      &:after {
        content: ' ';
        border-radius: 1rem;
        z-index: 10;
        display: block;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background: rgba(237, 237, 237, 0.7);
      }
    `
  )}
`;

const LockImage = styled.img`
  position: absolute;
  z-index: 11;
  top: 30%;
  left: 45%;
`;

const propTypes = { invoice: PropTypes.object, locked: PropTypes.bool };

const defaultProps = { invoice: {}, locked: false };

function Invoice({ invoice, locked }) {
  return (
    <InvoicePanel $locked={locked}>
      <InvoiceDetails invoice={invoice} />
      {locked && <LockImage src={lockIcon} />}
    </InvoicePanel>
  );
}

Invoice.propTypes = propTypes;
Invoice.defaultProps = defaultProps;

export default Invoice;
