import { SATURDAY, SUNDAY } from './constants';
import dayjs from './dayjs';

export function isWeekend(date = null) {
  if (null === date) {
    date = dayjs();
  }

  const dayOfWeek = date.day();

  return dayOfWeek === SATURDAY || dayOfWeek === SUNDAY;
}

export function isHoliday(date = null, listOfHolidays = null) {
  if (listOfHolidays === null) {
    return false;
  }
  if (null === date) {
    date = dayjs();
  }

  return !!listOfHolidays.find((day) => date.isSame(day, 'day'));
}
