import styled from 'styled-components';
import { prop } from 'styled-tools';

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${prop('$topMargin', 0)};
`;

export default TitleRow;
