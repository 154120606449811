import React from 'react';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';
import styled, { css } from 'styled-components';
import { ifProp, prop, switchProp } from 'styled-tools';

import hyperLinkHelper from '../../../utils/hyperLinkHelper';
import RichTextWithDictionary from '../../RichTextWithDictionary';
import linkResolver from '../../../utils/linkResolver';

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
  width: 100%;
  margin: 0.3125rem 0;
  padding: 1.25rem 1.5rem 1.25rem 0;

  ${ifProp(
    { $template: 'registration-landing' },
    css`
      margin: 0;
      padding: 0.5rem 0;

      &:first-child {
        padding-top: 1.25rem;
      }
    `
  )}

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    font-size: 1.25rem;
    margin-bottom: 0;
  }

  .color-red {
    color: ${prop('theme.colors.redPrimary')};
  }

  /* There is a weird behavior when adding labels to links where different browsers display different things. 
  
  In Chrome, a link with a label in prismic appears as <a><span class='color-black'>{content}</span></a> 
  In Firefox, a link with a label in prismic appears as <span class='color-black'><a>{content}</a></span> 
  
  So in order to cover our bases, we're styling anything with the color-black class, and any anchors inside a color-black container
  */
  .color-black,
  .color-black a {
    color: ${prop('theme.colors.black')};

    &:hover,
    &:active {
      color: ${prop('theme.colors.redPrimary')};
    }
  }
`;

const ProductSection = styled.div`
  display: flex;
  justify-content: ${prop('$justify', 'center')};
  align-items: ${prop('$alignment', 'top')};
  min-height: 5.875rem;

  @media(max-width: ${prop('theme.breakpoints.md')}) {
    justify-content: space-between;
  }

  @media(max-width: ${prop('theme.breakpoints.sm')}) {
    flex-direction: column;
    align-items: center;
  }

  // If we're on a standard page, we need to switch the flex-direction at a wider width
  ${ifProp(
    { $template: 'page' },
    css`
      @media (max-width: ${prop('theme.breakpoints.md')}) {
        flex-direction: column;
        align-items: center;
      }
    `
  )}

  ${ifProp(
    { backgroundStyle: 'with_background' },
    css`
      &:hover {
        #image-container {
          background-color: ${prop('theme.colors.redPrimary')};
        }
      }
    `
  )}

  // Leaving markup alone and doing styling through styles.
  ${ifProp(
    { label: 'image_left' },
    css`
      > *:first-child {
        order: 2;
        padding: 0 0 2rem 1.5rem;
      }

      > *:last-child {
        order: 1;
      }
    `
  )}
`;

const ProductContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${ifProp(
    { $imageMargin: 'condensed' },
    css`
      width: 67%;
    `,
    css`
      width: 50%;
    `
  )}

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    width: 80%;
  }

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    width: 100%;
    padding-right: 0;
    margin-top: 1.5rem;
  }
`;

const Wrapper = styled.div`
  ${switchProp('$type', {
    header: css`
      align-items: baseline;
      display: flex;
      margin-bottom: 0.5rem;
      h3 {
        font-size: 1.125rem;
        font-weight: ${prop('theme.fontWeights.bold')};
        width: 100%;
      }

      h4 {
        font-weight: ${prop('theme.fontWeights.semiBold')};
      }
    `,
    body: css`
      p {
        line-height: 1.5rem;
      }

      a {
        font-size: 0.8125rem;
        font-weight: ${prop('theme.fontWeights.semiBold')};
        text-transform: uppercase;
      }
    `
  })}

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    justify-content: center;
    text-align: center;
  }
`;

const AdditionalText = styled.div`
  margin-top: 1rem;

  p {
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: ${prop('theme.fontWeights.semiBold')};

    a::after {
      content: ' →';
      font-size: 1rem;
    }
  }
`;

const ImageContainer = styled.div`
  align-items: center;
  width: ${prop('$width')};
  height: ${prop('$height')};
  margin: 0;

  img {
    max-width: 100%;
    height: auto;
  }

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    ${switchProp('$imageMargin', {
      condensed: css`
        margin-right: 2.5rem;
      `,
      default: css`
        margin-right: 4.125rem;
      `
    })}
  }

  ${ifProp(
    { $backgroundStyle: 'with_background' },
    css`
      background-color: ${prop('theme.colors.greyDarker')};
      transition: background-color 0.2s linear;
      border-radius: 100%;
    `
  )}

  ${ifProp(
    { $imageBorder: 'true' },
    css`
      img {
        border: 1px solid ${prop('theme.colors.borderGrey')};
        border-radius: 1.5rem;
        padding: 1rem;
      }
    `
  )}
`;

const propTypes = {
  primary: PropTypes.object,
  field: PropTypes.object,
  label: PropTypes.string,
  template: PropTypes.string
};

const defaultProps = {
  primary: {},
  field: {},
  label: '',
  template: ''
};

function VanillaImageBlock({ primary, field, label, template }) {
  const { justify = 'center', alignment = 'top' } = primary;
  const {
    additional_text,
    image,
    text_block,
    title,
    image_margin = 'default',
    background_style,
    image_position,
    image_border,
    image_link
  } = field;
  const additionalText = additional_text && additional_text.text;
  const width = (image.dimensions && image.dimensions.width) || '72';
  const height = (image.dimensions && image.dimensions.height) || '72';
  const imagePosition = image_position || 'left';
  const imageLinkUrl = image_link && image_link.url;

  return (
    <StyledColumn key={`column-${title.text}`} $template={template}>
      <ProductSection
        label={label}
        backgroundStyle={background_style}
        $template={template}
        $justify={justify}
        $alignment={alignment}
      >
        {imagePosition === 'left' && image && (
          <ImageContainer
            id='image-container'
            $width={width + 'px'}
            $height={height + 'px'}
            $imageMargin={image_margin}
            $backgroundStyle={background_style}
            $imageBorder={image_border}
          >
            {imageLinkUrl ? (
              <a href={imageLinkUrl}>
                <img
                  alt={image.alt}
                  src={image.url}
                  height={height}
                  width={width}
                />
              </a>
            ) : (
              <img
                alt={image.alt}
                src={image.url}
                height={height}
                width={width}
              />
            )}
          </ImageContainer>
        )}
        <ProductContent $imageMargin={image_margin}>
          <Wrapper $type='header'>
            <RichTextWithDictionary render={title.richText} />
          </Wrapper>
          <Wrapper $type='body'>
            <RichTextWithDictionary
              render={text_block.richText}
              serializeHyperlink={hyperLinkHelper}
            />
          </Wrapper>
          {additionalText && (
            <AdditionalText>
              <PrismicRichText
                field={additional_text.richText}
                linkResolver={linkResolver}
              />
            </AdditionalText>
          )}
        </ProductContent>
        {imagePosition === 'right' && image && (
          <ImageContainer
            id='image-container'
            width={width + 'px'}
            height={height + 'px'}
            imageMargin={image_margin}
            backgroundStyle={background_style}
            $imageBorder={image_border}
          >
            <img
              alt={image.alt}
              src={image.url}
              height={height}
              width={width}
            />
          </ImageContainer>
        )}
      </ProductSection>
    </StyledColumn>
  );
}

VanillaImageBlock.propTypes = propTypes;
VanillaImageBlock.defaultProps = defaultProps;

export default VanillaImageBlock;
