import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import VideoPlayer from './components/VideoPlayer';
import BlockStyles from '../BlockStyles';

const EmptyMessage = styled.div`
  border: 1px solid black;
  text-align: center;
  margin: 1rem 0;
  padding: 1rem;
`;

const propTypes = {
  video: PropTypes.object,
  withBorder: PropTypes.bool,
  data: PropTypes.object,
  template: PropTypes.string
};

const defaultProps = {
  video: {},
  withBorder: false,
  template: ''
};

const EmbeddedVideoPlayer = ({ video, withBorder, data, template }) => {
  return (
    <>
      {video && (
        <BlockStyles
          template={template}
          sideNav={!data || data.source !== 'news_item'}
        >
          <div className='block'>
            <VideoPlayer data={data} video={video} withBorder={withBorder} />
          </div>
        </BlockStyles>
      )}
      {!video.url && !video.html && <EmptyMessage>No video found</EmptyMessage>}
    </>
  );
};

EmbeddedVideoPlayer.propTypes = propTypes;
EmbeddedVideoPlayer.defaultProps = defaultProps;

export default EmbeddedVideoPlayer;
