import React from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const ScrollContext = React.createContext({
  disableBodyScroll: () => {},
  enableBodyScroll: () => {}
});
ScrollContext.displayName = 'MenuContext';

function ScrollProvider({ children, bodyRef }) {
  return (
    <ScrollContext.Provider
      value={{
        disableBodyScroll: () => disableBodyScroll(bodyRef.current),
        enableBodyScroll: () => enableBodyScroll(bodyRef.current)
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
}

export { ScrollProvider };

export default ScrollContext;
