import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { Carousel } from 'react-responsive-carousel';

import Panel from './Panel';
import { trimFuturesSymbols } from '../../utils/functions';
import { DATA_SOURCE_FUTURES } from '../../utils/constants';

const StyledCarousel = styled(Carousel)`
  .control-dots {
    bottom: -5px;

    .dot {
      background: ${prop('theme.colors.carouselDotGrey')};
      opacity: 1;
      box-shadow: none;
      z-index: 100;

      &.selected {
        background: ${prop('theme.colors.redPrimary')};
      }
    }
  }
`;

const propTypes = {
  data: PropTypes.object,
  symbolObject: PropTypes.object
};

const defaultProps = {
  data: {},
  symbolObject: {}
};

function CarouselPanels({ data, symbolObject }) {
  return (
    data && (
      <StyledCarousel
        showArrows={false}
        showIndicators={true}
        infiniteLoop={true}
        showStatus={false}
        showThumbs={false}
        autoPlay={false}
      >
        {Object.keys(data)
          .filter((feed) => feed !== 'undefined')
          .map((feed) => {
            const trimSymbol = trimFuturesSymbols(feed);

            return (
              <Panel
                key={`panel_${feed}`}
                data={data[feed]}
                futuresData={
                  data[feed].Profile.dataSource === DATA_SOURCE_FUTURES
                }
                prismicSymbol={
                  symbolObject[trimSymbol] && symbolObject[trimSymbol].symbol
                }
                prismicTitle={
                  symbolObject[trimSymbol] && symbolObject[trimSymbol].title
                }
              />
            );
          })}
      </StyledCarousel>
    )
  );
}

CarouselPanels.propTypes = propTypes;
CarouselPanels.defaultProps = defaultProps;

export default CarouselPanels;
