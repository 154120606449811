import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { silentAuth } from '../../utils/auth';
import { fetchDataAfterAuthentication } from '../../state/actions/identityActions';
import actions from '../../state/actions';
import errorTypes from '../../state/actions/errorTypes';

function SessionCheck({ children }) {
  const dispatch = useDispatch();
  dispatch({ type: actions.SET_AUTH_LOADING, payload: true });

  useEffect(() => {
    silentAuth()
      .then((authResult) => {
        dispatch(
          fetchDataAfterAuthentication(authResult, () =>
            dispatch({ type: actions.SET_AUTH_LOADING, payload: false })
          )
        );
      })
      .catch(() => {
        dispatch({
          type: actions.SET_LOGIN_REQUIRED,
          payload: true
        });

        dispatch({ type: actions.SET_AUTH_LOADING, payload: false });
      });
  }, []);

  return children;
}

export default SessionCheck;
