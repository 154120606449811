import React, { useState } from 'react';
import { prop } from 'styled-tools';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';

const StickyDiv = styled.div`
  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    background-color: ${prop('theme.colors.backgroundGreyLight')};
    left: 0;
    margin-top: 0;
    max-width: 100vw;
    padding: 20px 0;
    width: 100%;
    flex: 0 0 100%;
    position: fixed;
    top: 108px;
    z-index: 100;
  }
`;

const propTypes = {
  parentPage: PropTypes.object,
  pageUid: PropTypes.string,
  title: PropTypes.object
};

const defaultProps = {
  parentPage: {},
  pageUid: '',
  title: {}
};

function Sidenav({ parentPage, pageUid, title }) {
  const [expandedSection, setExpandedSection] = useState(
    parentPage?.uid ? parentPage.uid : pageUid
  );

  const toggleNav = (event, uid, link) => {
    event.preventDefault();

    if (!expandedSection) {
      setExpandedSection(uid);
    } else {
      setExpandedSection(false);
    }

    navigate(link);
  };

  return (
    <StickyDiv>
      <MobileMenu
        expandedSection={expandedSection}
        title={title}
        toggleNav={toggleNav}
      />
      <DesktopMenu expandedSection={expandedSection} toggleNav={toggleNav} />
    </StickyDiv>
  );
}

Sidenav.propTypes = propTypes;
Sidenav.defaultProps = defaultProps;

export default Sidenav;
