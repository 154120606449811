import React from 'react';
import PropTypes from 'prop-types';

import linkResolver from '../../utils/linkResolver';
import MenuItem from './styled/MenuItem';
import MenuLink from './styled/MenuLink';
import RailMenuList from './styled/RailMenuList';
import SideRailContainer from './styled/SideRailContainer';

const propTypes = {
  toggle: PropTypes.func,
  menuItems: PropTypes.array,
  pageUid: PropTypes.string
};

const defaultProps = {
  toggle: () => {},
  menuItems: () => [],
  pageUid: ''
};

function NewsListRailMenu({ toggle, menuItems, pageUid }) {
  return (
    <SideRailContainer>
      <RailMenuList>
        {menuItems.map((menuItem) => {
          const { type, uid, title } = menuItem;

          return (
            <MenuItem
              key={`news_subnav_menu_${title}`}
              className={pageUid === uid ? 'active' : ''}
            >
              <MenuLink
                href={linkResolver({ type: type, uid: uid })}
                onClick={toggle}
              >
                {title}
              </MenuLink>
            </MenuItem>
          );
        })}
      </RailMenuList>
    </SideRailContainer>
  );
}

NewsListRailMenu.propTypes = propTypes;
NewsListRailMenu.defaultProps = defaultProps;

export default NewsListRailMenu;
