import styled from 'styled-components';
import { prop } from 'styled-tools';

const RailMenuList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin-right: 2rem;
  }
`;

export default RailMenuList;
