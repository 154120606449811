import styled from 'styled-components';
import { prop } from 'styled-tools';

const InputRow = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;

  & > div {
    margin-left: 24px;

    &:first-child {
      margin-left: 0;
    }
  }

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    flex-direction: column;

    & > div {
      margin-left: 0;
    }
  }
`;

export default InputRow;
