import { createGlobalStyle } from 'styled-components';
import { prop } from 'styled-tools';

import fontMetBlack from 'fonts/Metropolis-Black.woff2';
import fontMetBlackItalic from 'fonts/Metropolis-BlackItalic.woff2';
import fontMetBold from 'fonts/Metropolis-Bold.woff2';
import fontMetBoldItalic from 'fonts/Metropolis-BoldItalic.woff2';
import fontMetExtraBold from 'fonts/Metropolis-ExtraBold.woff2';
import fontMetExtraBoldItalic from 'fonts/Metropolis-ExtraBoldItalic.woff2';
import fontMetExtraLight from 'fonts/Metropolis-ExtraLight.woff2';
import fontMetExtraLightItalic from 'fonts/Metropolis-ExtraLightItalic.woff2';
import fontMetLight from 'fonts/Metropolis-Light.woff2';
import fontMetLightItalic from 'fonts/Metropolis-LightItalic.woff2';
import fontMetMedium from 'fonts/Metropolis-Medium.woff2';
import fontMetMediumItalic from 'fonts/Metropolis-MediumItalic.woff2';
import fontMetRegular from 'fonts/Metropolis-Regular.woff2';
import fontMetRegularItalic from 'fonts/Metropolis-RegularItalic.woff2';
import fontMetSemiBold from 'fonts/Metropolis-SemiBold.woff2';
import fontMetSemiBoldItalic from 'fonts/Metropolis-SemiBoldItalic.woff2';
import fontMetThin from 'fonts/Metropolis-Thin.woff2';
import fontMetThinItalic from 'fonts/Metropolis-ThinItalic.woff2';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'metropolis';
    src: url('${fontMetThin}') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'metropolis';
    src: url('${fontMetThinItalic}') format('woff2');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'metropolis';
    src: url('${fontMetExtraLight}') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

   @font-face {
    font-family: 'metropolis';
    src: url('${fontMetExtraLightItalic}') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'metropolis';
    src: url('${fontMetLight}') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'metropolis';
    src: url('${fontMetLightItalic}') format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'metropolis';
    src: url('${fontMetRegular}') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'metropolis';
    src: url('${fontMetRegularItalic}') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'metropolis';
    src: url('${fontMetMedium}') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'metropolis';
    src: url('${fontMetMediumItalic}') format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'metropolis';
    src: url('${fontMetSemiBold}') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'metropolis';
    src: url('${fontMetSemiBoldItalic}') format('woff2');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'metropolis';
    src: url('${fontMetBold}') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'metropolis';
    src: url('${fontMetBoldItalic}') format('woff2');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'metropolis';
    src: url('${fontMetExtraBold}') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

   @font-face {
    font-family: 'metropolis';
    src: url('${fontMetExtraBoldItalic}') format('woff2');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'metropolis';
    src: url('${fontMetBlack}') format('woff2');
    font-weight: 1000;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'metropolis';
    src: url('${fontMetBlackItalic}') format('woff2');
    font-weight: 1000;
    font-style: italic;
    font-display: swap;
  }

  html {
    --scroll-behavior: smooth;
    scroll-behavior: smooth;

    /* Disable smooth scrolling when users have prefers-reduced-motion enabled */
    @media screen and (prefers-reduced-motion: reduce) {
      --scroll-behavior: auto;
    	scroll-behavior: auto;
    }
  }

  body {
    margin: 0;
    color: ${prop('theme.colors.black')};
    font-family: ${prop('theme.fonts.default')};
    font-style: normal;
  }

  h2 {
    font-size: 1.875rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.125rem;
  }

  h2,
  h3,
  h4 {
    color: ${prop('theme.colors.black')};
    font-family: ${prop('theme.fonts.default')};
    font-stretch: normal;
    font-style: normal;
    font-weight: ${prop('theme.fontWeights.regular')};
    line-height: normal;
    letter-spacing: 0.005rem;
    margin-bottom: 1rem;
  }

  h5 {
    color: ${prop('theme.colors.allCapsHeader')};
    font-size: 1rem;
    font-weight: ${prop('theme.fontWeights.regular')};
    text-transform: uppercase;
  }

  p {
    font-family: ${prop('theme.fonts.default')};
    font-size: 1rem;
    font-weight: ${prop('theme.fontWeights.regular')};
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25rem;
    letter-spacing: 0.005rem;
    color: ${prop('theme.colors.blackSecondary')};
    margin-bottom: 1.25rem;
  }

  strong, b {
    font-weight: bold;
  }

  a {
    color: ${prop('theme.colors.redPrimary')};

    @media (max-width: ${prop('theme.breakpoints.sm')}) {
      word-break: break-word;
    }

    &:hover {
      color: ${prop('theme.colors.redPrimary')};
      text-decoration: underline;
    }
  }

  .clear {
    clear:both;
  }

  .right {
    float: right;
  }

  .left {
    float: left;
  }

  .text-red {
    color: ${prop('theme.colors.redPrimary')};
  }

  // overriding inline styles from react-cookie-consent
  .cookieConsent {
    > div {
      flex: inherit !important;

      @media (max-width: ${prop('theme.breakpoints.md')}) {
        margin-bottom: 0 !important;
      }
    }

    button {
      &:hover {
        background: black !important;
      }
    }
  }

  .anchorOffset[id] {
    scroll-margin-top: 9.85rem;

    @media (min-width: ${prop('theme.breakpoints.lg')}) {
      scroll-margin-top: 6.85rem;
    }
  }

  .expanded-icon {
    animation: expanding 300ms;

    @keyframes expanding {
      from {
        transform: rotate(0.5turn);
      }

      to {
        transform: rotate(1turn);
      }
    }
  }

  .collapsed-icon {
    animation: collapsing 300ms;

    @keyframes collapsing {
      from {
        transform: rotate(0.5turn);
      }

      to {
        transform: rotate(1turn);
      }
    }
  }
`;

export default GlobalStyle;
