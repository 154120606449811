import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PrismicRichText } from '@prismicio/react';

import linkResolver from '../../utils/linkResolver';
import TextBlockTitle from './TextBlockTitle';
import BlockStyles from '../BlockStyles';
import StyledTextBlock from './styled/StyledTextBlock';
import hyperLinkHelper from '../../utils/hyperLinkHelper';
import RichTextWithDictionary from '../RichTextWithDictionary';
import ButtonLink from '../ButtonLink';

const DisclaimerText = styled.div`
  margin-bottom: 0.5rem;
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;
`;

const propTypes = {
  content: PropTypes.array,
  disclaimer: PropTypes.object,
  header: PropTypes.object,
  template: PropTypes.string,
  titleHidden: PropTypes.bool,
  tooltip: PropTypes.object,
  embeddedVideo: PropTypes.object
};

const defaultProps = {
  content: [],
  header: null,
  primary: {},
  template: '',
  titleHidden: false,
  tooltip: {},
  embeddedVideo: {}
};

function TextBlock({
  content,
  disclaimer,
  header,
  primary,
  template,
  titleHidden,
  tooltip
}) {
  const type = header.richText[0] ? header.richText[0].type : null;
  const title = header ? header.text : null;
  const blockId = title ? title.replace(/\s/g, '') : '';
  const shouldDisplay = !primary.content_collapse || content.length > 0;

  return (
    <>
      {shouldDisplay && (
        <BlockStyles data={primary} template={template}>
          <StyledTextBlock id={blockId} className='block'>
            <TextBlockTitle
              header={header}
              template={template}
              title={title}
              titleHidden={titleHidden}
              tooltip={tooltip}
              type={type}
            />
            {content.map((block, i) => {
              return (
                <Fragment key={`block-${i}`}>
                  {disclaimer && disclaimer.text && (
                    <DisclaimerText>
                      <PrismicRichText
                        field={disclaimer.richText}
                        linkResolver={linkResolver}
                      />
                    </DisclaimerText>
                  )}
                  {block.text_block_content.text && (
                    <div>
                      <RichTextWithDictionary
                        render={block.text_block_content.richText}
                        serializeHyperlink={hyperLinkHelper}
                      />
                      {block.button_text && block.button_text.text && (
                        <ButtonContainer>
                          <ButtonLink
                            link={block.button_link}
                            format='outline'
                            size={412}
                            width='fit-content'
                            style='outline-black'
                            target={block.button_link.target}
                          >
                            {block.button_text.text}
                          </ButtonLink>
                        </ButtonContainer>
                      )}
                    </div>
                  )}
                </Fragment>
              );
            })}
          </StyledTextBlock>
        </BlockStyles>
      )}
    </>
  );
}

TextBlock.propTypes = propTypes;
TextBlock.defaultProps = defaultProps;

export default TextBlock;
