import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import { PrismicRichText } from '@prismicio/react';
import { Card as BaseCard, CardDeck as BaseCardDeck } from 'reactstrap';
import { get } from 'lodash-es';

import CardBody from './CardBody';
import linkResolver from '../../utils/linkResolver';
import BlockStyles from '../BlockStyles';

const GroupTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 2rem;

  h4 {
    font-weight: ${prop('theme.fontWeights.semiBold')};
  }
`;

const CardDeck = styled(BaseCardDeck)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    align-items: initial;
    flex-direction: row;
  }

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    align-items: center;
    flex-direction: column;
  }

  @media (min-width: ${prop('theme.breakpoints.xl')}) {
    align-items: initial;
    flex-direction: row;
  }
`;

const Card = styled(BaseCard)`
  &&& {
    margin-bottom: 1rem;
  }

  border: none;
  border-radius: 1rem;
  max-width: 19.5rem;
  min-width: 275px;

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    min-width: 300px;
  }

  @media (max-width: ${prop('theme.breakpoints.xs')}) {
    min-width: 200px;
    max-width: 275px;
  }

  @media (min-width: ${prop('theme.breakpoints.xl')}) {
    &&& {
      margin-bottom: 0;
    }
  }
`;

const CardHeader = styled.div`
  padding: 0.25rem 0;
  border-radius: 1rem 1rem 0 0;
  background-color: ${(props) =>
    props.$isFeatureCard
      ? props.theme.colors.redPrimary
      : props.theme.colors.borderGrey};

  ${ifProp(
    '$isFeatureCard',
    css`
      p {
        color: ${prop('theme.colors.white')};
      }
    `
  )}

  p {
    font-size: 0.875rem;
    font-weight: ${prop('theme.fontWeights.semiBold')};
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;
  }
`;

const CardFooter = styled.div`
  background-color: ${prop('theme.colors.backgroundGreyLighter')};
  border-radius: 0 0 1rem 1rem;
  text-align: center;
  padding: 1.25rem;
  border: 1px solid ${prop('theme.colors.borderGrey')};
  border-top: none;

  p {
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: ${prop('theme.fontWeights.bold')};
    letter-spacing: 0.8px;
    color: ${prop('theme.colors.allCapsHeader')};
  }

  h3 {
    font-weight: ${prop('theme.fontWeights.semiBold')};
  }

  .regular-weight {
    font-weight: ${prop('theme.fontWeights.regular')};
  }
`;

const propTypes = {
  data: PropTypes.array,
  primary: PropTypes.object,
  template: PropTypes.string
};

const defaultProps = {
  data: null,
  primary: {},
  template: ''
};

function CardGroup({ data, primary, template }) {
  if (!data) {
    return null;
  }

  return (
    <BlockStyles data={primary} template={template}>
      {get(primary.card_group_title, 'text') && (
        <GroupTitle>
          <PrismicRichText
            field={primary.card_group_title.richText}
            linkResolver={linkResolver}
          />
        </GroupTitle>
      )}
      <CardDeck className='block'>
        {data.map((card, index) => {
          return (
            <Card key={index}>
              {card.card_header.text && (
                <CardHeader $isFeatureCard={card.feature_card}>
                  <PrismicRichText
                    field={card.card_header.richText}
                    linkResolver={linkResolver}
                  />
                </CardHeader>
              )}
              <CardBody card={card} />
              {card.card_footer.text && (
                <CardFooter>
                  <PrismicRichText
                    field={card.card_footer.richText}
                    linkResolver={linkResolver}
                  />
                  {card.card_footer_main_content.text && (
                    <PrismicRichText
                      field={card.card_footer_main_content.richText}
                      linkResolver={linkResolver}
                    />
                  )}
                </CardFooter>
              )}
            </Card>
          );
        })}
      </CardDeck>
    </BlockStyles>
  );
}

CardGroup.propTypes = propTypes;
CardGroup.defaultProps = defaultProps;

export default CardGroup;
