import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, ifNotProp, prop } from 'styled-tools';
import { PrismicRichText } from '@prismicio/react';
import {
  CardBody as BaseCardBody,
  CardTitle as BaseCardTitle,
  CardSubtitle as BaseCardSubtitle
} from 'reactstrap';

import linkResolver from '../../utils/linkResolver';
import redCheckmark from 'images/icons/red-checkmark.svg';

const CardTitle = styled(BaseCardTitle)`
  margin: 1rem 0 1.75rem 0;

  h2 {
    font-weight: ${prop('theme.fontWeights.regular')};
  }

  h3 {
    font-weight: ${prop('theme.fontWeights.medium')};
  }
`;

const CardSubtitle = styled(BaseCardSubtitle)`
  margin: 0 0 1.75rem 0;
`;

const StyledCardBody = styled(BaseCardBody)`
  padding: 1.875rem;
  text-align: center;

  border: ${(props) =>
    props.$isFeatureCard
      ? `2px solid ${props.theme.colors.redPrimary}`
      : `1px solid ${props.theme.colors.borderGrey}`};

  border-radius: ${(props) => (props.$hasFooter ? '0' : '0 0 1rem 1rem')};

  ${ifNotProp(
    '$hasHeader',
    css`
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    `
  )}

  ${ifProp(
    '$hasFooter',
    css`
      border-bottom: none;
    `
  )}
`;

const MainContent = styled.div`
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 3.75rem;
  }

  h2 {
    font-weight: ${prop('theme.fontWeights.semiBold')};
  }

  h3 {
    font-weight: ${prop('theme.fontWeights.regular')};
  }

  .superscript {
    font-weight: ${prop('theme.fontWeights.regular')};
    font-size: 1.375rem;
    position: relative;
    top: -2rem;
  }

  .smaller-text {
    font-weight: ${prop('theme.fontWeights.regular')};
    font-size: 2.5rem;
    position: relative;
    top: -6px;
  }

  p {
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: ${prop('theme.fontWeights.medium')};
  }
`;

const ListContent = styled.div`
  text-align: left;

  ${ifProp(
    { listType: 'Checklist' },
    css`
      ul {
        list-style-type: none;
        list-style-image: url(${redCheckmark});
        padding-left: 0.875rem;

        li {
          padding-left: 0.5rem;

          br {
            margin-bottom: 0.25rem;
          }
        }
      }
    `
  )}
`;

const CTAButton = styled.a`
  display: inline-block;
  border-radius: 2.125rem;
  font-size: 0.875rem;
  font-weight: ${prop('theme.fontWeights.medium')};
  height: 2.5rem;
  letter-spacing: 1px;
  margin: 2rem 0 1.5rem;
  min-width: 12.5rem;
  padding-top: 0.6rem;
  text-transform: uppercase;
  width: max-content;
  cursor: pointer;

  background-color: ${(props) =>
    props.$isFeatureCard
      ? props.theme.colors.redPrimary
      : props.theme.colors.white};
  color: ${(props) =>
    props.$isFeatureCard
      ? props.theme.colors.white
      : props.theme.colors.redPrimary};
  border: ${(props) =>
    props.$isFeatureCard ? null : `1px solid ${props.theme.colors.redPrimary}`};

  &:hover {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) =>
      props.$isFeatureCard
        ? props.theme.colors.black
        : props.theme.colors.redPrimary};
    text-decoration: none;
  }
`;

const propTypes = {
  card: PropTypes.object
};

const defaultProps = {
  card: {}
};

function CardBody({ card }) {
  return (
    <StyledCardBody
      $isFeatureCard={card.feature_card}
      $hasFooter={
        card.card_footer.text ||
        (card.card_footer_main_content && card.card_footer_main_content.text)
      }
      $hasHeader={card.card_header.text}
    >
      <CardTitle $fontWeight={card.card_title_font_weight}>
        <PrismicRichText
          field={card.card_title.richText}
          linkResolver={linkResolver}
        />
      </CardTitle>
      <CardSubtitle>
        <PrismicRichText
          field={card.sub_card_title_content.richText}
          linkResolver={linkResolver}
        />
      </CardSubtitle>
      <MainContent>
        <PrismicRichText
          field={card.main_card_content.richText}
          linkResolver={linkResolver}
        />
        <PrismicRichText
          field={card.sub_main_card_content.richText}
          linkResolver={linkResolver}
        />
      </MainContent>
      {card.cta.text && card.cta_link.url && (
        <CTAButton
          $isFeatureCard={card.feature_card}
          href={card.cta_link.url}
          target={card.cta_link.target}
        >
          {card.cta.text}
        </CTAButton>
      )}
      {card.card_content_list.text && (
        <ListContent listType={card.card_content_list_type}>
          <PrismicRichText
            field={card.card_content_list.richText}
            linkResolver={linkResolver}
          />
        </ListContent>
      )}
    </StyledCardBody>
  );
}

CardBody.propTypes = propTypes;
CardBody.defaultProps = defaultProps;

export default CardBody;
