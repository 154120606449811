import React, { createContext, useEffect, useState } from 'react';
import { fetchSubscriptions } from '../state/actions/subscriberActions';
import { useDispatch, useSelector } from 'react-redux';

const SubscriptionsContext = createContext({
  subscriptionsLoading: false
});
SubscriptionsContext.displayName = 'SubscriptionsContext';

function SubscriptionsProvider({ children }) {
  const { identity } = useSelector((state) => state.memberInfo);
  const userIsAuthenticated = !!identity && Object.keys(identity).length > 0;
  const [subscriptionsLoading, setSubscriptionsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userIsAuthenticated) {
      setSubscriptionsLoading(true);
      dispatch(fetchSubscriptions(() => setSubscriptionsLoading(false)));
    }
  }, []);

  return (
    <SubscriptionsContext.Provider
      value={{ subscriptionsLoading: subscriptionsLoading }}
    >
      {children}
    </SubscriptionsContext.Provider>
  );
}

export { SubscriptionsProvider };

export default SubscriptionsContext;
