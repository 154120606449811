import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import PageContainer from '../components/layout-components/PageContainer';
import MarketNotices from '../components/MarketNotices';
import MainCol from '../components/PageBody/MainCol';
import RichTextWithDictionary from '../components/RichTextWithDictionary';
import SEO from '../components/seo';
import SideRailsMenu from '../components/SideRailsMenu';
import NotificationsRailMenu from '../components/SideRailsMenu/NotificationsRailMenu';

const ColumnWrapper = styled.div`
  width: 100%;
`;

const propTypes = {
  data: PropTypes.object
};

const defaultProps = {
  data: null
};

function NotificationsPage({ data: queryResult }) {
  const doc = queryResult.prismicNotificationsPage;
  const groups = queryResult.allPrismicNotificationGroup;
  if (!doc) {
    return null;
  }

  const data = doc.data;
  const { page_title, page_content, market_notices, banner_image } = data;

  const pageName = page_title ? page_title.text : '';

  const noticesFromGroups = groups.edges.flatMap((edge) =>
    get(edge.node.data, 'body', []).map((bodySection) => bodySection.primary)
  );

  return (
    <Layout headerBanner={banner_image && banner_image.url} title={pageName}>
      <PageContainer>
        <SideRailsMenu
          title={pageName}
          renderMenu={<NotificationsRailMenu />}
        />
        <MainCol>
          <ColumnWrapper>
            {page_content.text && (
              <div>
                <RichTextWithDictionary render={page_content.richText} />
              </div>
            )}
            <MarketNotices
              marketNotices={[...market_notices, ...noticesFromGroups]}
            />
          </ColumnWrapper>
        </MainCol>
      </PageContainer>
    </Layout>
  );
}

export const Head = ({ data: queryResult }) => {
  const pageData = queryResult.prismicNotificationsPage.data;

  if (!pageData) {
    return null;
  }

  const { seo_title, seo_meta_description, seo_image, page_title } = pageData;

  return (
    <SEO
      title={seo_title || page_title.text}
      description={seo_meta_description}
      image={seo_image}
    />
  );
};

export const query = graphql`
  query NotificationsPageQuery($id: String!) {
    prismicNotificationsPage(id: { eq: $id }) {
      _previewable
      data {
        page_title {
          text
          richText
        }
        page_content {
          text
          richText
        }
        banner_image {
          url
        }
        seo_meta_description
        seo_title
        market_notices {
          file {
            link_type
            size
            url
          }
          notice_date
          notice_title
          type
        }
      }
    }
    allPrismicNotificationGroup {
      edges {
        node {
          data {
            body {
              ... on PrismicNotificationGroupDataBodyNotification {
                primary {
                  file {
                    link_type
                    size
                    url
                  }
                  link {
                    uid
                    type
                    url
                    target
                  }
                  notice_date
                  notice_title
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`;

NotificationsPage.propTypes = propTypes;
NotificationsPage.defaultProps = defaultProps;

export default withPrismicPreview(NotificationsPage);
