import React from 'react';
import styled from 'styled-components';

const StyledParagraph = styled.p`
  font-size: 0.875rem;
  column-span: all;
  margin-top: 1rem;
`;

function Footnote({ data }) {
  const {
    primary: {
      footnote: { text }
    }
  } = data;

  return <StyledParagraph>{text}</StyledParagraph>;
}

export default Footnote;
