import React from 'react';

function CombinedContextProvider({ contexts, children }) {
  return (
    <>
      {contexts.reduceRight((acc, Context) => {
        return <Context>{acc}</Context>;
      }, children)}
    </>
  );
}

export default CombinedContextProvider;
