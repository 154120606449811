import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import defaultAvatar from '../../images/blank-profile-picture.svg';

export const StyledImg = styled.img`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border-radius: ${(props) => props.borderRadius};
`;

function Avatar({ avatar: { url }, alt, height, width, borderRadius }) {
  return (
    <StyledImg
      src={url ? url : defaultAvatar}
      alt={alt}
      height={height}
      width={width}
      borderRadius={borderRadius}
    />
  );
}

Avatar.propTypes = {
  avatar: PropTypes.object,
  alt: PropTypes.string,
  borderRadius: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
};
Avatar.defaultProps = {
  avatar: {},
  alt: 'avatar',
  borderRadius: '50%',
  height: '40px',
  width: '40px'
};

export default Avatar;
