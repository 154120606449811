import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const defaultState = null;
const SiteSettingsContext = React.createContext(defaultState);
SiteSettingsContext.displayName = 'SiteSettingsContext';

const siteSettingsQuery = graphql`
  query SiteSettingsQuery {
    allPrismicGlobalSiteSettings {
      edges {
        node {
          data {
            cookie_policy {
              url
            }
            price_cards_tooltip {
              richText
              text
            }
          }
        }
      }
    }
    allPrismicProduct {
      edges {
        node {
          data {
            historical_graph_data_source
            price_cards_data_source
            price_movement_widget_data_source
            contract_specs_data_source
            futures_symbol
          }
        }
      }
    }
  }
`;

function SiteSettingsProvider({ children }) {
  return (
    <StaticQuery
      query={`${siteSettingsQuery}`}
      render={(data) => {
        const globalSettings =
          data.allPrismicGlobalSiteSettings.edges[0].node.data;
        const productDocs = data.allPrismicProduct.edges;
        const productSettings = {};

        productDocs.forEach((productDoc) => {
          const product = productDoc.node.data;

          productSettings[product.futures_symbol] = {
            historical_graph_data_source: product.historical_graph_data_source,
            price_cards_data_source: product.price_cards_data_source,
            price_movement_widget_data_source:
              product.price_movement_widget_data_source,
            contract_specs_data_source: product.contract_specs_data_source
          };
        });

        const allSettings = {
          global: globalSettings,
          products: productSettings
        };

        return (
          <SiteSettingsContext.Provider value={allSettings}>
            {children}
          </SiteSettingsContext.Provider>
        );
      }}
    />
  );
}

export { SiteSettingsProvider };

export default SiteSettingsContext;
