import React from 'react';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, ifNotProp, prop, switchProp } from 'styled-tools';

const BlockStylesContainer = styled.div`
  background-color: ${prop('$bgColor')};
  margin-bottom: ${prop('$marginBottom', 0)}px;
  margin-top: ${prop('$marginTop', 0)}px;
  padding: 10px 16px;

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    padding: 10px 172px 10px 0;

    ${ifProp(
      '$priceFeed',
      css`
        padding-right: 260px;
      `
    )}
  }

  ${ifProp(
    '$sideNav',
    css`
      @media (min-width: ${prop('theme.breakpoints.lg')}) {
        padding-left: 208px;
      }

      @media (min-width: ${prop('theme.breakpoints.xl')}) {
        padding-left: 260px;
      }
    `
  )}

  ${ifProp(
    { $template: 'registration' },
    css`
      padding: 0 24px 24px;

      @media (min-width: ${prop('theme.breakpoints.lg')}) {
        padding: 0 160px;
      }

      @media (min-width: ${prop('theme.breakpoints.xl')}) {
        padding: 0 322px;
      }
    `
  )}

  && {
    ${ifNotProp(
      { $paddingBottom: undefined },
      css`
        padding-bottom: ${prop('$paddingBottom', 0)}px;
      `
    )}

    ${ifNotProp(
      { $paddingTop: undefined },
      css`
        padding-top: ${prop('$paddingTop', 0)}px;
      `
    )}
  }

  ${ifProp(
    '$displayBorder',
    css`
      ${switchProp('$borderPosition', {
        top: css`
          border-top: 1px solid ${prop('$borderColor')};
        `,
        bottom: css`
          border-bottom: 1px solid ${prop('$borderColor')};
        `,
        both: css`
          border-bottom: 1px solid ${prop('$borderColor')};
          border-top: 1px solid ${prop('$borderColor')};
        `
      })}
    `
  )}

  ${ifProp(
    { $disableSidePadding: true },
    css`
      &&& {
        padding-left: 0;
        padding-right: 0;
      }
    `
  )}
`;

const propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object,
  priceFeed: PropTypes.bool,
  sideNav: PropTypes.bool,
  template: PropTypes.string
};

const defaultProps = {
  data: {},
  priceFeed: false,
  sideNav: true,
  template: ''
};

function BlockStyles({ children, data, priceFeed, sideNav, template }) {
  const backgroundColor = get(
    data,
    'block_styles.document.data.background_color',
    'transparent'
  );
  const borderColor = get(data, 'block_styles.document.data.border_color');
  const borderPosition = get(
    data,
    'block_styles.document.data.border_position'
  );
  const displayBorder = get(data, 'block_styles.document.data.display_border');
  const paddingBottom = get(data, 'block_styles.document.data.padding_bottom');
  const paddingTop = get(data, 'block_styles.document.data.padding_top');
  const marginTop = get(data, 'block_styles.document.data.margin_top');
  const marginBottom = get(data, 'block_styles.document.data.margin_bottom');

  const priceFeedPages = ['homepage', 'product', 'products-landing'];
  const showPriceFeed = priceFeedPages.includes(template) ? true : priceFeed;
  const noSidePaddingPages = [
    'sign-in',
    'registration-landing',
    'news-item-two-column',
    'news-item',
    'no-side-padding',
    'community-portal'
  ];
  const disableSidePadding = noSidePaddingPages.includes(template);

  return (
    <BlockStylesContainer
      className='block-styles'
      $bgColor={backgroundColor}
      $borderColor={borderColor}
      $borderPosition={borderPosition}
      $displayBorder={displayBorder}
      $marginBottom={marginBottom}
      $marginTop={marginTop}
      $paddingBottom={paddingBottom}
      $paddingTop={paddingTop}
      $priceFeed={showPriceFeed}
      $sideNav={sideNav}
      $disableSidePadding={disableSidePadding}
      $template={template}
    >
      {children}
    </BlockStylesContainer>
  );
}

BlockStyles.propTypes = propTypes;
BlockStyles.defaultProps = defaultProps;

export default BlockStyles;
