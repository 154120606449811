import subscriberApiClient, {
  getErrorMessage,
  setAuthToken
} from '../../utils/subscriberApiClient';
import actions from '../actions';
import errorTypes from './errorTypes';
import { fetchContactInformation } from './contactInfoActions';
import { fetchAddresses } from './addressActions';
import { fetchSponsorship } from './sponsorshipActions';
import { fetchInvoices } from './invoiceActions';

export const fetchOrCreateIdentity = async (idToken, dispatch) => {
  const response = await subscriberApiClient.post('/authorizations', {
    id_token: idToken
  });
  if (!response.data || !response.data.identity) {
    throw Error('Error fetching identity');
  }

  dispatch({
    type: actions.REMOVE_API_ERROR,
    payload: errorTypes.IDENTITY_FETCH_FAILED
  });

  return { status: response.status, ...response.data };
};

export const linkSubscriberToIdentity = (completedCallback) => async (
  dispatch
) => {
  try {
    const response = await subscriberApiClient.post('/identity/link');

    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.IDENTITY_LINK
    });

    dispatch({
      type: actions.SET_IDENTITY,
      payload: {
        ...response.data.identity,
        registrationState: response.data.registration_state
      }
    });
  } catch (error) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.IDENTITY_LINK,
        message: getErrorMessage(error)
      }
    });
  }

  completedCallback();
};

export const confirmEmail = async (token) => {
  return subscriberApiClient
    .get('/identities/confirmation', {
      params: {
        confirmation_token: token
      }
    })
    .then((response) => response.data)
    .catch((error) => ({ errors: [getErrorMessage(error)] }));
};

export const fetchDataAfterAuthentication = (
  authResult,
  completedCallback = () => {},
  isLogin = false
) => async (dispatch) => {
  setAuthToken(authResult.accessToken);
  dispatch({ type: actions.SET_AUTH_TOKENS, payload: authResult });
  dispatch({ type: actions.SET_LOADING_STATUS, payload: true });
  const completeFunction = () => {
    dispatch({ type: actions.SET_LOADING_STATUS, payload: false });
    completedCallback();
  };

  fetchOrCreateIdentity(authResult.idToken, dispatch)
    .then((identityResponse) => {
      const identity = identityResponse.identity;
      dispatch({
        type: actions.SET_IDENTITY,
        payload: {
          ...identity,
          registrationState: identityResponse.registration_state
        }
      });
      // We only want to run this logic if the user is logging in, as opposed to just reloading the page
      if (isLogin) {
        window.dataLayer = window.dataLayer || [];
        if (identityResponse.status === 201) {
          window.dataLayer.push({ event: 'sign_up', method: 'Auth0' });
        } else {
          window.dataLayer.push({ event: 'login', method: 'Auth0' });
        }
      }

      const subscriber = identity.subscriber;
      if (subscriber) {
        dispatch({
          type: actions.SET_SUBSCRIBER,
          payload: subscriber
        });

        dispatch(fetchSponsorship());
        dispatch(fetchContactInformation());
        dispatch(fetchAddresses());
        dispatch(fetchInvoices(() => {}, completeFunction));
      } else {
        completeFunction();
      }
    })
    .catch((error) => {
      dispatch({
        type: actions.ADD_API_ERROR,
        payload: {
          type: errorTypes.IDENTITY_FETCH_FAILED,
          message: getErrorMessage(error)
        }
      });

      completeFunction();
    });
};

export const changePrimaryEmail = (
  newPrimaryEmail,
  successCallback,
  completedCallback
) => async (dispatch) => {
  try {
    await subscriberApiClient.post('/identity/change_primary_email', {
      primary_email: newPrimaryEmail
    });

    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.EMAIL_CHANGE
    });

    dispatch({ type: actions.UPDATE_PRIMARY_EMAIL, payload: newPrimaryEmail });

    successCallback();
  } catch (error) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.EMAIL_CHANGE,
        message: getErrorMessage(error, true)
      }
    });
  }

  completedCallback();
};
