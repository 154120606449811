import React from 'react';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';

import linkResolver from '../../utils/linkResolver';
import createDictionaryHtmlSerializer from './dictionaryHtmlSerializer';
import DictionaryContext from '../../context/DictionaryContext';

const propTypes = {
  render: PropTypes.array
};

function RichTextWithDictionary({ render, ...props }) {
  // We cannot set both htmlSerializer and serializeHyperLink (as seen in long console errors), and the latter is ignored if both are present.
  // So we remove here serializeHyperLink to avoid the console errors
  const filteredProps = Object.assign({}, props);
  delete filteredProps.serializeHyperlink;
  return (
    <>
      {/** Sometimes a field in a slice is missing, which leads to noisy console errors */}
      {render && (
        <DictionaryContext.Consumer>
          {(terms) => (
            <PrismicRichText
              field={render}
              linkResolver={linkResolver}
              components={createDictionaryHtmlSerializer(terms)}
              {...filteredProps}
            />
          )}
        </DictionaryContext.Consumer>
      )}
    </>
  );
}

RichTextWithDictionary.propTypes = propTypes;

export default RichTextWithDictionary;
