import React from 'react';
import Tooltip from '../Tooltip';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import RichTextWithDictionary from '../RichTextWithDictionary';

const TitleContainer = styled.div`
  display: flex;
`;

const propTypes = {
  header: PropTypes.object,
  template: PropTypes.string,
  title: PropTypes.string,
  titleHidden: PropTypes.bool,
  tooltip: PropTypes.object
};

const defaultProps = {
  header: null,
  template: '',
  title: '',
  titleHidden: false,
  tooltip: {}
};

function TextBlockTitle({
  header,
  template,
  title,
  titleHidden,
  tooltip,
  type
}) {
  const isBlockTitleVisible =
    !titleHidden &&
    (template !== 'page' ||
      (template === 'page' && type && type.match(/^heading([3-6])$/i)));

  return (
    <>
      {isBlockTitleVisible && (
        <>
          {tooltip && tooltip.length > 0 ? (
            <TitleContainer>
              <RichTextWithDictionary render={header} />
              {tooltip && <Tooltip content={tooltip} triggerValue={title} />}
            </TitleContainer>
          ) : (
            <>
              {header.text && (
                <RichTextWithDictionary render={header.richText} />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

TextBlockTitle.propTypes = propTypes;
TextBlockTitle.defaultProps = defaultProps;

export default TextBlockTitle;
