import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const defaultState = null;

const DictionaryContext = React.createContext(defaultState);
DictionaryContext.displayName = 'DictionaryContext';

const dictionaryQuery = graphql`
  query DictionaryQuery {
    allPrismicDictionaryTerms {
      edges {
        node {
          data {
            terms {
              term_definition {
                richText
              }
              term_name
            }
          }
        }
      }
    }
  }
`;

function DictionaryProvider({ children }) {
  return (
    <StaticQuery
      query={`${dictionaryQuery}`}
      render={(data) => {
        const doc = data.allPrismicDictionaryTerms.edges[0].node;
        return (
          <DictionaryContext.Provider value={doc && doc.data.terms}>
            {children}
          </DictionaryContext.Provider>
        );
      }}
    />
  );
}

export { DictionaryProvider };

export default DictionaryContext;
