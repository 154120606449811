import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PrismicRichText } from '@prismicio/react';

import linkResolver from '../../utils/linkResolver';
import RichTextWithDictionary from '../RichTextWithDictionary';
import BlockStyles from '../BlockStyles';

const TextContainer = styled.div`
  ${(props) =>
    props.numOfColumns === '2 Columns' && 'columns: 250px 2; column-gap: 3rem;'}
`;

function NewsItemText({ data, template }) {
  const { content_block_title, columns, content } = data.primary;

  return (
    <BlockStyles data={data.primary} template={template}>
      <section className='block'>
        {content_block_title.text && (
          <PrismicRichText
            field={content_block_title.richText}
            linkResolver={linkResolver}
          />
        )}
        <TextContainer numOfColumns={columns}>
          <RichTextWithDictionary render={content.richText} />
        </TextContainer>
      </section>
    </BlockStyles>
  );
}

NewsItemText.propTypes = {
  data: PropTypes.object
};
NewsItemText.defaultProps = {
  data: {}
};

export default NewsItemText;
