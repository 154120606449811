import React from 'react';
import first from 'lodash-es/first';
import PropTypes from 'prop-types';
import { ifProp, ifNotProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';
import { PrismicRichText } from '@prismicio/react';

import linkResolver from '../../../utils/linkResolver';
import hyperLinkHelper from '../../../utils/hyperLinkHelper';
import RichTextWithDictionary from '../../RichTextWithDictionary';

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.3125rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    font-size: 1.25rem;
    margin-bottom: 0;
  }

  .color-red {
    color: ${prop('theme.colors.redPrimary')};
  }
`;

const ContestContainer = styled.section`
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 1 0 250px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const ContestCopyContainer = styled.div`
  box-shadow: -0.0625rem 0.0625rem 0.375rem rgba(0, 0, 0, 0.16);

  > :first-child {
    padding: 1rem;
  }

  > :last-child {
    padding: 0 1rem 1rem 1rem;
  }

  ${ifNotProp(
    { label: 'product_contest_standalone_image' },
    css`
      margin-right: 0.5rem;

      @media (max-width: ${prop('theme.breakpoints.sm')}) {
        margin: 0;
        order: 2;
      }

      > * {
        padding: 1rem 1rem 0 1rem;
      }

      h2,
      h3,
      h4 {
        text-align: center;
        color: white;
        background-color: ${prop('theme.colors.redPrimary')};
      }
    `
  )}

  ${ifProp(
    { label: 'product_contest_standalone_image' },
    css`
      order: 2;
      margin-left: 1rem;

      @media (max-width: ${prop('theme.breakpoints.sm')}) {
        margin: 1rem 0 0;
      }

      > * {
        padding: 0 1rem 0;
      }
    `
  )}
`;

const ContestImageAndButtonContainer = styled.div`
  background: center / cover url('${prop('url')}') no-repeat;
  min-height: 335px;
  box-shadow: -0.0625rem 0.0625rem 0.375rem rgba(0, 0, 0, 0.16);


  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    order: 1;
    // Overriding cascade from MainCol
    margin-bottom: 1rem !important;
  }

  @media (max-width: ${prop('theme.breakpoints.md')}) and (max-width: ${prop(
  'theme.breakpoints.sm'
)}) {
    flex: 1 0 300px;
  }

  h3 {
    color: white;
    margin: 3rem 0 10rem 0;
    padding: 1rem;
    text-align: center;
  }

  // Link as button
  a {
    display: block;
    width: 95%;
    text-align: center;
    margin: 0 auto;
    // Overriding cascade from MainCol
    margin-bottom: 1rem !important;
    padding: 0.75rem;
    background-color: ${prop('theme.colors.blackSecondary')};
    border-radius: 1.5rem;
    color: ${prop('theme.colors.white')};
    font-weight: ${prop('theme.fontWeights.regular')};

    &:hover {
      background-color: ${prop('theme.colors.redPrimary')};
      color: ${prop('theme.colors.white')};
      text-decoration: none;
    }
  }
`;

const propTypes = {
  fields: PropTypes.array
};

const defaultProps = {
  fields: []
};

function ContestImageBlock({ field, label }) {
  const {
    image,
    text_block,
    title,
    button_link,
    button_link_text,
    additional_text
  } = field;
  const imageLink = image && image.url;
  const buttonLink = button_link && button_link.url;
  const additionalText = additional_text && additional_text[0].text;

  return (
    <StyledCol xs={12} key={`column-${first(title).text}`}>
      <ContestContainer label={label}>
        <ContestCopyContainer label={label}>
          <RichTextWithDictionary
            render={title}
            serializeHyperlink={hyperLinkHelper}
          />
          <RichTextWithDictionary
            render={text_block}
            serializeHyperlink={hyperLinkHelper}
          />
        </ContestCopyContainer>
        {/** Contest image with a call to action button */}
        {imageLink && label !== 'product_contest_standalone_image' && (
          <ContestImageAndButtonContainer url={imageLink}>
            {additionalText.length > 0 && (
              <PrismicRichText
                field={additional_text.richText}
                linkResolver={linkResolver}
              />
            )}
            {buttonLink && (
              <a
                href={button_link.url}
                target='_blank'
                rel='noreferrer noopener'
              >
                {button_link_text[0].text}
              </a>
            )}
          </ContestImageAndButtonContainer>
        )}
        {/** Standalone contest image */}
        {imageLink && label === 'product_contest_standalone_image' && (
          <div
            style={{
              boxShadow: '-0.0625rem 0.0625rem 0.375rem rgba(0, 0, 0, 0.16)'
            }}
          >
            <img src={imageLink} alt={image.alt} />
          </div>
        )}
      </ContestContainer>
    </StyledCol>
  );
}

ContestImageBlock.propTypes = propTypes;
ContestImageBlock.defaultProps = defaultProps;

export default ContestImageBlock;
