import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';

import ListItem from './styled/ListItem';
import FooterList from './styled/FooterList';

const propTypes = {
  items: PropTypes.array
};

const defaultProps = {
  items: null
};

const Title = styled.div`
  font-weight: ${prop('theme.fontWeights.semiBold')};
  margin-bottom: 0.5rem;
`;

function ItemList({ items, title }) {
  if (!items) {
    return null;
  }

  const itemList = items.map((item, index) => {
    const { item_name, item_link } = item;
    const itemName = item_name ? item_name.text : '';

    const { url: itemUrl } = item_link || {};
    const itemUrlName = item_name.text;
    const linkProps = {
      href: itemUrl,
      target: '_blank',
      rel: 'noopener noreferrer'
    };

    return (
      <ListItem key={itemUrlName}>
        <a {...linkProps}>{itemName}</a>
      </ListItem>
    );
  });

  return (
    <div>
      <FooterList>
        {title && <Title className='title'>{title}</Title>}
        {itemList}
      </FooterList>
    </div>
  );
}

ItemList.propTypes = propTypes;
ItemList.defaultProps = defaultProps;

export default ItemList;
