import React from 'react';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import { PrismicRichText } from '@prismicio/react';
import { Link } from 'gatsby';

import defaultImage from '../../images/smalls-default.png';
import { Thumbnail } from '../NewsComponents/styled/SharedComponents';
import dayjs from '../../utils/dayjs';
import { getYoutubeThumbnailUrl } from '../../utils/functions';
import { YOUTUBE_THUMBNAILS } from '../../utils/constants';

const ArticleItem = styled(Link)`
  border-bottom: 0.0625rem solid ${prop('theme.colors.borderGrey')};
  border-bottom-left-radius: 0.5rem;
  display: flex;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &:hover {
    text-decoration: none;

    h2 {
      color: ${prop('theme.colors.redPrimary')};
    }
  }
`;

const ItemBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ItemDate = styled.p`
  font-size: 1rem;
  font-weight: ${prop('theme.fontWeights.light')};
  line-height: 1.125rem;
  margin-bottom: 0.375rem;
`;

const ItemTitle = styled.div`
  h2 {
    font-size: 1rem;
    font-weight: ${prop('theme.fontWeights.semiBold')};
  }
`;

const propTypes = {
  articles: PropTypes.array
};

const defaultProps = {
  articles: []
};

function RecentArticles({ articles }) {
  if (articles.length === 0) {
    return null;
  }

  return (
    <>
      {articles.map((article, index) => {
        const articleLink = get(article, 'node.url');
        const newsItem = get(article, 'node.data');
        const {
          featured_image,
          featured_video_embed,
          post_title,
          post_date,
          news_item_type
        } = newsItem;
        const isVideoArticle = news_item_type === 'video';
        const videoThumbnailUrl = getYoutubeThumbnailUrl(
          featured_video_embed,
          YOUTUBE_THUMBNAILS.small
        );
        const altText = isVideoArticle
          ? get(post_title, 'text')
          : featured_image.alt;
        const thumbnail = isVideoArticle
          ? videoThumbnailUrl
          : get(
              featured_image,
              'thumbnails.list_item.url',
              get(featured_image, 'url')
            );
        const thumbnailType = thumbnail
          ? isVideoArticle
            ? 'featured_video'
            : 'featured'
          : 'default';

        return (
          <ArticleItem key={index} to={articleLink}>
            {featured_image && (
              <Thumbnail
                alt={altText ? altText : 'placeholder article image'}
                height={88}
                width={88}
                src={thumbnail ? thumbnail : defaultImage}
                $layout='list'
                $type={thumbnailType}
              />
            )}
            <ItemBody>
              {post_date && (
                <ItemDate>{dayjs(post_date).format('MMM D, YYYY')}</ItemDate>
              )}
              {post_title && (
                <ItemTitle>
                  <PrismicRichText field={post_title.richText} />
                </ItemTitle>
              )}
            </ItemBody>
          </ArticleItem>
        );
      })}
    </>
  );
}

RecentArticles.propTypes = propTypes;
RecentArticles.defaultProps = defaultProps;

export default RecentArticles;
