import React from 'react';
import map from 'lodash-es/map';
import PropTypes from 'prop-types';
import { prop } from 'styled-tools';
import styled from 'styled-components';

import StyledTh from './styled/StyledTh';
import HeaderContent from './HeaderContent';
import { getTableTooltips } from '../../utils/functions';

export const StyledThead = styled.thead`
  height: 3rem;
  position: relative;

  display: table-header-group;
  // this is to display the half border on the right side
  ::after {
    content: '';
    position: absolute;
    top: 1.5rem;
    height: 3rem;
    display: block;
    border-left: 1px solid ${prop('theme.colors.borderGrey')};
  }
`;

const propTypes = {
  columns: PropTypes.object,
  head: PropTypes.object,
  headerKeys: PropTypes.array
};

const defaultProps = {
  columns: {},
  head: {},
  headerKeys: []
};

function TableHeader({ columns, head, headerKeys }) {
  let columnWidth = head.column_width;
  if (columnWidth === 'same') {
    const numberOfColumns = Object.keys(columns).filter(
      (key) => columns[key].text
    ).length;
    columnWidth = `${(1 / numberOfColumns) * 100}%`;
  }

  let mappedColumns = map(columns, (column, index) => {
    let triggerValue = 'column-' + Math.floor(Math.random() * 200);
    let tableTooltips = getTableTooltips(column, head, headerKeys);

    // Making sure there are column headers.
    const arrayColumnCheck = column.text ? true : false;
    const stringColumnCheck =
      typeof column === 'string' && column.length > 0 ? true : false;

    if (arrayColumnCheck || stringColumnCheck) {
      return (
        <StyledTh
          key={'column-header-' + index + 1}
          withtooltip={
            tableTooltips && tableTooltips.length > 0 ? 'true' : 'false'
          }
          $columnWidth={columnWidth}
          colSpan={head[`${index}_span`]}
        >
          <HeaderContent
            column={column.richText}
            tableTooltips={tableTooltips}
            triggerValue={triggerValue}
          />
        </StyledTh>
      );
    }
  });

  return (
    <StyledThead>
      <tr>{mappedColumns}</tr>
    </StyledThead>
  );
}

TableHeader.propTypes = propTypes;
TableHeader.defaultProps = defaultProps;

export default TableHeader;
