import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ifNotProp, prop } from 'styled-tools';
import PropTypes from 'prop-types';
import BlockStyles from '../../BlockStyles';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash-es/get';

import { fetchSubscriptions } from '../../../state/actions/subscriberActions';
import copyIcon from '../../../images/icons/copy-icon.svg';
import { Spinner } from 'reactstrap';
import { SUBSCRIPTION_STATUS_ACTIVE } from '../../../utils/constants';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const CopyContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  width: fit-content;
  padding: 16px 24px;
  background-color: initial;
  border: 2px solid ${prop(`theme.colors.borderGreyCommunity`)};
  font-weight: ${prop('theme.fontWeights.medium')};

  ${ifNotProp(
    'disabled',
    css`
      &:hover {
        background-color: ${prop('theme.colors.backgroundGreyLightest')};
      }

      &:active {
        background-color: ${prop('theme.colors.backgroundGreyLight')};
      }
    `
  )}

  img {
    margin-left: 12px;
  }
`;

const CopyMessage = styled.div`
  margin-left: 16px;

  &.display {
    opacity: 1;
  }

  &.fade-out {
    opacity: 0;
    transition: opacity 0.25s ease-out;
  }
`;

const propTypes = { data: PropTypes.object, template: PropTypes.string };

const defaultProps = { data: {}, template: '' };

function CommunityMemberTokenDisplay({ data, template }) {
  const { subscriptions = [] } = useSelector((state) =>
    get(state, 'memberInfo.subscriber', {})
  );
  const identity = useSelector((state) => get(state, 'memberInfo.identity'));
  const userIsAuthenticated = !!identity && Object.keys(identity).length > 0;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  let navigator;
  if (typeof window !== 'undefined') {
    navigator = window.navigator;
  }

  useEffect(() => {
    if (userIsAuthenticated) {
      setIsLoading(true);
      dispatch(fetchSubscriptions(() => setIsLoading(false)));
    }
  }, [userIsAuthenticated]);

  const activeSubscriptionIndex = subscriptions.findIndex(
    (subscription) => subscription.status === SUBSCRIPTION_STATUS_ACTIVE
  );

  const token =
    activeSubscriptionIndex >= 0 &&
    subscriptions[activeSubscriptionIndex].external_subscription_code;

  return (
    <BlockStyles data={data.primary} template={template}>
      {isLoading ? (
        <Spinner />
      ) : (
        <Container>
          <CopyContainer
            disabled={!token}
            onClick={() => {
              navigator.clipboard.writeText(token);
              if (!isCopied) {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2500);
              }
            }}
          >
            <div>{token || 'No token found'}</div>
            <img src={copyIcon} height={24} width={24} />
          </CopyContainer>
          <CopyMessage className={isCopied ? 'display' : 'fade-out'}>
            Copied to clipboard!
          </CopyMessage>
        </Container>
      )}
    </BlockStyles>
  );
}

CommunityMemberTokenDisplay.propTypes = propTypes;
CommunityMemberTokenDisplay.defaultProps = defaultProps;

export default CommunityMemberTokenDisplay;
