import styled from 'styled-components';
import { prop } from 'styled-tools';

const FooterList = styled.ul`
  margin-top: 0.25rem;
  margin-bottom: 0;
  list-style: none;
  padding: 0;
  font-size: 0.875rem;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    text-align: left;
  }
`;

export default FooterList;
