import styled from 'styled-components';

const Spinner = styled.div`
  display: inline-block;
  width: 128px;
  height: 128px;
  vertical-align: text-bottom;
  border: 12px solid black;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.7s linear infinite spinner-border;
`;

export default Spinner;
