import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';

const StyledTh = styled.th`
  color: ${prop('theme.colors.black')};
  height: fit-content;
  font-weight: ${prop('theme.fontWeights.medium')};
  padding: 0.625rem 0.875rem;
  border-radius: 1.5rem;
  background-color: ${prop('theme.colors.backgroundGreyLighter')};
  position: relative;

  width: ${(props) =>
    props.$columnWidth && props.$columnWidth !== 'default'
      ? props.$columnWidth
      : '100%'};

  // this is to display the half border on the left side
  ::after {
    content: '';
    position: absolute;
    top: 1.5rem;
    right: 0;
    height: 3rem;
    border-left: 1px solid ${prop('theme.colors.borderGrey')};
  }

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    padding-left: 1.375rem;
  }

  > * {
    color: ${prop('theme.colors.black')};
  }

  h4 {
    font-size: 0.8125rem;
    font-weight: ${prop('theme.fontWeights.medium')};

    .center-align {
      text-align: center;
      display: block;
      width: 100%;
    }

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      font-size: 1rem;
    }

    ${ifProp(
      { withtooltip: 'true' },
      css`
        display: inline-block;
        margin-bottom: 0.625rem;
        padding-top: 0.625rem;
      `
    )}
  }
`;

export default StyledTh;
