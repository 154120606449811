import React, { Fragment, useEffect, useState } from 'react';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import MainMenuItem, { LinkStyles, StyledNavItem } from '../MainMenuItem';
import { NAV_VISIBILITY, NAV_SPECIAL } from '../../../utils/constants';
import { logout } from '../../../utils/auth';
import SubmenuItem from '../SubmenuItem';

const Divider = styled.span`
  border-left: 1.5px solid #cdcdcd;
  display: none;
  height: 2rem;
  margin: 0 16px;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    display: inline-block;
  }
`;

const SignOut = styled.button`
  ${LinkStyles}

  background: transparent;
  border: none;
`;

const propTypes = {
  menus: PropTypes.array
};

const defaultProps = {
  menus: []
};

function LoggedInMenu({ menus }) {
  const [alwaysItems, setAlwaysItems] = useState([]);

  useEffect(() => {
    const filteredAlwaysItems = menus.filter((menu) => {
      const visibility = get(menu, 'primary.visibility');

      if (
        typeof window !== 'undefined' &&
        window.location.pathname.includes('/community-portal')
      ) {
        return visibility === NAV_VISIBILITY.ALWAYS_VISIBLE;
      } else {
        return (
          visibility === NAV_VISIBILITY.ALWAYS_VISIBLE ||
          visibility === NAV_VISIBILITY.SHOW_EXCEPT_PORTAL
        );
      }
    });

    setAlwaysItems(filteredAlwaysItems);
  }, []);

  const loggedInItems = menus.filter((menu) => {
    return get(menu, 'primary.visibility') === NAV_VISIBILITY.SHOW_IF_LOGGED_IN;
  });

  return (
    <>
      {alwaysItems.map((item, index) => (
        <Fragment key={`always_menu_item_${index}`}>
          {item.slice_type === 'main_menu_item' && <MainMenuItem data={item} />}
          {item.slice_type === 'submenu_item' && <SubmenuItem data={item} />}
        </Fragment>
      ))}
      <Divider />
      {loggedInItems.map((item, index) => {
        const linkText = get(item, 'primary.link_text');
        const specialBehavior = get(item, 'primary.special_behavior');

        return (
          <Fragment key={`logged_in_menu_item_${index}`}>
            {specialBehavior === NAV_SPECIAL.SIGN_OUT && (
              <StyledNavItem
                onClick={() => {
                  logout();
                }}
              >
                <SignOut title={linkText}>{linkText}</SignOut>
              </StyledNavItem>
            )}
            {specialBehavior === 'Profile' && (
              <MainMenuItem
                data={{
                  primary: {
                    link_text: linkText,
                    link_style: 'Button',
                    menu_link: {
                      url:
                        '/community-portal/profile-settings/profile-information',
                      uid: 'profile-information'
                    }
                  }
                }}
              />
            )}
            {item.slice_type === 'main_menu_item' && !specialBehavior && (
              <MainMenuItem data={item} />
            )}
            {item.slice_type === 'submenu_item' && <SubmenuItem data={item} />}
          </Fragment>
        );
      })}
    </>
  );
}

LoggedInMenu.propTypes = propTypes;
LoggedInMenu.defaultProps = defaultProps;

export default LoggedInMenu;
