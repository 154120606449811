import React from 'react';
import get from 'lodash-es/get';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { ifProp, prop } from 'styled-tools';
import styled, { css } from 'styled-components';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import SEO from '../components/seo';
import Layout from '../components/layout';
import MainCol from '../components/PageBody/MainCol';
import NewsHero from '../components/NewsComponents/NewsHero';
import NewsSubnav from '../components/NewsComponents/NewsSubnav';
import LatestArticles from '../components/NewsComponents/LatestArticles';
import PageContainer from '../components/layout-components/PageContainer';
import RelatedArticles from '../components/NewsComponents/RelatedArticles';
import NewsItemPageBody from '../components/PageBody_NewsItem';
import linkResolver from '../utils/linkResolver';
import PriceFeeds from '../components/PriceFeeds';
import { GatsbyIpfFileProvider } from '../context/GatsbyIpfFileContext';
import TwoColumnWrapper from '../components/PageBody/styled/TwoColumnWrapper';

const NewsItemPageContainer = styled(PageContainer)`
  flex-direction: column;
`;

const StyledMainCol = styled(MainCol)`
  display: flex;
  flex-direction: column;

  @media (max-width: ${prop('theme.breakpoints.xxl')}) {
    padding: 0 3.75rem;
  }

  @media (max-width: ${prop('theme.breakpoints.md')}) {
    padding: 0 1.5rem;
  }
`;

const MainContent = styled.div`
  display: flex;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    flex-direction: column;
  }
`;

const ArticleWrapper = styled.article`
  margin-bottom: 6.875rem;
  flex: 1 1 80%;
  max-width: 80%;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin-right: 3rem;
  }

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    margin-top: 1.25rem;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin: 5rem 0 0;
    flex: 1 1 100%;
    max-width: 100%;
  }
`;

const SideColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 20%;
  max-width: 20%;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    align-items: flex-end;
    margin-top: 7.8rem;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    div {
      &:first-child {
        display: none;
      }
    }
  }

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    flex: 1 1 100%;
    max-width: 100%;
  }
`;

const MobilePriceFeedSection = styled.div`
  margin: 2rem 0;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    display: none;
  }
`;

const PageBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;

  ${ifProp(
    '$twoColumns',
    css`
      @media (min-width: ${prop('theme.breakpoints.lg')}) {
        flex-direction: row;
      }
    `
  )}
`;

const TagSection = styled.div`
  display: flex;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 24px;
  padding-bottom: 32px;
  border-bottom: 1px solid ${prop('theme.colors.borderGrey')};
`;

const TagLabel = styled.div`
  font-weight: ${prop('theme.fontWeights.semiBold')};
  color: ${prop('theme.colors.tagLabelGrey')};
  margin-right: 16px;
  height: 100%;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Tag = styled(Link)`
  background-color: ${prop('theme.colors.tagGrey')};
  color: ${prop('theme.colors.white')};
  font-weight: ${prop('theme.fontWeights.bold')};
  padding: 2px 12px 0;
  font-size: 0.875rem;
  border-radius: 8px;
  margin-right: 8px;
  margin-bottom: 8px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: ${prop('theme.colors.greyDarkest')};
    color: ${prop('theme.colors.white')};
    text-decoration: none;
  }
`;

const BackSection = styled.div`
  display: flex;
  margin-bottom: 3.75rem;
`;

const BackArrow = styled.span`
  font-size: 2.5rem;
  line-height: 24px;
  color: ${prop('theme.colors.redPrimary')};
  letter-spacing: 0.02em;
  margin-right: 0.5rem;
  transform: matrix(-1, 0, 0, 1, 0, 0);
`;

const BackLink = styled(Link)`
  color: ${prop('theme.colors.redPrimary')};
  font-size: 1.125rem;
  font-weight: ${prop('theme.fontWeights.regular')};
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;

function NewsItem({ data: queryResult, location }) {
  const latestArticles = get(queryResult, 'latestArticles.edges', []);
  const relatedArticles = get(queryResult, 'relatedArticles.edges', []);
  const tags = queryResult.prismicNewsItem.tags;
  const newsItemPageData = queryResult.prismicNewsItem.data;

  if (!newsItemPageData) {
    return null;
  }

  const { body, layout, news_item_type } = newsItemPageData;
  const { uid: parentUid, type: parentType } = get(
    location,
    'state.parentPage',
    { uid: 'news', type: 'news_landing_page' }
  );

  const filteredRelated = relatedArticles.filter((related) =>
    latestArticles.every((latest) => latest.node.id !== related.node.id)
  );

  return (
    <Layout>
      <GatsbyIpfFileProvider>
        <NewsItemPageContainer>
          <StyledMainCol>
            <MainContent>
              <ArticleWrapper>
                <NewsSubnav />
                <NewsHero primary={newsItemPageData} page='single' />
                {layout ? (
                  <PageBodyContainer $twoColumns>
                    <TwoColumnWrapper>
                      {body && <NewsItemPageBody body={body} twoColumns />}
                    </TwoColumnWrapper>
                  </PageBodyContainer>
                ) : (
                  <PageBodyContainer>
                    <NewsItemPageBody body={body} />
                  </PageBodyContainer>
                )}
                <TagSection>
                  <TagLabel>Tags:</TagLabel>
                  <TagContainer>
                    {tags.map((tag, index) => (
                      <Tag key={`${tag}-${index}`} to={`/search?query=${tag}`}>
                        {tag}
                      </Tag>
                    ))}
                  </TagContainer>
                </TagSection>
              </ArticleWrapper>
              <SideColumn>
                <PriceFeeds title='news-item' />
                {relatedArticles.length > 0 &&
                  news_item_type !== 'News Mention' && (
                    <RelatedArticles relatedArticles={filteredRelated} />
                  )}
              </SideColumn>
            </MainContent>
            <BackSection>
              <BackArrow>→</BackArrow>
              <BackLink to={linkResolver({ type: parentType, uid: parentUid })}>
                Back to News
              </BackLink>
            </BackSection>
          </StyledMainCol>
          <LatestArticles articles={latestArticles} />
          <MobilePriceFeedSection>
            <PriceFeeds title='news-item' />
          </MobilePriceFeedSection>
        </NewsItemPageContainer>
      </GatsbyIpfFileProvider>
    </Layout>
  );
}
export const Head = ({ data: queryResult }) => {
  const pageData = queryResult.prismicNewsItem.data;

  if (!pageData) {
    return null;
  }

  const { seo_title, seo_meta_description, seo_image } = pageData;

  return (
    <SEO
      title={seo_title}
      description={seo_meta_description}
      image={seo_image}
    />
  );
};

export const query = graphql`
  query NewsItemQuery($id: String!, $uid: String!, $category: String) {
    prismicNewsItem(id: { eq: $id }) {
      id
      uid
      tags
      _previewable
      data {
        seo_title
        seo_meta_description
        seo_image {
          url
        }
        layout
        news_item_type
        featured_image {
          alt
          url
          dimensions {
            height
            width
          }
          thumbnails {
            preview {
              url
              dimensions {
                height
                width
              }
            }
            list_item {
              url
              dimensions {
                height
                width
              }
            }
          }
        }
        featured_video_embed {
          html
          embed_url
          title
          provider_name
        }
        post_title {
          text
        }
        author {
          document {
            ... on PrismicAuthor {
              data {
                author_avatar {
                  alt
                  url
                  dimensions {
                    width
                    height
                  }
                }
                author_name
                author_title
              }
            }
          }
        }
        post_author {
          text
        }
        post_date
        post_categories {
          category {
            uid
            type
          }
        }
        body {
          ... on PrismicNewsItemDataBodyText {
            slice_type
            primary {
              content_block_title {
                richText
                text
              }
              columns
              content {
                richText
                text
              }
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
          }
          ... on PrismicNewsItemDataBodyGraphic {
            ...NewsItemBodyGraphicFragment
          }
          ... on PrismicNewsItemDataBodyEmbedVideo {
            slice_type
            primary {
              embed_video {
                html
                embed_url
                title
                provider_name
              }
            }
          }
          ... on PrismicNewsItemDataBodyTable3Cols {
            ...NewsItemTableFragment
          }
          ... on PrismicNewsItemDataBodyFootnote {
            slice_type
            primary {
              footnote {
                text
              }
            }
          }
          ... on PrismicNewsItemDataBodyNewsletterLiteForm {
            slice_type
            primary {
              lead_source
              checkbox_text
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
            }
          }
          ... on PrismicNewsItemDataBodyCtaBlock {
            slice_label
            slice_type
            primary {
              background_image {
                alt
                url
              }
              title {
                richText
                text
              }
              text_block {
                richText
                text
              }
              image_overlay
            }
            items {
              cta_style
              cta_text
              cta_link {
                link_type
                target
                url
                type
                uid
                tags
              }
            }
          }
          ... on PrismicNewsItemDataBodyButtonLink {
            slice_type
            primary {
              block_styles {
                document {
                  ...BlockStylesFragment
                }
              }
              button_text
              button_link {
                uid
                url
                type
                link_type
                tags
              }
              button_style
              font_weight
              min_width
            }
          }
        }
      }
    }
    latestArticles: allPrismicNewsItem(
      filter: { uid: { ne: $uid } }
      sort: { fields: data___post_date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          ...NewsInfo
        }
      }
    }
    relatedArticles: allPrismicNewsItem(
      filter: {
        uid: { ne: $uid }
        data: {
          post_categories: {
            elemMatch: { category: { uid: { eq: $category } } }
          }
        }
      }
      sort: { fields: data___post_date, order: DESC }
      limit: 8
    ) {
      edges {
        node {
          ...NewsInfo
        }
      }
    }
  }
`;

NewsItem.propTypes = {
  data: PropTypes.object
};
NewsItem.defaultProps = {
  data: null
};

export default withPrismicPreview(NewsItem);
