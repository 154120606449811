import get from 'lodash-es/get';

import subscriberApiClient, {
  getErrorMessage
} from '../../utils/subscriberApiClient';
import {
  PRIMARY_PURPOSE,
  PHONE_CONTACT_TYPE,
  RECOVERY_PURPOSE,
  EMAIL_CONTACT_TYPE
} from '../../utils/constants';
import actions from '../actions';
import errorTypes from './errorTypes';

export const fetchContactInformation = () => async (dispatch) => {
  try {
    const response = await subscriberApiClient.get(
      '/subscriber/contact_information'
    );

    dispatch({
      type: actions.SET_CONTACT_INFORMATION,
      payload: formatContactInformationResponse(
        response.data.contact_informations
      )
    });

    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.CONTACT_INFO_FETCH_FAILED
    });
  } catch (err) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.CONTACT_INFO_FETCH_FAILED,
        message: getErrorMessage(err)
      }
    });
  }
};

export const createContactInformation = (
  contactInformation,
  successCallback,
  finishedCallback
) => async (dispatch) => {
  const { phoneNumber, emailAddress } = contactInformation;
  const requestBody = [
    {
      purpose: PRIMARY_PURPOSE,
      information: phoneNumber,
      contact_type: PHONE_CONTACT_TYPE,
      confirmed: false
    }
  ];

  if (emailAddress) {
    requestBody.push({
      purpose: RECOVERY_PURPOSE,
      information: emailAddress,
      contact_type: EMAIL_CONTACT_TYPE,
      confirmed: false
    });
  }

  try {
    const response = await subscriberApiClient.post(
      '/subscriber/contact_information_batch',
      requestBody
    );

    dispatch({
      type: actions.SET_CONTACT_INFORMATION,
      payload: formatContactInformationResponse(
        response.data.map((item) => item.contact_information)
      )
    });
    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.CONTACT_INFO
    });

    successCallback();
  } catch (err) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.CONTACT_INFO,
        message: getErrorMessage(err)
      }
    });
  }

  finishedCallback();
};

export const updateContactInformation = (
  existingContactInformation,
  newContactInformation,
  successCallback,
  finishedCallback
) => async (dispatch) => {
  const { phoneNumber, emailAddress } = newContactInformation;
  const phone = {
    id: get(existingContactInformation, 'phoneNumber.id'),
    purpose: get(existingContactInformation, 'phoneNumber.purpose'),
    information: phoneNumber,
    contact_type: get(existingContactInformation, 'phoneNumber.contact_type'),
    confirmed: get(existingContactInformation, 'phoneNumber.confirmed')
  };
  const requestBody = [phone];

  if (emailAddress) {
    requestBody.push({
      id: get(existingContactInformation, 'emailAddress.id'),
      purpose: get(existingContactInformation, 'emailAddress.purpose'),
      information: emailAddress,
      contact_type: get(
        existingContactInformation,
        'emailAddress.contact_type'
      ),
      confirmed: get(existingContactInformation, 'emailAddress.confirmed')
    });
  }

  try {
    const response = await subscriberApiClient.patch(
      '/subscriber/contact_information_batch',
      requestBody
    );

    dispatch({
      type: actions.SET_CONTACT_INFORMATION,
      payload: formatContactInformationResponse(
        response.data.map((item) => item.contact_information)
      )
    });
    dispatch({
      type: actions.REMOVE_API_ERROR,
      payload: errorTypes.CONTACT_INFO
    });

    successCallback();
  } catch (err) {
    dispatch({
      type: actions.ADD_API_ERROR,
      payload: {
        type: errorTypes.CONTACT_INFO,
        message: getErrorMessage(err)
      }
    });
  }

  finishedCallback();
};

function formatContactInformationResponse(contactInformation) {
  if (!contactInformation) {
    return {};
  }

  return {
    phoneNumber: contactInformation.find(
      (info) =>
        info.contact_type === PHONE_CONTACT_TYPE &&
        info.purpose === PRIMARY_PURPOSE
    ),
    emailAddress: contactInformation.find(
      (info) =>
        info.contact_type === EMAIL_CONTACT_TYPE &&
        info.purpose === RECOVERY_PURPOSE
    )
  };
}
