import React, { useContext } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import PropTypes from 'prop-types';

import RegistrationTextBlock from '../RegistrationTextBlock';
import Alert from '../Alert';
import { PrismicRichText } from '@prismicio/react';
import PersonalInfoForm from '../PersonalInfoForm';
import StepCounter from './StepCounter';
import ContactInfoForm from '../ContactInfoForm';
import AgreementForm from '../AgreementForm';
import ButtonLinkBlock from '../../../ButtonLink/ButtonLinkBlock';
import BlockStyles from '../../../BlockStyles';
import ErrorAlert from './ErrorAlert';
import InvoiceBlock from '../InvoiceDisplay/InvoiceBlock';
import StripeCheckoutButtonBlock from '../StripeCheckoutButton/StripeCheckoutButtonBlock';
import StepContext from '../../../../context/StepContext';
import CommunityMemberTokenDisplay from '../CommunityMemberTokenDisplay';

const Body = styled.div`
  h2 {
    font-weight: ${prop('theme.fontWeights.semiBold')};
    margin-bottom: 24px;
  }
`;

const LinkedAlertContainer = styled.div`
  margin-bottom: 24px;
`;

const propTypes = {
  title: PropTypes.object,
  shouldDisplayTitle: PropTypes.bool,
  shouldDisplayStepCounter: PropTypes.bool,
  linkedAlert: PropTypes.object,
  isLinked: PropTypes.bool,
  body: PropTypes.array
};

const defaultProps = {
  title: {},
  shouldDisplayTitle: true,
  shouldDisplayStepCounter: true,
  linkedAlert: {},
  isLinked: false,
  body: []
};

function RegistrationStepPageBody({
  title,
  shouldDisplayTitle,
  shouldDisplayStepCounter = true,
  linkedAlert,
  isLinked = false,
  body
}) {
  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0);
  }

  const { currentStep } = useContext(StepContext);

  const componentOptions = {
    text_block: RegistrationTextBlock,
    button_link: ButtonLinkBlock,
    alert: Alert,
    personal_info_form: PersonalInfoForm,
    contact_form: ContactInfoForm,
    agreement_form: AgreementForm,
    stripe_checkout_button: StripeCheckoutButtonBlock,
    invoice_display: InvoiceBlock,
    community_member_token: CommunityMemberTokenDisplay
  };

  function getComponent(item, i) {
    const Component = componentOptions[item.slice_type];

    if (!Component) {
      return null;
    }

    return (
      <Component
        key={`${item.slice_type}-${i}`}
        data={item}
        template={'registration'}
      />
    );
  }

  return (
    <Body>
      <BlockStyles template='registration'>
        {shouldDisplayStepCounter && <StepCounter stepName={title.text} />}
        <ErrorAlert />
        {shouldDisplayTitle && title.text && (
          <PrismicRichText field={title.richText} />
        )}
      </BlockStyles>
      {isLinked && currentStep === 0 && (
        <LinkedAlertContainer>
          <Alert data={linkedAlert} />
        </LinkedAlertContainer>
      )}
      <>{body.map((item, i) => getComponent(item, i))}</>
    </Body>
  );
}

RegistrationStepPageBody.propTypes = propTypes;
RegistrationStepPageBody.defaultProps = defaultProps;

export default RegistrationStepPageBody;
